// some translations that may fall under categories lower down are also in here. the old objects were from before there was functionality to
// update translations via the bi app.this object is updated with values from the api

const languageStrings = {
  // english
  en_US: {
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Phone",
    t_next: "Next",
    t_previousQuestion: "Previous question",
    t_nextQuestion: "Next Question",
    t_confirm: "Confirm",
    t_goBack: "Go Back",
    t_videoCall: 'Video Call',

    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesAvailableLabel: "Dates Available",
    t_datesUnavailableLabel: "Dates Unavailable",
    t_noAppointmentConvenient: "No appointment time is convenient",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",

    t_noTimeSlotsAvailableMsg: "No time slots available. Please try a different date.",

    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send text message confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",

    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",

    t_coronavirusText: "COVID-19 NOTE: The health and safety of our employees and our patients is our top priority. Please note that our research facilities are not medical centers or hospitals. We do not see or treat sick patients. Measures are in place, however, to ensure that our facilities are appropriately screening visitors and following important standards for hygiene and proper sterilization.",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online video appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. most  smart phones and laptops have both).",
    t_siteNameTitle: "Site details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Selected protocols:",
    t_emrSelectProtocolsTextDD: "Selected protocol:",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    // rebook
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalContinueText2: "Please select an option below to select a new date and time for this appointment or to cancel the appointment entirely.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_rebookModalNoteCancellations: "Please note that if you wish to reschedule your appointment, it will not be canceled until you confirm the details of your new appointment.",
    t_rescheduleAppointmentButton: 'Reschedule Query',

    // cancellations
    t_cancelAppointment: 'Cancel Appointment',
    t_cancelAppointmentButton: 'Cancel Appointment',
    t_cancelApptConfirmHeader: 'Are you sure you wish to cancel your appointment?',
    t_cancelApptConfirmText: 'You will receive an email confirming your appointment cancellation.',
    t_cancelApptBtn: 'Yes',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: 'Your appointment was successfully cancelled.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'An error occurred while canceling your appointment.',
    t_closeBtn: 'Close',

    // smart site
    t_helpBtn: `Help`,
    t_ssInstruction1: 'Please select a site from the list below and click the "Next" button. You can use the left and right arrows to see more sites.',
    t_ssInstruction2: 'To search for additional sites or for sites in a different location, please enter your preferred location (Address/Zip/City) and distance (search radius), and click on *search icon*.',
    t_ssInstruction3: 'Click on *reset icon* to view your initial list of sites.',
    t_ssSearchInputPlaceholder: "E.g. '1666' or 'Walnut Grove'",

    t_ssDropdownLabel: 'Select distance',
    t_ssDropdownOption: 'Within %0% %unit%',
    t_noSites: 'There are no sites available',
    t_locationInputPlaceholder: 'Enter a location',
    t_ssDropdownDistanceUnit: 'miles',

    t_selectedDateLabel: 'Selected Date:',

    t_ssPopup1: `Now that you have qualified for a study, the next step is to book an appointment with a study clinic!<br>
    <br>
    On this page, you can select a site that is convenient to you. Depending on the selected site, you will be presented with a few different options once you click the “Next” button:<br><br>
    1. If the selected site does not offer online booking, we will reach out to you shortly by phone to book an appointment. <br><br>
    2. If the selected site does offer online booking, you may have the option to book the following types of appointments:<br>`,
    t_ssPopup2: `• On-Demand Video – a virtual, video appointment that will take place immediately.<br><br>
    • Scheduled Video – a virtual, video appointment that you can schedule for the future.<br><br>
    • Scheduled Phone – a virtual, phone appointment that you can schedule for the future.<br><br>
    • On-Site – an in-person appointment at a clinical site that you can schedule for the future.
    <br>`,
    t_ssPopup3: `<strong>What if I want to change my search area?</strong><br><br>
    No problem! Enter an address and distance (search radius) in the search bar, and we will provide a new site list for you to choose from.<br><br>
    <strong>What if no sites are convenient for me?</strong><br><br>
    If you select “No Research Site is Convenient,” we will reach out to you to discuss options and get you scheduled for an appointment. <br><br>
    <strong>What if I am unsure of how to get to my appointment.</strong><br><br>
    If you click the location tile, we will show you driving and transit directions to the site.`,

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1.',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    },

    maintenanceError: {
      header: 'Scheduled Maintenance',
      body: 'Sorry, we are currently down for scheduled maintenance.',
      extra_survey: 'Please provide your contact details by <a href="https://survey.alchemer.com/s3/6902271/Future-Clinical-Trial-Opportunity-Contact-Information-US">clicking here</a> <br/> and we will follow up with you soon about future clinical trials'
    },

    t_appointmentAlreadyBookedHeader: 'Wait! You Already Have An Appointment Scheduled With Us.',
    t_appointmentAlreadyBookedBody: 'We cannot book you for another appointment, because you already have one scheduled with us. <br><br> Please check your phone or search your email inbox for the confirmation details, which we sent previously. <br><br> If you need to make any changes to your existing appointment, please use the contact information contained in that email.',
    t_appointmentAlreadyBookedFooter: 'Thank you for your enthusiasm and interest in doing your part to advance medical research.',
    t_appointmentNotSuitable: "Appointment Not Suitable",
    t_appointmentNotSuitableText: "We're glad the location is convenient for you.",
    t_confirmToContactApptNotSuitable: "We will forward your contact information to the study clinic that you selected so they can contact you.",
    t_vfovCompletionHeader: "<b><i>Great news! You’ve already completed an appointment with our team and should hear <br/> from us again soon.</i></b>",
    t_vfovCompletionBody: "Have you experienced any life changes? Health and lifestyle changes may qualify you for </br> additional clinical research studies. Use the link in your confirmation email to complete an <br/> updated survey to see if you qualify now!",
    t_vfovCompletionFooter: "Thank you for your enthusiasm and interest in advancing clinical research! "
  },
  // ------ english gb
  en_GB: {
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Phone",
    t_next: "Next",
    t_previousQuestion: "Previous question",
    t_nextQuestion: "Next Question",
    t_confirm: "Confirm",
    t_goBack: "Go Back",
    t_videoCall: 'Video Call',

    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesAvailableLabel: "Dates Available",
    t_datesUnavailableLabel: "Dates Unavailable",
    t_noAppointmentConvenient: "No appointment time is convenient",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",

    t_noTimeSlotsAvailableMsg: "No time slots available. Please try a different date.",

    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send text message confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",

    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",

    t_coronavirusText: "COVID-19 NOTE: The health and safety of our employees and our patients is our top priority. Please note that our research facilities are not medical centers or hospitals. We do not see or treat sick patients. Measures are in place, however, to ensure that our facilities are appropriately screening visitors and following important standards for hygiene and proper sterilization.",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Mobile Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online video appointment for LATER",
    t_scheduleInPersonCard: "Schedule face to face visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. Most smart phones and laptops have both)",
    t_siteNameTitle: "Clinic details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Selected protocols:",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    // smart site
    t_helpBtn: `Help`,
    t_ssInstruction1: 'Please select a site from the list below and click the "Next" button. You can use the left and right arrows to see more sites.',
    t_ssInstruction2: 'To search for additional sites or for sites in a different location, please enter your preferred location (Address/Zip/City) and distance (search radius), and click on *search icon*.',
    t_ssInstruction3: 'Click on *reset icon* to view your initial list of sites.',
    t_ssSearchInputPlaceholder: "E.g. '1666' or 'Walnut Grove'",

    t_ssDropdownLabel: 'Select distance',
    t_ssDropdownOption: 'Within %0% %unit%',
    t_noSites: 'There are no sites available',
    t_locationInputPlaceholder: 'Enter a location',

    t_selectedDateLabel: 'Selected Date:',

    t_ssPopup1: `Now that you have qualified for a study, the next step is to book an appointment with a study clinic!<br>
    <br>
    On this page, you can select a site that is convenient to you. Depending on the selected site, you will be presented with a few different options once you click the “Next” button:<br><br>
    1. If the selected site does not offer online booking, we will reach out to you shortly by phone to book an appointment. <br><br>
    2. If the selected site does offer online booking, you may have the option to book the following types of appointments:<br>`,
    t_ssPopup2: `• On-Demand Video – a virtual, video appointment that will take place immediately.<br><br>
    • Scheduled Video – a virtual, video appointment that you can schedule for the future.<br><br>
    • Scheduled Phone – a virtual, phone appointment that you can schedule for the future.<br><br>
    • On-Site – an in-person appointment at a clinical site that you can schedule for the future.
    <br>`,
    t_ssPopup3: `<strong>What if I want to change my search area?</strong><br><br>
    No problem! Enter an address and distance (search radius) in the search bar, and we will provide a new site list for you to choose from.<br><br>
    <strong>What if no sites are convenient for me?</strong><br><br>
    If you select “No Research Site is Convenient,” we will reach out to you to discuss options and get you scheduled for an appointment. <br><br>
    <strong>What if I am unsure of how to get to my appointment.</strong><br><br>
    If you click the location tile, we will show you driving and transit directions to the site.`,

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    },

    maintenanceError: {
      header: 'Scheduled Maintenance',
      body: 'Sorry, we are currently down for scheduled maintenance.'
    },
    t_vfovCompletionHeader: "<b><i>Great news! You’ve already completed an appointment with our team and should hear from us again soon.</i></b>",
    t_vfovCompletionBody: "Have you experienced any life changes? Health and lifestyle changes may qualify you for additional clinical research studies. Use the link in your confirmation email to complete an updated survey to see if you qualify now!",
    t_vfovCompletionFooter: "Thank you for your enthusiasm and interest in advancing clinical research!"
  },
  // ------ english irish
  en_IE: {
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Phone",
    t_next: "Next",
    t_previousQuestion: "Previous question",
    t_nextQuestion: "Next Question",
    t_confirm: "Confirm",
    t_goBack: "Go Back",
    t_videoCall: 'Video Call',

    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesAvailableLabel: "Dates Available",
    t_datesUnavailableLabel: "Dates Unavailable",
    t_noAppointmentConvenient: "No appointment time is convenient",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",

    t_noTimeSlotsAvailableMsg: "No time slots available. Please try a different date.",

    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send text message confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",

    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",

    t_coronavirusText: "COVID-19 NOTE: The health and safety of our employees and our patients is our top priority. Please note that our research facilities are not medical centers or hospitals. We do not see or treat sick patients. Measures are in place, however, to ensure that our facilities are appropriately screening visitors and following important standards for hygiene and proper sterilization.",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Mobile Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online video appointment for LATER",
    t_scheduleInPersonCard: "Schedule face to face visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. Most smart phones and laptops have both)",
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_siteNameTitle: "Clinic details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Selected protocols:",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    }
  },
  // ------ english south africa
  en_ZA: {
    t_coronavirusText: "COVID-19 NOTE: The health and safety of our employees and our patients is our top priority. Please note that our research facilities are not medical centers or hospitals. We do not see or treat sick patients. Measures are in place, however, to ensure that our facilities are appropriately screening visitors and following important standards for hygiene and proper sterilization.",
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Phone",
    t_next: "Next",
    t_previousQuestion: "Previous question",
    t_nextQuestion: "Next Question",
    t_confirm: "Confirm",
    t_goBack: "Go Back",
    t_videoCall: 'Video Call',

    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesAvailableLabel: "Dates Available",
    t_datesUnavailableLabel: "Dates Unavailable",
    t_noAppointmentConvenient: "No appointment time is convenient",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Confirm your details below and we will get in contact with you as soon as possible to find an appointment that works for you.",

    t_noTimeSlotsAvailableMsg: "No time slots available. Please try a different date.",

    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send text message confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",

    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email Address",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call",
    t_optional: " (optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. most smart phones and laptops have both).",
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_siteNameTitle: "Site details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    // rebook
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalContinueText2: "Please select an option below to select a new date and time for this appointment or to cancel the appointment entirely.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_rebookModalNoteCancellations: "Please note that if you wish to reschedule your appointment, it will not be canceled until you confirm the details of your new appointment.",
    t_rescheduleAppointmentButton: 'Reschedule Query',

    // cancellations
    t_cancelAppointment: 'Cancel Appointment',
    t_cancelAppointmentButton: 'Cancel Appointment',
    t_cancelApptConfirmHeader: 'Are you sure you wish to cancel your appointment?',
    t_cancelApptConfirmText: 'You will receive an email confirming your appointment cancellation.',
    t_cancelApptBtn: 'Yes',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: 'Your appointment was successfully cancelled.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'An error occurred while canceling your appointment.',
    t_closeBtn: 'Close',

    // smart site
    t_helpBtn: `Help`,
    t_ssInstruction1: 'Please select a site from the list below and click the "Next" button. You can use the left and right arrows to see more sites.',
    t_ssInstruction2: 'To search for additional sites or for sites in a different location, please enter your preferred location (Address/Zip/City) and distance (search radius), and click on *search icon*.',
    t_ssInstruction3: 'Click on *reset icon* to view your initial list of sites.',
    t_ssSearchInputPlaceholder: "E.g. '1666' or 'Walnut Grove'",

    t_ssDropdownLabel: 'Select distance',
    t_ssDropdownOption: 'Within %0% %unit%',
    t_noSites: 'There are no sites available',
    t_locationInputPlaceholder: 'Enter a location',

    t_selectedDateLabel: 'Selected Date:',

    t_ssPopup1: `Now that you have qualified for a study, the next step is to book an appointment with a study clinic!<br>
    <br>
    On this page, you can select a site that is convenient to you. Depending on the selected site, you will be presented with a few different options once you click the “Next” button:<br><br>
    1. If the selected site does not offer online booking, we will reach out to you shortly by phone to book an appointment. <br><br>
    2. If the selected site does offer online booking, you may have the option to book the following types of appointments:<br>`,
    t_ssPopup2: `• On-Demand Video – a virtual, video appointment that will take place immediately.<br><br>
    • Scheduled Video – a virtual, video appointment that you can schedule for the future.<br><br>
    • Scheduled Phone – a virtual, phone appointment that you can schedule for the future.<br><br>
    • On-Site – an in-person appointment at a clinical site that you can schedule for the future.
    <br>`,
    t_ssPopup3: `<strong>What if I want to change my search area?</strong><br><br>
    No problem! Enter an address and distance (search radius) in the search bar, and we will provide a new site list for you to choose from.<br><br>
    <strong>What if no sites are convenient for me?</strong><br><br>
    If you select “No Research Site is Convenient,” we will reach out to you to discuss options and get you scheduled for an appointment. <br><br>
    <strong>What if I am unsure of how to get to my appointment.</strong><br><br>
    If you click the location tile, we will show you driving and transit directions to the site.`,

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1.',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    },

    maintenanceError: {
      header: 'Scheduled Maintenance',
      body: 'Sorry, we are currently down for scheduled maintenance.'
    },
    t_vfovCompletionHeader: "<b><i>Great news! You’ve already completed an appointment with our team and should hear from us again soon.</i></b>",
    t_vfovCompletionBody: "Have you experienced any life changes? Health and lifestyle changes may qualify you for additional clinical research studies. Use the link in your confirmation email to complete an updated survey to see if you qualify now!",
    t_vfovCompletionFooter: "Thank you for your enthusiasm and interest in advancing clinical research!"
  },
  // ------ english new zealand
  en_NZ: {
    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesUnavailableLabel: "Dates unavailable",
    t_datesAvailableLabel: "Dates available",
    t_next: "Next",
    t_noAppointmentConvenient: "No appointment time is convenient",
    t_previousQuestion: "Previous question",
    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",
    t_phone: "Phone",
    t_confirm: "Confirm",
    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_date: "Date",
    t_time: "Time",
    t_address: "[Address]",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send SMS confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_nextQuestion: "Next question",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",
    t_goBack: "Go back",
    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. most  smart phones and laptops have both).",
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_siteNameTitle: "Site details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call."
  },
  // ------ english canadian
  en_CA: {
    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesUnavailableLabel: "Dates unavailable",
    t_datesAvailableLabel: "Dates available",
    t_next: "Next",
    t_noAppointmentConvenient: "No appointment time is convenient",
    t_previousQuestion: "Previous question",
    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",
    t_phone: "Phone",
    t_confirm: "Confirm",
    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send SMS confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_nextQuestion: "Next question",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",
    t_goBack: "Go back",
    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. most  smart phones and laptops have both).",
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_siteNameTitle: "Site details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1.',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    },
    t_vfovCompletionHeader: "<b><i>Great news! You’ve already completed an appointment with our team and should hear from us again soon.</i></b>",
    t_vfovCompletionBody: "Have you experienced any life changes? Health and lifestyle changes may qualify you for additional clinical research studies. Use the link in your confirmation email to complete an updated survey to see if you qualify now!",
    t_vfovCompletionFooter: "Thank you for your enthusiasm and interest in advancing clinical research!"
  },
  // ------ english australian
  en_AU: {
    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesUnavailableLabel: "Dates unavailable",
    t_datesAvailableLabel: "Dates available",
    t_next: "Next",
    t_noAppointmentConvenient: "No appointment time is convenient",
    t_previousQuestion: "Previous question",
    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",
    t_phone: "Phone",
    t_confirm: "Confirm",
    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_date: "Date",
    t_time: "Time:",
    t_address: "[Address]",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send SMS confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_nextQuestion: "Next question",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",
    t_goBack: "Go back",
    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you!",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call."
  },
  // swedish
  sv_SE: {
    t_date: "Datum",
    t_time: "Tid",
    t_address: "Adress",
    t_tel: "Tel",
    t_email: "E -post",
    t_phone: "Telefon",
    t_next: "Nästa",
    t_previousQuestion: "Föregående fråga",
    t_nextQuestion: "Nästa fråga",
    t_confirm: "Bekräfta",
    t_goBack: "Gå tillbaka",
    t_videoCall: 'Videosamtal',

    t_selectDate: "Nästa steg är att få dig schemalagd för en tid hos studieläkarteamet. Under detta besök kommer studieläkarteamet att ytterligare diskutera studiekraven och besvara eventuella frågor du kan ha.",
    t_selectDateVideo: "Välj datum och tid som du vill schemalägga ditt online-videomöte för:",
    t_datesUnavailableLabel: "Otillgängliga datum",
    t_datesAvailableLabel: "Tillgängliga datum",
    t_next: "Nästa",
    t_noAppointmentConvenient: "Ingen mötestid är lämplig",
    t_previousQuestion: "Föregående fråga",
    t_noAppointmentsAtTheMoment: "Tyvärr finns det inga tillgängliga eller lämpliga tider på den här platsen just nu.",
    t_confirmToContact: "Bekräfta dina uppgifter nedan och vi kommer att kontakta dig så snart som möjligt.",
    t_phone: "Telefon",
    t_confirm: "Bekräfta",
    t_confirmCorrect: "Bekräfta att följande uppgifter överensstämmer innan du bokar en tid:",
    t_date: "Datum",
    t_time: "Tid",
    t_address: "Adress",
    t_sendEmailConfirmation: "Skicka e-postbekräftelse",
    t_sendSMSConfirmation: "Skicka SMS-bekräftelse",
    t_bookAppointment: "Boka tid",
    t_appointmentBooked: "Din tid är bokad!",
    t_nextQuestion: "Nästa fråga",
    t_somebodyElseBooked: "Tyvärr, någon bokade precis den här tiden. Försök att välja ett annat datum eller en annan tid.",
    t_goBack: "Gå tillbaka",
    t_bookingConfirmed: "Hej. Du är bekräftad för en tid en %date% kl. %time% med %facility%.",
    t_haveQuestionsContact: "Vid eventuella frågor eller om du behöver ändra tid, kontakta studieläkarens kontor direkt.",
    t_lookForward: "Vi ser fram emot att träffa dig!",
    t_regards: "Med vänliga hälsningar",
    t_unsubscribe: "Avanmäl från dessa notiser",
    t_bookingConfirmedContactOffice: "Tid bekräftad med %facility% den %date% kl. %time%. Ring kontoret på nummer %phone% med eventuella frågor",
    t_thankYouEndPage: "Tack så mycket",

    t_coronavirusText: "COVID-19 OBS: Hälsa och säkerhet för våra anställda och våra patienter är vår högsta prioritet. Observera att våra forskningsanläggningar inte är medicinska centra eller sjukhus. Vi ser eller behandlar inte sjuka patienter. Åtgärder är på plats, dock för att säkerställa att våra anläggningar på lämpligt sätt screenar besökare och följer viktiga standarder för hygien och korrekt sterilisering.",
    t_selectAppointmentType: "Hur vill du göra ditt möte?",
    t_appointmentTypeTitle: "Typ av möte",
    t_emailTitle: "E-post",
    t_mobilePhoneTitle: "Mobiltelefon",
    t_videNowCard: "Starta ditt online videomöte <br> NU",
    t_scheduleVideoCard: "Schemalägg ditt onlinevideoavtal för SENARE",
    t_scheduleInPersonCard: "Schemalägg personligt besök",
    t_videoNotSuitable: "Video möte inte lämpligt",
    t_confirmVideoInfo: "Ditt onlinevideoavtal kommer att äga rum på det datum och den tid som valts med studieläkarens team. Efter att du har bokat ditt möte får du ett bekräftelsemeddelande som innehåller en länk. När ditt möte ska starta, klicka på länken för att automatiskt starta videosamtalet.",
    t_videoModalText: "Bra! Vi behöver bara bekräfta följande detaljer innan du startar ditt videosamtal:",
    t_optional: "(Valfritt)",
    t_selectDateVideoSubtext: "För att kunna genomföra videomötet måste du ha tillgång till en enhet med en kamera och / eller mikrofon (t.ex. de flesta smarta telefoner och bärbara datorer har båda).",
    t_rebookModalIntro: "Du har redan ett bekräftat möte för denna studie:",
    t_rebookModalContinueText: "Om du fortsätter kommer du att kunna välja ett nytt datum och tid för det här mötet.",
    t_rebookModalNote: "Observera att ditt nuvarande möte inte kommer att avbrytas förrän du har bekräftat detaljerna för ditt nya möte.",
    t_siteNameTitle: "Webbplatsinformation",
    t_videNowNotConvenientIntro: "Om det nu inte passar dig:",
    t_videNowButton: "START NU",
    t_scheduleVideoButton: "SCHEMA VIDEOSAMTAL",
    t_scheduleInPersonButton: "SCHEMA BESÖK",
    t_schedulePhoneCallCard: "Schemalägg telefonsamtal",
    t_schedulePhoneCallButton: "SCHEMA TELEFONSAMTAL",
    t_emrSelectProtocolsText: "Valda protokoll:",
    t_selectDatePhone: "Vänligen välj datum och tid som du vill planera din telefonbokning:",
    t_selectDatePhoneSubtext: "För att kunna genomföra telefonbokningen behöver du tillgång till en telefon på ditt valda datum.",
    t_confirmPhoneInfo: "Ditt telefonsamtal kommer att äga rum på tid och datum som valts av platsens klinikerteam. Se till att du är tillgänglig med en bra telefonsignal för att ta emot samtalet.",

    // rebook
    t_rebookModalIntro: "Du har redan en bekräftad tid för denna studie:",
    t_rebookModalContinueText: "Om du fortsätter kommer du att kunna välja ett nytt datum och en ny tid för detta möte.",
    t_rebookModalContinueText2: "Välj ett alternativ nedan för att välja ett nytt datum och en ny tid för denna tid eller för att avboka mötet helt.",
    t_rebookModalNote: "Observera att ditt nuvarande möte inte kommer att avbrytas förrän du har bekräftat detaljerna för ditt nya möte.",
    t_rebookModalNoteCancellations: "Observera att om du vill boka om ditt möte kommer det inte att avbrytas förrän du har bekräftat detaljerna för ditt nya möte.",
    t_rescheduleAppointmentButton: 'Omplanera fråga',

    // cancellations
    t_cancelAppointment: 'Avboka möte',
    t_cancelAppointmentButton: 'Avbryt möte',
    t_cancelApptConfirmHeader: 'Är du säker på att du vill avboka ditt möte?',
    t_cancelApptConfirmText: 'Du kommer att få ett e -postmeddelande som bekräftar din avbokning.',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nej',
    t_apptCancelledSuccessHeader: 'Framgång',
    t_apptCancelledSuccessText: "Ditt möte avbröts.",
    t_apptCancelledErrorHeader: 'Fel',
    t_apptCancelledErrorText: "Ett fel uppstod när du avbokade ditt möte.",
    t_closeBtn: 'Stäng',

    // smart site
    t_helpBtn: `Hjälp`,
    t_ssInstruction1: 'Välj en webbplats från listan nedan och klicka på knappen "Nästa". Du kan använda vänster och höger pilar för att se fler webbplatser. ',
    t_ssInstruction2: 'För att söka efter ytterligare webbplatser eller efter webbplatser på en annan plats, ange önskad plats (adress/postnummer/stad) och avstånd (sökradie) och klicka på *search icon*.',
    t_ssInstruction3: 'Klicka på *reset icon* för att se din första lista med webbplatser.',
    t_ssSearchInputPlaceholder: "Exempelvis '1666' eller 'Walnut Grove'",

    t_ssDropdownLabel: 'Välj avstånd',
    t_ssDropdownOption: "Inom %0% %unit%",
    t_noSites: 'Det finns inga webbplatser tillgängliga',
    t_locationInputPlaceholder: 'Ange en plats',

    t_selectedDateLabel: 'Datum valt:',

    t_ssPopup1: `Nu när du har kvalificerat dig för en studie är nästa steg att boka tid hos en studieklinik! <br>
    <br>
    På den här sidan kan du välja en webbplats som passar dig. Beroende på den valda webbplatsen kommer du att få några olika alternativ när du klickar på knappen "Nästa": <br> <br>
    1. Om den valda webbplatsen inte erbjuder online -bokning kommer vi att kontakta dig inom kort för att boka tid. <br> <br>
    2. Om den valda webbplatsen erbjuder online -bokning kan du ha möjlighet att boka följande typer av möten: <br> `,
    t_ssPopup2: `• Video på begäran-ett virtuellt videomöte som kommer att äga rum omedelbart. <br> <br>
    • Schemalagd video - ett virtuellt videomöte som du kan schemalägga för framtiden. <br> <br>
    • Schemalagd telefon - ett virtuellt telefontid som du kan schemalägga för framtiden. <br> <br>
    • På plats-ett personligt möte på en klinisk plats som du kan schemalägga för framtiden.
    <br> `,
    t_ssPopup3: `<strong> Vad händer om jag vill ändra mitt sökområde? </strong> <br> <br>
    Inga problem! Ange en adress och avstånd (sökradie) i sökfältet så tillhandahåller vi en ny sajtlista att välja mellan. <br> <br>
    <strong> Tänk om inga webbplatser passar mig? </strong> <br> <br>
    Om du väljer "Ingen forskningsplats är bekväm" kommer vi att kontakta dig för att diskutera alternativ och få dig inbokad för ett möte. <br> <br>
    <strong> Vad händer om jag är osäker på hur jag kommer till mitt möte. </strong> <br> <br>
    Om du klickar på platsbrickan visar vi dig vägbeskrivning och vägbeskrivning till webbplatsen.`,

    revision_changed: {
      revision_changed_1: 'Frågor inom just denna undersökning har ändrats sedan du startade den. Därför måste du starta om från fråga 1.',
      revision_changed_2: 'Ber om ursäkt för eventuella besvär.',
      ctaText: "Starta nytt frågeformulär."
    },

    maintenanceError: {
      header: 'Schemalagt underhåll',
      body: 'Tyvärr, vi har för närvarande väntat på planerat underhåll.'
    },
    t_appointmentAlreadyBookedHeader: 'Vänta! Du har redan bokat ett möte med oss.',
    t_appointmentAlreadyBookedBody: 'Vi kan inte boka dig för ett nytt möte, eftersom du redan har ett inbokat med oss. <br><br> Kontrollera din telefon eller sök i din e-postinkorg efter bekräftelseinformationen som vi skickade tidigare. <br><br> Om du behöver göra några ändringar i ditt befintliga möte, använd kontaktinformationen i det e-postmeddelandet.',
    t_appointmentAlreadyBookedFooter: "Tack för din entusiasm och intresse att göra din del för att främja medicinsk forskning."
  },
  // spanish
  es_ES: {
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_tel: "Tel",
    t_email: "Correo electrónico",
    t_phone: "Teléfono",
    t_next: "Siguiente",
    t_previousQuestion: "Pregunta anterior",
    t_nextQuestion: "Siguiente pregunta",
    t_confirm: "Confirmar",
    t_goBack: "Regresar",
    t_videoCall: 'Videollamada',

    t_selectDate: "El siguiente paso es programar una cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio le informará con más detalle sobre los requisitos del estudio y responderá a cualquier pregunta que le pueda surgir.",
    t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
    t_datesUnavailableLabel: "Fechas no disponibles",
    t_datesAvailableLabel: "Fechas disponibles",
    t_next: "Siguiente",
    t_noAppointmentConvenient: "No me conviene la hora de la cita",
    t_previousQuestion: "Pregunta anterior",
    t_noAppointmentsAtTheMoment: "Lamentablemente, no disponemos de citas disponibles o convenientes en este centro en este momento.",
    t_confirmToContact: "Confirme sus datos a continuación para que nos pongamos en contacto con usted lo antes posible.",
    t_phone: "N.º de teléfono",
    t_confirm: "Confirmar",
    t_confirmCorrect: "Confirme que los datos indicados a continuación son correctos antes de reservar su cita:",
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
    t_sendSMSConfirmation: "Enviar confirmación por SMS",
    t_bookAppointment: "Reservar la cita",
    t_appointmentBooked: "¡Ha reservado su cita con éxito!",
    t_nextQuestion: "Siguiente pregunta",
    t_somebodyElseBooked: "Lo sentimos, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",
    t_goBack: "Atrás",
    t_bookingConfirmed: "Hola. Confirmamos que tiene una cita programada para el día %date% a las %time% en %facility%.",
    t_haveQuestionsContact: "Póngase en contacto directamente con el consultorio del médico del estudio si tiene alguna pregunta o necesita cambiar esta cita.",
    t_lookForward: "¡Esperamos verle pronto!",
    t_regards: "Saludos cordiales",
    t_unsubscribe: "Cancelar suscripción a estas notificaciones",
    t_bookingConfirmedContactOffice: "Cita confirmada para el día %date% a las %time% en %facility%. Llame al número de teléfono %phone% de la consulta si tiene alguna pregunta.",
    t_thankYouEndPage: "Gracias",
    t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
    t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
    t_appointmentTypeTitle: "Tipo de cita",
    t_emailTitle: "Correo electrónico",
    t_mobilePhoneTitle: "Teléfono móvil",
    t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
    t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
    t_scheduleInPersonCard: "Programar visita en persona",
    t_videoNotSuitable: "La cita de video no es adecuada",
    t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
    t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
    t_optional: "(Opcional)",
    t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
    t_siteNameTitle: "Detalles del sitio",
    t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
    t_videNowButton: "EMPEZAR AHORA",
    t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
    t_scheduleInPersonButton: "PROGRAMAR VISITA",
    t_schedulePhoneCallCard: "Programar llamada telefónica",
    t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
    t_emrSelectProtocolsText: "Protocolos seleccionados:",
    t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
    t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
    t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

    // rebook
    t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
    t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
    t_rebookModalContinueText2: "Seleccione una opción a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo",
    t_rebookModalNote: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rescheduleAppointmentButton: 'Reprogramar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar cita',
    t_cancelAppointmentButton: 'Cancelar cita',
    t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
    t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
    t_cancelApptBtn: 'Sí',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Éxito',
    t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
    t_closeBtn: 'Cerrar',

    // smart site
    t_helpBtn: `Ayuda`,
    t_ssInstruction1: 'Por favor, seleccione un sitio de la lista a continuación y haga clic en el botón "Siguiente". Puede utilizar las flechas izquierda y derecha para ver más sitios. ',
    t_ssInstruction2: 'Para buscar sitios adicionales o sitios en una ubicación diferente, ingrese su ubicación preferida (Dirección / Código Postal / Ciudad) y distancia (radio de búsqueda), y haga clic en *search icon*.',
    t_ssInstruction3: 'Haga clic en *reset icon* para ver su lista inicial de sitios.',
    t_ssSearchInputPlaceholder: "Por ejemplo, '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleccionar distancia',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'No hay sitios disponibles',
    t_locationInputPlaceholder: 'Ingrese una ubicación',

    t_selectedDateLabel: 'Fecha seleccionada:',

    t_ssPopup1: `¡Ahora que ha calificado para un estudio, el siguiente paso es reservar una cita con una clínica de estudio! <br>
    <br>
    En esta página, puede seleccionar un sitio que sea conveniente para usted. Dependiendo del sitio seleccionado, se le presentarán algunas opciones diferentes una vez que haga clic en el botón "Siguiente": <br> <br>
    1. Si el sitio seleccionado no ofrece reservas en línea, nos comunicaremos con usted en breve por teléfono para reservar una cita. <br> <br>
    2. Si el sitio seleccionado ofrece reservas en línea, es posible que tenga la opción de reservar los siguientes tipos de citas: <br> `,
    t_ssPopup2: `• Video a pedido: una cita de video virtual que se llevará a cabo de inmediato. <br> <br>
    • Video programado: una cita de video virtual que puede programar para el futuro. <br> <br>
    • Teléfono programado: una cita telefónica virtual que puede programar para el futuro. <br> <br>
    • En el sitio: una cita en persona en un sitio clínico que puede programar para el futuro.
    <br> `,
    t_ssPopup3: `<strong> ¿Qué pasa si quiero cambiar mi área de búsqueda? </strong> <br> <br>
    ¡No hay problema! Ingrese una dirección y una distancia (radio de búsqueda) en la barra de búsqueda, y le proporcionaremos una nueva lista de sitios para que elija. <br> <br>
    <strong> ¿Qué pasa si ningún sitio me resulta conveniente? </strong> <br> <br>
    Si selecciona "Ningún sitio de investigación es conveniente", nos comunicaremos con usted para analizar las opciones y programar una cita. <br> <br>
    <strong> ¿Qué sucede si no estoy seguro de cómo llegar a mi cita? </strong> <br> <br>
    Si hace clic en el mosaico de ubicación, le mostraremos las direcciones de conducción y tránsito al sitio.`,

    revision_changed: {
      revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
      revision_changed_2: 'Disculpas por las molestias.',
      ctaText: "Iniciar nuevo cuestionario",
    },

    MaintenanceError: {
      header: 'Mantenimiento programado',
      body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado'
    },

    t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
    t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
    t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica',
    t_vfovCompletionHeader: "<i>¡Buenas noticias! Ya ha completado una cita con nuestro equipo y debería escuchar de <br/> nosotros nuevamente pronto.</i>",
    t_vfovCompletionBody: "¿Ha experimentado algún cambio en su vida? Los cambios en la salud y el estilo de vida pueden hacer que califique para <br/> estudios adicionales de investigación clínica. Use el enlace en su correo de confirmación para completar una <br/> encuesta actualizada y ver si califica ahora.",
    t_vfovCompletionFooter: "¡Gracias por su entusiasmo e interés en avanzar en la investigación clínica!"
  },
  // ------ mexico
  es_MX: {
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_tel: "Tel",
    t_email: "Correo electrónico",
    t_phone: "Teléfono",
    t_next: "Siguiente",
    t_previousQuestion: "Pregunta anterior",
    t_nextQuestion: "Siguiente pregunta",
    t_confirm: "Confirmar",
    t_goBack: "Regresar",
    t_videoCall: 'Videollamada',

    t_selectDate: "El siguiente paso es programar una cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio le informará con más detalle sobre los requisitos del estudio y responderá a las preguntas que pueda tener.",
    t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
    t_datesUnavailableLabel: "Fechas no disponibles",
    t_datesAvailableLabel: "Fechas disponibles",
    t_next: "Siguiente",
    t_noAppointmentConvenient: "No me conviene ninguna de las fechas y horas para la cita",
    t_previousQuestion: "Pregunta anterior",
    t_noAppointmentsAtTheMoment: "Lamentablemente, no disponemos de citas disponibles o convenientes en este centro en este momento.",
    t_confirmToContact: "Confirme sus datos a continuación para que nos pongamos en contacto con usted lo antes posible.",
    t_phone: "N.º de teléfono",
    t_confirm: "Confirmar",
    t_confirmCorrect: "Confirme que los datos indicados a continuación son correctos antes de reservar su cita:",
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
    t_sendSMSConfirmation: "Enviar confirmación por SMS",
    t_bookAppointment: "Reservar la cita",
    t_appointmentBooked: "¡Ha reservado su cita con éxito!",
    t_nextQuestion: "Siguiente pregunta",
    t_somebodyElseBooked: "Lo sentimos, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",
    t_goBack: "Atrás",
    t_bookingConfirmed: "Hola. Confirmamos que tiene una cita programada para el día %date% a las %time% en %facility%.",
    t_haveQuestionsContact: "Póngase en contacto directamente con el consultorio del médico del estudio si tiene alguna pregunta o necesita cambiar esta cita.",
    t_lookForward: "¡Esperamos verle pronto!",
    t_regards: "Saludos cordiales",
    t_unsubscribe: "Cancelar suscripción a estas notificaciones",
    t_bookingConfirmedContactOffice: "Cita confirmada en %facility%. para el día %date% a las %time%. Llame al número de teléfono %phone% de la consulta si tiene alguna pregunta.",
    t_thankYouEndPage: "Gracias",
    t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
    t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
    t_appointmentTypeTitle: "Tipo de cita",
    t_emailTitle: "Correo electrónico",
    t_mobilePhoneTitle: "Teléfono móvil",
    t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
    t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
    t_scheduleInPersonCard: "Programar visita en persona",
    t_videoNotSuitable: "La cita de video no es adecuada",
    t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
    t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
    t_optional: "(Opcional)",
    t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
    t_siteNameTitle: "Detalles del sitio",
    t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
    t_videNowButton: "EMPEZAR AHORA",
    t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
    t_scheduleInPersonButton: "PROGRAMAR VISITA",
    t_schedulePhoneCallCard: "Programar llamada telefónica",
    t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
    t_emrSelectProtocolsText: "Protocolos seleccionados:",
    t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
    t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
    t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

    // rebook
    t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
    t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
    t_rebookModalContinueText2: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rebookModalContinueText2: `Por favor, seleccione una de las opciones a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo`,
    t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rescheduleAppointmentButton: 'Reprogramar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar cita',
    t_cancelAppointmentButton: 'Cancelar cita',
    t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
    t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
    t_cancelApptBtn: 'Sí',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Éxito',
    t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
    t_closeBtn: 'Cerrar',

    // smart site
    t_helpBtn: `Ayuda`,
    t_ssInstruction1: 'Por favor, seleccione un sitio de la lista a continuación y haga clic en el botón "Siguiente". Puede utilizar las flechas izquierda y derecha para ver más sitios. ',
    t_ssInstruction2: 'Para buscar sitios adicionales o sitios en una ubicación diferente, ingrese su ubicación preferida (Dirección / Código Postal / Ciudad) y distancia (radio de búsqueda), y haga clic en *search icon*.',
    t_ssInstruction3: 'Haga clic en *reset icon* para ver su lista inicial de sitios.',
    t_ssSearchInputPlaceholder: "Por ejemplo, '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleccionar distancia',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'No hay sitios disponibles',
    t_locationInputPlaceholder: 'Ingrese una ubicación',

    t_selectedDateLabel: 'Fecha seleccionada:',

    t_ssPopup1: `¡Ahora que ha calificado para un estudio, el siguiente paso es reservar una cita con una clínica de estudio! <br>
    <br>
    En esta página, puede seleccionar un sitio que sea conveniente para usted. Dependiendo del sitio seleccionado, se le presentarán algunas opciones diferentes una vez que haga clic en el botón "Siguiente": <br> <br>
    1. Si el sitio seleccionado no ofrece reservas en línea, nos comunicaremos con usted en breve por teléfono para reservar una cita. <br> <br>
    2. Si el sitio seleccionado ofrece reservas en línea, es posible que tenga la opción de reservar los siguientes tipos de citas: <br> `,
    t_ssPopup2: `• Video a pedido: una cita de video virtual que se llevará a cabo de inmediato. <br> <br>
    • Video programado: una cita de video virtual que puede programar para el futuro. <br> <br>
    • Teléfono programado: una cita telefónica virtual que puede programar para el futuro. <br> <br>
    • En el sitio: una cita en persona en un sitio clínico que puede programar para el futuro.
    <br> `,
    t_ssPopup3: `<strong> ¿Qué pasa si quiero cambiar mi área de búsqueda? </strong> <br> <br>
    ¡No hay problema! Ingrese una dirección y una distancia (radio de búsqueda) en la barra de búsqueda, y le proporcionaremos una nueva lista de sitios para que elija. <br> <br>
    <strong> ¿Qué pasa si ningún sitio me resulta conveniente? </strong> <br> <br>
    Si selecciona "Ningún sitio de investigación es conveniente", nos comunicaremos con usted para analizar las opciones y programar una cita. <br> <br>
    <strong> ¿Qué sucede si no estoy seguro de cómo llegar a mi cita? </strong> <br> <br>
    Si hace clic en el mosaico de ubicación, le mostraremos las direcciones de conducción y tránsito al sitio.`,

    revision_changed: {
      revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
      revision_changed_2: 'Disculpas por las molestias.',
      ctaText: "Iniciar nuevo cuestionario",
    },

    MaintenanceError: {
      header: 'Mantenimiento programado',
      body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado'
    },

    t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
    t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
    t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica',
    t_vfovCompletionHeader: "<i>¡Buenas noticias! Ya ha completado una cita con nuestro equipo y debería saber de <br/> nosotros nuevamente pronto.</i>",
    t_vfovCompletionBody: "¿Ha experimentado algún cambio en su vida? Los cambios en la salud y el estilo de vida pueden calificarlo para <br/> estudios adicionales de investigación clínica. Use el enlace en su correo de confirmación para completar una <br/> encuesta actualizada y ver si califica ahora.",
    t_vfovCompletionFooter: "¡Gracias por su entusiasmo e interés en avanzar en la investigación clínica!"
  },
  es_US: {
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_tel: "Tel",
    t_email: "Correo electrónico",
    t_phone: "Teléfono",
    t_next: "Siguiente",
    t_previousQuestion: "Pregunta anterior",
    t_nextQuestion: "Siguiente pregunta",
    t_confirm: "Confirmar",
    t_goBack: "Regresar",
    t_videoCall: 'Videollamada',

    t_selectDate: "El siguiente paso es programar una cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio le informará con más detalle sobre los requisitos del estudio y responderá a las preguntas que pueda tener.",
    t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
    t_datesUnavailableLabel: "Fechas no disponibles",
    t_datesAvailableLabel: "Fechas disponibles",
    t_next: "Siguiente",
    t_noAppointmentConvenient: "No me conviene ninguna de las fechas y horas para la cita",
    t_previousQuestion: "Pregunta anterior",
    t_noAppointmentsAtTheMoment: "Lamentablemente, no disponemos de citas disponibles o convenientes en este centro en este momento.",
    t_confirmToContact: "Confirme sus datos a continuación para que nos pongamos en contacto con usted lo antes posible.",
    t_phone: "N.º de teléfono",
    t_confirm: "Confirmar",
    t_confirmCorrect: "Confirme que los datos indicados a continuación son correctos antes de reservar su cita:",
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
    t_sendSMSConfirmation: "Enviar confirmación por SMS",
    t_bookAppointment: "Reservar la cita",
    t_appointmentBooked: "¡Ha reservado su cita con éxito!",
    t_nextQuestion: "Siguiente pregunta",
    t_somebodyElseBooked: "Lo sentimos, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",
    t_goBack: "Atrás",
    t_bookingConfirmed: "Hola. Confirmamos que tiene una cita programada para el día %date% a las %time% en %facility%.",
    t_haveQuestionsContact: "Póngase en contacto directamente con el consultorio del médico del estudio si tiene alguna pregunta o necesita cambiar esta cita.",
    t_lookForward: "¡Esperamos verle pronto!",
    t_regards: "Saludos cordiales",
    t_unsubscribe: "Cancelar suscripción a estas notificaciones",
    t_bookingConfirmedContactOffice: "Cita confirmada en %facility%. para el día %date% a las %time%. Llame al número de teléfono %phone% de la consulta si tiene alguna pregunta.",
    t_thankYouEndPage: "Gracias",
    t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
    t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
    t_appointmentTypeTitle: "Tipo de cita",
    t_emailTitle: "Correo electrónico",
    t_mobilePhoneTitle: "Teléfono móvil",
    t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
    t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
    t_scheduleInPersonCard: "Programar visita en persona",
    t_videoNotSuitable: "La cita de video no es adecuada",
    t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
    t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
    t_optional: "(Opcional)",
    t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
    t_siteNameTitle: "Detalles del sitio",
    t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
    t_videNowButton: "EMPEZAR AHORA",
    t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
    t_scheduleInPersonButton: "PROGRAMAR VISITA",
    t_schedulePhoneCallCard: "Programar llamada telefónica",
    t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
    t_emrSelectProtocolsText: "Protocolos seleccionados:",
    t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
    t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
    t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

    // rebook
    t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
    t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
    t_rebookModalContinueText2: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rebookModalContinueText2: `Por favor, seleccione una de las opciones a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo`,
    t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rescheduleAppointmentButton: 'Reprogramar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar cita',
    t_cancelAppointmentButton: 'Cancelar cita',
    t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
    t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
    t_cancelApptBtn: 'sí',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Éxito',
    t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
    t_closeBtn: 'Cerrar',

    // smart site
    t_helpBtn: `Ayuda`,
    t_ssInstruction1: 'Seleccione un centro de la siguiente lista y haga clic en el botón “Siguiente”. Puede utilizar las flechas izquierda y derecha para ver más centros.',
    t_ssInstruction2: 'Para buscar más centros o centros en otra ubicación, introduzca su ubicación preferida (dirección/código postal/ciudad), distancia (radio de búsqueda) y haga clic en “Buscar”. ',
    t_ssInstruction3: 'Haga clic en el botón “Reiniciar” para ver su lista inicial de centros.',
    t_ssSearchInputPlaceholder: "Por ejemplo, '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleccionar distancia',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'No hay sitios disponibles',
    t_locationInputPlaceholder: 'Ingrese una ubicación',
    t_ssDropdownDistanceUnit: 'millas',

    t_selectedDateLabel: 'Fecha seleccionada:',

    t_ssPopup1: `¡Ahora que ha calificado para un estudio, el siguiente paso es reservar una cita con una clínica de estudio! <br>
    <br>
    En esta página, puede seleccionar un sitio que sea conveniente para usted. Dependiendo del sitio seleccionado, se le presentarán algunas opciones diferentes una vez que haga clic en el botón "Siguiente": <br> <br>
    1. Si el sitio seleccionado no ofrece reservas en línea, nos comunicaremos con usted en breve por teléfono para reservar una cita. <br> <br>
    2. Si el sitio seleccionado ofrece reservas en línea, es posible que tenga la opción de reservar los siguientes tipos de citas: <br> `,
    t_ssPopup2: `• Video a pedido: una cita de video virtual que se llevará a cabo de inmediato. <br> <br>
    • Video programado: una cita de video virtual que puede programar para el futuro. <br> <br>
    • Teléfono programado: una cita telefónica virtual que puede programar para el futuro. <br> <br>
    • En el sitio: una cita en persona en un sitio clínico que puede programar para el futuro.
    <br> `,
    t_ssPopup3: `<strong> ¿Qué pasa si quiero cambiar mi área de búsqueda? </strong> <br> <br>
    ¡No hay problema! Ingrese una dirección y una distancia (radio de búsqueda) en la barra de búsqueda, y le proporcionaremos una nueva lista de sitios para que elija. <br> <br>
    <strong> ¿Qué pasa si ningún sitio me resulta conveniente? </strong> <br> <br>
    Si selecciona "Ningún sitio de investigación es conveniente", nos comunicaremos con usted para analizar las opciones y programar una cita. <br> <br>
    <strong> ¿Qué sucede si no estoy seguro de cómo llegar a mi cita? </strong> <br> <br>
    Si hace clic en el mosaico de ubicación, le mostraremos las direcciones de conducción y tránsito al sitio.`,

    revision_changed: {
      revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
      revision_changed_2: 'Disculpas por las molestias.',
      ctaText: "Iniciar nuevo cuestionario",
    },

    MaintenanceError: {
      header: 'Mantenimiento programado',
      body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado'
    },

    t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
    t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
    t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica',
    t_vfovCompletionHeader: "<b><i>¡Excelentes novedades! Ya ha completado una cita con nuestro equipo y pronto volverá a tener noticias nuestras.</i></b>",
    t_vfovCompletionBody: "¿Ha tenido algún cambio en su vida? Es posible que los cambios en su salud y estilo de vida le permitan calificar para estudios de investigación clínica adicionales. Utilice el enlace que figura en su correo electrónico de confirmación para completar una encuesta actualizada y comprobar si califica ahora mismo.",
    t_vfovCompletionFooter: "Gracias por su entusiasmo e interés en hacer avanzar la investigación clínica."
  },
  // ------ columbia
  es_CO: {
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_tel: "Tel",
    t_email: "Correo electrónico",
    t_phone: "Teléfono",
    t_next: "Siguiente",
    t_previousQuestion: "Pregunta anterior",
    t_nextQuestion: "Siguiente pregunta",
    t_confirm: "Confirmar",
    t_goBack: "Regresar",
    t_videoCall: 'Videollamada',

    t_selectDate: "El siguiente paso es programar una cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio le informará con más detalle sobre los requisitos del estudio y responderá cualquier pregunta que le pueda surgir.",
    t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
    t_datesUnavailableLabel: "Fechas no disponibles",
    t_datesAvailableLabel: "Fechas disponibles",
    t_next: "Siguiente",
    t_noAppointmentConvenient: "No me conviene la hora de la cita",
    t_previousQuestion: "Pregunta anterior",
    t_noAppointmentsAtTheMoment: "Lamentablemente, no disponemos de citas disponibles o convenientes en este centro en este momento.",
    t_confirmToContact: "Confirme sus datos a continuación para que nos pongamos en contacto con usted lo antes posible.",
    t_phone: "Teléfono",
    t_confirm: "Confirmar",
    t_confirmCorrect: "Confirme que los datos indicados a continuación son correctos antes de reservar su cita:",
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
    t_sendSMSConfirmation: "Enviar confirmación por SMS",
    t_bookAppointment: "Reservar la cita",
    t_appointmentBooked: "¡Ha reservado su cita con éxito!",
    t_nextQuestion: "Siguiente pregunta",
    t_somebodyElseBooked: "Lo sentimos, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",
    t_goBack: "Atrás",
    t_bookingConfirmed: "Hola. Confirmamos que tiene una cita programada para el %date% a las %time% en %facility%.",
    t_haveQuestionsContact: "Póngase en contacto directamente con el consultorio del médico del estudio si tiene alguna pregunta o necesita cambiar esta cita.",
    t_lookForward: "¡Esperamos verle pronto!",
    t_regards: "Saludos cordiales",
    t_unsubscribe: "Cancelar suscripción a estas alertas",
    t_bookingConfirmedContactOffice: "Cita confirmada para el %date% a las %time% en %facility%. Llame al consultorio al número de teléfono %phone% si tiene alguna pregunta.",
    t_thankYouEndPage: "Gracias",
    t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
    t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
    t_appointmentTypeTitle: "Tipo de cita",
    t_emailTitle: "Correo electrónico",
    t_mobilePhoneTitle: "Teléfono móvil",
    t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
    t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
    t_scheduleInPersonCard: "Programar visita en persona",
    t_videoNotSuitable: "La cita de video no es adecuada",
    t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
    t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
    t_optional: "(Opcional)",
    t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
    t_siteNameTitle: "Detalles del sitio",
    t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
    t_videNowButton: "EMPEZAR AHORA",
    t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
    t_scheduleInPersonButton: "PROGRAMAR VISITA",
    t_schedulePhoneCallCard: "Programar llamada telefónica",
    t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
    t_emrSelectProtocolsText: "Protocolos seleccionados:",
    t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
    t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
    t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

    // rebook
    t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
    t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
    t_rebookModalContinueText2: `Por favor, seleccione una de las opciones a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo`,
    t_rebookModalNote: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rescheduleAppointmentButton: 'Reprogramar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar cita',
    t_cancelAppointmentButton: 'Cancelar cita',
    t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
    t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
    t_cancelApptBtn: 'Sí',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Éxito',
    t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
    t_closeBtn: 'Cerrar',

    // smart site
    t_helpBtn: `Ayuda`,
    t_ssInstruction1: 'Por favor, seleccione un sitio de la lista a continuación y haga clic en el botón "Siguiente". Puede utilizar las flechas izquierda y derecha para ver más sitios. ',
    t_ssInstruction2: 'Para buscar sitios adicionales o sitios en una ubicación diferente, ingrese su ubicación preferida (Dirección / Código Postal / Ciudad) y distancia (radio de búsqueda), y haga clic en *search icon*.',
    t_ssInstruction3: 'Haga clic en *reset icon* para ver su lista inicial de sitios.',
    t_ssSearchInputPlaceholder: "Por ejemplo, '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleccionar distancia',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'No hay sitios disponibles',
    t_locationInputPlaceholder: 'Ingrese una ubicación',

    t_selectedDateLabel: 'Fecha seleccionada:',

    t_ssPopup1: `¡Ahora que ha calificado para un estudio, el siguiente paso es reservar una cita con una clínica de estudio! <br>
    <br>
    En esta página, puede seleccionar un sitio que sea conveniente para usted. Dependiendo del sitio seleccionado, se le presentarán algunas opciones diferentes una vez que haga clic en el botón "Siguiente": <br> <br>
    1. Si el sitio seleccionado no ofrece reservas en línea, nos comunicaremos con usted en breve por teléfono para reservar una cita. <br> <br>
    2. Si el sitio seleccionado ofrece reservas en línea, es posible que tenga la opción de reservar los siguientes tipos de citas: <br> `,
    t_ssPopup2: `• Video a pedido: una cita de video virtual que se llevará a cabo de inmediato. <br> <br>
    • Video programado: una cita de video virtual que puede programar para el futuro. <br> <br>
    • Teléfono programado: una cita telefónica virtual que puede programar para el futuro. <br> <br>
    • En el sitio: una cita en persona en un sitio clínico que puede programar para el futuro.
    <br> `,
    t_ssPopup3: `<strong> ¿Qué pasa si quiero cambiar mi área de búsqueda? </strong> <br> <br>
    ¡No hay problema! Ingrese una dirección y una distancia (radio de búsqueda) en la barra de búsqueda, y le proporcionaremos una nueva lista de sitios para que elija. <br> <br>
    <strong> ¿Qué pasa si ningún sitio me resulta conveniente? </strong> <br> <br>
    Si selecciona "Ningún sitio de investigación es conveniente", nos comunicaremos con usted para analizar las opciones y programar una cita. <br> <br>
    <strong> ¿Qué sucede si no estoy seguro de cómo llegar a mi cita? </strong> <br> <br>
    Si hace clic en el mosaico de ubicación, le mostraremos las direcciones de conducción y tránsito al sitio.`,

    revision_changed: {
      revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
      revision_changed_2: 'Disculpas por las molestias.',
      ctaText: "Iniciar nuevo cuestionario",
    },

    MaintenanceError: {
      header: 'Mantenimiento programado',
      body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado'
    },

    t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
    t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
    t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica'
  },
  // ------ argentina
  es_AR: {
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_tel: "Tel",
    t_email: "Correo electrónico",
    t_phone: "Teléfono",
    t_next: "Siguiente",
    t_previousQuestion: "Pregunta anterior",
    t_nextQuestion: "Siguiente pregunta",
    t_confirm: "Confirmar",
    t_goBack: "Regresar",
    t_videoCall: 'Videollamada',

    t_selectDate: "El siguiente paso es programarle una cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio analizará en mayor detalle los requisitos del estudio y responderá a cualquier pregunta que usted pueda tener.",
    t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
    t_datesUnavailableLabel: "Fechas no disponibles",
    t_datesAvailableLabel: "Fechas disponibles",
    t_next: "Siguiente",
    t_noAppointmentConvenient: "No hay una hora de cita conveniente",
    t_previousQuestion: "Pregunta anterior",
    t_noAppointmentsAtTheMoment: "Desafortunadamente, no hay citas disponibles o convenientes en este centro en este momento.",
    t_confirmToContact: "Confirme sus datos a continuación y nos pondremos en contacto con usted lo antes posible.",
    t_phone: "N.º de teléfono",
    t_confirm: "Confirmar",
    t_confirmCorrect: "Confirme que los datos indicados a continuación son correctos antes de reservar su cita:",
    t_date: "Fecha",
    t_time: "Hora",
    t_address: "Dirección",
    t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
    t_sendSMSConfirmation: "Enviar confirmación por SMS",
    t_bookAppointment: "Reservar la cita",
    t_appointmentBooked: "¡Ha reservado su cita con éxito!",
    t_nextQuestion: "Siguiente pregunta",
    t_somebodyElseBooked: "Lo sentimos, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",
    t_goBack: "Atrás",
    t_bookingConfirmed: "Hola. Usted tiene una cita confirmada para el día %date% a las %time% en %facility%.",
    t_haveQuestionsContact: "Si tiene alguna pregunta no necesita cambiar esta cita, póngase en contacto directamente con el consultorio del médico del estudio.",
    t_lookForward: "¡Esperamos verlo pronto!",
    t_regards: "Saludos cordiales",
    t_unsubscribe: "Desuscribirse de estas notificaciones",
    t_bookingConfirmedContactOffice: "Cita confirmada para el día %date% a las %time% en %facility%. Llame al consultorio al %phone% si tiene alguna pregunta.",
    t_thankYouEndPage: "Gracias",
    t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
    t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
    t_appointmentTypeTitle: "Tipo de cita",
    t_emailTitle: "Correo electrónico",
    t_mobilePhoneTitle: "Teléfono móvil",
    t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
    t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
    t_scheduleInPersonCard: "Programar visita en persona",
    t_videoNotSuitable: "La cita de video no es adecuada",
    t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
    t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
    t_optional: "(Opcional)",
    t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
    t_siteNameTitle: "Detalles del sitio",
    t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
    t_videNowButton: "EMPEZAR AHORA",
    t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
    t_scheduleInPersonButton: "PROGRAMAR VISITA",
    t_schedulePhoneCallCard: "Programar llamada telefónica",
    t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
    t_emrSelectProtocolsText: "Protocolos seleccionados:",
    t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
    t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
    t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

    // rebook
    t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
    t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
    t_rebookModalContinueText2: `Por favor, seleccione una de las opciones a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo`,
    t_rebookModalNote: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
    t_rescheduleAppointmentButton: 'Reprogramar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar cita',
    t_cancelAppointmentButton: 'Cancelar cita',
    t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
    t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
    t_cancelApptBtn: 'Sí',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Éxito',
    t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
    t_closeBtn: 'Cerrar',

    // smart site
    t_helpBtn: `Ayuda`,
    t_ssInstruction1: 'Por favor, seleccione un sitio de la lista a continuación y haga clic en el botón "Siguiente". Puede utilizar las flechas izquierda y derecha para ver más sitios. ',
    t_ssInstruction2: 'Para buscar sitios adicionales o sitios en una ubicación diferente, ingrese su ubicación preferida (Dirección / Código Postal / Ciudad) y distancia (radio de búsqueda), y haga clic en *search icon*.',
    t_ssInstruction3: 'Haga clic en *reset icon* para ver su lista inicial de sitios.',
    t_ssSearchInputPlaceholder: "Por ejemplo, '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleccionar distancia',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'No hay sitios disponibles',
    t_locationInputPlaceholder: 'Ingrese una ubicación',

    t_selectedDateLabel: 'Fecha seleccionada:',

    t_ssPopup1: `¡Ahora que ha calificado para un estudio, el siguiente paso es reservar una cita con una clínica de estudio! <br>
    <br>
    En esta página, puede seleccionar un sitio que sea conveniente para usted. Dependiendo del sitio seleccionado, se le presentarán algunas opciones diferentes una vez que haga clic en el botón "Siguiente": <br> <br>
    1. Si el sitio seleccionado no ofrece reservas en línea, nos comunicaremos con usted en breve por teléfono para reservar una cita. <br> <br>
    2. Si el sitio seleccionado ofrece reservas en línea, es posible que tenga la opción de reservar los siguientes tipos de citas: <br> `,
    t_ssPopup2: `• Video a pedido: una cita de video virtual que se llevará a cabo de inmediato. <br> <br>
    • Video programado: una cita de video virtual que puede programar para el futuro. <br> <br>
    • Teléfono programado: una cita telefónica virtual que puede programar para el futuro. <br> <br>
    • En el sitio: una cita en persona en un sitio clínico que puede programar para el futuro.
    <br> `,
    t_ssPopup3: `<strong> ¿Qué pasa si quiero cambiar mi área de búsqueda? </strong> <br> <br>
    ¡No hay problema! Ingrese una dirección y una distancia (radio de búsqueda) en la barra de búsqueda, y le proporcionaremos una nueva lista de sitios para que elija. <br> <br>
    <strong> ¿Qué pasa si ningún sitio me resulta conveniente? </strong> <br> <br>
    Si selecciona "Ningún sitio de investigación es conveniente", nos comunicaremos con usted para analizar las opciones y programar una cita. <br> <br>
    <strong> ¿Qué sucede si no estoy seguro de cómo llegar a mi cita? </strong> <br> <br>
    Si hace clic en el mosaico de ubicación, le mostraremos las direcciones de conducción y tránsito al sitio.`,

    revision_changed: {
      revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
      revision_changed_2: 'Disculpas por las molestias.',
      ctaText: "Iniciar nuevo cuestionario",
    },

    MaintenanceError: {
      header: 'Mantenimiento programado',
      body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado'
    },

    t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
    t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
    t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica'
  },

//spanish Chile
es_CL: {
  t_date: "Fecha",
  t_time: "Hora",
  t_address: "Dirección",
  t_tel: "Tel",
  t_email: "Correo electrónico",
  t_phone: "Teléfono",
  t_next: "Siguiente",
  t_previousQuestion: "Pregunta anterior",
  t_nextQuestion: "Próxima pregunta",
  t_confirm: "Confirmar",
  t_goBack: "Volver",
  t_videoCall: 'Videollamada',

  t_selectDate: "El próximo paso es la programación de su cita con el equipo del médico del estudio. Durante esta visita, el equipo del médico del estudio conversará con usted más detalles de los requerimientos del estudio y contestará sus preguntas.",
  t_selectDateVideo: "Seleccione la fecha y hora para la que desea programar su cita de video en línea:",
  t_datesAvailableLabel: "Fechas disponibles",
  t_datesUnavailableLabel: "Fechas no disponibles",
  t_noAppointmentConvenient: "No hay una hora para cita conveniente",

  t_noAppointmentsAtTheMoment: "Lamentablemente, no disponemos de citas disponibles o convenientes en este centro en este momento.",
  t_confirmToContact: "Confirme su detalles a continuación y nos pondremos en contacto con usted lo antes posible.",

  t_noTimeSlotsAvailableMsg: " No hay franjas horarias disponibles. Por favor intente con una fecha diferente.",

  t_confirmCorrect: "Confirme que los siguientes detalles son correctos antes de reservar su cita.",
  t_sendEmailConfirmation: "Enviar confirmación por correo electrónico",
  t_sendSMSConfirmation: "Enviar confirmación por SMS",
  t_bookAppointment: "Reservar cita",
  t_appointmentBooked: "¡Su cita se ha reservado con éxito!",
  t_somebodyElseBooked: "Disculpe, otra persona acaba de reservar esta hora de cita. Intente seleccionar otra fecha u hora.",

  t_bookingConfirmed: "Hola, Ha confirmado su cita el %date% a las %time% en %facility%.",
  t_haveQuestionsContact: "Si tiene alguna pregunta o tiene que cambiar su cita, póngase en contacto directamente con el consultorio del médico del estudio.",
  t_lookForward: "¡Esperamos verlo pronto!",
  t_regards: "Saludos cordiales",
  t_unsubscribe: "Cancelar la suscripción a estas alertas",
  t_bookingConfirmedContactOffice: "Cita confirmada en %facility% el %date% a las %time%. Llame al consultorio al teléfono %phone% si tiene preguntas",
  t_thankYouEndPage: "Gracias",
  t_coronavirusText: "COVID-19 NOTA: La salud y la seguridad de nuestros empleados y nuestros pacientes es nuestra máxima prioridad. Tenga en cuenta que nuestras instalaciones de investigación no son centros médicos u hospitales. No vemos ni tratamos a pacientes enfermos. Se han adoptado medidas, sin embargo, para asegurarnos de que nuestras instalaciones estén evaluando adecuadamente a los visitantes y sigan estándares importantes de higiene y esterilización adecuada. ",
  t_selectAppointmentType: "¿Cómo le gustaría realizar su cita?",
  t_appointmentTypeTitle: "Tipo de cita",
  t_emailTitle: "Correo electrónico",
  t_mobilePhoneTitle: "Teléfono móvil",
  t_videNowCard: "Inicie su cita de video en línea <br> AHORA",
  t_scheduleVideoCard: "Programe su cita de video en línea para más tarde",
  t_scheduleInPersonCard: "Programar visita en persona",
  t_videoNotSuitable: "La cita de video no es adecuada",
  t_confirmVideoInfo: "Su cita por video en línea se llevará a cabo en la fecha y hora seleccionadas con el equipo del médico del estudio. Después de reservar su cita, recibirá un correo electrónico de confirmación que contiene un enlace. Cuando su cita debe comenzar, haga clic en el enlace para iniciar automáticamente la videollamada. ",
  t_videoModalText: "¡Genial! Solo necesitamos que confirmes los siguientes detalles antes de iniciar tu videollamada:",
  t_optional: "(Opcional)",
  t_selectDateVideoSubtext: "Para realizar la cita de video, necesitará acceso a un dispositivo con cámara y / o micrófono (por ejemplo, la mayoría de los teléfonos inteligentes y computadoras portátiles tienen ambos).",
  t_siteNameTitle: "Detalles del sitio",
  t_videNowNotConvenientIntro: "Si ahora no es conveniente para usted:",
  t_videNowButton: "EMPEZAR AHORA",
  t_scheduleVideoButton: "PROGRAMAR VIDEO LLAMADA",
  t_scheduleInPersonButton: "PROGRAMAR VISITA",
  t_schedulePhoneCallCard: "Programar llamada telefónica",
  t_schedulePhoneCallButton: "PROGRAMAR LLAMADA",
  t_emrSelectProtocolsText: "Protocolos seleccionados:",
  t_selectDatePhone: "Seleccione la fecha y la hora en que le gustaría programar su cita telefónica:",
  t_selectDatePhoneSubtext: "Para poder realizar la cita telefónica, necesitará acceso a un teléfono en la fecha elegida.",
  t_confirmPhoneInfo: "Su cita telefónica se llevará a cabo en la fecha y hora seleccionadas con el equipo médico del sitio. Asegúrese de estar disponible con una buena señal telefónica para recibir la llamada.",

  // rebook
  t_rebookModalIntro: "Ya tiene una cita confirmada para este estudio:",
  t_rebookModalContinueText: "Si continúa, podrá seleccionar una nueva fecha y hora para esta cita.",
  t_rebookModalContinueText2: "Seleccione una opción a continuación para seleccionar una nueva fecha y hora para esta cita o para cancelar la cita por completo",
  t_rebookModalNote: "Tenga en cuenta que su cita actual no se cancelará hasta que confirme los detalles de su nueva cita",
  t_rebookModalNoteCancellations: "Tenga en cuenta que si desea reprogramar su cita, no se cancelará hasta que confirme los detalles de su nueva cita",
  t_rescheduleAppointmentButton: 'Reprogramar consulta',

  // cancellations
  t_cancelAppointment: 'Cancelar cita',
  t_cancelAppointmentButton: 'Cancelar cita',
  t_cancelApptConfirmHeader: '¿Está seguro de que desea cancelar su cita?',
  t_cancelApptConfirmText: 'Recibirá un correo electrónico confirmando la cancelación de su cita.',
  t_cancelApptBtn: 'Sí',
  t_cancelBackBtn: 'No',
  t_apptCancelledSuccessHeader: 'Éxito',
  t_apptCancelledSuccessText: 'Su cita fue cancelada con éxito.',
  t_apptCancelledErrorHeader: 'Error',
  t_apptCancelledErrorText: 'Ocurrió un error al cancelar su cita.',
  t_closeBtn: 'Cerrar',

  // smart site
  t_helpBtn: `Help`,
  t_ssInstruction1: 'Seleccione un sitio de la siguiente lista y haga clic en el botón “Siguiente”. Puede utilizar las flechas izquierda y derecha para ver más sitios.',
  t_ssInstruction2: 'Para buscar más sitios o sitios en otra ubicación, introduzca su ubicación preferida (dirección/código postal/ciudad), distancia (radio de búsqueda) y haga clic en “Buscar”.',
  t_ssInstruction3: 'Haga clic en el botón “Reiniciar” para ver su lista inicial de sitios.',

  t_ssDropdownLabel: 'Seleccionar distancia',
  t_ssDropdownOption: 'Dentro de %0% %unit%',
  t_noSites: 'No hay sitios disponibles',
  t_locationInputPlaceholder: 'Ingrese una ubicación.',

  t_selectedDateLabel: 'Fecha seleccionada:',

  t_ssPopup1: `Ahora que cumple con los requisitos para participar en un estudio, el próximo paso es pedir una cita en la clínica del estudio.<br>
  <br>
  En esta página, puede elegir el sitio que le resulte más cómodo. Según el sitio que seleccione, se le mostrarán diferentes opciones cuando haga clic en el botón “Siguiente”.<br><br>
  1. Si el sitio que seleccionó no permite hacer reservas en línea, lo contactaremos por teléfono en breve para reservar una cita. <br><br>
  2. Si el sitio  que seleccionó permite hacer reservas en línea, podrá reservar los siguientes tipos de citas:<br>`,
  t_ssPopup2: `Video a demanda: una cita virtual por video que se realizará inmediatamente.<br><br>
  • Video programado: una cita virtual por video que puede programar para otro momento.<br><br>
  • Llamada programada: una cita virtual por teléfono que puede programar para otro momento.<br><br>
  • En el sitio: una cita en persona en el sitio clínico que puede programar para otro momento.
  <br>`,
  t_ssPopup3: `<strong>¿Puedo cambiar mi área de búsqueda?</strong><br><br>
  No hay problema. Ingrese una dirección y una distancia (radio de búsqueda) en el cuadro de búsqueda y le proporcionaremos una nueva lista de sitios para que elija.<br><br>
  <strong>¿Qué puedo hacer si ninguno de los sitios me resulta cómodo?</strong><br><br>
  Si selecciona “No me resulta cómodo ningún sitio”, lo contactaremos para ofrecerle opciones y reservar una cita. <br><br>
  <strong>¿Qué puedo hacer si no sé cómo llegar a mi cita?</strong><br><br>
  Si hace clic en el ícono que aparecen en el mapa, le mostraremos las indicaciones para llegar al sitio en coche y en transporte público.`,

  revision_changed: {
  revision_changed_1: 'Las preguntas de esta encuesta en particular se han modificado desde que la inició. Por lo tanto, deberá volver a comenzar desde la Pregunta 1. ',
  revision_changed_2: 'Disculpas por las molestias.',
  ctaText: "Iniciar nuevo cuestionario",      
  },

  maintenanceError: {
    header: 'Mantenimiento programado',
    body: 'Lo sentimos, actualmente estamos fuera de servicio por mantenimiento programado',
    extra_survey: 'Proporcione sus datos de contacto haciendo clic aquí <a href="https://survey.alchemer.com/s3/6902271/Future-Clinical-Trial-Opportunity-Contact-Information-US">clicking here</a> <br/> y pronto nos comunicaremos con usted sobre futuros ensayos clínicos '
  },

  t_appointmentAlreadyBookedHeader: '¡Espera! Ya Tienes Una Cita Programada Con Nosotros.',
  t_appointmentAlreadyBookedBody: 'No podemos programarlo para otra cita porque ya tiene una programada con nosotros. <br><br> Verifique su teléfono o busque en su bandeja de entrada de correo electrónico los detalles de confirmación, que le enviamos anteriormente. <br><br> Si necesita realizar algún cambio en su cita actual, utilice la información de contacto que se encuentra en ese correo electrónico.',
  t_appointmentAlreadyBookedFooter: 'Gracias por su entusiasmo e interés en hacer su parte para avanzar en la investigación médica',
  t_vfovCompletionHeader: "<i>¡Grandes noticias! Ya ha completado una cita con nuestro equipo y debería saber <br/> de nosotros nuevamente pronto.</i>",
  t_vfovCompletionBody: "¿Ha experimentado algún cambio en su vida? Los cambios en la salud y el estilo de vida pueden calificarlo para <br/> estudios adicionales de investigación clínica. Use el enlace en su correo de confirmación para completar una <br/> encuesta actualizada y ver si califica ahora.",
  t_vfovCompletionFooter: "¡Gracias por su entusiasmo e interés en avanzar en la investigación clínica!"
},


  ru_UA: {
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_tel: 'Тел',
    t_email: "Электронная почта",
    t_phone: "Телефон",
    t_next: "Далее",
    t_previousQuestion: 'Предыдущий вопрос',
    t_nextQuestion: 'Следующий вопрос',
    t_confirm: 'Подтвердить',
    t_goBack: "Вернуться",
    t_videoCall: 'Видеозвонок',

    t_selectDate: 'Следующий шаг - назначить вам встречу с командой врача-исследователя. Во время этого визита команда врача-исследователя дополнительно обсудит требования к исследованию и ответит на любые ваши вопросы.',
    t_selectDateVideo: "Выберите дату и время, когда вы хотите назначить онлайн-видеовстречу на:",
    t_datesAvailableLabel: "Доступные даты",
    t_datesUnavailableLabel: "Даты недоступны",
    t_noAppointmentConvenient: "Удобное время без записи",

    t_noAppointmentsAtTheMoment: 'Член группы по обучению клиники свяжется с вами, чтобы назначить встречу, которая лучше всего подходит для вас.',
    t_confirmToContact: "Подтвердите свою информацию ниже, и мы свяжемся с вами в ближайшее время.",

    t_noTimeSlotsAvailableMsg: "Нет доступных временных интервалов. Попробуйте другую дату.",

    t_confirmCorrect: "Пожалуйста, подтвердите правильность следующих данных перед записью на прием:",
    t_sendEmailConfirmation: "Отправить подтверждение по электронной почте",
    t_sendSMSConfirmation: "Подтвердить отправку текстового сообщения",
    t_bookAppointment: "Записаться на прием",
    t_appointmentBooked: "Ваша встреча успешно забронирована!",
    t_somebodyElseBooked: "Извините, кто-то еще только что забронировал эту встречу. Попробуйте выбрать другую дату или время.",

    t_bookingConfirmed: "Привет. Вас подтвердили на встречу% date% в% time% с% объекта%.",
    t_haveQuestionsContact: 'Если у вас есть какие-либо вопросы или вам нужно изменить время приема, пожалуйста, обращайтесь напрямую в кабинет врача-исследователя.',
    t_lookForward: 'Ждем вас!',
    t_regards: "С уважением",
    t_unsubscribe: "Вы успешно отписались",
    t_bookingConfirmedContactOffice: 'Назначение подтверждено% объекта% на% date% at% time%. С любыми вопросами обращайтесь в офис% phone%',
    t_thankYouEndPage: "Спасибо",

    t_coronavirusText: 'COVID-19 ПРИМЕЧАНИЕ. Здоровье и безопасность наших сотрудников и пациентов являются нашим главным приоритетом. Обратите внимание, что наши исследовательские центры не являются медицинскими центрами или больницами. Мы не принимаем и не лечим больных. Меры принимаются, тем не менее, чтобы гарантировать, что наши учреждения надлежащим образом проверяют посетителей и соблюдают важные стандарты гигиены и надлежащей стерилизации.',
    t_selectAppointmentType: "Как бы вы хотели провести встречу?",
    t_appointmentTypeTitle: "Тип встречи",
    t_emailTitle: "Электронная почта",
    t_mobilePhoneTitle: 'Сотовый телефон',
    t_videNowCard: "Начните онлайн-видеовстречу <br> СЕЙЧАС",
    t_scheduleVideoCard: "Назначьте онлайн-видеовстречу ПОЗЖЕ",
    t_scheduleInPersonCard: "Запланировать личное посещение",
    t_videoNotSuitable: "Видеозапись не подходит",
    t_confirmVideoInfo: "Ваша онлайн-видеовстреча состоится в день и время, выбранные командой врача-исследователя. После записи на прием вы получите электронное письмо с подтверждением, содержащее ссылку. Когда ваша встреча должна начаться, нажмите на ссылку для автоматического начала видеозвонка. ",
    t_videoModalText: 'Отлично! Нам просто нужно, чтобы вы подтвердили следующие данные перед началом видеовстречи:',
    t_optional: "(Необязательно)",
    t_selectDateVideoSubtext: 'Чтобы провести видеовстречу, вам потребуется доступ к устройству с камерой и / или микрофоном (например, большинство смартфонов и ноутбуков имеют и то, и другое).',
    t_siteNameTitle: "Детали сайта",
    t_videNowNotConvenientIntro: "Если сейчас вам не удобно:",
    t_videNowButton: 'НАЧАТЬ СЕЙЧАС',
    t_scheduleVideoButton: "РАСПИСАНИЕ ВИДЕО ВЫЗОВА",
    t_scheduleInPersonButton: "РАСПИСАНИЕ ВИЗИТА",
    t_schedulePhoneCallCard: "Запланировать телефонный звонок",
    t_schedulePhoneCallButton: 'ПО РАСПИСАНИЮ ЗВОНКА',
    t_emrSelectProtocolsText: "Выбранные протоколы:",
    t_selectDatePhone: 'Выберите дату и время, когда вы хотите назначить встречу по телефону:',
    t_selectDatePhoneSubtext: 'Чтобы провести телефонную встречу, вам понадобится доступ к телефону в выбранный вами день',
    t_confirmPhoneInfo: "Ваша телефонная встреча состоится в день и время, выбранные командой клиницистов на месте. Пожалуйста, убедитесь, что вы доступны с хорошим телефонным сигналом, чтобы принять звонок.",

    // rebook
    t_rebookModalIntro: "У вас уже есть подтвержденная встреча для этого исследования:",
    t_rebookModalContinueText: "Если вы продолжите, вы сможете выбрать новую дату и время для этой встречи.",
    t_rebookModalContinueText2: `Выберите вариант ниже, чтобы выбрать новую дату и время для этой встречи или полностью отменить встречу`,
    t_rebookModalNote: 'Обратите внимание, что ваша текущая встреча не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rebookModalNoteCancellations: 'Обратите внимание, что если вы хотите перенести встречу, она не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rescheduleAppointmentButton: 'Запланировать запрос',

    // cancellations
    t_cancelAppointment: 'Отменить встречу',
    t_cancelAppointmentButton: 'Отменить встречу',
    t_cancelApptConfirmHeader: 'Вы уверены, что хотите отменить встречу?',
    t_cancelApptConfirmText: 'Вы получите электронное письмо с подтверждением отмены встречи.',
    t_cancelApptBtn: 'да',
    t_cancelBackBtn: 'Нет',
    t_apptCancelledSuccessHeader: 'Успех',
    t_apptCancelledSuccessText: 'Ваша встреча была успешно отменена.',
    t_apptCancelledErrorHeader: 'Ошибка',
    t_apptCancelledErrorText: 'Произошла ошибка при отмене встречи.',
    t_closeBtn: 'Закрыть',

    // smart site
    t_helpBtn: `Помощь`,
    t_ssInstruction1: 'Пожалуйста, выберите сайт из списка ниже и нажмите кнопку «Далее». Вы можете использовать стрелки влево и вправо, чтобы увидеть больше сайтов. ',
    t_ssInstruction2: 'Для поиска дополнительных сайтов или сайтов в другом месте введите предпочтительное местоположение (адрес / индекс / город) и расстояние (радиус поиска) и нажмите *search icon*.',
    t_ssInstruction3: 'Щелкните *reset icon*, чтобы просмотреть исходный список сайтов.',
    t_ssSearchInputPlaceholder: "Например, '1666' или 'Ореховая роща'",

    t_ssDropdownLabel: 'Выбрать расстояние',
    t_ssDropdownOption: 'В пределах% 0%% unit%',
    t_noSites: 'Нет доступных сайтов',
    t_locationInputPlaceholder: 'Укажите местоположение',

    t_selectedDateLabel: 'Выбранная дата:',

    t_ssPopup1: `Теперь, когда вы прошли квалификацию для участия в исследовании, следующий шаг - записаться на прием в учебную клинику! <br>
    <br>
    На этой странице вы можете выбрать удобный вам сайт. В зависимости от выбранного сайта после нажатия кнопки «Далее» вам будет предложено несколько различных вариантов: <br> <br>
    1. Если выбранный сайт не предлагает онлайн-бронирование, мы свяжемся с вами в ближайшее время по телефону, чтобы записаться на прием. <br> <br>
    2. Если выбранный сайт предлагает онлайн-бронирование, у вас может быть возможность забронировать следующие типы встреч: <br> `,
    t_ssPopup2: `• Видео по запросу - виртуальная видеовстреча, которая состоится немедленно. <br> <br>
    • Запланированное видео - виртуальная видеовстреча, которую можно запланировать на будущее. <br> <br>
    • Телефон по расписанию - виртуальная телефонная встреча, которую можно запланировать на будущее. <br> <br>
    • На месте - личный прием в клиническом центре, который вы можете запланировать на будущее.
    <br> `,
    t_ssPopup3: `<strong> Что, если я хочу изменить область поиска? </strong> <br> <br>
    Без проблем! Введите адрес и расстояние (радиус поиска) в строку поиска, и мы предоставим вам новый список сайтов на выбор. <br> <br>
    <strong> Что делать, если мне не удобны никакие сайты? </strong> <br> <br>
    Если вы выберете «Нет удобного места для исследований», мы свяжемся с вами, чтобы обсудить варианты и назначить вам встречу. <br> <br>
    <strong> Что делать, если я не знаю, как попасть на прием? </strong> <br> <br>
    Если вы нажмете плитку с местоположением, мы покажем вам маршруты проезда и проезда к сайту.`,

    revision_changed: {
      revision_changed_1: `Вопросы в этом конкретном опросе были изменены с тех пор, как вы его начали. Следовательно, вам нужно будет начать заново с вопроса 1.`,
      revision_changed_2: `Приносим извинения за неудобства.`,
      ctaText: `Начать новую анкету.`
    },

    maintenanceError: {
      header: `Плановое обслуживание`,
      body: "Извините, в настоящее время мы закрыты на плановое техническое обслуживание."
    },

    t_appointmentAlreadyBookedHeader: 'Подождите! У вас уже назначена встреча с нами.',
    t_appointmentAlreadyBookedBody: 'Мы не можем записать вас на другую встречу, потому что она у вас уже запланирована. <br><br> Пожалуйста, проверьте свой телефон или найдите в своем почтовом ящике детали подтверждения, которые мы отправили ранее. <br><br> Если вам нужно внести какие-либо изменения в существующую встречу, используйте контактную информацию, содержащуюся в этом письме.',
    t_appointmentAlreadyBookedFooter: 'Спасибо за ваш энтузиазм и интерес к участию в продвижении медицинских исследований'
  },
  // ----- russian latvia
  ru_LV: {
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_tel: `Тел`,
    t_email: "Электронная почта",
    t_phone: "Телефон",
    t_next: "Далее",
    t_previousQuestion: `Предыдущий вопрос`,
    t_nextQuestion: `Следующий вопрос`,
    t_confirm: `Подтвердить`,
    t_goBack: "Вернуться",
    t_videoCall: 'Видеозвонок',

    t_selectDate: "Следующий шаг - это назначить Вам визит к персоналу врача-исследователя. В ходе этого визита персонал врача-исследователя дополнительно обсудит с Вами требования исследования и ответит на все вопросы, которые у Вас могут возникнуть.",
    t_datesUnavailableLabel: "Даты, на которые нет записи",
    t_datesAvailableLabel: "Свободные даты",
    t_next: "Далее",
    t_noAppointmentConvenient: "Удобное время для визита отсутствует",
    t_previousQuestion: "Предыдущий вопрос",
    t_noAppointmentsAtTheMoment: "К сожалению, в настоящее время мы не можем предложить свободного или удобного времени для визита в этот центр.",
    t_confirmToContact: "Подтвердите свои данные ниже, и мы при первой же возможности свяжемся с Вами.",
    t_phone: "Телефон",
    t_confirm: "Подтвердить",
    t_confirmCorrect: "Прежде чем записаться на визит, подтвердите, пожалуйста, правильность следующих данных:",
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_sendEmailConfirmation: "Выслать подтверждение по электронной почте",
    t_sendSMSConfirmation: "Выслать подтверждение с помощью СМС-сообщения",
    t_bookAppointment: "Записаться на визит",
    t_appointmentBooked: "Вы успешно записаны на визит!",
    t_nextQuestion: "Следующий вопрос",
    t_somebodyElseBooked: "Извините, но на это время только что записался другой человек. Попробуйте выбрать другую дату или время.",
    t_goBack: "Назад",
    t_bookingConfirmed: "Здравствуйте! Подтверждаем, что Вы записаны на визит %date% в %time% в %facility%.",
    t_haveQuestionsContact: "Если у Вас возникнут какие-либо вопросы или Вам необходимо будет изменить время этого визита, пожалуйста, свяжитесь напрямую с офисом врача-исследователя.",
    t_lookForward: "С нетерпением ждем скорой встречи с Вами!",
    t_regards: "С уважением",
    t_unsubscribe: "Отписаться от этих уведомлений",
    t_bookingConfirmedContactOffice: "Подтверждаем запись на визит в %facility% %date% в %time%. С любыми вопросами звоните, пожалуйста, в офис по телефону %phone%.",
    t_thankYouEndPage: "Благодарим Вас!",

    // rebook
    t_rebookModalIntro: "У вас уже есть подтвержденная встреча для этого исследования:",
    t_rebookModalContinueText: "Если вы продолжите, вы сможете выбрать новую дату и время для этой встречи.",
    t_rebookModalContinueText2: `Выберите вариант ниже, чтобы выбрать новую дату и время для этой встречи или полностью отменить встречу`,
    t_rebookModalNote: 'Обратите внимание, что ваша текущая встреча не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rebookModalNoteCancellations: 'Обратите внимание, что если вы хотите перенести встречу, она не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rescheduleAppointmentButton: 'Запланировать запрос',

    // cancellations
    t_cancelAppointment: 'Отменить встречу',
    t_cancelAppointmentButton: 'Отменить встречу',
    t_cancelApptConfirmHeader: 'Вы уверены, что хотите отменить встречу?',
    t_cancelApptConfirmText: 'Вы получите электронное письмо с подтверждением отмены встречи.',
    t_cancelApptBtn: 'Да',
    t_cancelBackBtn: 'Нет',
    t_apptCancelledSuccessHeader: 'Успех',
    t_apptCancelledSuccessText: 'Ваша встреча была успешно отменена.',
    t_apptCancelledErrorHeader: 'Ошибка',
    t_apptCancelledErrorText: 'Произошла ошибка при отмене встречи.',
    t_closeBtn: 'Закрыть',

    // smart site
    t_helpBtn: `Помощь`,
    t_ssInstruction1: 'Пожалуйста, выберите сайт из списка ниже и нажмите кнопку «Далее». Вы можете использовать стрелки влево и вправо, чтобы увидеть больше сайтов. ',
    t_ssInstruction2: 'Для поиска дополнительных сайтов или сайтов в другом месте введите предпочтительное местоположение (адрес / индекс / город) и расстояние (радиус поиска) и нажмите *search icon*.',
    t_ssInstruction3: 'Щелкните *reset icon*, чтобы просмотреть исходный список сайтов.',
    t_ssSearchInputPlaceholder: "Например, '1666' или 'Ореховая роща'",

    t_ssDropdownLabel: 'Выбрать расстояние',
    t_ssDropdownOption: 'В пределах% 0%% unit%',
    t_noSites: 'Нет доступных сайтов',
    t_locationInputPlaceholder: 'Укажите местоположение',

    t_selectedDateLabel: 'Выбранная дата:',

    t_ssPopup1: `Теперь, когда вы прошли квалификацию для участия в исследовании, следующий шаг - записаться на прием в учебную клинику! <br>
    <br>
    На этой странице вы можете выбрать удобный вам сайт. В зависимости от выбранного сайта после нажатия кнопки «Далее» вам будет предложено несколько различных вариантов: <br> <br>
    1. Если выбранный сайт не предлагает онлайн-бронирование, мы свяжемся с вами в ближайшее время по телефону, чтобы записаться на прием. <br> <br>
    2. Если выбранный сайт предлагает онлайн-бронирование, у вас может быть возможность забронировать следующие типы встреч: <br> `,
    t_ssPopup2: `• Видео по запросу - виртуальная видеовстреча, которая состоится немедленно. <br> <br>
    • Запланированное видео - виртуальная видеовстреча, которую можно запланировать на будущее. <br> <br>
    • Телефон по расписанию - виртуальная телефонная встреча, которую можно запланировать на будущее. <br> <br>
    • На месте - личный прием в клиническом центре, который вы можете запланировать на будущее.
    <br> `,
    t_ssPopup3: `<strong> Что, если я хочу изменить область поиска? </strong> <br> <br>
    Без проблем! Введите адрес и расстояние (радиус поиска) в строку поиска, и мы предоставим вам новый список сайтов на выбор. <br> <br>
    <strong> Что делать, если мне не удобны никакие сайты? </strong> <br> <br>
    Если вы выберете «Нет удобного места для исследований», мы свяжемся с вами, чтобы обсудить варианты и назначить вам встречу. <br> <br>
    <strong> Что делать, если я не знаю, как попасть на прием? </strong> <br> <br>
    Если вы нажмете плитку с местоположением, мы покажем вам маршруты проезда и проезда к сайту.`,

    revision_changed: {
      revision_changed_1: `Вопросы в этом конкретном опросе были изменены с тех пор, как вы его начали. Следовательно, вам нужно будет начать заново с вопроса 1.`,
      revision_changed_2: `Приносим извинения за неудобства.`,
      ctaText: `Начать новую анкету.`
    },

    maintenanceError: {
      header: `Плановое обслуживание`,
      body: "Извините, в настоящее время мы закрыты на плановое техническое обслуживание."
    },

    t_appointmentAlreadyBookedHeader: 'Подождите! У вас уже назначена встреча с нами.',
    t_appointmentAlreadyBookedBody: 'Мы не можем записать вас на другую встречу, потому что она у вас уже запланирована. <br><br> Пожалуйста, проверьте свой телефон или найдите в своем почтовом ящике детали подтверждения, которые мы отправили ранее. <br><br> Если вам нужно внести какие-либо изменения в существующую встречу, используйте контактную информацию, содержащуюся в этом письме.',
    t_appointmentAlreadyBookedFooter: 'Спасибо за ваш энтузиазм и интерес к участию в продвижении медицинских исследований'
  },

  // ------ russian lithuania
  ru_LT: {
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_tel: `Тел`,
    t_email: "Электронная почта",
    t_phone: "Телефон",
    t_next: "Далее",
    t_previousQuestion: `Предыдущий вопрос`,
    t_nextQuestion: `Следующий вопрос`,
    t_confirm: `Подтвердить`,
    t_goBack: "Вернуться",
    t_videoCall: 'Видеозвонок',

    t_selectDate: "Следующий шаг - это назначить Вам визит к персоналу врача-исследователя. В ходе этого визита персонал врача-исследователя дополнительно обсудит с Вами требования исследования и ответит на все вопросы, которые у Вас могут возникнуть.",
    t_datesUnavailableLabel: "Даты, на которые нет записи",
    t_datesAvailableLabel: "Свободные даты",
    t_next: "Далее",
    t_noAppointmentConvenient: "Удобное время для визита отсутствует",
    t_previousQuestion: "Предыдущий вопрос",
    t_noAppointmentsAtTheMoment: "К сожалению, в настоящее время мы не можем предложить свободного или удобного времени для визита в этот центр.",
    t_confirmToContact: "Подтвердите свои данные ниже, и мы при первой же возможности свяжемся с Вами.",
    t_phone: "Телефон",
    t_confirm: "Подтвердить",
    t_confirmCorrect: "Прежде чем записаться на визит, подтвердите, пожалуйста, правильность следующих данных:",
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_sendEmailConfirmation: "Выслать подтверждение по электронной почте",
    t_sendSMSConfirmation: "Выслать подтверждение с помощью СМС-сообщения",
    t_bookAppointment: "Записаться на визит",
    t_appointmentBooked: "Вы успешно записаны на визит!",
    t_nextQuestion: "Следующий вопрос",
    t_somebodyElseBooked: "Извините, но на это время только что записался другой человек. Попробуйте выбрать другую дату или время.",
    t_goBack: "Назад",
    t_bookingConfirmed: "Здравствуйте! Подтверждаем, что Вы записаны на визит %date% в %time% в %facility%.",
    t_haveQuestionsContact: "Если у Вас возникнут какие-либо вопросы или Вам необходимо будет изменить время этого визита, пожалуйста, свяжитесь напрямую с офисом врача-исследователя.",
    t_lookForward: "С нетерпением ждем скорой встречи с Вами!",
    t_regards: "С уважением",
    t_unsubscribe: "Отписаться от этих уведомлений",
    t_bookingConfirmedContactOffice: "Подтверждаем запись на визит в %facility% %date% в %time%. С любыми вопросами звоните, пожалуйста, в офис по телефону %phone%.",
    t_thankYouEndPage: "Благодарим Вас!",

    // rebook
    t_rebookModalIntro: "У вас уже есть подтвержденная встреча для этого исследования:",
    t_rebookModalContinueText: "Если вы продолжите, вы сможете выбрать новую дату и время для этой встречи.",
    t_rebookModalContinueText2: `Выберите вариант ниже, чтобы выбрать новую дату и время для этой встречи или полностью отменить встречу`,
    t_rebookModalNote: 'Обратите внимание, что ваша текущая встреча не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rebookModalNoteCancellations: 'Обратите внимание, что если вы хотите перенести встречу, она не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rescheduleAppointmentButton: 'Запланировать запрос',

    // cancellations
    t_cancelAppointment: 'Отменить встречу',
    t_cancelAppointmentButton: 'Отменить встречу',
    t_cancelApptConfirmHeader: 'Вы уверены, что хотите отменить встречу?',
    t_cancelApptConfirmText: 'Вы получите электронное письмо с подтверждением отмены встречи.',
    t_cancelApptBtn: 'да',
    t_cancelBackBtn: 'Нет',
    t_apptCancelledSuccessHeader: 'Успех',
    t_apptCancelledSuccessText: 'Ваша встреча была успешно отменена.',
    t_apptCancelledErrorHeader: 'Ошибка',
    t_apptCancelledErrorText: 'Произошла ошибка при отмене встречи.',
    t_closeBtn: 'Закрыть',

    // smart site
    t_helpBtn: `Помощь`,
    t_ssInstruction1: 'Пожалуйста, выберите сайт из списка ниже и нажмите кнопку «Далее». Вы можете использовать стрелки влево и вправо, чтобы увидеть больше сайтов. ',
    t_ssInstruction2: 'Для поиска дополнительных сайтов или сайтов в другом месте введите предпочтительное местоположение (адрес / индекс / город) и расстояние (радиус поиска) и нажмите *search icon*.',
    t_ssInstruction3: 'Щелкните *reset icon*, чтобы просмотреть исходный список сайтов.',
    t_ssSearchInputPlaceholder: "Например, '1666' или 'Ореховая роща'",

    t_ssDropdownLabel: 'Выбрать расстояние',
    t_ssDropdownOption: 'В пределах% 0%% unit%',
    t_noSites: 'Нет доступных сайтов',
    t_locationInputPlaceholder: 'Укажите местоположение',

    t_selectedDateLabel: 'Выбранная дата:',

    t_ssPopup1: `Теперь, когда вы прошли квалификацию для участия в исследовании, следующий шаг - записаться на прием в учебную клинику! <br>
    <br>
    На этой странице вы можете выбрать удобный вам сайт. В зависимости от выбранного сайта после нажатия кнопки «Далее» вам будет предложено несколько различных вариантов: <br> <br>
    1. Если выбранный сайт не предлагает онлайн-бронирование, мы свяжемся с вами в ближайшее время по телефону, чтобы записаться на прием. <br> <br>
    2. Если выбранный сайт предлагает онлайн-бронирование, у вас может быть возможность забронировать следующие типы встреч: <br> `,
    t_ssPopup2: `• Видео по запросу - виртуальная видеовстреча, которая состоится немедленно. <br> <br>
    • Запланированное видео - виртуальная видеовстреча, которую можно запланировать на будущее. <br> <br>
    • Телефон по расписанию - виртуальная телефонная встреча, которую можно запланировать на будущее. <br> <br>
    • На месте - личный прием в клиническом центре, который вы можете запланировать на будущее.
    <br> `,
    t_ssPopup3: `<strong> Что, если я хочу изменить область поиска? </strong> <br> <br>
    Без проблем! Введите адрес и расстояние (радиус поиска) в строку поиска, и мы предоставим вам новый список сайтов на выбор. <br> <br>
    <strong> Что делать, если мне не удобны никакие сайты? </strong> <br> <br>
    Если вы выберете «Нет удобного места для исследований», мы свяжемся с вами, чтобы обсудить варианты и назначить вам встречу. <br> <br>
    <strong> Что делать, если я не знаю, как попасть на прием? </strong> <br> <br>
    Если вы нажмете плитку с местоположением, мы покажем вам маршруты проезда и проезда к сайту.`,

    revision_changed: {
      revision_changed_1: `Вопросы в этом конкретном опросе были изменены с тех пор, как вы его начали. Следовательно, вам нужно будет начать заново с вопроса 1.`,
      revision_changed_2: `Приносим извинения за неудобства.`,
      ctaText: `Начать новую анкету.`
    },

    maintenanceError: {
      header: `Плановое обслуживание`,
      body: "Извините, в настоящее время мы закрыты на плановое техническое обслуживание."
    },

    t_appointmentAlreadyBookedHeader: 'Подождите! У вас уже назначена встреча с нами.',
    t_appointmentAlreadyBookedBody: 'Мы не можем записать вас на другую встречу, потому что она у вас уже запланирована. <br><br> Пожалуйста, проверьте свой телефон или найдите в своем почтовом ящике детали подтверждения, которые мы отправили ранее. <br><br> Если вам нужно внести какие-либо изменения в существующую встречу, используйте контактную информацию, содержащуюся в этом письме.',
    t_appointmentAlreadyBookedFooter: 'Спасибо за ваш энтузиазм и интерес к участию в продвижении медицинских исследований'
  },
  ru_IL: {
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_tel: `Тел`,
    t_email: "Электронная почта",
    t_phone: "Телефон",
    t_next: "Далее",
    t_previousQuestion: `Предыдущий вопрос`,
    t_nextQuestion: `Следующий вопрос`,
    t_confirm: `Подтвердить`,
    t_goBack: "Вернуться",
    t_videoCall: 'Видеозвонок',

    t_selectDate: "Следующий шаг - это назначить Вам визит к персоналу врача-исследователя. В ходе этого визита персонал врача-исследователя дополнительно обсудит с Вами требования исследования и ответит на все вопросы, которые у Вас могут возникнуть.",
    t_datesUnavailableLabel: "Даты, на которые нет записи",
    t_datesAvailableLabel: "Свободные даты",
    t_next: "Далее",
    t_noAppointmentConvenient: "Удобное время для визита отсутствует",
    t_previousQuestion: "Предыдущий вопрос",
    t_noAppointmentsAtTheMoment: "К сожалению, в настоящее время мы не можем предложить свободного или удобного времени для визита в этот центр.",
    t_confirmToContact: "Подтвердите свои данные ниже, и мы при первой же возможности свяжемся с Вами.",
    t_phone: "Телефон",
    t_confirm: "Подтвердить",
    t_confirmCorrect: "Прежде чем записаться на визит, подтвердите, пожалуйста, правильность следующих данных:",
    t_date: "Дата",
    t_time: "Время",
    t_address: "Адрес",
    t_sendEmailConfirmation: "Выслать подтверждение по электронной почте",
    t_sendSMSConfirmation: "Выслать подтверждение с помощью СМС-сообщения",
    t_bookAppointment: "Записаться на визит",
    t_appointmentBooked: "Вы успешно записаны на визит!",
    t_nextQuestion: "Следующий вопрос",
    t_somebodyElseBooked: "Извините, но на это время только что записался другой человек. Попробуйте выбрать другую дату или время.",
    t_goBack: "Назад",
    t_bookingConfirmed: "Здравствуйте! Подтверждаем, что Вы записаны на визит %date% в %time% в %facility%.",
    t_haveQuestionsContact: "Если у Вас возникнут какие-либо вопросы или Вам необходимо будет изменить время этого визита, пожалуйста, свяжитесь напрямую с офисом врача-исследователя.",
    t_lookForward: "С нетерпением ждем скорой встречи с Вами!",
    t_regards: "С уважением",
    t_unsubscribe: "Отписаться от этих уведомлений",
    t_bookingConfirmedContactOffice: "Подтверждаем запись на визит в %facility% %date% в %time%. С любыми вопросами звоните, пожалуйста, в офис по телефону %phone%.",
    t_thankYouEndPage: "Благодарим Вас!",

    // rebook
    t_rebookModalIntro: "У вас уже есть подтвержденная встреча для этого исследования:",
    t_rebookModalContinueText: "Если вы продолжите, вы сможете выбрать новую дату и время для этой встречи.",
    t_rebookModalContinueText2: `Выберите вариант ниже, чтобы выбрать новую дату и время для этой встречи или полностью отменить встречу`,
    t_rebookModalNote: 'Обратите внимание, что ваша текущая встреча не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rebookModalNoteCancellations: 'Обратите внимание, что если вы хотите перенести встречу, она не будет отменена, пока вы не подтвердите детали новой встречи.',
    t_rescheduleAppointmentButton: 'Запланировать запрос',

    // cancellations
    t_cancelAppointment: 'Отменить встречу',
    t_cancelAppointmentButton: 'Отменить встречу',
    t_cancelApptConfirmHeader: 'Вы уверены, что хотите отменить встречу?',
    t_cancelApptConfirmText: 'Вы получите электронное письмо с подтверждением отмены встречи.',
    t_cancelApptBtn: 'да',
    t_cancelBackBtn: 'Нет',
    t_apptCancelledSuccessHeader: 'Успех',
    t_apptCancelledSuccessText: 'Ваша встреча была успешно отменена.',
    t_apptCancelledErrorHeader: 'Ошибка',
    t_apptCancelledErrorText: 'Произошла ошибка при отмене встречи.',
    t_closeBtn: 'Закрыть',

    // smart site
    t_helpBtn: `Помощь`,
    t_ssInstruction1: 'Пожалуйста, выберите сайт из списка ниже и нажмите кнопку «Далее». Вы можете использовать стрелки влево и вправо, чтобы увидеть больше сайтов. ',
    t_ssInstruction2: 'Для поиска дополнительных сайтов или сайтов в другом месте введите предпочтительное местоположение (адрес / индекс / город) и расстояние (радиус поиска) и нажмите *search icon*.',
    t_ssInstruction3: 'Щелкните *reset icon*, чтобы просмотреть исходный список сайтов.',
    t_ssSearchInputPlaceholder: "Например, '1666' или 'Ореховая роща'",

    t_ssDropdownLabel: 'Выбрать расстояние',
    t_ssDropdownOption: 'В пределах% 0%% unit%',
    t_noSites: 'Нет доступных сайтов',
    t_locationInputPlaceholder: 'Укажите местоположение',

    t_selectedDateLabel: 'Выбранная дата:',

    t_ssPopup1: `Теперь, когда вы прошли квалификацию для участия в исследовании, следующий шаг - записаться на прием в учебную клинику! <br>
    <br>
    На этой странице вы можете выбрать удобный вам сайт. В зависимости от выбранного сайта после нажатия кнопки «Далее» вам будет предложено несколько различных вариантов: <br> <br>
    1. Если выбранный сайт не предлагает онлайн-бронирование, мы свяжемся с вами в ближайшее время по телефону, чтобы записаться на прием. <br> <br>
    2. Если выбранный сайт предлагает онлайн-бронирование, у вас может быть возможность забронировать следующие типы встреч: <br> `,
    t_ssPopup2: `• Видео по запросу - виртуальная видеовстреча, которая состоится немедленно. <br> <br>
    • Запланированное видео - виртуальная видеовстреча, которую можно запланировать на будущее. <br> <br>
    • Телефон по расписанию - виртуальная телефонная встреча, которую можно запланировать на будущее. <br> <br>
    • На месте - личный прием в клиническом центре, который вы можете запланировать на будущее.
    <br> `,
    t_ssPopup3: `<strong> Что, если я хочу изменить область поиска? </strong> <br> <br>
    Без проблем! Введите адрес и расстояние (радиус поиска) в строку поиска, и мы предоставим вам новый список сайтов на выбор. <br> <br>
    <strong> Что делать, если мне не удобны никакие сайты? </strong> <br> <br>
    Если вы выберете «Нет удобного места для исследований», мы свяжемся с вами, чтобы обсудить варианты и назначить вам встречу. <br> <br>
    <strong> Что делать, если я не знаю, как попасть на прием? </strong> <br> <br>
    Если вы нажмете плитку с местоположением, мы покажем вам маршруты проезда и проезда к сайту.`,

    revision_changed: {
      revision_changed_1: `Вопросы в этом конкретном опросе были изменены с тех пор, как вы его начали. Следовательно, вам нужно будет начать заново с вопроса 1.`,
      revision_changed_2: `Приносим извинения за неудобства.`,
      ctaText: `Начать новую анкету.`
    },

    maintenanceError: {
      header: `Плановое обслуживание`,
      body: "Извините, в настоящее время мы закрыты на плановое техническое обслуживание."
    },

    t_appointmentAlreadyBookedHeader: 'Подождите! У вас уже назначена встреча с нами.',
    t_appointmentAlreadyBookedBody: 'Мы не можем записать вас на другую встречу, потому что она у вас уже запланирована. <br><br> Пожалуйста, проверьте свой телефон или найдите в своем почтовом ящике детали подтверждения, которые мы отправили ранее. <br><br> Если вам нужно внести какие-либо изменения в существующую встречу, используйте контактную информацию, содержащуюся в этом письме.',
    t_appointmentAlreadyBookedFooter: 'Спасибо за ваш энтузиазм и интерес к участию в продвижении медицинских исследований'
  },
  // latvian
  lv_LV: {
    t_date: "Datums",
    t_time: "Laiks",
    t_address: "Adrese",
    t_tel: "Tel",
    t_email: "E -pasts",
    t_phone: "Tālrunis",
    t_next: "Nākamais",
    t_previousQuestion: `Iepriekšējais jautājums`,
    t_nextQuestion: "Nākamais jautājums",
    t_confirm: "Apstiprināt",
    t_goBack: "Atgriezties",
    t_videoCall: "Videozvans",

    t_selectDate: "Nākamais solis ir ieplānot jums vizīti ar pētījuma ārstu komandu. Šīs vizītes laikā pētījuma ārsta komanda sīkāk pārrunās pētījuma prasības un atbildēs uz visiem jūs interesējošiem jautājumiem",
    t_datesUnavailableLabel: "Nepieejamie datumi",
    t_datesAvailableLabel: "Pieejamie datumi",
    t_next: "Nākamais",
    t_noAppointmentConvenient: "Neviens vizītes laiks nav ērts",
    t_previousQuestion: "Iepriekšējais jautājums",
    t_noAppointmentsAtTheMoment: "Diemžēl pašlaik šajā centrā nav pieejami vizīšu laiki vai ērti vizīšu laiki.",
    t_confirmToContact: "Apstipriniet savus datus zemāk, un mēs pēc iespējas ātrāk ar jums sazināsimies.",
    t_phone: "Tālruņa numurs",
    t_confirm: "Apstiprināt",
    t_confirmCorrect: "Pirms vizītes pieteikšanas apstipriniet, ka šī informācija ir pareiza:",
    t_date: "Datums",
    t_time: "Laiks",
    t_address: "Adrese",
    t_sendEmailConfirmation: "Sūtīt apstiprinājumu uz e-pastu",
    t_sendSMSConfirmation: "Sūtīt apstiprinājumu īsziņā",
    t_bookAppointment: "Pieteikt vizīti",
    t_appointmentBooked: "Jūsu vizīte ir veiksmīgi pieteikta!",
    t_nextQuestion: "Nākamais jautājums",
    t_somebodyElseBooked: "Diemžēl kāds cits ir tikko pieteicis šo vizītes laiku. Mēģiniet izvēlēties citu datumu vai laiku.",
    t_goBack: "Atpakaļ",
    t_bookingConfirmed: "Sveiki! Jums ir apstiprināta vizīte datumā %date% plkst. %time% iestādē %facility%.",
    t_haveQuestionsContact: "Ja jums ir kādi jautājumi vai jums ir jāmaina šīs vizītes laiks, sazinieties tieši ar pētījuma ārsta kabinetu.",
    t_lookForward: "Uz tikšanos!",
    t_regards: "Ar cieņu",
    t_unsubscribe: "Atcelt šo brīdinājumu abonementu",
    t_bookingConfirmedContactOffice: "Vizīte apstiprināta iestādē %facility% datumā %date% plkst. %time%. Ja jums rodas kādi jautājumi, zvaniet uz biroju pa tālruņa nr. %phone%.",
    t_thankYouEndPage: "Paldies!",

    // rebook
    t_rebookModalIntro: "Jums jau ir apstiprināta tikšanās ar šo pētījumu:",
    t_rebookModalContinueText: "Ja turpināsit, varēsit izvēlēties jaunu tikšanās datumu un laiku.",
    t_rebookModalContinueText2: "Lūdzu, atlasiet tālāk norādīto opciju, lai izvēlētos jaunu šīs tikšanās datumu un laiku vai pilnībā atceltu tikšanos",
    t_rebookModalNote: "Lūdzu, ņemiet vērā, ka jūsu pašreizējā tikšanās netiks atcelta, kamēr neesat apstiprinājis informāciju par savu jauno tikšanos.",
    t_rebookModalNoteCancellations: "Lūdzu, ņemiet vērā: ja vēlaties pārplānot tikšanos, tā netiks atcelta, kamēr nebūsiet apstiprinājis informāciju par savu jauno tikšanos.",
    t_rescheduleAppointmentButton: `Pārplānot vaicājumu`,

    // cancellations
    t_cancelAppointment: 'Atcelt tikšanos',
    t_cancelAppointmentButton: 'Atcelt tikšanos',
    t_cancelApptConfirmHeader: 'Vai tiešām vēlaties atcelt savu tikšanos?',
    t_cancelApptConfirmText: 'Jūs saņemsiet e -pasta ziņojumu ar apstiprinājumu par tikšanās atcelšanu.',
    t_cancelApptBtn: 'Jā',
    t_cancelBackBtn: 'Nē',
    t_apptCancelledSuccessHeader: 'Veiksme',
    t_apptCancelledSuccessText: 'Jūsu tikšanās tika veiksmīgi atcelta.',
    t_apptCancelledErrorHeader: 'Kļūda',
    t_apptCancelledErrorText: 'Atceļot tikšanos, radās kļūda.',
    t_closeBtn: 'Aizvērt',

    // smart site
    t_helpBtn: "Palīdzība",
    t_ssInstruction1: 'Lūdzu, atlasiet vietni no zemāk esošā saraksta un noklikšķiniet uz pogas "Tālāk". Varat izmantot kreiso un labo bultiņu, lai redzētu vairāk vietņu. ',
    t_ssInstruction2: "Lai meklētu papildu vietnes vai vietnes citā vietā, lūdzu, ievadiet vēlamo atrašanās vietu (adrese/pasta indekss/pilsēta) un attālumu (meklēšanas rādiuss) un noklikšķiniet uz *search icon*.",
    t_ssInstruction3: 'Noklikšķiniet uz *reset icon*, lai apskatītu sākotnējo vietņu sarakstu.',
    t_ssSearchInputPlaceholder: "Piemēram, '1666' vai 'Walnut Grove'",

    t_ssDropdownLabel: 'Atlasīt attālumu',
    t_ssDropdownOption: ' %0% %unit% robežās',
    t_noSites: "Nav pieejama neviena vietne",
    t_locationInputPlaceholder: 'Ievadiet atrašanās vietu',

    t_selectedDateLabel: 'Выбранная дата:',

    t_ssPopup1: `Tagad, kad esat kvalificējies studijām, nākamais solis ir rezervēt tikšanos studiju klīnikā! <br>
    <br>
    Šajā lapā jūs varat izvēlēties sev ērtu vietni. Atkarībā no atlasītās vietnes, noklikšķinot uz pogas Tālāk, jums tiks piedāvātas dažas dažādas iespējas: <br> <br>
    1. Ja izvēlētā vietne nepiedāvā rezervāciju tiešsaistē, mēs drīz sazināsimies ar jums pa tālruni, lai rezervētu tikšanos. <br> <br>
    2. Ja izvēlētā vietne piedāvā tiešsaistes rezervāciju, jums var būt iespēja rezervēt šāda veida tikšanās: <br> `,
    t_ssPopup2: `• Video pēc pieprasījuma-virtuāla video tikšanās, kas notiks nekavējoties. <br> <br>
    • Plānotais video - virtuāla video tikšanās, kuru varat ieplānot nākotnei. <br> <br>
    • Plānotais tālrunis - virtuāla tikšanās pa tālruni, kuru varat ieplānot nākotnei. <br> <br>
    • Uz vietas-personīga tikšanās klīniskā vietā, kuru varat ieplānot nākotnei.
    <br> `,
    t_ssPopup3: `<strong> Ko darīt, ja vēlos mainīt meklēšanas apgabalu? </strong> <br> <br>
    Nekādu problēmu! Meklēšanas joslā ievadiet adresi un attālumu (meklēšanas rādiuss), un mēs jums piedāvāsim jaunu vietņu sarakstu. <br> <br>
    <strong> Ko darīt, ja neviena vietne man nav ērta? </strong> <br> <br>
    Ja izvēlēsities “Neviena izpētes vietne nav ērta”, mēs ar jums sazināsimies, lai apspriestu iespējas un ieplānotu tikšanos. <br> <br>
    <strong> Ko darīt, ja neesmu pārliecināts, kā nokļūt tikšanās reizē. </strong> <br> <br>
    Ja noklikšķināsit uz atrašanās vietas elementa, mēs parādīsim braukšanas un sabiedriskā transporta norādes uz vietni.`,

    revision_changed: {
      revision_changed_1: 'Jautājumi šajā aptaujā ir mainīti kopš tās uzsākšanas. Tādēļ jums būs jāsāk no 1. jautājuma.',
      revision_changed_2: "Atvainojiet par sagādātajām neērtībām.",
      ctaText: "Sāciet jaunu anketu."
    },

    maintenanceError: {
      header: `Plānotā apkope`,
      body: 'Atvainojiet, mums pašlaik nav paredzēta plānotā apkope.'
    },


    t_appointmentAlreadyBookedHeader: 'Pagaidiet! Jums jau ir norunāta tikšanās ar mums.',
    t_appointmentAlreadyBookedBody: 'Mēs nevaram rezervēt jūs uz citu tikšanos, jo jums tāda jau ir pie mums. <br><br> Lūdzu, pārbaudiet savu tālruni vai meklējiet e-pasta iesūtnē apstiprinājuma informāciju, ko mēs nosūtījām iepriekš. <br><br> Ja jums ir jāveic izmaiņas esošajā tikšanās reizē, lūdzu, izmantojiet šajā e-pastā ietverto kontaktinformāciju.',
    t_appointmentAlreadyBookedFooter: 'Paldies par jūsu entuziasmu un interesi sniegt savu ieguldījumu, lai veicinātu medicīnisko izpēti.'
  },
  ja_JP: {
    t_date: `Date`,
    t_time: `Time`,
    t_address: `Address`,
    t_tel: `Tel`,
    t_email: `Email`,
    t_phone: `Phone`,
    t_next: `Next`,
    t_previousQuestion: `前の質問`,
    t_nextQuestion: `次の質問`,
    t_confirm: `確認`,
    t_goBack: `Go Back`,
    t_videoCall: `ビデオ通話`,

    t_selectDate: "次のステップは、治験担当医師のチームとの予約をすることです。この来院中に、治験担当医師のチームがさらに、治験の要件を説明し、あなたの質問すべてに回答します。",
    t_datesUnavailableLabel: "利用不可能な日付",
    t_datesAvailableLabel: "利用可能な日付",
    t_next: "次へ",
    t_noAppointmentConvenient: "どの予約時刻も不便です",
    t_previousQuestion: "以前の質問",
    t_noAppointmentsAtTheMoment: "残念ながら、この施設では、現在利用可能な、または便利な予約枠がありません。",
    t_confirmToContact: "以下の詳細を確認してくだされば、できる限り速やかにご連絡いたします。",
    t_phone: "電話番号",
    t_confirm: "確認",
    t_confirmCorrect: "予約をされる前に、以下の詳細が正しいことを確認してください: ",
    t_date: "日付",
    t_time: "時刻",
    t_address: "住所",
    t_sendEmailConfirmation: "Eメール確認を送信する",
    t_sendSMSConfirmation: "SMS 確認を送信する",
    t_bookAppointment: "予約する",
    t_appointmentBooked: "あなたの予約は無事に予定されました！",
    t_nextQuestion: "次の質問",
    t_somebodyElseBooked: "済みませんが、他の誰かがこの予約スロットに今予約しました。異なる日付または時刻の選択を試みてください。",
    t_goBack: "戻る",
    t_bookingConfirmed: "こんにちは、あなたは、%date% の %time% に %facility% との予約を確認済みです。",
    t_haveQuestionsContact: "この予約について何かご質問があるか、または変更の必要がある場合は、治験担当医師の事務所に直接連絡してください。",
    t_lookForward: "お会いできるのを楽しみにしています！",
    t_regards: "よろしくお願いいたします",
    t_unsubscribe: "これらのアラートの登録を取り消す",
    t_bookingConfirmedContactOffice: "%facility% との予約は、%date% の %time% に確認済みです。何かご質問がありましたら、 %phone% で事務所までお電話ください",
    t_thankYouEndPage: `ありがとうございました`,

    // rebook
    t_rebookModalIntro: "この調査の予約はすでに確認されています: ",
    t_rebookModalContinueText: "続行すると、この予定の新しい日時を選択できるようになります。",
    t_rebookModalContinueText2: `この予定の新しい日時を選択するか、予定を完全にキャンセルするには、以下のオプションを選択してください`,
    t_rebookModalNote: "新しい予定の詳細を確認するまで、現在の予定はキャンセルされないことに注意してください。",
    t_rebookModalNoteCancellations: "予約の再スケジュールを希望する場合、新しい予約の詳細を確認するまでキャンセルされないことに注意してください。",
    t_rescheduleAppointmentButton: 'クエリの再スケジュール',

    // cancellations
    t_cancelAppointment: '予定のキャンセル',
    t_cancelAppointmentButton: '予定のキャンセル',
    t_cancelApptConfirmHeade: '予約をキャンセルしてもよろしいですか？',
    t_cancelApptConfirmText: '予約のキャンセルを確認するメールが届きます。',
    t_cancelApptBtn: 'はい',
    t_cancelBackBtn: '番号',
    t_apptCancelledSuccessHeader: '成功',
    t_apptCancelledSuccessText: '予定は正常にキャンセルされました。',
    t_apptCancelledErrorHeader: 'エラー',
    t_apptCancelledErrorText: '予約のキャンセル中にエラーが発生しました。',
    t_closeBtn: '閉じる',

    //smart site
    t_helpBtn: `ヘルプ`,
    t_ssInstruction1: '以下のリストからサイトを選択し、[次へ]ボタンをクリックしてください。左矢印と右矢印を使用して、より多くのサイトを表示できます',
    t_ssInstruction2: '追加のサイトまたは別の場所にあるサイトを検索するには、希望の場所（住所/郵便番号/都市）と距離（検索半径）を入力し、*search icon* をクリックしてください。',
    t_ssInstruction3: '*reset icon*をクリックして、サイトの最初のリストを表示します。',
    t_ssSearchInputPlaceholder: "例: 「1666」または「ウォルナットグローブ」",

    t_ssDropdownLabel: '距離を選択',
    t_ssDropdownOption: '％0 ％％ unit％以内',
    t_noSites: '利用可能なサイトがありません',
    t_locationInputPlaceholder: '場所を入力してください',

    t_selectedDateLabel: '選択日：',

    t_ssPopup1: `これで研究の資格が得られたので、次のステップは研究クリニックとの約束を予約することです！<br>
    <br>
    このページでは、自分に都合の良いサイトを選択できます。選択したサイトに応じて、[次へ]ボタンをクリックすると、いくつかの異なるオプションが表示されます。<br> <br>
    1.選択したサイトでオンライン予約が提供されていない場合は、電話ですぐに連絡して予約を取ります。 <br> <br>
    2.選択したサイトでオンライン予約が提供されている場合は、次の種類の予約を選択できる場合があります。<br> `,
    t_ssPopup2: `•オンデマンドビデオ–すぐに行われる仮想のビデオアポイントメント。<br> <br>
    •スケジュールされたビデオ–将来に向けてスケジュールできる仮想のビデオアポイントメント。<br> <br>
    •スケジュールされた電話–将来に向けてスケジュールできる仮想の電話予約。<br> <br>
    •オンサイト–将来のスケジュールを立てることができる臨床現場での直接の予約。
    <br> `,
    t_ssPopup3: `<strong>検索領域を変更したい場合はどうすればよいですか？</ strong> <br> <br>
    問題ない！検索バーに住所と距離（検索範囲）を入力すると、選択できる新しいサイトリストが提供されます。<br> <br>
    <strong>自分にとって便利なサイトがない場合はどうなりますか？</ strong> <br> <br>
    「便利な研究施設はありません」を選択した場合は、オプションについて話し合い、予約のスケジュールを立てるためにご連絡いたします。 <br> <br>
    <strong>予約の取り方がわからない場合はどうすればよいですか。</ strong> <br> <br>
    ロケーションタイルをクリックすると、サイトへの運転ルートと交通機関のルートが表示されます。`,

    Revision_changed: {
      Revision_changed_1: `この特定の調査内の質問は、開始してから変更されています。したがって、質問1からやり直す必要があります`,
      Revision_changed_2: `ご不便をおかけして申し訳ありません。'、`,
      ctaText: `「新しいアンケートを開始してください。」`
    },

    MaintenanceError: {
      header: '定期メンテナンス',
      body: `申し訳ありませんが、現在、定期メンテナンスのためにダウンしています。`
    },

    t_appointmentAlreadyBookedHeader: '待ってください！あなたはすでに私たちとの約束を予定しています。」',
    t_appointmentAlreadyBookedBody: 'すでに予約が入っているため、別の予約を予約することはできません。 <br> <br>以前に送信した確認の詳細については、携帯電話を確認するか、メールの受信トレイを検索してください。 <br> <br>既存の予定に変更を加える必要がある場合は、その電子メールに含まれている連絡先情報を使用してください。',
    t_appointmentAlreadyBookedFooter: '医学研究を進めるためにあなたの役割を果たすことに熱意と関心を持ってくれてありがとう。'
  },

  //german
  de_DE: {
    t_date: `Datum`,
    t_time: `Zeit`,
    t_address: `Adresse`,
    t_tel: `Tel`,
    t_email: `E-Mail`,
    t_phone: `Telefon`,
    t_next: `Weiter`,
    t_vorherigeFrage: `Vorherige Frage`,
    t_nextQuestion: `Nächste Frage`,
    t_confirm: `Bestätigen`,
    t_goBack: `Zurück`,
    t_videoCall: `Videoanruf`,

    t_selectDate: "Wählen Sie Tag und Uhrzeit Ihres Termin aus:",
    t_datesAvailableLabel: "Tage verfügbar",
    t_datesUnavailableLabel: "Tage nicht verfügbar",
    t_noAppointmentConvenient: "Keine geeignete Zeit verfügbar",

    t_noAppointmentsAtTheMoment: "Ein Mitarbeiter des klinischen Studienbetreuungsteams wird sich mit Ihnen in Verbindung setzen, um einen für Sie am besten geeigneten Termin zu vereinbaren.",
    t_confirmToContact: "Bitte bestätigen Sie Ihre untenstehenden Angaben und wir werden uns in Kürze mit Ihnen in Verbindung setzen.",

    t_noTimeSlotsAvailableMsg: "Leider stehen an diesem Zentrum derzeit keine geeigneten Termine zur Verfügung.",

    t_confirmCorrect: "Bitte bestätigen Sie, dass die folgenden Angaben korrekt sind, bevor Sie Ihren Termin buchen:",
    t_sendEmailConfirmation: "Bestätigung per E-Mail senden",
    t_sendSMSConfirmation: "Bestätigung per SMS senden",
    t_bookAppointment: "Termin buchen",
    t_appointmentBooked: "Ihr Termin wurde erfolgreich gebucht!",
    t_somebodyElseBooked: "Es tut uns leid, dieser Termin wurde gerade von jemand anderem gebucht. Wählen Sie bitte einen anderen Tag oder eine andere Uhrzeit aus.",

    t_bookingConfirmed: "Hallo. Ihr Termin am %date% um %time% am Zentrum %facility% ist bestätigt.",
    t_haveQuestionsContact: "Sollten Sie Fragen haben oder Ihren Termin ändern müssen, kontaktieren Sie bitte direkt das Studienteam.",
    t_lookForward: "Wir freuen uns auf Sie!",
    t_regards: "Mit freundlichen Grüßen",
    t_unsubscribe: "Diese Art Mitteilungen abbestellen",
    t_bookingConfirmedContactOffice: "Termin am Zentrum %facility% am %date% um %time% bestätigt. Bei Fragen, kontaktieren Sie bitte das Zentrum unter %phone%.",
    t_thankYouEndPage: "Vielen Dank",

    t_coronavirusText: "COVID-19 HINWEIS: Die Gesundheit und Sicherheit unserer Mitarbeiter und unserer Patienten hat für uns oberste Priorität. Bitte beachten Sie, dass unsere Forschungseinrichtungen keine regulären medizinischen Praxen (wie z. B. Ihr Hausarzt) oder Krankenhäuser sind. Wir sehen oder behandeln keine akut kranken Patienten. Es sind jedoch Maßnahmen vorhanden, um sicherzustellen, dass unsere Einrichtungen die Besucher angemessen überprüfen und wichtige Standards für Hygiene und ordnungsgemäße Sterilisation einhalten.",
    t_selectAppointmentType: "Wie möchten Sie Ihren Termin wahrnehmen?",
    t_appointmentTypeTitle: "Termin-Art",
    t_emailTitle: "E-Mail Adresse",
    t_mobilePhoneTitle: "Mobiltelefon",
    t_videNowCard: "Online-Video-Termin jetzt starten",
    t_scheduleVideoCard: "Online-Video-Termin vereinbaren",
    t_scheduleInPersonCard: "Persönlichen Besuch vereinbaren",
    t_videoNotSuitable: "Ein Video-Termin kommt für mich nicht in Frage.",
    t_confirmVideoInfo: "Ihr Online-Video-Termin findet am ausgewählten Termin mit dem Studienteam statt. Nach der Terminvereinbarung erhalten Sie eine Bestätigungs-E-Mail mit einem Link. Klicken Sie auf den Link, sobald Ihr Termin ansteht, um den Videoanruf automatisch zu starten.",
    t_videoModalText: "Wunderbar! Bitte bestätigen Sie uns vor Beginn Ihres Videoanrufes die folgenden Angaben",
    t_optional: " (optional)",
    t_selectDateVideoSubtext: "Für die Durchführung des Video-Termines benötigen Sie ein Gerät mit einer Kamera und/oder einem Mikrofon (z.B. die meisten Smartphones und Laptops verfügen über diese Funktionen).",
    t_rebookModalIntro: "Ihr Termin für diese Studie ist bereits bestätigt:",
    t_rebookModalContinueText: "Wenn Sie fortfahren, können Sie ein neues Datum und eine neue Uhrzeit für diesen Termin wählen.",
    t_rebookModalNote: "Beachten Sie bitte, dass Ihr derzeitiger Termin nicht abgesagt wird, solange Sie die Angaben für Ihren neuen Termin nicht bestätigen.",
    t_siteNameTitle: "Zentrumsangaben",
    t_selectDateVideo: "Bitte wählen Sie Datum und Uhrzeit aus, an dem Sie Ihren Online-Video-Termin vereinbaren möchten:",
    t_videNowNotConvenientIntro: "Falls es Ihnen jetzt nicht passt:",
    t_videNowCard: "Starten Sie JETZT Ihren Video Termin",
    t_scheduleVideoCard: "Vereinbaren Sie Ihren Video Termin für einen SPÄTEREN Zeitpunkt",
    t_videNowButton: "JETZT STARTEN",
    t_scheduleVideoButton: "VIDEO ANRUF VEREINBAREN",
    t_scheduleInPersonButton: "BESUCH VEREINBAREN",
    t_schedulePhoneCallCard: "Telefontermin vereinbaren", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "Anruf vereinbaren", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Ausgewählte Protokolle:",
    t_selectDatePhone: "Bitte wählen Sie Datum und Uhrzeit für Ihren Telefontermin aus:",
    t_selectDatePhoneSubtext: "Zur Durchführung des Telefontermins benötigen Sie an dem von Ihnen gewählten Datum Zugang zu einem Telefon",
    t_confirmPhoneInfo: "Ihr Telefontermin findet zu der von Ihnen ausgewählten Uhrzeit/Datum mit dem Zentrumspersonal statt. Bitte stellen Sie sicher, dass Sie über ein gutes Telefonnetz verfügen, um den Anruf entgegenzunehmen.",

    // rebook
    t_rebookModalIntro: "Sie haben bereits einen bestätigten Termin für diese Studie:",
    t_rebookModalContinueText: "Wenn Sie fortfahren, können Sie ein neues Datum und eine neue Uhrzeit für diesen Termin auswählen.",
    t_rebookModalContinueText2: `Bitte wählen Sie unten eine Option aus, um ein neues Datum und eine neue Uhrzeit für diesen Termin auszuwählen oder den Termin vollständig zu stornieren`,
    t_rebookModalNote: "Bitte beachten Sie, dass Ihr aktueller Termin erst storniert wird, wenn Sie die Details für Ihren neuen Termin bestätigt haben.",
    t_rebookModalNoteCancellations: "Bitte beachten Sie, dass wenn Sie Ihren Termin verschieben möchten, dieser erst abgesagt wird, wenn Sie die Details Ihres neuen Termins bestätigt haben.",
    t_rescheduleAppointmentButton: 'Abfrage neu planen',

    // cancellations
    t_cancelAppointment: 'Termin stornieren',
    t_cancelAppointmentButton: 'Termin stornieren',
    t_cancelApptConfirmHeader: 'Sind Sie sicher, dass Sie Ihren Termin absagen möchten?',
    t_cancelApptConfirmText: 'Sie erhalten eine E-Mail mit der Bestätigung Ihrer Terminabsage.',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nein',
    t_apptCancelledSuccessHeader: 'Erfolg',
    t_apptCancelledSuccessText: 'Ihr Termin wurde erfolgreich abgesagt.',
    t_apptCancelledErrorHeader: 'Fehler',
    t_apptCancelledErrorText: 'Beim Absagen Ihres Termins ist ein Fehler aufgetreten.',
    t_closeBtn: 'Schließen',

    // smart site
    t_helpBtn: `Hilfe`,
    t_ssInstruction1: 'Bitte wählen Sie eine Site aus der Liste unten aus und klicken Sie auf die Schaltfläche "Weiter". Sie können die Links- und Rechtspfeile verwenden, um weitere Websites anzuzeigen.',
    t_ssInstruction2: 'Um nach weiteren Sites oder Sites an einem anderen Ort zu suchen, geben Sie bitte Ihren bevorzugten Standort (Adresse/PLZ/Stadt) und Entfernung (Suchradius) ein und klicken Sie auf *search icon*.',
    t_ssInstruction3: 'Klicken Sie auf *reset icon*, um Ihre anfängliche Site-Liste anzuzeigen.',
    t_ssSearchInputPlaceholder: "z.B. '1666' oder 'Walnut Grove'",

    t_ssDropdownLabel: 'Entfernung auswählen',
    t_ssDropdownOption: 'Innerhalb von %0% %unit%',
    t_noSites: 'Es sind keine Sites verfügbar',
    t_locationInputPlaceholder: 'Geben Sie einen Standort ein',

    t_selectedDateLabel: 'Ausgewähltes Datum:',

    t_ssPopup1: `Da Sie sich nun für eine Studie qualifiziert haben, ist der nächste Schritt, einen Termin in einer Studienklinik zu buchen!<br>
    <br>
    Auf dieser Seite können Sie eine für Sie geeignete Site auswählen. Abhängig von der ausgewählten Site werden Ihnen nach dem Klicken auf die Schaltfläche "Weiter" verschiedene Optionen angezeigt:<br><br>
    1. Wenn die ausgewählte Website keine Online-Buchung anbietet, werden wir Sie in Kürze telefonisch kontaktieren, um einen Termin zu vereinbaren. <br><br>
    2. Wenn die ausgewählte Website eine Online-Buchung anbietet, haben Sie möglicherweise die Möglichkeit, die folgenden Arten von Terminen zu buchen:<br>`,
    t_ssPopup2: `• On-Demand-Video – ein virtueller Videotermin, der sofort stattfindet.<br><br>
    • Geplantes Video – ein virtueller Videotermin, den Sie für die Zukunft planen können.<br><br>
    • Geplantes Telefon – ein virtueller Telefontermin, den Sie für die Zukunft planen können.<br><br>
    • Vor Ort – ein persönlicher Termin an einem klinischen Standort, den Sie für die Zukunft planen können.
    <br>`,
    t_ssPopup3: `<strong>Was ist, wenn ich meinen Suchbereich ändern möchte?</strong><br><br>
    Kein Problem! Geben Sie eine Adresse und Entfernung (Suchradius) in die Suchleiste ein und wir stellen Ihnen eine neue Standortliste zur Auswahl zur Verfügung.<br><br>
    <strong>Was ist, wenn keine Websites für mich geeignet sind?</strong><br><br>
    Wenn Sie "Kein Forschungsstandort ist bequem" auswählen, werden wir uns mit Ihnen in Verbindung setzen, um die Optionen zu besprechen und einen Termin für Sie zu vereinbaren. <br><br>
    <strong>Was ist, wenn ich nicht sicher bin, wie ich zu meinem Termin komme.</strong><br><br>
    Wenn Sie auf die Kachel für den Standort klicken, zeigen wir Ihnen eine Wegbeschreibung zur Site an.`,

    revision_changed: {
      revision_changed_1: 'Die Fragen in dieser speziellen Umfrage wurden geändert, seit Sie sie gestartet haben. Daher müssen Sie bei Frage 1 neu beginnen.',
      revision_changed_2: 'Entschuldigung für etwaige Unannehmlichkeiten.',
      ctaText: "Neuen Fragebogen starten."
    },

    maintenanceError: {
      header: 'Geplante Wartung',
      body: 'Entschuldigung, wir sind derzeit wegen geplanter Wartungsarbeiten nicht erreichbar.'
    },

    t_appointmentAlreadyBookedHeader: 'Warte! Sie haben bereits einen Termin mit uns vereinbart.',
    t_appointmentAlreadyBookedBody: 'Wir können Sie nicht für einen anderen Termin buchen, da Sie bereits einen Termin bei uns haben. <br><br> Bitte überprüfen Sie Ihr Telefon oder durchsuchen Sie Ihren E-Mail-Posteingang nach den Bestätigungsdetails, die wir Ihnen zuvor gesendet haben. <br><br> Wenn Sie Änderungen an Ihrem bestehenden Termin vornehmen müssen, verwenden Sie bitte die in dieser E-Mail enthaltenen Kontaktinformationen.',
    t_appointmentAlreadyBookedFooter: 'Vielen Dank für Ihren Enthusiasmus und Ihr Interesse, Ihren Teil zum Fortschritt der medizinischen Forschung beizutragen.',
    t_vfovCompletionHeader: "<b><i>Hervorragend! Sie haben bereits einen Termin mit unserem Team vereinbart und sollten in Kürze wieder von uns hören.</i></b>",
    t_vfovCompletionBody: "Hat es bei Ihnen Veränderungen gegeben? Veränderungen Ihres Gesundheitszustands und Ihrer Lebensweise könnten Sie für zusätzliche klinische Forschungsstudien qualifizieren. Verwenden Sie den Link in Ihrer Bestätigungs-E-Mail, um einen aktualisierten Fragebogen auszufüllen und herauszufinden, ob Sie jetzt geeignet sind!",
    t_vfovCompletionFooter: "Vielen Dank für Ihr Engagement und Ihr Interesse, die klinische Forschung voranzutreiben!"
  },
  // ------ german austria
  de_AT: {
    t_date: `Datum`,
    t_time: `Zeit`,
    t_address: `Adresse`,
    t_tel: `Tel`,
    t_email: `E-Mail`,
    t_phone: `Telefon`,
    t_next: `Weiter`,
    t_vorherigeFrage: `Vorherige Frage`,
    t_nextQuestion: `Nächste Frage`,
    t_confirm: `Bestätigen`,
    t_goBack: `Zurück`,
    t_videoCall: `Videoanruf`,

    t_selectDate: "Im nächsten Schritt wird ein Termin mit dem Team des Prüfarztes vereinbart. Während dieses Besuches wird das Team des Prüfarztes die Studienanforderungen näher mit Ihnen besprechen und etwaige Fragen, die Sie haben, beantworten.",
    t_datesUnavailableLabel: "Daten nicht verfügbar",
    t_datesAvailableLabel: "Daten verfügbar",
    t_next: "Weiter",
    t_noAppointmentConvenient: "Kein Termin passt",
    t_previousQuestion: "Vorhergehende Frage",
    t_confirmToContact: "Bestätigen Sie Ihre Daten unten und wir werden Sie so rasch als möglich kontaktieren.",
    t_phone: "Telefonnummer",
    t_confirm: "Bestätigen",
    t_confirmCorrect: "Bitte bestätigen Sie, dass die folgenden Daten korrekt sind, bevor Sie Ihren Termin reservieren:",
    t_date: "Datum",
    t_time: "Uhrzeit",
    t_sendEmailConfirmation: "E-Mail-Bestätigung senden",
    t_sendSMSConfirmation: "SMS-Bestätigung senden",
    t_bookAppointment: "Termin vereinbaren",
    t_appointmentBooked: "Ihr Termin wurde erfolgreich vereinbart!",
    t_nextQuestion: "Nächste Frage",
    t_somebodyElseBooked: "Es tut uns leid, aber jemand anderes hat soeben diesen Termin vereinbart. Wählen Sie bitte ein unterschiedliches Datum oder eine andere Uhrzeit aus.",
    t_goBack: "Zurück",
    t_bookingConfirmed: "Hallo! Wir bestätigen Ihren Termin am %date% um %time% bei %facility%.",
    t_haveQuestionsContact: "Falls Sie Fragen haben oder diesen Termin ändern möchten, kontaktieren Sie bitte direkt das Prüfzentrum.",
    t_lookForward: "Bis bald!",
    t_regards: "Liebe Grüße,",
    t_unsubscribe: "Diese Benachrichtigungen abbestellen",
    t_bookingConfirmedContactOffice: "Der Termin bei %facility% am %date% um %time% ist bestätigt. Bitte rufen Sie das Büro unter der Nummer %phone% an, falls Sie Fragen haben.",

    t_selectDateVideo: "Bitte wählen Sie das Datum und die Uhrzeit aus, für die Sie Ihren Online-Videotermin planen möchten:",
    t_noAppointmentsAtTheMoment: "Ein Mitglied des Studienbetreuungsteams der Klinik wird sich mit Ihnen in Verbindung setzen, um einen Termin zu vereinbaren, der für Sie am besten geeignet ist.",
    t_noTimeSlotsAvailableMsg: "Keine Zeitfenster verfügbar. Bitte versuchen Sie es mit einem anderen Datum.",
    t_thankYouEndPage: "Vielen Dank",
    t_coronavirusText: "COVID-19 HINWEIS: Die Gesundheit und Sicherheit unserer Mitarbeiter und unserer Patienten hat für uns oberste Priorität. Bitte beachten Sie, dass unsere Forschungseinrichtungen keine medizinischen Zentren oder Krankenhäuser sind. Wir sehen oder behandeln keine kranken Patienten. Es sind jedoch Maßnahmen vorhanden, um sicherzustellen, dass unsere Einrichtungen die Besucher angemessen überprüfen und wichtige Standards für Hygiene und ordnungsgemäße Sterilisation einhalten.",
    t_selectAppointmentType: "Wie möchten Sie Ihren Termin durchführen?",
    t_appointmentTypeTitle: "Terminart",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Mobiltelefon",
    t_videNowCard: "Starten Sie JETZT Ihren Online-Videotermin",
    t_scheduleVideoCard: "Planen Sie Ihren Online-Videotermin für SPÄTER",
    t_scheduleInPersonCard: "Planen Sie einen persönlichen Besuch",
    t_videoNotSuitable: "Videotermin nicht geeignet",
    t_confirmVideoInfo: "Ihr Online-Videotermin findet an dem Datum und der Uhrzeit statt, die vom Team des Studienarztes ausgewählt wurden. Nach der Buchung Ihres Termins erhalten Sie eine Bestätigungs-E-Mail mit einem Link. Wenn Ihr Termin beginnen soll, klicken Sie auf den Link, um den Videoanruf automatisch zu starten.",
    t_videoModalText: "Groß! Sie müssen lediglich die folgenden Details bestätigen, bevor Sie Ihren Videoanruf starten:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "Um den Videotermin durchzuführen, benötigen Sie Zugriff auf ein Gerät mit einer Kamera und / oder einem Mikrofon (z. B. haben die meisten Smartphones und Laptops beide).",
    t_rebookModalIntro: "Sie haben bereits einen bestätigten Termin für diese Studie:",
    t_rebookModalContinueText: "Wenn Sie fortfahren, können Sie ein neues Datum und eine neue Uhrzeit für diesen Termin auswählen.",
    t_rebookModalNote: "Bitte beachten Sie, dass Ihr aktueller Termin erst abgesagt wird, wenn Sie die Details für Ihren neuen Termin bestätigt haben.",
    t_siteNameTitle: "Klinikdetails",
    t_videNowNotConvenientIntro: "Wenn jetzt für Sie nicht bequem ist:",
    t_videNowButton: "JETZT ANFANGEN",
    t_scheduleVideoButton: "ZEITPLAN VIDEO CALL",
    t_scheduleInPersonButton: "ZEITPLAN BESUCH",
    t_schedulePhoneCallCard: "Telefonanruf planen",
    t_schedulePhoneCallButton: "ZEITPLAN ANRUFEN",
    t_emrSelectProtocolsText: "Ausgewählte Protokolle:",
    t_selectDatePhone: "Bitte wählen Sie das Datum und die Uhrzeit aus, zu der Sie Ihren Telefontermin vereinbaren möchten:",
    t_selectDatePhoneSubtext: "Um den Telefontermin durchführen zu können, benötigen Sie an dem von Ihnen gewählten Datum Zugang zu einem Telefon.",
    t_confirmPhoneInfo: "Ihr telefonischer Termin findet zu dem Zeitpunkt und dem Datum statt, die vom Team des Klinikers vor Ort ausgewählt wurden. Bitte stellen Sie sicher, dass Sie über ein gutes Telefonsignal verfügen, um den Anruf entgegenzunehmen.",

    // rebook
    t_rebookModalIntro: "Sie haben bereits einen bestätigten Termin für diese Studie:",
    t_rebookModalContinueText: "Wenn Sie fortfahren, können Sie ein neues Datum und eine neue Uhrzeit für diesen Termin auswählen.",
    t_rebookModalContinueText2: `Bitte wählen Sie unten eine Option aus, um ein neues Datum und eine neue Uhrzeit für diesen Termin auszuwählen oder den Termin vollständig zu stornieren`,
    t_rebookModalNote: "Bitte beachten Sie, dass Ihr aktueller Termin erst storniert wird, wenn Sie die Details für Ihren neuen Termin bestätigt haben.",
    t_rebookModalNoteCancellations: "Bitte beachten Sie, dass wenn Sie Ihren Termin verschieben möchten, dieser erst abgesagt wird, wenn Sie die Details Ihres neuen Termins bestätigt haben.",
    t_rescheduleAppointmentButton: 'Abfrage neu planen',

    // cancellations
    t_cancelAppointment: 'Termin stornieren',
    t_cancelAppointmentButton: 'Termin stornieren',
    t_cancelApptConfirmHeader: 'Sind Sie sicher, dass Sie Ihren Termin absagen möchten?',
    t_cancelApptConfirmText: 'Sie erhalten eine E-Mail mit der Bestätigung Ihrer Terminabsage.',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nein',
    t_apptCancelledSuccessHeader: 'Erfolg',
    t_apptCancelledSuccessText: 'Ihr Termin wurde erfolgreich abgesagt.',
    t_apptCancelledErrorHeader: 'Fehler',
    t_apptCancelledErrorText: 'Beim Absagen Ihres Termins ist ein Fehler aufgetreten.',
    t_closeBtn: 'Schließen',

    // smart site
    t_helpBtn: `Hilfe`,
    t_ssInstruction1: 'Bitte wählen Sie eine Site aus der Liste unten aus und klicken Sie auf die Schaltfläche "Weiter". Sie können die Links- und Rechtspfeile verwenden, um weitere Websites anzuzeigen.',
    t_ssInstruction2: 'Um nach weiteren Sites oder Sites an einem anderen Ort zu suchen, geben Sie bitte Ihren bevorzugten Standort (Adresse/PLZ/Stadt) und Entfernung (Suchradius) ein und klicken Sie auf *search icon*.',
    t_ssInstruction3: 'Klicken Sie auf *reset icon*, um Ihre anfängliche Site-Liste anzuzeigen.',
    t_ssSearchInputPlaceholder: "z.B. '1666' oder 'Walnut Grove'",

    t_ssDropdownLabel: 'Entfernung auswählen',
    t_ssDropdownOption: 'Innerhalb von %0% %unit%',
    t_noSites: 'Es sind keine Sites verfügbar',
    t_locationInputPlaceholder: 'Geben Sie einen Standort ein',

    t_selectedDateLabel: 'Ausgewähltes Datum:',

    t_ssPopup1: `Da Sie sich nun für eine Studie qualifiziert haben, ist der nächste Schritt, einen Termin in einer Studienklinik zu buchen!<br>
    <br>
    Auf dieser Seite können Sie eine für Sie geeignete Site auswählen. Abhängig von der ausgewählten Site werden Ihnen nach dem Klicken auf die Schaltfläche "Weiter" verschiedene Optionen angezeigt:<br><br>
    1. Wenn die ausgewählte Website keine Online-Buchung anbietet, werden wir Sie in Kürze telefonisch kontaktieren, um einen Termin zu vereinbaren. <br><br>
    2. Wenn die ausgewählte Website eine Online-Buchung anbietet, haben Sie möglicherweise die Möglichkeit, die folgenden Arten von Terminen zu buchen:<br>`,
    t_ssPopup2: `• On-Demand-Video – ein virtueller Videotermin, der sofort stattfindet.<br><br>
    • Geplantes Video – ein virtueller Videotermin, den Sie für die Zukunft planen können.<br><br>
    • Geplantes Telefon – ein virtueller Telefontermin, den Sie für die Zukunft planen können.<br><br>
    • Vor Ort – ein persönlicher Termin an einem klinischen Standort, den Sie für die Zukunft planen können.
    <br>`,
    t_ssPopup3: `<strong>Was ist, wenn ich meinen Suchbereich ändern möchte?</strong><br><br>
    Kein Problem! Geben Sie eine Adresse und Entfernung (Suchradius) in die Suchleiste ein und wir stellen Ihnen eine neue Standortliste zur Auswahl zur Verfügung.<br><br>
    <strong>Was ist, wenn keine Websites für mich geeignet sind?</strong><br><br>
    Wenn Sie "Kein Forschungsstandort ist bequem" auswählen, werden wir uns mit Ihnen in Verbindung setzen, um die Optionen zu besprechen und einen Termin für Sie zu vereinbaren. <br><br>
    <strong>Was ist, wenn ich nicht sicher bin, wie ich zu meinem Termin komme.</strong><br><br>
    Wenn Sie auf die Kachel für den Standort klicken, zeigen wir Ihnen eine Wegbeschreibung zur Site an.`,

    revision_changed: {
      revision_changed_1: 'Die Fragen in dieser speziellen Umfrage wurden geändert, seit Sie sie gestartet haben. Daher müssen Sie bei Frage 1 neu beginnen.',
      revision_changed_2: 'Entschuldigung für etwaige Unannehmlichkeiten.',
      ctaText: "Neuen Fragebogen starten."
    },

    maintenanceError: {
      header: 'Geplante Wartung',
      body: 'Entschuldigung, wir sind derzeit wegen geplanter Wartungsarbeiten nicht erreichbar.'
    },

    t_appointmentAlreadyBookedHeader: 'Warte! Sie haben bereits einen Termin mit uns vereinbart.',
    t_appointmentAlreadyBookedBody: 'Wir können Sie nicht für einen anderen Termin buchen, da Sie bereits einen Termin bei uns haben. <br><br> Bitte überprüfen Sie Ihr Telefon oder durchsuchen Sie Ihren E-Mail-Posteingang nach den Bestätigungsdetails, die wir Ihnen zuvor gesendet haben. <br><br> Wenn Sie Änderungen an Ihrem bestehenden Termin vornehmen müssen, verwenden Sie bitte die in dieser E-Mail enthaltenen Kontaktinformationen.',
    t_appointmentAlreadyBookedFooter: 'Vielen Dank für Ihren Enthusiasmus und Ihr Interesse, Ihren Teil zum Fortschritt der medizinischen Forschung beizutragen.'
  },
  // french
  fr_FR: {
    t_date: `Date`,
    t_time: `Heure`,
    t_address: `Adresse`,
    t_tel: `Tél`,
    t_email: `E-mail`,
    t_phone: `Téléphone`,
    t_next: `Suivant`,
    t_previousQuestion: `Question précédente`,
    t_nextQuestion: `Question suivante`,
    t_confirm: `Confirmer`,
    t_goBack: `Revenir en arrière`,
    t_videoCall: `Appel vidéo`,

    t_selectDate: "La prochaine étape consiste à vous fixer un rendez-vous avec l'équipe du médecin de l'étude. Au cours de cette visite, l'équipe du médecin de l'étude vous parlera de manière plus approfondie des exigences de l'étude et répondra à vos éventuelles questions.",
    t_selectDateVideo: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez planifier votre rendez-vous vidéo en ligne:",
    t_datesUnavailableLabel: "Dates indisponibles",
    t_datesAvailableLabel: "Dates disponibles",
    t_next: "Suivant",
    t_noAppointmentConvenient: "Aucune heure de rendez-vous n'est appropriée",
    t_previousQuestion: "Question précédente",
    t_noAppointmentsAtTheMoment: "Malheureusement, il n'existe aucun rendez-vous disponible ou approprié dans ce centre pour le moment.",
    t_confirmToContact: "Confirmez vos coordonnées ci-dessous et nous vous contacterons aussi rapidement que possible.",
    t_phone: "Téléphone",
    t_confirm: "Confirmez",
    t_confirmCorrect: "Veuillez confirmer que les informations suivantes sont correctes avant de fixer votre rendez-vous :",
    t_date: "Date",
    t_time: "Heure",
    t_address: "Adresse",
    t_sendEmailConfirmation: "Envoyer une confirmation par courriel",
    t_sendSMSConfirmation: "Envoyer une confirmation par message texte",
    t_bookAppointment: "Prendre rendez-vous",
    t_appointmentBooked: "Votre rendez-vous a été pris avec succès!",
    t_nextQuestion: "Question suivante",
    t_somebodyElseBooked: "Désolé, quelqu'un d'autre vient juste de réserver ce créneau de rendez-vous. Essayez de sélectionner une autre date ou un créneau horaire différent.",
    t_goBack: "Revenir en arrière",
    t_bookingConfirmed: "Bonjour. Votre rendez vous du %date% à %time% avec %facility% est confirmé.",
    t_haveQuestionsContact: "Si vous avez des questions ou si vous avez besoin de modifier ce rendez-vous, veuillez contacter directement le cabinet du médecin de l'étude.",
    t_lookForward: "Nous avons hâte de vous rencontrer!",
    t_regards: "Cordialement",
    t_unsubscribe: "Se désabonner de ces alertes",
    t_bookingConfirmedContactOffice: "Rendez-vous confirmé avec %facility% le %date% à %time%. Veuillez appeler le cabinet au %phone% si vous avez des questions",
    t_thankYouEndPage: "Merci",
    t_coronavirusText: "COVID-19 REMARQUE: La santé et la sécurité de nos employés et de nos patients est notre priorité absolue. Veuillez noter que nos installations de recherche ne sont pas des centres médicaux ou des hôpitaux. Nous ne voyons ni ne traitons les patients malades. Des mesures sont en place, cependant, pour nous assurer que nos installations contrôlent correctement les visiteurs et respectent les normes importantes d'hygiène et de stérilisation appropriées. ",
    t_selectAppointmentType: "Comment souhaitez-vous organiser votre rendez-vous?",
    t_appointmentTypeTitle: "Type de rendez-vous",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Téléphone portable",
    t_videNowCard: "Commencez votre rendez-vous vidéo en ligne <br> MAINTENANT",
    t_scheduleVideoCard: "Planifiez votre rendez-vous vidéo en ligne pour plus tard",
    t_scheduleInPersonCard: "Planifier une visite en personne",
    t_videoNotSuitable: "Rendez-vous vidéo ne convient pas",
    t_confirmVideoInfo: "Votre rendez-vous vidéo en ligne aura lieu à la date et à l'heure sélectionnées avec l'équipe du médecin de l'étude. Après avoir pris votre rendez-vous, vous recevrez un e-mail de confirmation contenant un lien. Lorsque votre rendez-vous doit commencer, cliquez sur le lien pour démarrer automatiquement l'appel vidéo. ",
    t_videoModalText: "Génial! Nous avons juste besoin que vous confirmiez les détails suivants avant de commencer votre appel vidéo:",
    t_optional: "(Facultatif)",
    t_selectDateVideoSubtext: "Pour organiser le rendez-vous vidéo, vous devrez accéder à un appareil avec une caméra et / ou un microphone (par exemple, la plupart des téléphones intelligents et des ordinateurs portables ont les deux).",
    t_siteNameTitle: "Détails du site",
    t_videNowNotConvenientIntro: "Si cela ne vous convient pas:",
    t_videNowButton: "COMMENCER MAINTENANT",
    t_scheduleVideoButton: "PLANIFIER UN APPEL VIDÉO",
    t_scheduleInPersonButton: "PLANIFIER UNE VISITE",
    t_schedulePhoneCallCard: "Planifier un appel téléphonique",
    t_schedulePhoneCallButton: "PLANIFIER UN APPEL",
    t_emrSelectProtocolsText: "Protocoles sélectionnés:",
    t_selectDatePhone: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez prendre rendez-vous téléphonique:",
    t_selectDatePhoneSubtext: "Afin de prendre rendez-vous téléphonique, vous aurez besoin d'accéder à un téléphone à la date choisie.",
    t_confirmPhoneInfo: "Votre rendez-vous téléphonique aura lieu à l'heure et à la date sélectionnées avec l'équipe de cliniciens du site. Veuillez vous assurer que vous êtes disponible avec un bon signal téléphonique pour recevoir l'appel.",

    // Rebook
    t_rebookModalIntro: "Les réservations pour cette enquête ont déjà été confirmées :",
    t_rebookModalContinueText: "Si vous continuez, vous pourrez sélectionner une nouvelle date et heure pour ce rendez-vous.",
    t_rebookModalContinueText2: `Veuillez sélectionner une option ci-dessous pour sélectionner une nouvelle date et heure pour ce rendez-vous ou pour annuler complètement le rendez-vous`,
    t_rebookModalNote: "Veuillez noter que le rendez-vous en cours ne sera pas annulé tant que vous n'aurez pas confirmé les détails du nouveau rendez-vous.",
    t_rebookModalNoteCancellations: "Si vous souhaitez reprogrammer une réservation, veuillez noter qu'elle ne sera pas annulée tant que vous n'aurez pas confirmé les détails de la nouvelle réservation.",
    t_rescheduleAppointmentButton: 'Replanifier la requête',

    // cancellations
    t_cancelAppointment: 'Annuler la réservation',
    t_cancelAppointmentButton: 'Annuler la réservation',
    t_cancelApptConfirmHeader: 'Êtes-vous sûr de vouloir annuler votre réservation ? ',
    t_cancelApptConfirmText: `Vous recevrez un e-mail confirmant l'annulation de votre réservation. `,
    t_cancelApptBtn: 'Oui',
    t_cancelBackBtn: 'Non',
    t_apptCancelledSuccessHeader: 'Succès',
    t_apptCancelledSuccessText: 'Le rendez-vous a été annulé avec succès. ',
    t_apptCancelledErrorHeader: 'Erreur',
    t_apptCancelledErrorText: `Une erreur s'est produite lors de l'annulation de la réservation. `,
    t_closeBtn: 'fermer',

    // smart site
    t_helpBtn: `Aide`,
    t_ssInstruction1: `Veuillez sélectionner un site dans la liste ci-dessous et cliquez sur le bouton « Suivant ». Vous pouvez utiliser les flèches gauche et droite pour voir plus de sites.`,
    t_ssInstruction2: `Pour rechercher des sites supplémentaires ou des sites dans un emplacement différent, veuillez entrer votre emplacement préféré (Adresse/Zip/Ville) et la distance (rayon de recherche), et cliquez sur *search icon*.`,
    t_ssInstruction3: `Cliquez sur *reset icon* pour afficher votre liste initiale de sites.`,
    t_ssSearchInputPlaceholder: `Par exemple, '1666' ou 'Walnut Grove'`,

    t_ssDropdownLabel: 'Sélectionner la distance',
    t_ssDropdownOption: 'Dans %0% %unit%',
    t_noSites: `Il n'y a pas de sites disponibles`,
    t_locationInputPlaceholder: `Entrez un emplacement`,

    t_selectedDateLabel: 'Date sélectionnée:',

    t_ssPopup1: `Maintenant que vous êtes qualifié pour une étude, la prochaine étape consiste à prendre rendez-vous avec une clinique d'étude !<br>
    <br>
    Sur cette page, vous pouvez sélectionner un site qui vous convient. Selon le site sélectionné, plusieurs options différentes vous seront présentées une fois que vous aurez cliqué sur le bouton « Suivant » :<br><br>
    1. Si le site sélectionné ne propose pas de réservation en ligne, nous vous contacterons sous peu par téléphone pour prendre rendez-vous. <br><br>
    2. Si le site sélectionné propose une réservation en ligne, vous pouvez avoir la possibilité de réserver les types de rendez-vous suivants :<br>`,
    t_ssPopup2: `• Vidéo à la demande – un rendez-vous vidéo virtuel qui aura lieu immédiatement.<br><br>
    • Vidéo programmée : un rendez-vous vidéo virtuel que vous pouvez planifier pour l'avenir.<br><br>
    • Téléphone programmé – un rendez-vous téléphonique virtuel que vous pouvez programmer pour l'avenir.<br><br>
    • Sur place – un rendez-vous en personne sur un site clinique que vous pouvez planifier pour l'avenir.
    <br>`,
    t_ssPopup3: `<strong>Et si je veux changer ma zone de recherche ?</strong><br><br>
    Aucun problème! Entrez une adresse et une distance (rayon de recherche) dans la barre de recherche, et nous vous fournirons une nouvelle liste de sites parmi laquelle choisir.<br><br>
    <strong>Et si aucun site ne me convient ?</strong><br><br>
    Si vous sélectionnez « Aucun site de recherche n'est pratique », nous vous contacterons pour discuter des options et vous fixer un rendez-vous. <br><br>
    <strong>Que faire si je ne sais pas comment me rendre à mon rendez-vous.</strong><br><br>
    Si vous cliquez sur la vignette d'emplacement, nous vous montrerons les directions de conduite et de transit vers le site.`,

    revision_changed: {
      revision_changed_1: `Cette question particulière de l'enquête a changé depuis qu'elle a commencé. Par conséquent, vous devez recommencer à partir de la question 1.`,
      revision_changed_2: 'Désolé pour le désagrément. ',
      ctaText: "Démarrer une nouvelle enquête."
    },

    maintenanceError: {
      header: 'Entretien régulier',
      body: "Désolé, il est actuellement en panne en raison d'un entretien régulier."
    },

    t_appointmentAlreadyBookedHeader: 'Attendez ! Vous avez déjà un rendez-vous avec nous.',
    t_appointmentAlreadyBookedBody: 'Nous ne pouvons pas vous réserver pour un autre rendez-vous, car vous en avez déjà un prévu avec nous. <br><br> Veuillez vérifier votre téléphone ou rechercher dans votre boîte de réception les détails de confirmation que nous avons envoyés précédemment. <br><br> Si vous devez apporter des modifications à votre rendez-vous existant, veuillez utiliser les coordonnées contenues dans cet e-mail.',
    t_appointmentAlreadyBookedFooter: "Merci pour votre enthousiasme et votre intérêt à faire votre part pour faire avancer la recherche médicale."
  },
  // ------ french canadian
  fr_CA: {
    t_date: `Date`,
    t_time: `Heure`,
    t_address: `Adresse`,
    t_tel: `Tél`,
    t_email: `E-mail`,
    t_phone: `Téléphone`,
    t_next: `Suivant`,
    t_previousQuestion: `Question précédente`,
    t_nextQuestion: `Question suivante`,
    t_confirm: `Confirmer`,
    t_goBack: `Revenir en arrière`,
    t_videoCall: `Appel vidéo`,

    t_selectDate: "La prochaine étape consiste à vous fixer un rendez-vous avec l'équipe du médecin de l'étude. Au cours de cette visite, l'équipe du médecin de l'étude vous parlera de manière plus approfondie des exigences de l'étude et répondra à vos éventuelles questions.",
    t_selectDateVideo: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez planifier votre rendez-vous vidéo en ligne:",
    t_datesUnavailableLabel: "Dates indisponibles",
    t_datesAvailableLabel: "Dates disponibles",
    t_next: "Suivant",
    t_noAppointmentConvenient: "Aucune heure de rendez-vous n'est appropriée",
    t_previousQuestion: "Question précédente",
    t_noAppointmentsAtTheMoment: "Malheureusement, il n'existe aucun rendez-vous disponible ou approprié dans ce centre pour le moment.",
    t_confirmToContact: "Confirmez vos coordonnées ci-dessous et nous vous contacterons aussi rapidement que possible.",
    t_phone: "Téléphone",
    t_confirm: "Confirmez",
    t_confirmCorrect: "Veuillez confirmer que les informations suivantes sont correctes avant de fixer votre rendez-vous :",
    t_date: "Date",
    t_time: "Heure",
    t_address: "Adresse",
    t_sendEmailConfirmation: "Envoyer une confirmation par courriel",
    t_sendSMSConfirmation: "Envoyer une confirmation par message texte",
    t_bookAppointment: "Prendre rendez-vous",
    t_appointmentBooked: "Votre rendez-vous a été pris avec succès!",
    t_nextQuestion: "Question suivante",
    t_somebodyElseBooked: "Désolé, quelqu'un d'autre vient juste de réserver ce créneau de rendez-vous. Essayez de sélectionner une autre date ou un créneau horaire différent.",
    t_goBack: "Revenir en arrière",
    t_bookingConfirmed: "Bonjour. Votre rendez vous du %date% à %time% avec %facility% est confirmé.",
    t_haveQuestionsContact: "Si vous avez des questions ou si vous avez besoin de modifier ce rendez-vous, veuillez contacter directement le cabinet du médecin de l'étude.",
    t_lookForward: "Nous avons hâte de vous rencontrer!",
    t_regards: "Cordialement",
    t_unsubscribe: "Se désabonner de ces alertes",
    t_bookingConfirmedContactOffice: "Rendez-vous confirmé avec %facility% le %date% à %time%. Veuillez appeler le cabinet au %phone% si vous avez des questions",
    t_thankYouEndPage: "Merci",
    t_coronavirusText: "COVID-19 REMARQUE: La santé et la sécurité de nos employés et de nos patients est notre priorité absolue. Veuillez noter que nos installations de recherche ne sont pas des centres médicaux ou des hôpitaux. Nous ne voyons ni ne traitons les patients malades. Des mesures sont en place, cependant, pour nous assurer que nos installations contrôlent correctement les visiteurs et respectent les normes importantes d'hygiène et de stérilisation appropriées. ",
    t_selectAppointmentType: "Comment souhaitez-vous organiser votre rendez-vous?",
    t_appointmentTypeTitle: "Type de rendez-vous",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Téléphone portable",
    t_videNowCard: "Commencez votre rendez-vous vidéo en ligne <br> MAINTENANT",
    t_scheduleVideoCard: "Planifiez votre rendez-vous vidéo en ligne pour plus tard",
    t_scheduleInPersonCard: "Planifier une visite en personne",
    t_videoNotSuitable: "Rendez-vous vidéo ne convient pas",
    t_confirmVideoInfo: "Votre rendez-vous vidéo en ligne aura lieu à la date et à l'heure sélectionnées avec l'équipe du médecin de l'étude. Après avoir pris votre rendez-vous, vous recevrez un e-mail de confirmation contenant un lien. Lorsque votre rendez-vous doit commencer, cliquez sur le lien pour démarrer automatiquement l'appel vidéo. ",
    t_videoModalText: "Génial! Nous avons juste besoin que vous confirmiez les détails suivants avant de commencer votre appel vidéo:",
    t_optional: "(Facultatif)",
    t_selectDateVideoSubtext: "Pour organiser le rendez-vous vidéo, vous devrez accéder à un appareil avec une caméra et / ou un microphone (par exemple, la plupart des téléphones intelligents et des ordinateurs portables ont les deux).",
    t_siteNameTitle: "Détails du site",
    t_videNowNotConvenientIntro: "Si cela ne vous convient pas:",
    t_videNowButton: "COMMENCER MAINTENANT",
    t_scheduleVideoButton: "PLANIFIER UN APPEL VIDÉO",
    t_scheduleInPersonButton: "PLANIFIER UNE VISITE",
    t_schedulePhoneCallCard: "Planifier un appel téléphonique",
    t_schedulePhoneCallButton: "PLANIFIER UN APPEL",
    t_emrSelectProtocolsText: "Protocoles sélectionnés:",
    t_selectDatePhone: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez prendre rendez-vous téléphonique:",
    t_selectDatePhoneSubtext: "Afin de prendre rendez-vous téléphonique, vous aurez besoin d'accéder à un téléphone à la date choisie.",
    t_confirmPhoneInfo: "Votre rendez-vous téléphonique aura lieu à l'heure et à la date sélectionnées avec l'équipe de cliniciens du site. Veuillez vous assurer que vous êtes disponible avec un bon signal téléphonique pour recevoir l'appel.",

    // Rebook
    t_rebookModalIntro: "Les réservations pour cette enquête ont déjà été confirmées :",
    t_rebookModalContinueText: "Si vous continuez, vous pourrez sélectionner une nouvelle date et heure pour ce rendez-vous.",
    t_rebookModalContinueText2: `Veuillez sélectionner une option ci-dessous pour sélectionner une nouvelle date et heure pour ce rendez-vous ou pour annuler complètement le rendez-vous`,
    t_rebookModalNote: "Veuillez noter que le rendez-vous en cours ne sera pas annulé tant que vous n'aurez pas confirmé les détails du nouveau rendez-vous.",
    t_rebookModalNoteCancellations: "Si vous souhaitez reprogrammer une réservation, veuillez noter qu'elle ne sera pas annulée tant que vous n'aurez pas confirmé les détails de la nouvelle réservation.",
    t_rescheduleAppointmentButton: 'Replanifier la requête',

    // cancellations
    t_cancelAppointment: 'Annuler la réservation',
    t_cancelAppointmentButton: 'Annuler la réservation',
    t_cancelApptConfirmHeader: 'Êtes-vous sûr de vouloir annuler votre réservation ? ',
    t_cancelApptConfirmText: `Vous recevrez un e-mail confirmant l'annulation de votre réservation. `,
    t_cancelApptBtn: 'Oui',
    t_cancelBackBtn: 'Non',
    t_apptCancelledSuccessHeader: 'Succès',
    t_apptCancelledSuccessText: 'Le rendez-vous a été annulé avec succès. ',
    t_apptCancelledErrorHeader: 'Erreur',
    t_apptCancelledErrorText: `Une erreur s'est produite lors de l'annulation de la réservation. `,
    t_closeBtn: 'fermer',

    // smart site
    t_helpBtn: `Aide`,
    t_ssInstruction1: `Veuillez sélectionner un site dans la liste ci-dessous et cliquez sur le bouton « Suivant ». Vous pouvez utiliser les flèches gauche et droite pour voir plus de sites.`,
    t_ssInstruction2: `Pour rechercher des sites supplémentaires ou des sites dans un emplacement différent, veuillez entrer votre emplacement préféré (Adresse/Zip/Ville) et la distance (rayon de recherche), et cliquez sur *search icon*.`,
    t_ssInstruction3: `Cliquez sur *reset icon* pour afficher votre liste initiale de sites.`,
    t_ssSearchInputPlaceholder: `Par exemple, '1666' ou 'Walnut Grove'`,

    t_ssDropdownLabel: 'Sélectionner la distance',
    t_ssDropdownOption: 'Dans %0% %unit%',
    t_noSites: `Il n'y a pas de sites disponibles`,
    t_locationInputPlaceholder: `Entrez un emplacement`,

    t_selectedDateLabel: 'Date sélectionnée:',

    t_ssPopup1: `Maintenant que vous êtes qualifié pour une étude, la prochaine étape consiste à prendre rendez-vous avec une clinique d'étude !<br>
    <br>
    Sur cette page, vous pouvez sélectionner un site qui vous convient. Selon le site sélectionné, plusieurs options différentes vous seront présentées une fois que vous aurez cliqué sur le bouton « Suivant » :<br><br>
    1. Si le site sélectionné ne propose pas de réservation en ligne, nous vous contacterons sous peu par téléphone pour prendre rendez-vous. <br><br>
    2. Si le site sélectionné propose une réservation en ligne, vous pouvez avoir la possibilité de réserver les types de rendez-vous suivants :<br>`,
    t_ssPopup2: `• Vidéo à la demande – un rendez-vous vidéo virtuel qui aura lieu immédiatement.<br><br>
    • Vidéo programmée : un rendez-vous vidéo virtuel que vous pouvez planifier pour l'avenir.<br><br>
    • Téléphone programmé – un rendez-vous téléphonique virtuel que vous pouvez programmer pour l'avenir.<br><br>
    • Sur place – un rendez-vous en personne sur un site clinique que vous pouvez planifier pour l'avenir.
    <br>`,
    t_ssPopup3: `<strong>Et si je veux changer ma zone de recherche ?</strong><br><br>
    Aucun problème! Entrez une adresse et une distance (rayon de recherche) dans la barre de recherche, et nous vous fournirons une nouvelle liste de sites parmi laquelle choisir.<br><br>
    <strong>Et si aucun site ne me convient ?</strong><br><br>
    Si vous sélectionnez « Aucun site de recherche n'est pratique », nous vous contacterons pour discuter des options et vous fixer un rendez-vous. <br><br>
    <strong>Que faire si je ne sais pas comment me rendre à mon rendez-vous.</strong><br><br>
    Si vous cliquez sur la vignette d'emplacement, nous vous montrerons les directions de conduite et de transit vers le site.`,

    revision_changed: {
      revision_changed_1: `Cette question particulière de l'enquête a changé depuis qu'elle a commencé. Par conséquent, vous devez recommencer à partir de la question 1.`,
      revision_changed_2: 'Désolé pour le désagrément. ',
      ctaText: "Démarrer une nouvelle enquête."
    },

    maintenanceError: {
      header: 'Entretien régulier',
      body: "Désolé, il est actuellement en panne en raison d'un entretien régulier."
    },

    t_appointmentAlreadyBookedHeader: 'Attendez ! Vous avez déjà un rendez-vous avec nous.',
    t_appointmentAlreadyBookedBody: 'Nous ne pouvons pas vous réserver pour un autre rendez-vous, car vous en avez déjà un prévu avec nous. <br><br> Veuillez vérifier votre téléphone ou rechercher dans votre boîte de réception les détails de confirmation que nous avons envoyés précédemment. <br><br> Si vous devez apporter des modifications à votre rendez-vous existant, veuillez utiliser les coordonnées contenues dans cet e-mail.',
    t_appointmentAlreadyBookedFooter: "Merci pour votre enthousiasme et votre intérêt à faire votre part pour faire avancer la recherche médicale."
  },
  // ------ french belgian
  fr_BE: {
    t_date: `Date`,
    t_time: `Heure`,
    t_address: `Adresse`,
    t_tel: `Tél`,
    t_email: `E-mail`,
    t_phone: `Téléphone`,
    t_next: `Suivant`,
    t_previousQuestion: `Question précédente`,
    t_nextQuestion: `Question suivante`,
    t_confirm: `Confirmer`,
    t_goBack: `Revenir en arrière`,
    t_videoCall: `Appel vidéo`,

    t_selectDate: "La prochaine étape consiste à vous fixer un rendez-vous avec l'équipe du médecin de l'étude. Au cours de cette visite, l'équipe du médecin de l'étude vous parlera de manière plus approfondie des exigences de l'étude et répondra à vos éventuelles questions.",
    t_selectDateVideo: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez planifier votre rendez-vous vidéo en ligne:",
    t_datesUnavailableLabel: "Dates indisponibles",
    t_datesAvailableLabel: "Dates disponibles",
    t_next: "Suivant",
    t_noAppointmentConvenient: "Aucune heure de rendez-vous n'est appropriée",
    t_previousQuestion: "Question précédente",
    t_noAppointmentsAtTheMoment: "Malheureusement, il n'existe aucun rendez-vous disponible ou approprié dans ce centre pour le moment.",
    t_confirmToContact: "Confirmez vos coordonnées ci-dessous et nous vous contacterons aussi rapidement que possible.",
    t_phone: "Téléphone",
    t_confirm: "Confirmez",
    t_confirmCorrect: "Veuillez confirmer que les informations suivantes sont correctes avant de fixer votre rendez-vous :",
    t_date: "Date",
    t_time: "Heure",
    t_address: "Adresse",
    t_sendEmailConfirmation: "Envoyer une confirmation par e-mail",
    t_sendSMSConfirmation: "Envoyer une confirmation par SMS",
    t_bookAppointment: "Prendre rendez-vous",
    t_appointmentBooked: "Votre rendez-vous a été pris avec succès !",
    t_nextQuestion: "Question suivante",
    t_somebodyElseBooked: "Désolé, quelqu'un d'autre vient juste de réserver ce créneau de rendez-vous. Essayez de sélectionner une autre date ou un créneau horaire différent.",
    t_goBack: "Revenir en arrière",
    t_bookingConfirmed: "Bonjour. Votre rendez vous du %date% à %time% avec %facility% est confirmé.",
    t_haveQuestionsContact: "Si vous avez des questions ou si vous avez besoin de modifier ce rendez-vous, veuillez contacter directement le cabinet du médecin de l'étude.",
    t_lookForward: "Nous avons hâte de vous rencontrer !",
    t_regards: "Cordialement",
    t_unsubscribe: "Se désabonner de ces alertes",
    t_bookingConfirmedContactOffice: "Rendez-vous confirmé avec %facility% le %date% à %time%. Veuillez appeler le cabinet au %phone% si vous avez des questions",
    t_thankYouEndPage: "Merci",
    t_coronavirusText: "COVID-19 REMARQUE: La santé et la sécurité de nos employés et de nos patients est notre priorité absolue. Veuillez noter que nos installations de recherche ne sont pas des centres médicaux ou des hôpitaux. Nous ne voyons ni ne traitons les patients malades. Des mesures sont en place, cependant, pour nous assurer que nos installations contrôlent correctement les visiteurs et respectent les normes importantes d'hygiène et de stérilisation appropriées. ",
    t_selectAppointmentType: "Comment souhaitez-vous organiser votre rendez-vous?",
    t_appointmentTypeTitle: "Type de rendez-vous",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Téléphone portable",
    t_videNowCard: "Commencez votre rendez-vous vidéo en ligne <br> MAINTENANT",
    t_scheduleVideoCard: "Planifiez votre rendez-vous vidéo en ligne pour plus tard",
    t_scheduleInPersonCard: "Planifier une visite en personne",
    t_videoNotSuitable: "Rendez-vous vidéo ne convient pas",
    t_confirmVideoInfo: "Votre rendez-vous vidéo en ligne aura lieu à la date et à l'heure sélectionnées avec l'équipe du médecin de l'étude. Après avoir pris votre rendez-vous, vous recevrez un e-mail de confirmation contenant un lien. Lorsque votre rendez-vous doit commencer, cliquez sur le lien pour démarrer automatiquement l'appel vidéo. ",
    t_videoModalText: "Génial! Nous avons juste besoin que vous confirmiez les détails suivants avant de commencer votre appel vidéo:",
    t_optional: "(Facultatif)",
    t_selectDateVideoSubtext: "Pour organiser le rendez-vous vidéo, vous devrez accéder à un appareil avec une caméra et / ou un microphone (par exemple, la plupart des téléphones intelligents et des ordinateurs portables ont les deux).",
    t_siteNameTitle: "Détails du site",
    t_videNowNotConvenientIntro: "Si cela ne vous convient pas:",
    t_videNowButton: "COMMENCER MAINTENANT",
    t_scheduleVideoButton: "PLANIFIER UN APPEL VIDÉO",
    t_scheduleInPersonButton: "PLANIFIER UNE VISITE",
    t_schedulePhoneCallCard: "Planifier un appel téléphonique",
    t_schedulePhoneCallButton: "PLANIFIER UN APPEL",
    t_emrSelectProtocolsText: "Protocoles sélectionnés:",
    t_selectDatePhone: "Veuillez sélectionner la date et l'heure auxquelles vous souhaitez prendre rendez-vous téléphonique:",
    t_selectDatePhoneSubtext: "Afin de prendre rendez-vous téléphonique, vous aurez besoin d'accéder à un téléphone à la date choisie.",
    t_confirmPhoneInfo: "Votre rendez-vous téléphonique aura lieu à l'heure et à la date sélectionnées avec l'équipe de cliniciens du site. Veuillez vous assurer que vous êtes disponible avec un bon signal téléphonique pour recevoir l'appel.",

    // Rebook
    t_rebookModalIntro: "Les réservations pour cette enquête ont déjà été confirmées :",
    t_rebookModalContinueText: "Si vous continuez, vous pourrez sélectionner une nouvelle date et heure pour ce rendez-vous.",
    t_rebookModalContinueText2: `Veuillez sélectionner une option ci-dessous pour sélectionner une nouvelle date et heure pour ce rendez-vous ou pour annuler complètement le rendez-vous`,
    t_rebookModalNote: "Veuillez noter que le rendez-vous en cours ne sera pas annulé tant que vous n'aurez pas confirmé les détails du nouveau rendez-vous.",
    t_rebookModalNoteCancellations: "Si vous souhaitez reprogrammer une réservation, veuillez noter qu'elle ne sera pas annulée tant que vous n'aurez pas confirmé les détails de la nouvelle réservation.",
    t_rescheduleAppointmentButton: 'Replanifier la requête',

    // cancellations
    t_cancelAppointment: 'Annuler la réservation',
    t_cancelAppointmentButton: 'Annuler la réservation',
    t_cancelApptConfirmHeader: 'Êtes-vous sûr de vouloir annuler votre réservation ? ',
    t_cancelApptConfirmText: `Vous recevrez un e-mail confirmant l'annulation de votre réservation. `,
    t_cancelApptBtn: 'Oui',
    t_cancelBackBtn: 'Non',
    t_apptCancelledSuccessHeader: 'Succès',
    t_apptCancelledSuccessText: 'Le rendez-vous a été annulé avec succès. ',
    t_apptCancelledErrorHeader: 'Erreur',
    t_apptCancelledErrorText: `Une erreur s'est produite lors de l'annulation de la réservation. `,
    t_closeBtn: 'fermer',

    // smart site
    t_helpBtn: `Aide`,
    t_ssInstruction1: `Veuillez sélectionner un site dans la liste ci-dessous et cliquez sur le bouton « Suivant ». Vous pouvez utiliser les flèches gauche et droite pour voir plus de sites.`,
    t_ssInstruction2: `Pour rechercher des sites supplémentaires ou des sites dans un emplacement différent, veuillez entrer votre emplacement préféré (Adresse/Zip/Ville) et la distance (rayon de recherche), et cliquez sur *search icon*.`,
    t_ssInstruction3: `Cliquez sur *reset icon* pour afficher votre liste initiale de sites.`,
    t_ssSearchInputPlaceholder: `Par exemple, '1666' ou 'Walnut Grove'`,

    t_ssDropdownLabel: 'Sélectionner la distance',
    t_ssDropdownOption: 'Dans %0% %unit%',
    t_noSites: `Il n'y a pas de sites disponibles`,
    t_locationInputPlaceholder: `Entrez un emplacement`,

    t_selectedDateLabel: 'Date sélectionnée:',

    t_ssPopup1: `Maintenant que vous êtes qualifié pour une étude, la prochaine étape consiste à prendre rendez-vous avec une clinique d'étude !<br>
    <br>
    Sur cette page, vous pouvez sélectionner un site qui vous convient. Selon le site sélectionné, plusieurs options différentes vous seront présentées une fois que vous aurez cliqué sur le bouton « Suivant » :<br><br>
    1. Si le site sélectionné ne propose pas de réservation en ligne, nous vous contacterons sous peu par téléphone pour prendre rendez-vous. <br><br>
    2. Si le site sélectionné propose une réservation en ligne, vous pouvez avoir la possibilité de réserver les types de rendez-vous suivants :<br>`,
    t_ssPopup2: `• Vidéo à la demande – un rendez-vous vidéo virtuel qui aura lieu immédiatement.<br><br>
    • Vidéo programmée : un rendez-vous vidéo virtuel que vous pouvez planifier pour l'avenir.<br><br>
    • Téléphone programmé – un rendez-vous téléphonique virtuel que vous pouvez programmer pour l'avenir.<br><br>
    • Sur place – un rendez-vous en personne sur un site clinique que vous pouvez planifier pour l'avenir.
    <br>`,
    t_ssPopup3: `<strong>Et si je veux changer ma zone de recherche ?</strong><br><br>
    Aucun problème! Entrez une adresse et une distance (rayon de recherche) dans la barre de recherche, et nous vous fournirons une nouvelle liste de sites parmi laquelle choisir.<br><br>
    <strong>Et si aucun site ne me convient ?</strong><br><br>
    Si vous sélectionnez « Aucun site de recherche n'est pratique », nous vous contacterons pour discuter des options et vous fixer un rendez-vous. <br><br>
    <strong>Que faire si je ne sais pas comment me rendre à mon rendez-vous.</strong><br><br>
    Si vous cliquez sur la vignette d'emplacement, nous vous montrerons les directions de conduite et de transit vers le site.`,

    revision_changed: {
      revision_changed_1: `Cette question particulière de l'enquête a changé depuis qu'elle a commencé. Par conséquent, vous devez recommencer à partir de la question 1.`,
      revision_changed_2: 'Désolé pour le désagrément. ',
      ctaText: "Démarrer une nouvelle enquête."
    },

    maintenanceError: {
      header: 'Entretien régulier',
      body: "Désolé, il est actuellement en panne en raison d'un entretien régulier."
    },

    t_appointmentAlreadyBookedHeader: 'Attendez ! Vous avez déjà un rendez-vous avec nous.',
    t_appointmentAlreadyBookedBody: 'Nous ne pouvons pas vous réserver pour un autre rendez-vous, car vous en avez déjà un prévu avec nous. <br><br> Veuillez vérifier votre téléphone ou rechercher dans votre boîte de réception les détails de confirmation que nous avons envoyés précédemment. <br><br> Si vous devez apporter des modifications à votre rendez-vous existant, veuillez utiliser les coordonnées contenues dans cet e-mail.',
    t_appointmentAlreadyBookedFooter: "Merci pour votre enthousiasme et votre intérêt à faire votre part pour faire avancer la recherche médicale."
  },
  // dutch
  nl_NL: {
    t_datum: `Datum`,
    t_time: `Tijd`,
    t_adres: `Adres`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefoon`,
    t_next: `Volgende`,
    t_previousQuestion: `Vorige vraag`,
    t_nextQuestion: `Volgende vraag`,
    t_confirm: `Bevestigen`,
    t_goBack: `Ga terug`,
    t_videoCall: `Video-oproep`,

    t_selectDate: "De volgende stap is om een afspraak te maken met het team van de onderzoeksarts. Tijdens dit bezoek zal het team van de onderzoeksarts de onderzoeksvereisten verder met u bespreken en alle vragen die u heeft beantwoorden.",
    t_datesUnavailableLabel: "Niet-beschikbare data.",
    t_datesAvailableLabel: "Beschikbare data.",
    t_next: "Volgende",
    t_noAppointmentConvenient: "Geen van de tijden voor de afspraken komt gelegen",
    t_previousQuestion: "Vorige vraag",
    t_noAppointmentsAtTheMoment: "Helaas zijn er momenteel geen beschikbare of geschikte afspraakmogelijkheden op deze locatie.",
    t_confirmToContact: "Bevestig uw contactgegevens hieronder en we nemen zo snel mogelijk contact met u op.",
    t_phone: "Telefoon",
    t_confirm: "Bevestig",
    t_confirmCorrect: "Bevestig dat de onderstaande contactgegevens juist zijn voordat u uw afspraak maakt.",
    t_date: "Datum",
    t_time: "Tijdstip",
    t_address: "Adres",
    t_sendEmailConfirmation: "Stuur een bevestiging per e-mail",
    t_sendSMSConfirmation: "Stuur een bevestiging per SMS",
    t_bookAppointment: "Maak een afspraak",
    t_appointmentBooked: "Uw afspraak is gepland!",
    t_nextQuestion: "Volgende vraag",
    t_somebodyElseBooked: "Sorry, iemand anders heeft net een afspraak gemaakt op deze datum/dit tijdstip. Kies een andere datum of een ander tijdstip.",
    t_goBack: "Ga terug",
    t_bookingConfirmed: "Hoi. Uw afspraak op %date% op %time% bij %facility% is bevestigd.",
    t_haveQuestionsContact: "Als u vragen heeft of u moet deze afspraak verzetten, neem dan onmiddellijk contact op met de onderzoeksarts.",
    t_lookForward: "We kijken ernaar uit om u te ontmoeten!",
    t_regards: "Met vriendelijke groet,",
    t_unsubscribe: "Afmelden voor deze meldingen",
    t_bookingConfirmedContactOffice: "De afspraak op %date% op %time% bij %facility% is bevestigd. Neem contact op met het kantoor op %phone% als u vragen heeft",
    t_thankYouEndPage: "Dank u wel",

    // rebook
    t_rebookModalIntro: "Reserveringen voor deze enquête zijn al bevestigd:",
    t_rebookModalContinueText: "Als u doorgaat, kunt u een nieuwe datum en tijd voor deze afspraak selecteren.",
    t_rebookModalContinueText2: `Selecteer een optie hieronder om een nieuwe datum en tijd voor deze afspraak te selecteren of om de afspraak volledig te annuleren`,
    t_rebookModalNote: "Houd er rekening mee dat de huidige afspraak niet wordt geannuleerd totdat u de details van de nieuwe afspraak bevestigt.",
    t_rebookModalNoteCancellations: "Als u een reservering opnieuw wilt plannen, houd er dan rekening mee dat deze niet wordt geannuleerd totdat u de details van de nieuwe reservering bevestigt.",
    t_recheduleAppointmentButton: 'Vraag opnieuw plannen',

    //cancellation
    t_cancelAppointment: 'Reservering annuleren',
    t_cancelAppointmentButton: 'Reservering annuleren',
    t_cancelApptConfirmHeader: 'Weet je zeker dat je je reservering wilt annuleren? ',
    t_cancelApptConfirmText: 'U ontvangt een e-mail ter bevestiging van uw reserveringsannulering. ',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nee',
    t_apptCancelledSuccessHeader: 'Succes',
    t_apptCancelledSuccessText: 'De afspraak is succesvol geannuleerd. ',
    t_apptCancelledErrorHeader: 'Fout',
    t_apptCancelledErrorText: 'Er is een fout opgetreden tijdens het annuleren van de reservering. ',
    t_closeBtn: 'sluiten',

    // smart site
    t_helpBtn: `helpen`,
    t_ssInstruction1: 'Selecteer een site uit de onderstaande lijst en klik op de knop "Volgende". U kunt de linker- en rechterpijl gebruiken om meer sites te zien.',
    t_ssInstruction2: 'Als u naar extra sites of naar sites op een andere locatie wilt zoeken, voert u uw voorkeurslocatie (adres/postcode/plaats) en afstand (zoekradius) in en klikt u op *search icon*.',
    t_ssInstruction3: 'Klik op *reset icon* om uw eerste lijst met sites te bekijken.',
    t_ssSearchInputPlaceholder: "Bijvoorbeeld '1666' of 'Walnut Grove'",

    t_ssDropdownLabel: 'Selecteer afstand',
    t_ssDropdownOption: 'Binnen %0% %unit%',
    t_noSites: 'Er zijn geen sites beschikbaar',
    t_locationInputPlaceholder: 'Voer een locatie in',

    t_selectedDateLabel: 'Geselecteerde datum:',

    t_ssPopup1: `Nu je gekwalificeerd bent voor een studie, is de volgende stap het maken van een afspraak met een studiekliniek!<br>
    <br>
    Op deze pagina kunt u een site selecteren die bij u past. Afhankelijk van de geselecteerde site, krijgt u een aantal verschillende opties te zien zodra u op de knop "Volgende" klikt:<br><br>
    1. Als de geselecteerde site geen online boeking aanbiedt, nemen we binnenkort telefonisch contact met u op om een ​​afspraak te maken. <br><br>
    2. Als de geselecteerde site online boeken aanbiedt, heeft u mogelijk de mogelijkheid om de volgende soorten afspraken te boeken:<br>`,
    t_ssPopup2: `• On-Demand Video – een virtuele video-afspraak die direct plaatsvindt.<br><br>
    • Geplande video - een virtuele video-afspraak die u voor de toekomst kunt plannen.<br><br>
    • Geplande telefoon – een virtuele, telefonische afspraak die u voor de toekomst kunt plannen.<br><br>
    • On-site – een persoonlijke afspraak op een klinische locatie die u voor de toekomst kunt plannen.
    <br>`,
    t_ssPopup3: `<strong>Wat als ik mijn zoekgebied wil wijzigen?</strong><br><br>
    Geen probleem! Voer een adres en afstand (zoekradius) in de zoekbalk in en we zullen een nieuwe lijst met sites voor je maken waaruit je kunt kiezen.<br><br>
    <strong>Wat als er geen sites geschikt zijn voor mij?</strong><br><br>
    Als u 'Geen onderzoekssite is handig' selecteert, nemen we contact met u op om de opties te bespreken en een afspraak voor u in te plannen. <br><br>
    <strong>Wat als ik niet zeker weet hoe ik op mijn afspraak moet komen.</strong><br><br>
    Als u op de locatietegel klikt, tonen we u een routebeschrijving voor de auto en het openbaar vervoer naar de site.`,

    revision_changed: {
      revision_changed_1: 'Deze specifieke enquêtevraag is gewijzigd sinds het begin. Daarom moet u opnieuw beginnen bij vraag 1.',
      revision_changed_2: 'Sorry voor het ongemak.',
      ctaText: "Start een nieuw onderzoek."
    },

    maintenanceError: {
      header: "Regelmatig onderhoud",
      body: "Sorry, het is momenteel niet beschikbaar vanwege regulier onderhoud."
    },

    t_appointmentAlreadyBookedHeader: 'Wacht! U heeft al een afspraak bij ons ingepland.',
    t_appointmentAlreadyBookedBody: 'We kunnen je niet inboeken voor een andere afspraak, omdat je al een afspraak bij ons hebt staan. <br><br> Controleer uw telefoon of zoek in uw e-mailinbox naar de bevestigingsgegevens die we eerder hebben verzonden. <br><br> Als u wijzigingen wilt aanbrengen in uw bestaande afspraak, gebruik dan de contactgegevens in die e-mail.',
    t_appointmentAlreadyBookedFooter: 'Bedankt voor je enthousiasme en interesse om je steentje bij te dragen aan het bevorderen van medisch onderzoek.'
  },
  nl_BE: {
    t_datum: `Datum`,
    t_time: `Tijd`,
    t_adres: `Adres`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefoon`,
    t_next: `Volgende`,
    t_previousQuestion: `Vorige vraag`,
    t_nextQuestion: `Volgende vraag`,
    t_confirm: `Bevestigen`,
    t_goBack: `Ga terug`,
    t_videoCall: `Video-oproep`,

    t_selectDate: "De volgende stap is om een afspraak te maken met het team van de onderzoeksarts. Tijdens dit bezoek zal het team van de onderzoeksarts de onderzoeksvereisten verder met u bespreken en alle vragen die u heeft beantwoorden.",
    t_datesUnavailableLabel: "Niet-beschikbare data.",
    t_datesAvailableLabel: "Beschikbare data.",
    t_next: "Volgende",
    t_noAppointmentConvenient: "Geen van de tijden voor de afspraken komt gelegen",
    t_previousQuestion: "Vorige vraag",
    t_noAppointmentsAtTheMoment: "Helaas zijn er momenteel geen beschikbare of geschikte afspraakmogelijkheden op deze locatie.",
    t_confirmToContact: "Bevestig uw contactgegevens hieronder en we nemen zo snel mogelijk contact met u op.",
    t_phone: "Telefoon",
    t_confirm: "Bevestig",
    t_confirmCorrect: "Bevestig dat de onderstaande contactgegevens juist zijn voordat u uw afspraak maakt.",
    t_date: "Datum",
    t_time: "Tijdstip",
    t_address: "Adres",
    t_sendEmailConfirmation: "Stuur een bevestiging per e-mail",
    t_sendSMSConfirmation: "Stuur een bevestiging per SMS",
    t_bookAppointment: "Maak een afspraak",
    t_appointmentBooked: "Uw afspraak is gepland!",
    t_nextQuestion: "Volgende vraag",
    t_somebodyElseBooked: "Sorry, iemand anders heeft net een afspraak gemaakt op deze datum/dit tijdstip. Kies een andere datum of een ander tijdstip.",
    t_goBack: "Ga terug",
    t_bookingConfirmed: "Hallo. Uw afspraak op %date% op %time% bij %facility% is bevestigd.",
    t_haveQuestionsContact: "Als u vragen heeft of u moet deze afspraak verzetten, neem dan onmiddellijk contact op met de onderzoeksarts.",
    t_lookForward: "We kijken ernaar uit om u te ontmoeten!",
    t_regards: "Met vriendelijke groet,",
    t_unsubscribe: "Afmelden voor deze meldingen",
    t_bookingConfirmedContactOffice: "De afspraak op %date% op %time% bij %facility% is bevestigd. Neem contact op met het kantoor op %phone% als u vragen heeft",
    t_thankYouEndPage: "Dank u wel",

    // rebook
    t_rebookModalIntro: "Reserveringen voor deze enquête zijn al bevestigd:",
    t_rebookModalContinueText: "Als u doorgaat, kunt u een nieuwe datum en tijd voor deze afspraak selecteren.",
    t_rebookModalContinueText2: `Selecteer een optie hieronder om een nieuwe datum en tijd voor deze afspraak te selecteren of om de afspraak volledig te annuleren`,
    t_rebookModalNote: "Houd er rekening mee dat de huidige afspraak niet wordt geannuleerd totdat u de details van de nieuwe afspraak bevestigt.",
    t_rebookModalNoteCancellations: "Als u een reservering opnieuw wilt plannen, houd er dan rekening mee dat deze niet wordt geannuleerd totdat u de details van de nieuwe reservering bevestigt.",
    t_recheduleAppointmentButton: 'Vraag opnieuw plannen',

    //cancellation
    t_cancelAppointment: 'Reservering annuleren',
    t_cancelAppointmentButton: 'Reservering annuleren',
    t_cancelApptConfirmHeader: 'Weet je zeker dat je je reservering wilt annuleren? ',
    t_cancelApptConfirmText: 'U ontvangt een e-mail ter bevestiging van uw reserveringsannulering. ',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nee',
    t_apptCancelledSuccessHeader: 'Succes',
    t_apptCancelledSuccessText: 'De afspraak is succesvol geannuleerd. ',
    t_apptCancelledErrorHeader: 'Fout',
    t_apptCancelledErrorText: 'Er is een fout opgetreden tijdens het annuleren van de reservering. ',
    t_closeBtn: 'Sluiten',

    // smart site
    t_helpBtn: `Helpen`,
    t_ssInstruction1: 'Selecteer een site uit de onderstaande lijst en klik op de knop "Volgende". U kunt de linker- en rechterpijl gebruiken om meer sites te zien.',
    t_ssInstruction2: 'Als u naar extra sites of naar sites op een andere locatie wilt zoeken, voert u uw voorkeurslocatie (adres/postcode/plaats) en afstand (zoekradius) in en klikt u op *search icon*.',
    t_ssInstruction3: 'Klik op *reset icon* om uw eerste lijst met sites te bekijken.',
    t_ssSearchInputPlaceholder: "Bijvoorbeeld '1666' of 'Walnut Grove'",

    t_ssDropdownLabel: 'Selecteer afstand',
    t_ssDropdownOption: 'Binnen %0% %unit%',
    t_noSites: 'Er zijn geen sites beschikbaar',
    t_locationInputPlaceholder: 'Voer een locatie in',

    t_selectedDateLabel: 'Geselecteerde datum:',

    t_ssPopup1: `Nu je gekwalificeerd bent voor een studie, is de volgende stap het maken van een afspraak met een studiekliniek!<br>
    <br>
    Op deze pagina kunt u een site selecteren die bij u past. Afhankelijk van de geselecteerde site, krijgt u een aantal verschillende opties te zien zodra u op de knop "Volgende" klikt:<br><br>
    1. Als de geselecteerde site geen online boeking aanbiedt, nemen we binnenkort telefonisch contact met u op om een ​​afspraak te maken. <br><br>
    2. Als de geselecteerde site online boeken aanbiedt, heeft u mogelijk de mogelijkheid om de volgende soorten afspraken te boeken:<br>`,
    t_ssPopup2: `• On-Demand Video – een virtuele video-afspraak die direct plaatsvindt.<br><br>
    • Geplande video - een virtuele video-afspraak die u voor de toekomst kunt plannen.<br><br>
    • Geplande telefoon – een virtuele, telefonische afspraak die u voor de toekomst kunt plannen.<br><br>
    • On-site – een persoonlijke afspraak op een klinische locatie die u voor de toekomst kunt plannen.
    <br>`,
    t_ssPopup3: `<strong>Wat als ik mijn zoekgebied wil wijzigen?</strong><br><br>
    Geen probleem! Voer een adres en afstand (zoekradius) in de zoekbalk in en we zullen een nieuwe lijst met sites voor je maken waaruit je kunt kiezen.<br><br>
    <strong>Wat als er geen sites geschikt zijn voor mij?</strong><br><br>
    Als u 'Geen onderzoekssite is handig' selecteert, nemen we contact met u op om de opties te bespreken en een afspraak voor u in te plannen. <br><br>
    <strong>Wat als ik niet zeker weet hoe ik op mijn afspraak moet komen.</strong><br><br>
    Als u op de locatietegel klikt, tonen we u een routebeschrijving voor de auto en het openbaar vervoer naar de site.`,

    revision_changed: {
      revision_changed_1: 'Deze specifieke enquêtevraag is gewijzigd sinds het begin. Daarom moet u opnieuw beginnen bij vraag 1.',
      revision_changed_2: 'Sorry voor het ongemak.',
      ctaText: "Start een nieuw onderzoek."
    },

    maintenanceError: {
      header: "Regelmatig onderhoud",
      body: "Sorry, het is momenteel niet beschikbaar vanwege regulier onderhoud."
    },

    t_appointmentAlreadyBookedHeader: 'Wacht! U heeft al een afspraak bij ons ingepland.',
    t_appointmentAlreadyBookedBody: 'We kunnen je niet inboeken voor een andere afspraak, omdat je al een afspraak bij ons hebt staan. <br><br> Controleer uw telefoon of zoek in uw e-mailinbox naar de bevestigingsgegevens die we eerder hebben verzonden. <br><br> Als u wijzigingen wilt aanbrengen in uw bestaande afspraak, gebruik dan de contactgegevens in die e-mail.',
    t_appointmentAlreadyBookedFooter: 'Bedankt voor je enthousiasme en interesse om je steentje bij te dragen aan het bevorderen van medisch onderzoek.'
  },
  // danish
  da_DK: {
    t_date: `Dato`,
    t_time: `Tid`,
    t_address: `Adresse`,
    t_tel: `Tlf`,
    t_email: `E -mail`,
    t_phone: `Telefon`,
    t_next: `Næste`,
    t_previousQuestion: `Forrige spørgsmål`,
    t_nextQuestion: `Næste spørgsmål`,
    t_confirm: `Bekræft`,
    t_goBack: `Gå tilbage`,
    t_videoCall: `Videoopkald`,

    t_confirmCorrect: "Bekræft venligst, at de følgende oplysninger er korrekte, før du booker din aftale:",
    t_date: "Dato",
    t_time: "Tid",
    t_address: "Adresse",
    t_sendEmailConfirmation: "Send e-mail-bekræftelse",
    t_sendSMSConfirmation: "Send SMS-bekræftelse",
    t_bookAppointment: "Book aftale",
    t_appointmentBooked: "Du har nu booket din aftale!",
    t_nextQuestion: "Næste spørgsmål",
    t_somebodyElseBooked: "Desværre er der en anden, der netop har booket denne aftale. Prøv at vælge en anden dato eller et andet tidspunkt.",
    t_goBack: "Gå tilbage",
    t_bookingConfirmed: "Hej. Din aftale den %date% kl. %time% på %facility% er nu bekræftet.",
    t_haveQuestionsContact: "Hvis du har spørgsmål eller det er nødvendigt at ændre denne aftale, bedes du kontakte forsøgslægens kontor direkte.",
    t_lookForward: "Vi glæder os til at se dig!",
    t_regards: "Med venlig hilsen",
    t_unsubscribe: "Frameld dig disse beskeder",
    t_bookingConfirmedContactOffice: "Aftale bekræftet på %facility% den %date% kl. %time%. Ring venligst kontoret på %phone%, hvis du har spørgsmål",
    t_thankYouEndPage: "Tak skal du have",
    t_selectDate: "Det næste trin er at få lavet en aftale med forsøgslægens team. I løbet af dette besøg vil forsøgslægens team diskutere forsøgskravene med dig i flere detaljer, og svare på alle de spørgsmål, du evt har.",
    t_datesUnavailableLabel: "Datoer ikke tilgængelige",
    t_datesAvailableLabel: "Datoer tilgængelige",
    t_next: "Næste",
    t_noAppointmentConvenient: "Ingen aftaletid passer mig",
    t_previousQuestion: "Tidligere spørgsmål",
    t_noAppointmentsAtTheMoment: "Desværre er der aktuelt ikke nogen tilgængelige aftaler, eller ingen der passer mig på dette center.",
    t_confirmToContact: "Bekræft dine oplysninger nedenfor, og vi vil kontakte dig snarest muligt.",
    t_phone: "Telefon",
    t_confirm: "Bekræft",
    t_previousQuestion: "Tidligere spørgsmål",

    // rebook
    t_rebookModalIntro: "Reservationer til denne undersøgelse er allerede blevet bekræftet:",
    t_rebookModalContinueText: "Hvis du fortsætter, vil du kunne vælge en ny dato og et nyt tidspunkt for denne aftale.",
    t_rebookModalContinueText2: `Vælg en indstilling herunder for at vælge en ny dato og tid for denne aftale eller for at annullere aftalen helt`,
    t_rebookModalNote: "Bemærk, at den aktuelle aftale ikke bliver annulleret, før du har bekræftet detaljerne i den nye aftale.",
    t_rebookModalNoteCancellations: "Hvis du ønsker at omlægge en reservation, skal du være opmærksom på, at den ikke vil blive annulleret, før du har bekræftet detaljerne i den nye reservation.",
    t_rescheduleAppointmentButton: 'Omplanlæg forespørgsel',

    // cancellations
    t_cancelAppointment: 'Annuller reservation',
    t_cancelAppointmentButton: 'Annuller reservation',
    t_cancelApptConfirmHeader: 'Er du sikker på, at du vil annullere din reservation? ',
    t_cancelApptConfirmText: 'Du modtager en e -mail, der bekræfter din annullering af din reservation. ',
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Ingen',
    t_apptCancelledSuccessHeader: 'Succes',
    t_apptCancelledSuccessText: 'Aftalen blev aflyst. ',
    t_apptCancelledErrorHeader: 'Fejl',
    t_apptCancelledErrorText: 'Der opstod en fejl under annullering af reservationen. ',
    t_closeBtn: 'luk',

    // Smart site
    t_helpBtn: `Hjælp`,
    t_ssInstruction1: 'Vælg et websted fra listen herunder, og klik på knappen "Næste". Du kan bruge venstre og højre pil til at se flere websteder. ',
    t_ssInstruction2: 'Hvis du vil søge efter flere websteder eller efter websteder på et andet sted, skal du indtaste din foretrukne placering (adresse/postnummer/by) og afstand (søgeradius) og klikke på *search icon*.',
    t_ssInstruction3: 'Klik på *reset icon* for at se din første liste over websteder.',
    t_ssSearchInputPlaceholder: "F.eks. '1666' eller 'Walnut Grove'",

    t_ssDropdownLabel: 'Vælg afstand',
    t_ssDropdownOption: 'Inden for %0% %unit%',
    t_noSites: 'Der er ingen tilgængelige websteder',
    t_locationInputPlaceholder: 'Angiv en placering',

    t_selectedDateLabel: 'Valgt dato:',

    t_ssPopup1: `Nu hvor du har kvalificeret dig til et studie, er det næste trin at booke en tid hos en studieklinik! <br>
    <br>
    På denne side kan du vælge et websted, der er praktisk for dig. Afhængigt af det valgte websted vil du blive præsenteret for et par forskellige muligheder, når du klikker på knappen "Næste": <br> <br>
    1. Hvis det valgte websted ikke tilbyder online booking, kontakter vi dig snarest telefonisk for at booke en tid. <br> <br>
    2. Hvis det valgte websted tilbyder online booking, har du muligvis mulighed for at booke følgende typer af aftaler: <br> `,
    t_ssPopup2: `• Video på forespørgsel-en virtuel videoaftale, der finder sted med det samme. <br> <br>
    • Planlagt video - en virtuel videoaftale, som du kan planlægge for fremtiden. <br> <br>
    • Planlagt telefon - en virtuel telefonaftale, som du kan planlægge for fremtiden. <br> <br>
    • On-Site-en personlig aftale på et klinisk sted, som du kan planlægge for fremtiden.
    <br> `,
    t_ssPopup3: `<strong> Hvad hvis jeg vil ændre mit søgeområde? </strong> <br> <br>
    Intet problem! Indtast en adresse og afstand (søgeradius) i søgelinjen, så giver vi en ny webstedsliste, som du kan vælge imellem. <br> <br>
    <strong> Hvad hvis ingen websteder er bekvemme for mig? </strong> <br> <br>
    Hvis du vælger "Intet forskningssted er praktisk", kontakter vi dig for at diskutere muligheder og få dig planlagt til en aftale. <br> <br>
    <strong> Hvad hvis jeg er usikker på, hvordan jeg kommer til min aftale. </strong> <br> <br>
    Hvis du klikker på placeringsflisen, viser vi dig kørsels- og transitvejledning til stedet.`,

    revision_changed: {
      revision_changed_1: 'Spørgsmål inden for denne særlige undersøgelse er blevet ændret, siden du startede den. Derfor bliver du nødt til at genstarte fra spørgsmål 1.',
      revision_changed_2: 'Beklager ulejligheden.',
      ctaText: "Start nyt spørgeskema."
    },

    maintenanceError: {
      header: 'Planlagt vedligeholdelse',
      body: 'Beklager, men vi er i øjeblikket ude af planlagt vedligeholdelse.'
    },

    t_appointmentAlreadyBookedHeader: 'Vent! Du har allerede planlagt en aftale med os.',
    t_appointmentAlreadyBookedBody: 'Vi kan ikke booke dig til en ny tid, fordi du allerede har en planlagt tid hos os. <br><br> Tjek venligst din telefon eller søg i din e-mail-indbakke for bekræftelsesoplysningerne, som vi sendte tidligere. <br><br> Hvis du har brug for at foretage ændringer i din eksisterende aftale, bedes du bruge kontaktoplysningerne i den e-mail.',
    t_appointmentAlreadyBookedFooter: 'Tak for din entusiasme og interesse i at gøre din del for at fremme medicinsk forskning.'
  },
  //polish
  pl_PL: {
    t_data: `Data`,
    t_time: `Czas`,
    t_address: `Adres`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefon`,
    t_next: `Dalej`,
    t_previousQuestion: `Poprzednie pytanie`,
    t_nextQuestion: `Następne pytanie`,
    t_confirm: `Potwierdź`,
    t_goBack: `Wróć`,
    t_videoCall: `Rozmowa wideo`,

    t_coronavirusText: "Zdrowie i bezpieczeństwo pacjentów oraz naszych pracowników jest naszym najwyższym priorytetem. Należy pamiętać, że nasze przychodnie nie są ośrodkami medycznymi ani szpitalami. Nie widzimy ani nie leczymy chorych pacjentów. Wprowadziliśmy mimo to  środki ostrożności zapewniające, że nasze przychodnie odpowiednio monitorują gości i przestrzegają ważnych norm higieny i właściwej sterylizacji.",

    t_selectDate: 'Wybierz datę i godzinę wizyty:',
    t_datesAvailableLabel: "Dostępne terminy",
    t_datesUnavailableLabel: "Niedostępne terminy",
    t_noAppointmentConvenient: "Brak dogodnego terminu spotkania",

    t_noAppointmentsAtTheMoment: "Członek naszego zespołu badawczego skontaktuje się z Panem/Panią telefonicznie, aby umówić wizytę w dogodnym terminie.",
    t_confirmToContact: "Potwierdź proszę swoje dane poniżej, a wkrótce skontaktujemy się z Tobą.",

    t_noTimeSlotsAvailableMsg: "Niestety obecenie nie ma dostepnych lub dogodnych terminów wizyt w danym ośrodku.",

    t_confirmCorrect: "Prosimy potwierdź poprawność następujących danych przed rejestracją wizyty:",
    t_sendEmailConfirmation: "Wyślij potwierdzenie mailem",
    t_sendSMSConfirmation: "Wyślij potwierdzenie SMS-em",
    t_bookAppointment: "Umów wizytę",
    t_appointmentBooked: "Twoja wizyta została umówiona!",
    t_somebodyElseBooked: "Niestety, ktoś inny właśnie zarezerwował ten termin wizyty. Spróbuj wybrać inną datę lub godzinę.",

    t_bookingConfirmed: "Witamy. Potwierdziłeś wizytę w dniu %data%  o godzinie %godzina%  w %miejsce%.",
    t_haveQuestionsContact: "W przypadku jakichkolwiek pytań lub potrzeby zmiany terminu wziyty prosimy o kontakt bezpośrednio z gabinetem lekarza prowadzącego badanie.",
    t_lookForward: "Czekamy na Ciebie!",
    t_regards: "Z poważaniem",
    t_unsubscribe: "Anuluj subskrycję tych powiadomień",
    t_bookingConfirmedContactOffice: "Placówka  %miejsce% potwierdziła wizytę w dniu %data% o godzinie %godzina%. W razie jakichkolwiek pytań prosimy o kontakt pod numerem: %numer telefonu%",
    t_thankYouEndPage: "DZIĘKUJEMY",

    t_selectAppointmentType: "W jaki sposób chciałabyś/chciałbyś przeprowadzić wizytę?",
    t_appointmentTypeTitle: "Rodzaj spotkania",
    t_emailTitle: "Adres e-mail",
    t_mobilePhoneTitle: "Numer telefonu komórkowego.",
    t_videNowCard: "Rozpocznij teraz spotkanie video.",
    t_scheduleVideoCard: "Zaplanuj spotkanie video online.",
    t_scheduleInPersonCard: "Zaplanuj wizytę osobistą.",
    t_videoNotSuitable: "Nie mam możliwości odbycia spotkania video.",
    t_confirmVideoInfo: "Spotkanie wideo online odbędzie się w wybranym dniu i godzinie oraz zostanie przeprowadzone przez zespół prowadzący dane badanie. Po zarezerwowaniu terminu otrzymasz wiadomość e-mail z potwierdzeniem zawierającym link do spotkania. Kiedy spotkanie powinno się rozpocząć, kliknij w link, aby automatycznie rozpocząć rozmowę video.",
    t_videoModalText: "Świetnie! Musimy tylko potwierdzić następujące szczegóły przed rozpoczęciem rozmowy wideo",
    t_optional: " (opcjonalnie)",
    t_selectDateVideoSubtext: "Aby przeprowadzić spotkanie video, potrzebujesz dostępu do urządzenia z kamerą i / lub mikrofonem (większość smartfonów i laptopów ma obie wymagane funkcje)",
    t_siteNameTitle: "Dane Ośrodka",
    t_selectDateVideo: "Wybierz datę i godzinę spotkania video online",
    t_videNowNotConvenientIntro: "Jeśli teraz nie jest dla ciebie wygodnie:",
    t_videNowCard: "Rozpocznij spotkanie online już teraz",
    t_scheduleVideoCard: "Zaplanuj spotkanie online na później",
    t_videNowButton: "Rozpocznij teraz",
    t_scheduleVideoButton: "Zaplanuj spotkanie video",
    t_scheduleInPersonButton: "Zaplanuj wizytę",
    t_schedulePhoneCallCard: "Zaplanuj połączenie telefoniczne", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "Zaplanuj połączenie", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Wybierz protokół:",
    t_selectDatePhone: "Wybierz datę i godzinę, aby zaplanować spotkanie telefoniczne:",
    t_selectDatePhoneSubtext: "Aby umówić się na spotkanie telefoniczne, będziesz potrzebować dostępu do telefonu w wybranym dniu",
    t_confirmPhoneInfo: "Twoje spotkanie telefoniczne odbędzie się w wybranym czasie. Upewnij się, że masz dobry sygnał telefoniczny do odebrania połączenia.",

    // rebook
    t_rebookModalIntro: "Rezerwacje na tę ankietę zostały już potwierdzone:",
    t_rebookModalContinueText: "Jeśli będziesz kontynuować, będziesz mógł wybrać nową datę i godzinę tego spotkania.",
    t_rebookModalContinueText2: `Proszę wybrać opcję poniżej, aby wybrać nową datę i godzinę tego spotkania lub całkowicie anulować spotkanie`,
    t_rebookModalNote: "Pamiętaj, że bieżące spotkanie nie zostanie odwołane, dopóki nie potwierdzisz szczegółów nowego spotkania.",
    t_rebookModalNoteCancellations: "Jeśli chcesz przełożyć rezerwację, pamiętaj, że nie zostanie ona anulowana, dopóki nie potwierdzisz szczegółów nowej rezerwacji.",
    t_rescheduleAppointmentButton: 'Zmień termin zapytania',

    // cancellations
    t_cancelAppointment: 'Anuluj rezerwację',
    t_cancelAppointmentButton: 'Anuluj rezerwację',
    t_cancelApptConfirmHeader: 'Czy na pewno chcesz anulować rezerwację?',
    t_cancelApptConfirmText: 'Otrzymasz e-mail potwierdzający anulowanie rezerwacji.',
    t_cancelApptBtn: 'Tak',
    t_cancelBackBtn: 'Nie',
    t_apptCancelledSuccessHeader: 'Sukces',
    t_apptCancelledSuccessText: 'Spotkanie zostało pomyślnie anulowane.',
    t_apptCancelledErrorHeader: 'Błąd',
    t_apptCancelledErrorText: 'Wystąpił błąd podczas anulowania rezerwacji.',
    t_closeBtn: 'Zamknij',

    // smart site
    t_helpBtn: `Pomoc`,
    t_ssInstruction1: 'Wybierz witrynę z poniższej listy i kliknij przycisk „Dalej”. Możesz użyć strzałek w lewo i w prawo, aby zobaczyć więcej witryn. ',
    t_ssInstruction2: 'Aby wyszukać dodatkowe witryny lub witryny w innej lokalizacji, wprowadź preferowaną lokalizację (adres/kod pocztowy/miasto) i odległość (promień wyszukiwania), a następnie kliknij *search icon*.',
    t_ssInstruction3: 'Kliknij na *reset icon*, aby wyświetlić swoją początkową listę witryn.',
    t_ssSearchInputPlaceholder: "Np. '1666' lub 'Walnut Grove'",

    t_ssDropdownLabel: 'Wybierz odległość',
    t_ssDropdownOption: 'W granicach %0% %unit%',
    t_noSites: 'Brak dostępnych witryn',
    t_locationInputPlaceholder: 'Wprowadź lokalizację',

    t_selectedDateLabel: 'Wybrana data:',

    t_ssPopup1: `Teraz, gdy zakwalifikowałeś się do udziału w badaniu, następnym krokiem jest zarezerwowanie wizyty w klinice badawczej!<br>
    <br>
    Na tej stronie możesz wybrać dogodną dla siebie witrynę. W zależności od wybranej witryny po kliknięciu przycisku „Dalej” zostanie wyświetlonych kilka różnych opcji:<br><br>
    1. Jeśli wybrana strona nie oferuje rezerwacji online, wkrótce skontaktujemy się z Tobą telefonicznie, aby umówić się na wizytę. <br><br>
    2. Jeśli wybrana strona oferuje rezerwację online, możesz mieć możliwość zarezerwowania następujących rodzajów spotkań:<br>`,
    t_ssPopup2: `• Wideo na żądanie – wirtualne spotkanie wideo, które odbędzie się natychmiast.<br><br>
    • Zaplanowane wideo – wirtualne spotkanie wideo, które możesz zaplanować na przyszłość.<br><br>
    • Scheduled Phone – wirtualna, telefoniczna wizyta, którą możesz zaplanować na przyszłość.<br><br>
    • On-Site – osobista wizyta w placówce klinicznej, którą można zaplanować na przyszłość.
    <br>`,
    t_ssPopup3: `<strong>A jeśli chcę zmienić obszar wyszukiwania?</strong><br><br>
    Nie ma problemu! Wprowadź adres i odległość (promień wyszukiwania) w pasku wyszukiwania, a my udostępnimy Ci nową listę witryn do wyboru.<br><br>
    <strong>A jeśli żadna witryna nie jest dla mnie wygodna?</strong><br><br>
    Jeśli wybierzesz opcję „Żadna witryna badawcza nie jest wygodna”, skontaktujemy się z Tobą, aby omówić opcje i umówić się na spotkanie. <br><br>
    <strong>Co zrobić, jeśli nie wiem, jak dostać się na wizytę.</strong><br><br>
    Jeśli klikniesz kafelek lokalizacji, pokażemy Ci wskazówki dojazdu i dojazdu do witryny.`,

    revision_changed: {
      revision_changed_1: 'Pytania w tej konkretnej ankiecie zostały zmienione od momentu jej rozpoczęcia. Dlatego będziesz musiał zacząć od pytania 1.',
      revision_changed_2: 'przepraszam za wszelkie niedogodności.',
      ctaText: "Rozpocznij nowy kwestionariusz."
    },

    maintenanceError: {
      header: 'zaplanowana konserwacja',
      body: 'Przepraszamy, obecnie nie mamy zaplanowanej konserwacji.'
    },

    t_appointmentAlreadyBookedHeader: 'Czekaj! Masz już u nas umówioną wizytę.',
    t_appointmentAlreadyBookedBody: 'Nie możemy umówić Cię na kolejne spotkanie, ponieważ masz już u nas zaplanowane. <br><br> Sprawdź swój telefon lub poszukaj w skrzynce e-mailowej szczegółów potwierdzenia, które wysłaliśmy wcześniej. <br><br> Jeśli chcesz wprowadzić jakiekolwiek zmiany w istniejącym spotkaniu, skorzystaj z danych kontaktowych zawartych w tym e-mailu. ',
    t_appointmentAlreadyBookedFooter: 'Dziękuję za entuzjazm i zainteresowanie udziałem w postępie badań medycznych.',
    t_vfovCompletionHeader: "<b><i>Mamy dobre wieści! Odbyłeś/odbyłaś już spotkanie z naszym zespołem i wkrótce ponownie się z Tobą skontaktujemy.</i></b>",
    t_vfovCompletionBody: "Czy w Twoim życiu zaszły jakieś zmiany? Zmiany w stanie zdrowia i stylu życia mogą kwalifikować Cię do dodatkowych badań klinicznych. Kliknij łącze z potwierdzeniem znajdujące się w wiadomości e-mail, aby wypełnić zaktualizowaną ankietę i sprawdzić, czy spełniasz kryteria kwalifikacji!",
    t_vfovCompletionFooter: "Dziękujemy za entuzjazm i zainteresowanie rozwojem badań klinicznych!"
  },
  //afrikaans
  af_ZA: {
    t_date: `Datum`,
    t_time: 'Tyd',
    t_address: `Adres`,
    t_tel: `Tel`,
    t_email: `E -pos`,
    t_phone: 'Telefoon',
    t_next: 'Volgende',
    t_previousVraag: `Vorige vraag`,
    t_nextQuestion: `Volgende vraag`,
    t_confirm: 'Bevestig',
    t_goBack: 'Gaan terug',
    t_videoCall: 'Video -oproep',

    t_selectDate: "Kies 'n datum en tyd vir u afspraak",
    // t_selectDateVideo: "",
    t_datesAvailableLabel: "Datums beskikbaar",
    t_datesUnavailableLabel: "Datums nie beskikbaar nie",
    t_noAppointmentConvenient: "Geen afspraaktyd is gerieflik nie",

    t_noAppointmentsAtTheMoment: "n Lid van ons studiesorgspan sal u telefonies kontak om 'n afspraak te skeduleer wat die beste vir u pas.",
    t_confirmToContact: "Bevestig asseblief u inligting hieronder, en ons sal binnekort kontak met u maak.",

    // t_noTimeSlotsAvailableMsg: "",

    t_confirmCorrect: "Bevestig asseblief dat die volgende besonderhede korrek is voor u die afspraak bespreek",
    // t_sendEmailConfirmation: "",
    // t_sendSMSConfirmation: "",
    t_bookAppointment: "Maak 'n afspraak",
    t_appointmentBooked: "U afspraak is suksesvol bespreek!",
    t_somebodyElseBooked: "Jammer, iemand anders het pas die afspraakgleuf bespreek. Probeer 'n ander datum of tyd kies.",

    // t_bookingConfirmed: "",
    t_haveQuestionsContact: "As u enige vrae het of u hierdie afspraak moet verander, kontak die kantoor van die doktorsdokter direk.",
    // t_lookForward: "",
    // t_regards: "",
    // t_unsubscribe: "",
    // t_bookingConfirmedContactOffice: "",
    t_thankYouEndPage: "Dankie",

    t_selectAppointmentType: "Hoe verkies u om u afspraak te doen?",
    t_appointmentTypeTitle: "Afspraak tipe",
    t_emailTitle: "E-pos adres",
    t_mobilePhoneTitle: "Selfoon",
    t_scheduleVideoCard: "Skeduleer aanlyn video afspraak",
    t_scheduleInPersonCard: "Skeduleer besoek in persoon",
    t_videoNotSuitable: "'N Video afspraak is nie geskik vir my.",
    t_confirmVideoInfo: "U aanlyn video afspraak sal plaasvind op die dag en tyd gekies met die studiesdokter span. Nadat u afspraak bevestig is, sal u n bevestigings e-pos ontvang wat 'n skakel bevat. Wanneer die afspraak gereed is om te begin, kliek op die skakel om automaties die video te begin. ",
    t_videoModalText: "Goed so! Ons benodig dat u die volgende besonderhede bevestig voordat u video oproep kan begin",
    t_optional: " (opsioneel)",
    t_selectDateVideoSubtext: "In orde om die video afspraak af te handel, sal u toegang moet het na 'n toestel met n kamera/of mikrofoon(meeste slimfone en skootrekenaars het albei)",
    t_siteNameTitle: "Sentrum besonderhede",
    t_selectDateVideo: "Kies asseblief die dag en tyd wanneer u die aanlyn video afspraak wil skeduleer",
    t_videNowNotConvenientIntro: "Indien dit u nie nou pas nie:",
    t_videNowCard: "Begin NOU met u aanlynvideo-afspraak",
    t_videNowButton: "BEGIN NOU",
    t_scheduleVideoButton: "SKEDULEER VIDEO OPROEP",
    t_scheduleInPersonButton: "SKEDULEER BESOEK",
    t_schedulePhoneCallCard: "Skeduleer telefoonoproep", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "Skeduleer Oproep", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Kies protokolle",
    t_selectDatePhone: "Kies die datum en tyd waarop u u telefoniese afspraak wil skeduleer:",
    t_selectDatePhoneSubtext: "Om die telefoonafspraak te kan doen, moet u op die gekose datum toegang hê tot 'n telefoon",
    t_confirmPhoneInfo: "U telefoniese afspraak sal plaasvind op die tyd en datum wat u gekies het met die kliniese span. Sorg dat u beskikbaar is met 'n goeie telefoonsein om die oproep te ontvang.",

    // rebook
    t_rebookModalIntro: "Besprekings vir hierdie opname is reeds bevestig:",
    t_rebookModalContinueText: "As u voortgaan, sal u 'n nuwe datum en tyd vir hierdie afspraak kan kies.",
    t_rebookModalContinueText2: `Kies 'n opsie hieronder om 'n nuwe datum en tyd vir hierdie afspraak te kies of om die afspraak heeltemal te kanselleer`,
    t_rebookModalNote: "Let daarop dat die huidige afspraak eers gekanselleer sal word totdat u die besonderhede van die nuwe afspraak bevestig het.",
    t_rebookModalNoteCancellations: "As u 'n bespreking wil herskeduleer, let daarop dat dit nie gekanselleer sal word voordat u die besonderhede van die nuwe bespreking bevestig het nie.",
    t_rescheduleAppointmentButton: 'Herskeduleer afspraak',

    // cancellations
    t_cancelAppointment: 'Kanselleer afspraak',
    t_cancelAppointmentButton: 'Kanselleer afspraak',
    t_cancelApptConfirmHeader: 'Is u seker dat u u bespreking wil kanselleer? ',
    t_cancelApptConfirmText: `U sal 'n e - pos ontvang wat die kansellasie van u bespreking bevestig.`,
    t_cancelApptBtn: 'Ja',
    t_cancelBackBtn: 'Nee',
    t_apptCancelledSuccessHeader: 'Sukses',
    t_apptCancelledSuccessText: 'Die afspraak is suksesvol gekanselleer. ',
    t_apptCancelledErrorHeader: 'Fout',
    t_apptCancelledErrorText: `'n Fout het voorgekom tydens die kansellasie van die bespreking.`,
    t_closeBtn: 'sluit',

    // smart site
    t_helpBtn: `Hulp`,
    t_ssInstruction1: `Kies 'n webwerf uit die onderstaande lys en klik op die' Volgende 'knoppie. U kan die pyltjies links en regs gebruik om meer webwerwe te sien. `,
    t_ssInstruksie2: `Om addisionele webwerwe of webwerwe op 'n ander plek te soek, voer u ligging (adres/pos/stad) en afstand (soekradius) in en klik op *search icon*.`,
    t_ssInstruction3: 'Klik op *reset icon* om u aanvanklike lys met webwerwe te sien.',
    t_ssSearchInputPlaceholder: "Bv. '1666' of 'Walnut Grove'",

    t_ssDropdownLabel: 'Kies afstand',
    t_ssDropdownOption: 'Binne %0% %unit%',
    t_noSites: 'Daar is geen webwerwe beskikbaar nie',
    t_locationInputPlaceholder: `Voer 'n ligging in`,

    t_selectedDateLabel: 'Gekose datum:',

    t_ssPopup1: `Noudat u vir 'n studie gekwalifiseer het, is die volgende stap om 'n afspraak te maak by 'n studiekliniek! <br>
    <br>
    Op hierdie bladsy kan u 'n webwerf kies wat u die beste pas. Afhangende van die geselekteerde webwerf, word u 'n paar verskillende opsies aangebied sodra u op die "Volgende" -knoppie klik: <br> <br>
    1. As die gekose webwerf nie 'n aanlyn bespreking bied nie, sal ons binnekort telefonies kontak om 'n afspraak te maak. <br> <br>
    2. As die geselekteerde webwerf wel aanlyn bespreking bied, kan u die volgende tipes afsprake bespreek: <br> `,
    t_ssPopup2: `• Video op aanvraag-'n virtuele video-afspraak wat onmiddellik sal plaasvind. <br> <br>
    • Geskeduleerde video - 'n virtuele video -afspraak wat u vir die toekoms kan beplan. <br> <br>
    • Geskeduleerde telefoon - 'n virtuele telefoniese afspraak wat u vir die toekoms kan reël. <br> <br>
    • Ter plaatse-'n persoonlike afspraak op 'n kliniese terrein wat u vir die toekoms kan reël.
    <br> `,
    t_ssPopup3: `<strong> Wat as ek my soekgebied wil verander? </strong> <br> <br>
    Geen probleem! Voer 'n adres en afstand (soekradius) in die soekbalk in, en ons sal 'n nuwe werflys verskaf waaruit u kan kies. <br> <br>
    <strong> Wat as daar geen webwerwe vir my is nie? </strong> <br> <br>
    As u 'Geen navorsingswebwerf is gerieflik' kies nie, kontak ons ​​u om die opsies te bespreek en vir u 'n afspraak te maak. <br> <br>
    <strong> Wat as ek nie seker is hoe om by my afspraak uit te kom nie. </strong> <br> <br>
    As u op die liggingsteël klik, sal ons u die rigtingaanwysings na die webwerf wys.`,

    revision_changed: {
      revision_changed_1: 'Vrae binne hierdie spesifieke opname is verander sedert u dit begin het. Daarom sal u weer van Vraag 1 af moet begin.',
      revision_changed_2: 'Verskonings vir enige ongerief.',
      ctaText: "Begin 'n nuwe vraelys."
    },

    maintenanceError: {
      header: 'geskeduleerde onderhoud',
      body: 'Jammer, ons is tans af vir geskeduleerde onderhoud.'
    },

    t_appointmentAlreadyBookedHeader: `Wag! Jy het reeds 'n afspraak met ons geskeduleer.`,
    t_appointmentAlreadyBookedBody: `Ons kan jou nie vir 'n ander afspraak bespreek nie, want jy het reeds een by ons geskeduleer. <br><br> Gaan asseblief jou foon na of soek jou e-pos inkassie vir die bevestigingsbesonderhede wat ons voorheen gestuur het. <br><br> As jy enige veranderinge aan jou bestaande afspraak moet maak, gebruik asseblief die kontakinligting vervat in daardie e-pos.`,
    t_appointmentAlreadyBookedFooter: 'Dankie vir jou entoesiasme en belangstelling om jou deel te doen om mediese navorsing te bevorder.',
    t_vfovCompletionHeader: "<b><i>Goeie nuus! Jy het reeds ’n afspraak met ons span gehaden behoort binnekort weer van ons te hoor.</i></b>",
    t_vfovCompletionBody: "Het jy enige lewensveranderinge ervaar? Jy mag moontlik vir bykomende kliniese navorsingstudies in aanmerking kom as daar gesondheids- of leefstylveranderinge was. Gebruik die skakel in jou bevestiging-e-pos om ’n bygewerkte vraelys te voltooi om te sien of jy nou in aanmerking kom!",
    t_vfovCompletionFooter: "Dankie vir jou entoesiasme en belangstelling om kliniese navorsing te bevorder!"
  },
  //hungarian
  hu_HU: {
    t_date: `Dátum`,
    t_time: `Idő`,
    t_address: `Cím`,
    t_tel: `Tel`,
    t_email: `E -mail`,
    t_phone: `Telefon`,
    t_next: `Következő`,
    t_previousQuestion: `Előző kérdés`,
    t_nextQuestion: `Következő kérdés`,
    t_confirm: `Megerősítés`,
    t_goBack: "Menj vissza",
    t_videoCall: "Videohívás",

    t_selectDate: 'Válassza ki a találkozó dátumát és időpontját:',
    t_datesAvailableLabel: "Elérhető dátumok",
    t_datesUnavailableLabel: "Nem elérhető dátumok",
    t_noAppointmentConvenient: "Nincs megfelelő időpont",

    t_noAppointmentsAtTheMoment: "A rendelő vizsgálócsoportjának egyik tagja felveszi Önnel a kapcsolatot, hogy megbeszéljék az Ön számára legmegfelelőbb időpontot.",
    t_confirmToContact: "Kérjük, erősítse meg alább adatait, és hamarosan kapcsolatba lépünk Önnel.",

    t_noTimeSlotsAvailableMsg: "Sajnos ebben a rendelőben jelenleg nem elérhetőek vagy nincsenek megfelelő időpontok.",

    t_confirmCorrect: "A találkozója lefoglalása előtt kérjük, erősítse meg, hogy a következő adatok helyesek:",
    t_sendEmailConfirmation: "Küldjön email-visszaigazolást",
    t_sendSMSConfirmation: "Küldjön SMS-visszaigazolást",
    t_bookAppointment: "Időpont foglalása",
    t_appointmentBooked: "A találkozóját sikeresen lefoglalta!",
    t_somebodyElseBooked: "Sajnálom, valaki éppen lefoglalta ezt az időpontot. Próbáljon meg egy másik dátumot vagy időt kiválasztani.",

    t_bookingConfirmed: "Üdvözöljük. Lefoglaltuk az Ön találkozóját %dátummal %időpontban %rendelőben %.",
    t_haveQuestionsContact: "Ha bármilyen kérdése van, vagy meg kell változtatnia ezt az időpontot, kérjük, forduljon közvetlenül a vizsgálóorvos rendelőjéhez.",
    t_lookForward: "Viszontlátásra!",
    t_regards: "Üdvözlettel",
    t_unsubscribe: "Leiratkozok ezekről a figyelmeztetésekről",
    t_bookingConfirmedContactOffice: "Találkozóját lefoglaltuk  %dátummal %időpontban %rendelőben. Bármilyen kérdéssel hívja a rendelőt a %telefonszámon.",
    t_thankYouEndPage: "KÖSZÖNJÜK!",

    t_coronavirusText: "COVID-19 MEGJEGYZÉS: Munkatársaink és pácienseink egészsége és biztonsága a legfontosabb számunkra. Kérem, vegye figyelembe, hogy vizsgálóhelyeink nem járóbetegellátó rendelők, és nem kórházak. Nem fogadunk vagy kezelünk fertőzött betegeket. Ugyanakkor gondoskodunk arról, hogy létesítményeink megfelelő módon szűrjék a látogatókat, és megfeleljenek a higiénia és a megfelelő sterilizálás fontos előírásainak.",
    t_selectAppointmentType: "Hogyan szeretne részt venni a találkozón?",
    t_appointmentTypeTitle: "Látogatás típusa",
    t_emailTitle: "E-mail cím",
    t_mobilePhoneTitle: "Mobiltelefon",
    t_videNowCard: "Indítsa el az online videó-találkozót most",
    t_scheduleVideoCard: "Ütemezze be az online videó-találkozó időpontját",
    t_scheduleInPersonCard: "Ütemezze a személyes látogatás időpontját",
    t_videoNotSuitable: "A videó-találkozó nem megfelelő számomra",
    t_confirmVideoInfo: "A kollégánkkal történő online video-találkozóra a kiválasztott napon és időpontban kerül sor.",
    t_videoModalText: "Nagyszerű! A videóhívás megkezdése előtt már csak meg kell erősítenie a következő részleteket",
    t_optional: " (nem kötelező)",
    t_selectDateVideoSubtext: "A videó-találkozóhoz szükséges egy kamerával és / vagy mikrofonnal rendelkező eszköz (pl. a legtöbb okostelefonnak és laptopnak egyaránt van)",
    t_siteNameTitle: "Információk a vizsgálóhelyről",
    t_selectDateVideo: "Kérjük, válassza ki azt a dátumot és időpontot, amikor Önnek megfelelő az online videó-találkozó:",
    t_videNowNotConvenientIntro: "Ha most nem alkalmas az Ön számára:",
    t_videNowCard: "Kezdje meg online találkozóját MOST",
    t_scheduleVideoCard: "Foglalja le online időpontját KÉSŐBB",
    t_videNowButton: "KEZDÉS MOST",
    t_scheduleVideoButton: "FOGLALJON VIDEÓ HÍVÁST",
    t_scheduleInPersonButton: "FOGLALJON VIZITET",
    t_schedulePhoneCallCard: "Telefonhívás ütemezése", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "Hívás ütemezése", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Kiválasztott protokollok",
    t_selectDatePhone: "Kérjük, válassza ki azt a dátumot és időt,amikor a telefonos találkozót ütemezni kívánja:",
    t_selectDatePhoneSubtext: "A telefonos találkozó lebonyolításához a kiválasztott időben telefonon elérhetőnek kell lennie.",
    t_confirmPhoneInfo: "A kollégánkkal történő telefonos megbeszélésre a kiválasztott napon és időpontban kerül sor. Ellenőrizze, hogy a hívás fogadásához megfelelőjelerőséggelrendelkezik.",

    // Rebook
    t_rebookModalIntro: "A felméréssel kapcsolatos foglalásokat már megerősítettük:",
    t_rebookModalContinueText: "Ha folytatja, új dátumot és időt választhat a találkozóhoz.",
    t_rebookModalContinueText2: "Kérjük, válasszon az alábbi lehetőségek közül a találkozó új dátumának és időpontjának kiválasztásához, vagy a találkozó teljes lemondásához",
    t_rebookModalNote: "Kérjük, vegye figyelembe, hogy a jelenlegi megbeszélés nem törlődik, amíg nem erősíti meg az új találkozó részleteit.",
    t_rebookModalNoteCancellations: "Ha újra szeretné ütemezni a foglalást, kérjük, vegye figyelembe, hogy a foglalás nem törlődik, amíg nem erősíti meg az új foglalás részleteit.",
    t_rescheduleAppointmentButton: 'Időpont átütemezése',

    //cancellations
    t_cancelAppointment: 'Időpont lemondása',
    t_cancelAppointmentButton: 'Időpont lemondása',
    t_cancelApptConfirmHeader: 'Biztosan törli a foglalást? ',
    t_cancelApptConfirmText: 'Kapni fog egy e -mailt a foglalás törléséről. ',
    t_cancelApptBtn: 'Igen',
    t_cancelBackBtn: 'Nem',
    t_apptCancelledSuccessHeader: 'Siker',
    t_apptCancelledSuccessText: 'A megbeszélést sikeresen törölték. ',
    t_apptCancelledErrorHeader: 'Hiba',
    t_apptCancelledErrorText: 'Hiba történt a foglalás törlése közben. ',
    t_closeBtn: 'bezár',

    // smart site
    t_helpBtn: `Súgó`,
    t_ssInstruction1: 'Kérjük, válasszon egy webhelyet az alábbi listából, majd kattintson a "Tovább" gombra. A bal és jobb nyíllal további webhelyeket tekinthet meg. ',
    t_ssInstruction2: "Ha további webhelyeket szeretne keresni, vagy más helyeken szeretne keresni, kérjük, adja meg a kívánt helyet (Cím/Irányítószám/Város) és a távolságot (keresési sugár), majd kattintson a *search icon* gombra.",
    t_ssInstruction3: 'Kattintson a *reset icon* gombra a webhelyek kezdeti listájának megtekintéséhez.',
    t_ssSearchInputPlaceholder: `Pl."1666" vagy "Walnut Grove"`,

    t_ssDropdownLabel: 'Távolság kiválasztása',
    t_ssDropdownOption: ' %0% %unit% -on belül',
    t_noSites: 'Nincs elérhető webhely',
    t_locationInputPlaceholder: 'Adjon meg egy helyet',

    t_selectedDateLabel: 'Kiválasztott dátum:',

    t_ssPopup1: `Most, hogy jogosult egy tanulmányra, a következő lépés az, hogy foglaljon időpontot egy tanulmányi klinikára! <br>
    <br>
    Ezen az oldalon kiválaszthatja az Ön számára kényelmes webhelyet. A kiválasztott webhelytől függően a „Következő” gombra kattintva néhány különböző lehetőség közül választhat: <br> <br>
    1. Ha a kiválasztott webhely nem kínál online foglalást, rövidesen telefonon felvesszük Önnel a kapcsolatot, hogy időpontot foglalhassunk. <br> <br>
    2. Ha a kiválasztott webhely online foglalást kínál, akkor lehetősége van a következő típusú találkozók lefoglalására: <br> `,
    t_ssPopup2: `• Igény szerinti videó-virtuális, videó találkozó, amely azonnal megtörténik. <br> <br>
    • Ütemezett videó - virtuális, videó találkozó, amelyet a jövőre ütemezhet. <br> <br>
    • Ütemezett telefon - virtuális, telefonos találkozó, amelyet a jövőre ütemezhet. <br> <br>
    • Helyszíni-személyes találkozó egy klinikai helyszínen, amelyet a jövőre vonatkozóan ütemezhet.
    <br> `,
    t_ssPopup3: `<strong> Mi van, ha módosítani akarom a keresési területemet? </strong> <br> <br>
    Nincs mit! Írjon be egy címet és távolságot (keresési sugár) a keresősávba, és új webhelylistát biztosítunk Önnek. <br> <br>
    <strong> Mi van, ha egyetlen webhely sem kényelmes számomra? </strong> <br> <br>
    Ha a „Nincs kutatási webhely kényelmes” lehetőséget választja, felvesszük Önnel a kapcsolatot, hogy megvitassuk a lehetőségeket, és megbeszéljük a találkozót. <br> <br>
    <strong> Mi a teendő, ha nem vagyok biztos abban, hogyan juthatok el a megbeszélt időponthoz. </strong> <br> <br>
    Ha rákattint a helycsempére, megjelenítjük a webhelyre vezető autós és tömegközlekedési útvonalakat.`,

    revision_changed: {
      revision_changed_1: 'A kérdések ebben a felmérésben megváltoztak, mióta Ön elkezdte. Ezért az 1. kérdéstől kell újrakezdenie.',
      revision_changed_2: 'Elnézést a kellemetlenségekért.',
      ctaText: "Új kérdőív indítása."
    },

    maintenanceError: {
      header: 'ütemezett karbantartás',
      body: 'Sajnáljuk, jelenleg nem tartunk ütemezett karbantartást.'
    },

    t_appointmentAlreadyBookedHeader: `Várj! Már van időpont egyeztetve velünk.`,
    t_appointmentAlreadyBookedBody: `Nem tudunk újabb időpontra foglalni, mert már van időpontot egyeztetve velünk. <br><br> Kérjük, ellenőrizze telefonját, vagy keresse meg e-mail postafiókjában az általunk korábban elküldött visszaigazolási adatokat. <br><br> Ha módosítania kell meglévő időpontját, kérjük, használja az abban az e-mailben található elérhetőségi adatokat.`,
    t_appointmentAlreadyBookedFooter: `Köszönjük lelkesedését és érdeklődését az orvosi kutatások előmozdítása iránt.`,
    t_vfovCompletionHeader : "<b><i>Remek hír! Már egyeztetett időpontot a csapatunkkal és hamarosan újra értesíteni fogjuk.</i></b>",
    t_vfovCompletionBody : "Tapasztalt-e bármilyen változást életmódjában? Az egészségügyi és életmódbeli változások további klinikai vizsgálatokban való részvételre tehetik alkalmassá. A megerősítő e-mailben található link segítségével töltsön ki egy frissített kérdőívet, hogy megtudja most azonnal, alkalmas-e a részvételre!",
    t_vfovCompletionFooter : "Köszönjük lelkesedését és érdeklődését a klinikai kutatások előmozdítása iránt!"
  },
  //ukrainian
  uk_UA: {
    t_date: `Дата`,
    t_time: `Час`,
    t_address: `Адреса`,
    t_tel: `Тел`,
    t_email: `Електронна пошта`,
    t_phone: `Телефон`,
    t_next: `Далі`,
    t_previousQuestion: `Попереднє питання`,
    t_nextQuestion: `Наступне питання`,
    t_confirm: `Підтвердити`,
    t_goBack: `Назад`,
    t_videoCall: `Відеодзвінок`,

    t_coronavirusText: "COVID-19 ПРИМІТКА: Здоров'я та безпека наших працівників та наших пацієнтів є нашим головним пріоритетом. Зверніть увагу, що наші дослідницькі установи не є медичними центрами чи лікарнями. Ми не оглядаємо і не лікуємо хворих пацієнтів. Однак ми вживаємо всі заходи для забезпечення належного обстеження відвідувачів та дотримання важливих стандартів гігієни та належної стерилізації.",
    t_date: "Дата",
    t_time: "Час",
    t_address: "Адреса",
    t_tel: "Телефон",
    t_email: "електронною поштою",
    t_phone: "Телефон",
    t_next: "Далі",
    t_previousQuestion: "Попереднє запитання",
    t_nextQuestion: "Наступне питання",
    t_confirm: "Підтвердити",
    t_goBack: "Назад",
    t_selectDate: 'Оберіть дату та час візиту, зручні для Вас',
    t_datesAvailableLabel: "Доступні дати",
    t_datesUnavailableLabel: "Дати недоступні",
    t_noAppointmentConvenient: "Немає вільного часу за Вашим запитом",
    t_noAppointmentsAtTheMoment: "Член команди нашої клініки зв'яжеться з Вами, щоб призначити зустріч у зручний для Вас час.",
    t_confirmToContact: "Будь ласка, підтвердьте свої дані нижче, і ми незабаром зв'яжемося з Вами.",
    t_noTimeSlotsAvailableMsg: "На жаль, наразі немає вільних чи зручних для Вас дати та часу",
    t_confirmCorrect: "Будь ласка, підтвердіть, що наступна інформація є правильною перед тим, як призначити візит",
    t_sendEmailConfirmation: "Відправити підтвердження електронною поштою",
    t_sendSMSConfirmation: "Відправити підтвердження SMS повідомленням",
    t_bookAppointment: "Призначити візит",
    t_appointmentBooked: "Ваш візит успішно призначений",
    t_somebodyElseBooked: "Вибачте, хтось інший щойно призначив візит на обрані Вами дату та час. Спробуйте вибрати іншу дату чи час.",
    t_bookingConfirmed: "Доброго дня! Ви підтвердили візит на %date% о %time% з %facility%.",
    t_haveQuestionsContact: "Якщо у Вас є питання або необхідно перенести візит, будь ласка, зв'яжіться безпосередньо з лікарем-дослідником.",
    t_lookForward: "Чекаємо Вас!",
    t_regards: "З повагою,",
    t_unsubscribe: "Скасувати підписку на ці сповіщення",
    t_bookingConfirmedContactOffice: "Візит з %facility% на %date% о %time% підтверджено. У разі виникнення будь-яких питань, будь ласка, телефонуйте за номером %phone%",
    t_thankYouEndPage: "ДЯКУЄМО",

    // rebook
    t_rebookModalIntro: "Бронювання для цього опитування вже підтверджені:",
    t_rebookModalContinueText: "Якщо ви продовжите, то зможете вибрати нову дату та час для цієї зустрічі.",
    t_rebookModalContinueText2: `Будь ласка, виберіть варіант нижче, щоб вибрати нову дату та час для цієї зустрічі або повністю скасувати зустріч`,
    t_rebookModalNote: "Зверніть увагу, що поточна зустріч не буде скасована, поки ви не підтвердите деталі нової зустрічі.",
    t_rebookModalNoteCancellations: "Якщо ви бажаєте змінити час бронювання, зверніть увагу, що воно не буде скасовано, поки ви не підтвердите деталі нового бронювання.",
    t_rescheduleAppointmentButton: 'Запит на перенесення',

    // cancellations
    t_cancelAppointment: 'Скасувати бронювання',
    t_cancelAppointmentButton: 'Скасувати бронювання',
    t_cancelApptConfirmHeader: 'Ви впевнені, що хочете скасувати бронювання? ',
    t_cancelApptConfirmText: 'Ви отримаєте електронний лист із підтвердженням скасування бронювання. ',
    t_cancelApptBtn: 'Так',
    t_cancelBackBtn: 'Ні',
    t_apptCancelledSuccessHeader: 'Успіх',
    t_apptCancelledSuccessText: 'Зустріч успішно скасовано.',
    t_apptCancelledErrorHeader: 'Помилка',
    t_apptCancelledErrorText: 'Сталася помилка під час скасування бронювання.',
    t_closeBtn: 'закрити',

    // smart site
    t_helpBtn: `Довідка`,
    t_ssInstruction1: 'Виберіть сайт зі списку нижче та натисніть кнопку "Далі". Ви можете використовувати стрілки вліво і вправо, щоб побачити більше сайтів. ',
    t_ssInstruction2: 'Щоб шукати додаткові сайти або сайти в іншому місці, будь ласка, введіть бажане місце розташування (Адреса/Поштовий індекс/Місто) та відстань (радіус пошуку), а потім натисніть *search icon*.',
    t_ssInstruction3: 'Натисніть *reset icon*, щоб переглянути початковий список сайтів.',
    t_ssSearchInputPlaceholder: `Наприклад," 1666 "або" Горіховий гай"`,

    t_ssDropdownLabel: 'Вибрати відстань',
    t_ssDropdownOption: 'В межах %0% %unit%',
    t_noSites: 'Немає доступних сайтів',
    t_locationInputPlaceholder: 'Введіть місцезнаходження',

    t_selectedDateLabel: 'Вибрана дата:',

    t_ssPopup1: `Тепер, коли Ви отримали право на дослідження, наступний крок - записатися на прийом до навчальної клініки! <br>
    <br>
    На цій сторінці ви можете вибрати зручний для вас сайт. Залежно від обраного сайту вам буде запропоновано кілька різних варіантів, як тільки ви натиснете кнопку «Далі»: <br> <br>
    1. Якщо вибраний сайт не пропонує онлайн -бронювання, ми незабаром зв’яжемося з вами по телефону, щоб записатися на прийом. <br> <br>
    2. Якщо вибраний сайт пропонує онлайн -бронювання, у вас може бути можливість забронювати такі типи зустрічей: <br> `,
    t_ssPopup2: `• Відео на вимогу-віртуальне відеозустріч, яка відбудеться негайно. <br> <br>
    • Заплановане відео - віртуальне відеозустріч, яку можна запланувати на майбутнє. <br> <br>
    • Запланований телефон - віртуальна телефонна зустріч, яку можна запланувати на майбутнє. <br> <br>
    • На місці-особистий прийом у клінічному закладі, який можна запланувати на майбутнє.
    <br> `,
    t_ssPopup3: `<strong> Що робити, якщо я хочу змінити область пошуку? </strong> <br> <br>
    Нема проблем! Введіть адресу та відстань (радіус пошуку) у рядку пошуку, і ми надамо вам новий список сайтів на вибір. <br> <br>
    <strong> Що робити, якщо для мене немає зручних сайтів? </strong> <br> <br>
    Якщо ви виберете "Ніякий дослідницький сайт не зручний", ми зв'яжемося з вами, щоб обговорити варіанти та призначити вам призначення на прийом. <br> <br>
    <strong> Що робити, якщо я не впевнений, як дістатися до зустрічі? </strong> <br> <br>
    Якщо ви натиснете плитку місцеположення, ми покажемо вам маршрути проїзду та транзиту до сайту.`,

    revision_changed: {
      revision_changed_1: 'Це конкретне питання опитування змінилося з моменту його початку. Тому вам потрібно починати все з першого питання.',
      revision_changed_2: 'Вибачте за незручності.',
      ctaText: "Розпочати нове розслідування."
    },

    maintenanceError: {
      header: "Регулярне обслуговування",
      body: 'Вибачте, наразі він не працює через регулярне обслуговування".'
    },

    t_appointmentAlreadyBookedHeader: 'Зачекайте! У вас уже запланована зустріч з нами.',
    t_appointmentAlreadyBookedBody: 'Ми не можемо записати вас на іншу зустріч, тому що у вас уже запланована зустріч. <br><br> Перевірте свій телефон або знайдіть у своїй електронній пошті деталі підтвердження, які ми надіслали раніше. <br><br> Якщо вам потрібно внести будь-які зміни в наявну зустріч, скористайтеся контактною інформацією, що міститься в цьому електронному листі.',
    t_appointmentAlreadyBookedFooter: "Дякуємо за ваш ентузіазм і зацікавленість у внесенні вашої участі у просуванні медичних досліджень"
  },
  //czech
  cs_CZ: {
    t_date: `Datum`,
    t_time: `Čas`,
    t_address: `Adresa`,
    t_tel: `Tel`,
    t_email: `E -mail`,
    t_phone: `Telefon`,
    t_next: `Další`,
    t_previousQuestion: `Předchozí otázka`,
    t_nextQuestion: `Další otázka`,
    t_confirm: `Potvrdit`,
    t_goBack: `Zpět`,
    t_videoCall: `Videohovor`,

    t_selectDate: 'Zvolte datum a čas pro Vaši schůzku',
    t_datesAvailableLabel: "Dostupné datumy",
    t_datesUnavailableLabel: "Nedostupné datumy",
    t_noAppointmentConvenient: "Není k dispozici vyhovující čas pro schůzku",

    t_noAppointmentsAtTheMoment: "Člen týmu péče příslušné kliniky Vás bude kontaktovat telefonicky a naplánuje schůzku, která Vám bude vyhovovat.",
    t_confirmToContact: "Potvrďte prosím níže své údaje a my se Vám brzy ozveme.",

    t_noTimeSlotsAvailableMsg: "Na této klinice bohužel nejsou momentálně dostupné nebo vyhovující termíny.",

    t_confirmCorrect: "Dříve, než zarezervujete Vaši schůzku, potvrďte prosím správnost níže uvedených údajů:",
    t_sendEmailConfirmation: "Poslat potvrzení emailem",
    t_sendSMSConfirmation: "Poslat potvrzení prostřednictvím SMS",
    t_bookAppointment: "Rezervovat schůzku",
    t_appointmentBooked: "Vaše schůzka byla úspěšně rezervována!",
    t_somebodyElseBooked: "Je nám líto, ale někdo jiný si právě zarezervoval tento termín. Zkuste vybrat jiné datum nebo čas.",

    t_bookingConfirmed: "Dobrý den. Vaše shůzka dne %date% v %time% na adrese %facility% byla potvrzena.",
    t_haveQuestionsContact: "Máte-li jakékoliv dotazy, nebo potřebujete-li změnit detaily Vaší rezervace, kontaktujet přímo příslušnou kliniku.",
    t_lookForward: "Budeme se těšit na Vaši návštěvu.",
    t_regards: "S přátelským pozdravem",
    t_unsubscribe: "Odhlásit zasílání těchto upozornění",
    t_bookingConfirmedContactOffice: "Schůzka na adrese %facility% dne %date% v %time% byla potvrzena. V případě jakýchkoliv otázek kontaktujete přímo příslušnou kliniku na telefonním čísle %phone%",
    t_thankYouEndPage: "DĚKUJEME",

    t_coronavirusText: "Zdraví a bezpečnost našich zaměstnanců a pacientů je naší hlavní prioritou. Na naši kliniku nedocházejí akutně nemocní pacienti, a pacienti ve studiích, kteří k nám přichází, nechodí v okamžiku, kdy jsou nakaženi respiračním omezemím. Navíc jsme zavedli opatření, kterými prověřujeme návštěvníky a dodržujeme potřebné normy pro hygienu a řádnou sterilizaci.",
    t_selectAppointmentType: "Jaká forma schůzky Vám nejlépe vyhovuje?",
    t_appointmentTypeTitle: "Typ návštěvy",
    t_emailTitle: "Emailová adresa",
    t_mobilePhoneTitle: "Mobilní telefon",
    t_videNowCard: "Zahájit schůzku prostřednictvím videa",
    t_scheduleVideoCard: "Naplánujte video hovor",
    t_scheduleInPersonCard: "Naplánujte osobní návštěvu",
    t_videoNotSuitable: "Schůzka prostřednictvím video hovoru pro mě není vhodná",
    t_confirmVideoInfo: "Vaše online video schůzka s týmem studijního lékaře se uskuteční ve zvolený den a čas. Po rezervaci schůzky obdržíte potvrzovací e-mail, který bude obsahovat odkaz. V době naplánované schůzky zahájíte videohovor kliknutím na tento  odkaz.",
    t_videoModalText: "Výborně! Před zahájením videohovoru potvrďte následující údaje",
    t_optional: " (nepovinné)",
    t_selectDateVideoSubtext: "Na video-schůzku budete potřebovat zařízení s kamerou  a/nebo mikrofonem (např. většina chytrých telefonů a notebooků má obojí)",
    t_siteNameTitle: "Detaily pobočky",
    t_selectDateVideo: "Vyberte datum a čas pro schůzku prostřednictvím video hovoru:",
    t_videNowNotConvenientIntro: "Pokud Vám to nyní nevyhovuje:",
    t_videNowCard: "Nyní začněte Vaši on-line chůzku",
    t_scheduleVideoCard: "naplánujte si Vaši on-line schůzku na později",
    t_videNowButton: "SPUSTIT TEĎ",
    t_scheduleVideoButton: "NAPLÁNOVAT VIDEO HOVOR",
    t_scheduleInPersonButton: "NAPLÁNOVAT NÁVŠTĚVU",
    t_schedulePhoneCallCard: "Naplánovat telefonní hovor", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "Naplánovat hovor", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Vybrané protokoly:",
    t_selectDatePhone: "Zvolte datum a čas, na který chcete naplánovat schůzku přes telefon:",
    t_selectDatePhoneSubtext: "Abyste mohli realizovat telefonickou schůzku, budete potřebovat ve Vámi zvolený den přístup k telefonu",
    t_confirmPhoneInfo: "Vaše telefonická schůzka proběhne ve zvolený den a čas s týmem klinického lékaře. Ujistěte se, že máte dobrý telefonní signál k přijetí hovoru.",

    // rebook
    t_rebookModalIntro: `Rezervace pro tento průzkum již byly potvrzeny:`,
    t_rebookModalContinueText: `Pokud budete pokračovat, budete moci pro toto setkání vybrat nové datum a čas.`,
    t_rebookModalContinueText2: `Vyberte níže uvedenou možnost a vyberte nové datum a čas pro tuto schůzku nebo schůzku zcela zrušte`,
    t_rebookModalNote: `Upozorňujeme, že aktuální schůzka nebude zrušena, dokud nepotvrdíte podrobnosti o nové schůzce.`,
    t_rebookModalNoteCancelutions: `Pokud si přejete změnit rezervaci rezervace, vezměte prosím na vědomí, že nebude zrušena, dokud nepotvrdíte podrobnosti o nové rezervaci.`,
    t_rescheduleAppointmentButton: 'Přeplánovat dotaz',

    // cancellations
    t_cancelAppointment: 'Zrušit rezervaci',
    t_cancelAppointmentButton: 'Zrušit rezervaci',
    t_cancelApptConfirmHeader: `Opravdu chcete zrušit rezervaci?`,
    t_cancelApptConfirmText: `Obdržíte e -mail s potvrzením zrušení rezervace.`,
    t_cancelApptBtn: 'Ano',
    t_cancelBackBtn: 'Ne',
    t_apptCancelledSuccessHeader: 'Úspěch',
    t_apptCancelledSuccessText: 'Schůzka byla úspěšně zrušena. ',
    t_apptCancelledErrorHeader: 'Chyba',
    t_apptCancelledErrorText: 'Při rušení rezervace došlo k chybě. ',
    t_closeBtn: 'Zavřít',

    // smart site
    t_helpBtn: `Pomoc`,
    t_ssInstruction1: 'Vyberte prosím web ze seznamu níže a klikněte na tlačítko „Další“. K zobrazení dalších webů můžete použít šipky doleva a doprava. ',
    t_ssInstruction2: 'Chcete -li vyhledat další stránky nebo stránky na jiném místě, zadejte preferovanou polohu (adresa/PSČ/město) a vzdálenost (poloměr vyhledávání) a klikněte na *search icon*.',
    t_ssInstruction3: 'Kliknutím na *reset icon* zobrazíte svůj počáteční seznam webů.',
    t_ssSearchInputPlaceholder: "Např. '1666' nebo 'Walnut Grove'",

    t_ssDropdownLabel: 'Vyberte vzdálenost',
    t_ssDropdownOption: 'Do %0% %unit%',
    t_noSites: 'Nejsou k dispozici žádné stránky',
    t_locationInputPlaceholder: 'Zadejte umístění',

    t_selectedDateLabel: 'Vybrané datum:',

    t_ssPopup1: `Nyní, když jste se kvalifikovali ke studiu, je dalším krokem rezervace schůzky se studijní klinikou! <br>
    <br>
    Na této stránce si můžete vybrat web, který vám vyhovuje. V závislosti na vybraném webu se vám po kliknutí na tlačítko „Další“ zobrazí několik různých možností: <br> <br>
    1. Pokud vybraný web nenabízí online rezervaci, brzy vás kontaktujeme telefonicky a domluvíme si schůzku. <br> <br>
    2. Pokud vybraný web nabízí online rezervaci, můžete mít možnost rezervovat následující typy schůzek: <br> `,
    t_ssPopup2: `• Video na vyžádání-virtuální video schůzka, která proběhne okamžitě. <br> <br>
    • Plánované video - virtuální schůzka s videem, kterou si můžete naplánovat do budoucna. <br> <br>
    • Plánovaný telefon - virtuální telefonická schůzka, kterou si můžete naplánovat do budoucna. <br> <br>
    • On-Site-osobní schůzka na klinice, kterou si můžete naplánovat do budoucna.
    <br> `,
    t_ssPopup3: `<strong> <strong> Co když chci změnit svou vyhledávací oblast? </strong> <br> <br>
    Žádný problém! Do vyhledávacího pole zadejte adresu a vzdálenost (poloměr vyhledávání) a my vám poskytneme nový seznam webů, ze kterého si můžete vybrat. <br> <br>
    <strong> Co když mi žádné webové stránky nevyhovují? </strong> <br> <br>
    Pokud zvolíte „Žádný web výzkumu není vhodný“, budeme vás kontaktovat, abychom prodiskutovali možnosti a domluvili si schůzku. <br> <br>
    <strong> Co když si nejsem jistý, jak se dostanu ke své schůzce. </strong> <br> <br>
    Pokud kliknete na dlaždici umístění, ukážeme vám trasu jízdy a veřejné dopravy na web .`,

    revision_changed: {
      revision_changed_1: 'Otázky tohoto konkrétního průzkumu byly od chvíle, kdy jste začal(a) s vyplňováním, změněny. Proto budete muset začít znovu od první otázky.',
      revision_changed_2: 'Omlouváme se za způsobené nepříjemnosti.',
      ctaText: "Spustit nový dotazník."
    },

    maintenanceError: {
      header: 'Naplánovaná údržba',
      body: 'Omlouváme se, aktuálně jsme mimo provoz kvůli plánované údržbě.'
    },

    t_appointmentAlreadyBookedHeader: 'Počkejte! Už máte s námi domluvenou schůzku.',
    t_appointmentAlreadyBookedBody: 'Nemůžeme si vás zarezervovat na další schůzku, protože u nás již jednu naplánovanou máte. <br><br> Zkontrolujte prosím svůj telefon nebo vyhledejte ve své e-mailové schránce potvrzovací údaje, které jsme vám zaslali dříve. <br><br> Pokud potřebujete provést nějaké změny ve své stávající schůzce, použijte prosím kontaktní informace uvedené v tomto e-mailu.',
    t_appointmentAlreadyBookedFooter: 'Děkuji za vaše nadšení a zájem přispět svým dílem k pokroku v lékařském výzkumu.',
    t_vfovCompletionHeader : "<b><i>Skvělá zpráva! Již jste absolvoval/a schůzku s naším týmem a brzo bychom se Vám měli znovu ozvat.</i></b>",
    t_vfovCompletionBody : "Došlo u Vás k nějakým životním změnám? Na základě změn zdravotního stavu a životního stylu můžete být způsobilý/á k účasti v dalších výzkumných klinických hodnoceních. Použijte nyní odkaz v potvrzovacím e-mailu k provedení aktualizovaného průzkumu, aby se zjistilo, zda jste způsobilý/á!",
    t_vfovCompletionFooter : "Děkujeme Vám za Váš zápal a zájem o pokrok klinického výzkumu!"
  },
  //bulgarian
  bg_BG: {
    t_date: `Дата`,
    t_time: `Време`,
    t_address: `Адрес`,
    t_tel: `Тел`,
    t_email: `Имейл`,
    t_phone: `Телефон`,
    t_next: `Напред`,
    t_previousQuestion: `Предишен въпрос`,
    t_nextQuestion: `Следващ въпрос`,
    t_confirm: `Потвърди`,
    t_goBack: `Назад`,
    t_videoCall: `Видеообаждане`,

    t_coronavirusText: "COVID-19 БЕЛЕЖКА: Здравето и безопасността на нашите служители и пациенти са наш основен приоритет. Моля, обърнете внимание, че нашите изследователски центрове не са стандартни медицински центрове или болници. Ние не приемаме и не лекуваме болни пациенти. Предприемат се мерки за осигуряване на подходяща проверка на посетителите и спазване на важни стандарти за хигиена и правилна стерилизация.",
    t_date: "дата",
    t_time: "Час",
    t_address: "адрес",
    t_tel: "телефон",
    t_email: "електронна поща",
    t_phone: "телефон",
    t_next: "Следващ",
    t_previousQuestion: "Предишен въпрос",
    t_nextQuestion: "Следващ въпрос",
    t_confirm: "Потвърди",
    t_goBack: "Върни се",
    t_selectDate: 'Моля, изберете дата и час за Вашата консултация',
    t_datesAvailableLabel: "Датата е свободна",
    t_datesUnavailableLabel: "Датата не е свободна",
    t_noAppointmentConvenient: "Нито един час не е удобен за Вас",
    t_noAppointmentsAtTheMoment: "Член от екипа на центъра по проучването ще се свърже с вас, за да запише консултация, която е най-удобна за вас.",
    t_confirmToContact: "Моля, потвърдете информацията си по-долу и скоро ще се свържем с вас.",
    t_noTimeSlotsAvailableMsg: "За съжаление в момента няма свободни или удобни за Вас часове в този център",
    t_confirmCorrect: "Моля, потвърдете, че следните детайли са верни, преди да резервирате вашият час:",
    t_sendEmailConfirmation: "Изпрати е-мейл потвърждение",
    t_sendSMSConfirmation: "Изпрати SMS потвърждение",
    t_bookAppointment: "Запазете час",
    t_appointmentBooked: "Вашият час е запазен успешно!",
    t_somebodyElseBooked: "За съжаление, някой друг току-що е резервирал този час. Опитайте да изберете друга дата или час.",
    t_bookingConfirmed: "Здравейте. Потвърждаваме вaшият час за % дата % в% часа % в % център%.",
    t_haveQuestionsContact: "Ако имате някакви въпроси или трябва да промените този час, моля, свържете се директно с медицинския център",
    t_lookForward: "Очакваме Ви!",
    t_regards: "Поздрави",
    t_unsubscribe: "Отпишете се от тези съобщения",
    t_bookingConfirmedContactOffice: "Вашият час е потвърден в% център% на% дата% в% часа%. Моля, обадете се в медицинския център на% телефон% ако имате въпроси",
    t_thankYouEndPage: "БЛАГОДАРИМ ВИ",
    t_selectAppointmentType: "Как бихте искали да се проведе вашата консултация?",
    t_appointmentTypeTitle: "Вид на консултацията",
    t_emailTitle: "Имейл адрес",
    t_mobilePhoneTitle: "Мобилен телефон",
    t_videNowCard: "Започнете онлайн видео консултация сега",
    t_scheduleVideoCard: "Запишете онлайн видео консултация",
    t_scheduleInPersonCard: "Запишете посещение в центъра по проучването",
    t_videoNotSuitable: "Видео консултацията не е подходяща за мен",
    t_confirmVideoInfo: "Вашата онлайн видео консултация ще се проведе на датата и часа, избрани от Вас с екипа по проучването. След като запазите вашият час, ще получите имейл за потвърждение, който съдържа връзка. За да стартирате вашата консултация, кликнете върху връзката, и ще започнете автоматично разговора.",
    t_videoModalText: "Страхотно! Просто трябва да потвърдите следните детайли, преди да започнете видео обаждането",
    t_optional: " (по избор)",
    t_selectDateVideoSubtext: "За да проведете видео консултация, ще ви е необходим достъп до устройство с камера и / или микрофон (напр. повечето смарт телефони и лаптопи имат и двете)",
    t_siteNameTitle: "Детайли на центъра по проучването",
    t_selectDateVideo: "Моля, изберете датата и часа, за който искате да запишете онлайн видео консултация",
    t_videNowNotConvenientIntro: "Ако  сега не е удобно за вас:",
    t_videNowCard: "Вашата консултация ще започне сега",
    t_scheduleVideoCard: "Планирайте вашата консултация за по-късно",
    t_videNowButton: "Започва сега",
    t_scheduleVideoButton: "Планирайте видео консултация",
    t_scheduleInPersonButton: "Планирайте консултация",
    t_schedulePhoneCallCard: "Планирайте обаждане", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_schedulePhoneCallButton: "РАЗГЛЕЖДАНЕ НА ОДОБРЕНИЕ", // GOOGLE TRANSLATE - WILL PROBABLY NEED UPDATING
    t_emrSelectProtocolsText: "Избрани протоколи:",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    // rebook
    t_rebookModalIntro: "Резервациите за това проучване вече са потвърдени:",
    t_rebookModalContinueText: "Ако продължите, ще можете да изберете нова дата и час за тази среща.",
    t_rebookModalContinueText2: `Моля, изберете опция по -долу, за да изберете нова дата и час за тази среща или да отмените изцяло срещата`,
    t_rebookModalNote: "Моля, обърнете внимание, че текущата среща няма да бъде отменена, докато не потвърдите подробностите за новата среща.",
    t_rebookModalNoteCancellations: "Ако искате да разсрочите резервация, моля, имайте предвид, че тя няма да бъде отменена, докато не потвърдите подробностите за новата резервация.",
    t_rescheduleAppointmentButton: 'Заявка за разсрочване',

    // cancellations
    t_cancelAppointment: 'Отмяна на резервация',
    t_cancelAppointmentButton: 'Отмяна на резервация',
    t_cancelApptConfirmHeader: 'Сигурни ли сте, че искате да отмените резервацията си? ',
    t_cancelApptConfirmText: `Ще получите имейл, потвърждаващ отмяната на вашата резервация.`,
    t_cancelApptBtn: 'Да',
    t_cancelBackBtn: 'Не',
    t_apptCancelledSuccessHeader: 'Успех',
    t_apptCancelledSuccessText: 'Срещата е отменена успешно. ',
    t_apptCancelledErrorHeader: "Грешка",
    t_apptCancelledErrorText: 'Възникна грешка при отмяната на резервацията. ',
    t_closeBtn: 'Затвори',

    // smart site
    t_helpBtn: `Помощ`,
    t_ssInstruction1: 'Моля, изберете сайт от списъка по -долу и кликнете върху бутона "Напред". Можете да използвате стрелките наляво и надясно, за да видите още сайтове. ',
    t_ssInstruction2: 'За да търсите допълнителни сайтове или за сайтове на друго място, моля, въведете предпочитаното от вас място (Адрес/Пощенски код/Град) и разстояние (радиус на търсене), и кликнете върху *search icon*.',
    t_ssInstruction3: 'Щракнете върху *reset icon*, за да видите първоначалния си списък със сайтове.',
    t_ssSearchInputPlaceholder: `Например„ 1666 “или„ Орехова горичка “`,

    t_ssDropdownLabel: 'Изберете разстояние',
    t_ssDropdownOption: 'В рамките на %0% %unit%',
    t_noSites: `Няма налични сайтове`,
    t_locationInputPlaceholder: 'Въведете местоположение',

    t_selectedDateLabel: 'Избрана дата:',

    t_ssPopup1: `Сега, след като сте се класирали за изследване, следващата стъпка е да си запишете час за проучвателна клиника! <br>
    <br>
    На тази страница можете да изберете удобен за вас сайт. В зависимост от избрания сайт, след като щракнете върху бутона „Напред“, ще ви бъдат представени няколко различни опции: <br> <br>
    1. Ако избраният сайт не предлага онлайн резервация, ние скоро ще се свържем с вас по телефона, за да запазите среща. <br> <br>
    2. Ако избраният сайт предлага онлайн резервация, може да имате възможност да резервирате следните видове срещи: <br> `,
    t_ssPopup2: `• Видео по заявка-виртуална видео среща, която ще се проведе незабавно. <br> <br>
    • Видео по график - виртуална видео среща, която можете да насрочите за в бъдеще. <br> <br>
    • Телефон по график - виртуална телефонна среща, която можете да насрочите за в бъдеще. <br> <br>
    • На място-лична среща в клиничен обект, която можете да насрочите за бъдещето.
    <br> `,
    t_ssPopup3: `<strong> Ами ако искам да променя областта за търсене? </strong> <br> <br>
    Няма проблем! Въведете адрес и разстояние (радиус на търсене) в лентата за търсене и ние ще ви предоставим нов списък с сайтове, от който да избирате. <br> <br>
    <strong> Ами ако няма удобни за мен сайтове? </strong> <br> <br>
    Ако изберете „Няма изследователски сайт е удобен“, ние ще се свържем с вас, за да обсъдим опциите и да ви уговорим за среща. <br> <br>
    <strong> Ами ако не съм сигурен как да стигна до срещата си. </strong> <br> <br>
    Ако щракнете върху плочката за местоположение, ще ви покажем упътвания за шофиране и транзит до сайта.`,

    revision_changed: {
      revision_changed_1: 'Въпросите в този конкретен въпросник са променени откакто сте го започнали. Следователно ще трябва да започнете отново от Въпрос 1.',
      revision_changed_2: 'Извинявам се за неудобството.',
      ctaText: "Започнете нов въпросник."
    },

    maintenanceError: {
      header: 'Планова поддръжка',
      body: 'За съжаление, в момента не работим за планирана поддръжка.'
    },

    t_appointmentAlreadyBookedHeader: 'Изчакайте! Вече имате уговорен час при нас.',
    t_appointmentAlreadyBookedBody: 'Не можем да ви резервираме за друга среща, защото вече имате насрочена среща с нас. <br><br> Моля, проверете телефона си или потърсете във входящата си поща за подробностите за потвърждение, които изпратихме по-рано. <br><br> Ако трябва да направите някакви промени в съществуващата си среща, моля, използвайте информацията за контакт, съдържаща се в този имейл.',
    t_appointmentAlreadyBookedFooter: 'Благодаря ви за вашия ентусиазъм и интерес да направите своята роля за напредъка на медицинските изследвания.',
    t_vfovCompletionHeader : "<b><i>Страхотни новини! Вече сте направили среща с нашия екип и ще се свържем с Вас отново скоро.</i></b>",
    t_vfovCompletionBody : "Имали ли сте някаква промяна в живота си? Промените в здравословното състояние и начина на живот могат да Ви дадат възможност за участие в допълнителни клинични изследователски проучвания. Използвайте връзката във имейлa за потвърждение, за да попълните актуализирана анкета, за да видите дали отговаряте на изискванията сега!",
    t_vfovCompletionFooter : "Благодарим Ви за ентусиазма и интереса към развитието на клиничните изследвания! "
  },
  //Hebrew
  iw_IL: {
    t_date: "Date",
    t_time: "Time",
    t_address: "Address",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Phone",
    t_next: "Next",
    t_previousQuestion: "Previous question",
    t_nextQuestion: "Next Question",
    t_confirm: "Confirm",
    t_goBack: "Go Back",

    t_selectDate: "The next step is to get you scheduled for an appointment with the study doctor's team. During this visit, the study doctor's team will further discuss the study requirements and answer any questions you may have.",
    t_selectDateVideo: "Please select the date and time you would like to schedule your online video appointment for:",
    t_datesAvailableLabel: "Dates Available",
    t_datesUnavailableLabel: "Dates Unavailable",
    t_noAppointmentConvenient: "No appointment time is convenient",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Please confirm your information below and we will reach out to you shortly.",

    t_noTimeSlotsAvailableMsg: "No time slots available. Please try a different date.",

    t_confirmCorrect: "Please confirm that the following details are correct before booking your appointment:",
    t_sendEmailConfirmation: "Send email confirmation",
    t_sendSMSConfirmation: "Send text message confirmation",
    t_bookAppointment: "Book appointment",
    t_appointmentBooked: "Your appointment has been successfully booked!",
    t_somebodyElseBooked: "Sorry, somebody else has just booked this appointment slot. Try selecting a different date or time.",

    t_bookingConfirmed: "Hi. You are confirmed for an appointment on %date% at %time% with %facility%.",
    t_haveQuestionsContact: "If you have any questions or need to change this appointment, please contact the study doctor's office directly.",
    t_lookForward: "We look forward to seeing you!",
    t_regards: "Kind Regards",
    t_unsubscribe: "You have successfully un-subscribed",
    t_bookingConfirmedContactOffice: "Appointment confirmed with %facility% on %date% at %time%. Please call the office at %phone% with any questions",
    t_thankYouEndPage: "Thank you",

    t_coronavirusText: "COVID-19 NOTE: The health and safety of our employees and our patients is our top priority. Please note that our research facilities are not medical centers or hospitals. We do not see or treat sick patients. Measures are in place, however, to ensure that our facilities are appropriately screening visitors and following important standards for hygiene and proper sterilization.",
    t_selectAppointmentType: "How would you like to conduct your appointment?",
    t_appointmentTypeTitle: "Appointment type",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Cell Phone",
    t_videNowCard: "Start your online video appointment <br>NOW",
    t_scheduleVideoCard: "Schedule your online video appointment for LATER",
    t_scheduleInPersonCard: "Schedule in-person visit",
    t_videoNotSuitable: "Video appointment not suitable",
    t_confirmVideoInfo: "Your online video appointment will take place on the date and time selected with the study doctor's team. After booking your appointment, you will receive a confirmation email that contains a link. When your appointment is due to start, click on the link to automatically start the video call.",
    t_videoModalText: "Great! We just need you to confirm the following details before starting your video call:",
    t_optional: " (Optional)",
    t_selectDateVideoSubtext: "In order to conduct the video appointment, you will need access to a device with a camera and/or microphone (e.g. most  smart phones and laptops have both).",
    t_siteNameTitle: "Site details",
    t_videNowNotConvenientIntro: "If now is not convenient for you:",
    t_videNowButton: "START NOW",
    t_scheduleVideoButton: "SCHEDULE VIDEO CALL",
    t_scheduleInPersonButton: "SCHEDULE VISIT",
    t_schedulePhoneCallCard: "Schedule phone call",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Selected protocols:",
    t_selectDatePhone: "Please select the date and time you would like to schedule your telephone appointment:",
    t_selectDatePhoneSubtext: "In order to conduct the telephone appointment, you will need access to a telephone on your chosen date.",
    t_confirmPhoneInfo: "Your telephone appointment will take place on the time and date selected with the site clinician team. Please ensure you are available with a good telephone signal to receive the call.",

    // rebook
    t_rebookModalIntro: "You already have a confirmed appointment for this study:",
    t_rebookModalContinueText: "If you continue, you will be able to select a new date and time for this appointment.",
    t_rebookModalContinueText2: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_rebookModalNote: "Please note that your current appointment will not be cancelled until you confirm the details for your new appointment.",
    t_rebookModalNoteCancellations: "Please note that if you wish to reschedule your appointment, it will not be canceled until you confirm the details of your new appointment.",
    t_rescheduleAppointmentButton: 'Reschedule Query',

    // cancellations
    t_cancelAppointment: 'Cancel Appointment',
    t_cancelAppointmentButton: 'Cancel Appointment',
    t_cancelApptConfirmHeader: 'Are you sure you wish to cancel your appointment?',
    t_cancelApptConfirmText: 'You will receive an email confirming your appointment cancellation.',
    t_cancelApptBtn: 'Yes',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: 'Your appointment was successfully cancelled.',
    t_apptCancelledErrorHeader: 'Error',
    t_apptCancelledErrorText: 'An error occurred while canceling your appointment.',
    t_closeBtn: 'Close',

    // smart site
    t_helpBtn: `Help`,
    t_ssInstruction1: 'Please select a site from the list below and click the "Next" button. You can use the left and right arrows to see more sites.',
    t_ssInstruction2: 'To search for additional sites or for sites in a different location, please enter your preferred location (Address/Zip/City) and distance (search radius), and click on *search icon*.',
    t_ssInstruction3: 'Click on *reset icon* to view your initial list of sites.',
    t_ssSearchInputPlaceholder: "E.g. '1666' or 'Walnut Grove'",

    t_ssDropdownLabel: 'Select distance',
    t_ssDropdownOption: 'Within %0% %unit%',
    t_noSites: 'There are no sites available',
    t_locationInputPlaceholder: 'Enter a location',

    t_selectedDateLabel: 'תאריך נבחר:',

    t_ssPopup1: `Now that you have qualified for a study, the next step is to book an appointment with a study clinic!<br>
    <br>
    On this page, you can select a site that is convenient to you. Depending on the selected site, you will be presented with a few different options once you click the “Next” button:<br><br>
    1. If the selected site does not offer online booking, we will reach out to you shortly by phone to book an appointment. <br><br>
    2. If the selected site does offer online booking, you may have the option to book the following types of appointments:<br>`,
    t_ssPopup2: `• On-Demand Video – a virtual, video appointment that will take place immediately.<br><br>
    • Scheduled Video – a virtual, video appointment that you can schedule for the future.<br><br>
    • Scheduled Phone – a virtual, phone appointment that you can schedule for the future.<br><br>
    • On-Site – an in-person appointment at a clinical site that you can schedule for the future.
    <br>`,
    t_ssPopup3: `<strong>What if I want to change my search area?</strong><br><br>
    No problem! Enter an address and distance (search radius) in the search bar, and we will provide a new site list for you to choose from.<br><br>
    <strong>What if no sites are convenient for me?</strong><br><br>
    If you select “No Research Site is Convenient,” we will reach out to you to discuss options and get you scheduled for an appointment. <br><br>
    <strong>What if I am unsure of how to get to my appointment.</strong><br><br>
    If you click the location tile, we will show you driving and transit directions to the site.`,

    revision_changed: {
      revision_changed_1: 'Questions within this particular survey have been changed since you started it. Therefore, you will need to re-start from Question 1.',
      revision_changed_2: 'Apologies for any inconvenience.',
      ctaText: "Start new questionnaire."
    },

    maintenanceError: {
      header: 'Scheduled Maintenance',
      body: 'Sorry, we are currently down for scheduled maintenance.'
    }
  },
  //Italian
  it_IT: {
    t_date: `Data`,
    t_time: `Tempo`,
    t_address: `Indirizzo`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefono`,
    t_next: `Successivo`,
    t_previousQuestion: `Domanda precedente`,
    t_nextQuestion: `Domanda successiva`,
    t_confirm: `Conferma`,
    t_goBack: `Torna indietro`,
    t_videoCall: `Videochiamata`,

    t_selectDate: "Il passaggio successivo consiste nel fissare un appuntamento con il team del medico dello studio. Durante questa visita, il team del medico dello studio discuterà ulteriormente i requisiti dello studio e risponderà a qualsiasi domanda tu possa avere.",
    t_selectDateVideo: "Seleziona la data e l'ora per cui desideri programmare il tuo appuntamento video online:",
    t_datesAvailableLabel: "Date disponibili",
    t_datesUnavailableLabel: "Date non disponibili",
    t_noAppointmentConvenient: "Nessun orario dell'appuntamento è conveniente",

    t_noAppointmentsAtTheMoment: "Un membro del team di cura dello studio della clinica ti contatterà per fissare un appuntamento che funzioni meglio per te.",
    t_confirmToContact: "Conferma le tue informazioni di seguito e ti contatteremo a breve.",

    t_noTimeSlotsAvailableMsg: "Nessuna fascia oraria disponibile. Prova con una data diversa.",

    t_confirmCorrect: "Si prega di confermare che i seguenti dettagli siano corretti prima di prenotare l'appuntamento:",
    t_sendEmailConfirmation: "Invia conferma email",
    t_sendSMSConfirmation: "Invia conferma SMS",
    t_bookAppointment: "Prenota appuntamento",
    t_appointmentBooked: "Il tuo appuntamento è stato prenotato con successo!",
    t_somebodyElseBooked: "Siamo spiacenti, qualcun altro ha appena prenotato questo spazio per appuntamenti. Prova a selezionare una data o un'ora diversa.",

    t_bookingConfirmed: "Ciao. Sei confermato per un appuntamento il %date% alle %time% con %facility%.",
    t_haveQuestionsContact: "Se hai domande o hai bisogno di cambiare questo appuntamento, contatta direttamente lo studio del medico dello studio.",
    t_lookForward: "Non vediamo l'ora di vederti!",
    t_regards: "Cordiali saluti",
    t_unsubscribe: "Hai annullato con successo l'iscrizione",
    t_bookingConfirmedContactOffice: "Appuntamento confermato con %facility% il %date% alle %time%. Si prega di chiamare l'ufficio al %phone% per qualsiasi domanda",
    t_thankYouEndPage: "Grazie",

    t_coronavirusText: "NOTA COVID-19: la salute e la sicurezza dei nostri dipendenti e dei nostri pazienti è la nostra massima priorità. Si prega di notare che le nostre strutture di ricerca non sono centri medici o ospedali. Non vediamo né curiamo pazienti malati. Sono in atto misure, tuttavia, per garantire che le nostre strutture controllino adeguatamente i visitatori e seguano importanti standard per l'igiene e la corretta sterilizzazione.",
    t_selectAppointmentType: "Come vorresti condurre il tuo appuntamento?",
    t_appointmentTypeTitle: "Tipo di appuntamento",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Cellulare",
    t_videNowCard: "Inizia il tuo appuntamento video online <br>ORA",
    t_scheduleVideoCard: "Pianifica il tuo appuntamento video online per DOPO",
    t_scheduleInPersonCard: "Programma visita di persona",
    t_videoNotSuitable: "Appuntamento video non adatto",
    t_confirmVideoInfo: "Il tuo video appuntamento online si svolgerà nella data e nell'ora selezionate con l'équipe del medico dello studio. Dopo aver prenotato l'appuntamento, riceverai un'e-mail di conferma contenente un link. Quando il tuo appuntamento deve iniziare, fai clic sul link per avviare automaticamente la videochiamata.",
    t_videoModalText: "Fantastico! Abbiamo solo bisogno che tu confermi i seguenti dettagli prima di iniziare la tua videochiamata:",
    t_opzionale: " (Facoltativo)",
    t_selectDateVideoSubtext: "Per condurre l'appuntamento video, è necessario accedere a un dispositivo con fotocamera e/o microfono (ad es. la maggior parte degli smartphone e dei laptop li ha entrambi).",
    t_siteNameTitle: "Dettagli del sito",
    t_videNowNotConvenientIntro: "Se ora non ti conviene:",
    t_videNowButton: "INIZIA ORA",
    t_scheduleVideoButton: "PIANIFICA VIDEO CHIAMATA",
    t_scheduleInPersonButton: "SCHEDULE VISITA",
    t_schedulePhoneCallCard: "Pianifica telefonata",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Protocolli selezionati:",
    t_selectDatePhone: "Seleziona la data e l'ora in cui desideri fissare il tuo appuntamento telefonico:",
    t_selectDatePhoneSubtext: "Per condurre l'appuntamento telefonico, dovrai accedere a un telefono nella data prescelta.",
    t_confirmPhoneInfo: "Il tuo appuntamento telefonico avrà luogo nell'ora e nella data selezionate con il team clinico del sito. Assicurati di essere disponibile con un buon segnale telefonico per ricevere la chiamata.",

    // rebook
    t_rebookModalIntro: "Le prenotazioni per questo sondaggio sono già state confermate:",
    t_rebookModalContinueText: "Se continui, potrai selezionare una nuova data e ora per questo appuntamento.",
    t_rebookModalContinueText2: `Seleziona un'opzione di seguito per selezionare una nuova data e ora per questo appuntamento o per annullare completamente l'appuntamento`,
    t_rebookModalNote: "Tieni presente che l'appuntamento corrente non verrà annullato fino a quando non confermerai i dettagli del nuovo appuntamento.",
    t_rebookModalNoteCancellations: "Se desideri riprogrammare una prenotazione, tieni presente che non verrà cancellata fino a quando non confermerai i dettagli della nuova prenotazione.",
    t_rescheduleAppointmentButton: 'Riprogramma query',

    // cancellations
    t_cancelAppointment: 'Annulla prenotazione',
    t_cancelAppointmentButton: 'Annulla prenotazione',
    t_cancelApptConfirmHeader: 'Sei sicuro di voler cancellare la tua prenotazione? ',
    t_cancelApptConfirmText: `Riceverai un'email di conferma della cancellazione della prenotazione.`,
    t_cancelApptBtn: 'Sì',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: `L'appuntamento è stato annullato con successo.`,
    t_apptCancelledErrorHeader: 'Errore',
    t_apptCancelledErrorText: `Si è verificato un errore durante l'annullamento della prenotazione.`,
    t_closeBtn: 'chiudi',

    // smart site
    t_helpBtn: `Aiuto`,
    t_ssInstruction1: `Seleziona un sito dall'elenco sottostante e fai clic sul pulsante "Avanti".Puoi usare le frecce sinistra e destra per vedere più siti.`,
    t_ssInstruction2: 'Per cercare siti aggiuntivi o siti in una posizione diversa, inserisci la tua posizione preferita (Indirizzo/CAP/Città) e la distanza (raggio di ricerca) e fai clic su *search icon*.',
    t_ssInstruction3: 'Fai clic su *reset icon* per visualizzare il tuo elenco iniziale di siti.',
    t_ssSearchInputPlaceholder: "Es. '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleziona distanza',
    t_ssDropdownOption: 'Entro %0% %unit%',
    t_noSites: 'Non ci sono siti disponibili',
    t_locationInputPlaceholder: 'Inserisci una posizione',

    t_selectedDateLabel: 'Data selezionata:',

    t_ssPopup1: `Ora che ti sei qualificato per uno studio, il passo successivo è prenotare un appuntamento con una clinica dello studio!<br>
    <br>
    In questa pagina puoi selezionare un sito che ti è comodo. A seconda del sito selezionato, ti verranno presentate alcune opzioni diverse dopo aver fatto clic sul pulsante "Avanti":<br><br>
    1. Se il sito selezionato non offre la prenotazione online, ti contatteremo a breve telefonicamente per prenotare un appuntamento. <br><br>
    2. Se il sito selezionato offre la prenotazione online, potresti avere la possibilità di prenotare i seguenti tipi di appuntamenti:<br>`,
    t_ssPopup2: `• Video on-demand – un video appuntamento virtuale che avrà luogo immediatamente.<br><br>
    • Video programmato: un video appuntamento virtuale che puoi programmare per il futuro.<br><br>
    • Telefono programmato: un appuntamento telefonico virtuale che puoi programmare per il futuro.<br><br>
    • In sede: un appuntamento di persona presso un sito clinico che è possibile programmare per il futuro.
    <br>`,
    t_ssPopup3: `<strong>E se volessi cambiare la mia area di ricerca?</strong><br><br>
    Nessun problema! Inserisci un indirizzo e una distanza (raggio di ricerca) nella barra di ricerca e ti forniremo un nuovo elenco di siti tra cui scegliere.<br><br>
    <strong>E se nessun sito è conveniente per me?</strong><br><br>
    Se selezioni "Nessun sito di ricerca è conveniente", ti contatteremo per discutere le opzioni e fissare un appuntamento. <br><br>
    <strong>E se non sono sicuro di come arrivare al mio appuntamento.</strong><br><br>
    Se fai clic sul riquadro della posizione, ti mostreremo le indicazioni stradali e di transito verso il sito.`,

    revision_changed: {
      revision_changed_1: `Le domande all'interno di questo particolare sondaggio sono state modificate da quando l'hai avviato. Pertanto, dovrai ricominciare dalla domanda 1`,
      revision_changed_2: `Ci scusiamo per l'inconveniente.`,
      ctaText: "Avvia nuovo questionario."
    },

    maintenanceError: {
      header: 'Manutenzione programmata',
      body: 'Siamo spiacenti, al momento siamo fuori servizio per manutenzione programmata.'
    },

    t_appointmentAlreadyBookedHeader: 'Aspetta! Hai già un appuntamento programmato con noi.',
    t_appointmentAlreadyBookedBody: `Non possiamo prenotarti per un altro appuntamento, perché ne hai già uno programmato con noi. <br><br> Controlla il tuo telefono o cerca nella tua casella di posta elettronica per i dettagli di conferma, che abbiamo inviato in precedenza. <br><br> Se è necessario apportare modifiche all'appuntamento esistente, utilizzare le informazioni di contatto contenute nell'e-mail.`,
    t_appointmentAlreadyBookedFooter: "Grazie per il tuo entusiasmo e interesse nel fare la tua parte per far progredire la ricerca medica."
  },
  // ------ swiss italian
  it_CH: {
    t_date: `Data`,
    t_time: `Tempo`,
    t_address: `Indirizzo`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefono`,
    t_next: `Successivo`,
    t_previousQuestion: `Domanda precedente`,
    t_nextQuestion: `Domanda successiva`,
    t_confirm: `Conferma`,
    t_goBack: `Torna indietro`,
    t_videoCall: `Videochiamata`,

    t_selectDate: "Il passaggio successivo consiste nel fissare un appuntamento con il team del medico dello studio. Durante questa visita, il team del medico dello studio discuterà ulteriormente i requisiti dello studio e risponderà a qualsiasi domanda tu possa avere.",
    t_selectDateVideo: "Seleziona la data e l'ora per cui desideri programmare il tuo appuntamento video online:",
    t_datesAvailableLabel: "Date disponibili",
    t_datesUnavailableLabel: "Date non disponibili",
    t_noAppointmentConvenient: "Nessun orario dell'appuntamento è conveniente",

    t_noAppointmentsAtTheMoment: "Un membro del team di cura dello studio della clinica ti contatterà per fissare un appuntamento che funzioni meglio per te.",
    t_confirmToContact: "Conferma le tue informazioni di seguito e ti contatteremo a breve.",

    t_noTimeSlotsAvailableMsg: "Nessuna fascia oraria disponibile. Prova con una data diversa.",

    t_confirmCorrect: "Si prega di confermare che i seguenti dettagli siano corretti prima di prenotare l'appuntamento:",
    t_sendEmailConfirmation: "Invia conferma email",
    t_sendSMSConfirmation: "Invia conferma SMS",
    t_bookAppointment: "Prenota appuntamento",
    t_appointmentBooked: "Il tuo appuntamento è stato prenotato con successo!",
    t_somebodyElseBooked: "Siamo spiacenti, qualcun altro ha appena prenotato questo spazio per appuntamenti. Prova a selezionare una data o un'ora diversa.",

    t_bookingConfirmed: "Ciao. Sei confermato per un appuntamento il %date% alle %time% con %facility%.",
    t_haveQuestionsContact: "Se hai domande o hai bisogno di cambiare questo appuntamento, contatta direttamente lo studio del medico dello studio.",
    t_lookForward: "Non vediamo l'ora di vederti!",
    t_regards: "Cordiali saluti",
    t_unsubscribe: "Hai annullato con successo l'iscrizione",
    t_bookingConfirmedContactOffice: "Appuntamento confermato con %facility% il %date% alle %time%. Si prega di chiamare l'ufficio al %phone% per qualsiasi domanda",
    t_thankYouEndPage: "Grazie",

    t_coronavirusText: "NOTA COVID-19: la salute e la sicurezza dei nostri dipendenti e dei nostri pazienti è la nostra massima priorità. Si prega di notare che le nostre strutture di ricerca non sono centri medici o ospedali. Non vediamo né curiamo pazienti malati. Sono in atto misure, tuttavia, per garantire che le nostre strutture controllino adeguatamente i visitatori e seguano importanti standard per l'igiene e la corretta sterilizzazione.",
    t_selectAppointmentType: "Come vorresti condurre il tuo appuntamento?",
    t_appointmentTypeTitle: "Tipo di appuntamento",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Cellulare",
    t_videNowCard: "Inizia il tuo appuntamento video online <br>ORA",
    t_scheduleVideoCard: "Pianifica il tuo appuntamento video online per DOPO",
    t_scheduleInPersonCard: "Programma visita di persona",
    t_videoNotSuitable: "Appuntamento video non adatto",
    t_confirmVideoInfo: "Il tuo video appuntamento online si svolgerà nella data e nell'ora selezionate con l'équipe del medico dello studio. Dopo aver prenotato l'appuntamento, riceverai un'e-mail di conferma contenente un link. Quando il tuo appuntamento deve iniziare, fai clic sul link per avviare automaticamente la videochiamata.",
    t_videoModalText: "Fantastico! Abbiamo solo bisogno che tu confermi i seguenti dettagli prima di iniziare la tua videochiamata:",
    t_opzionale: " (Facoltativo)",
    t_selectDateVideoSubtext: "Per condurre l'appuntamento video, è necessario accedere a un dispositivo con fotocamera e/o microfono (ad es. la maggior parte degli smartphone e dei laptop li ha entrambi).",
    t_siteNameTitle: "Dettagli del sito",
    t_videNowNotConvenientIntro: "Se ora non ti conviene:",
    t_videNowButton: "INIZIA ORA",
    t_scheduleVideoButton: "PIANIFICA VIDEO CHIAMATA",
    t_scheduleInPersonButton: "SCHEDULE VISITA",
    t_schedulePhoneCallCard: "Pianifica telefonata",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Protocolli selezionati:",
    t_selectDatePhone: "Seleziona la data e l'ora in cui desideri fissare il tuo appuntamento telefonico:",
    t_selectDatePhoneSubtext: "Per condurre l'appuntamento telefonico, dovrai accedere a un telefono nella data prescelta.",
    t_confirmPhoneInfo: "Il tuo appuntamento telefonico avrà luogo nell'ora e nella data selezionate con il team clinico del sito. Assicurati di essere disponibile con un buon segnale telefonico per ricevere la chiamata.",

    // rebook
    t_rebookModalIntro: "Le prenotazioni per questo sondaggio sono già state confermate:",
    t_rebookModalContinueText: "Se continui, potrai selezionare una nuova data e ora per questo appuntamento.",
    t_rebookModalContinueText2: `Seleziona un'opzione di seguito per selezionare una nuova data e ora per questo appuntamento o per annullare completamente l'appuntamento`,
    t_rebookModalNote: "Tieni presente che l'appuntamento corrente non verrà annullato fino a quando non confermerai i dettagli del nuovo appuntamento.",
    t_rebookModalNoteCancellations: "Se desideri riprogrammare una prenotazione, tieni presente che non verrà cancellata fino a quando non confermerai i dettagli della nuova prenotazione.",
    t_rescheduleAppointmentButton: 'Riprogramma query',

    // cancellations
    t_cancelAppointment: 'Annulla prenotazione',
    t_cancelAppointmentButton: 'Annulla prenotazione',
    t_cancelApptConfirmHeader: 'Sei sicuro di voler cancellare la tua prenotazione? ',
    t_cancelApptConfirmText: `Riceverai un'email di conferma della cancellazione della prenotazione.`,
    t_cancelApptBtn: 'sì',
    t_cancelBackBtn: 'No',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: `L'appuntamento è stato annullato con successo.`,
    t_apptCancelledErrorHeader: 'Errore',
    t_apptCancelledErrorText: `Si è verificato un errore durante l'annullamento della prenotazione.`,
    t_closeBtn: 'chiudi',

    // smart site
    t_helpBtn: `Aiuto`,
    t_ssInstruction1: `Seleziona un sito dall'elenco sottostante e fai clic sul pulsante "Avanti".Puoi usare le frecce sinistra e destra per vedere più siti.`,
    t_ssInstruction2: 'Per cercare siti aggiuntivi o siti in una posizione diversa, inserisci la tua posizione preferita (Indirizzo/CAP/Città) e la distanza (raggio di ricerca) e fai clic su *search icon*.',
    t_ssInstruction3: 'Fai clic su *reset icon* per visualizzare il tuo elenco iniziale di siti.',
    t_ssSearchInputPlaceholder: "Es. '1666' o 'Walnut Grove'",

    t_ssDropdownLabel: 'Seleziona distanza',
    t_ssDropdownOption: 'Entro %0% %unit%',
    t_noSites: 'Non ci sono siti disponibili',
    t_locationInputPlaceholder: 'Inserisci una posizione',

    t_selectedDateLabel: 'Data selezionata:',

    t_ssPopup1: `Ora che ti sei qualificato per uno studio, il passo successivo è prenotare un appuntamento con una clinica dello studio!<br>
    <br>
    In questa pagina puoi selezionare un sito che ti è comodo. A seconda del sito selezionato, ti verranno presentate alcune opzioni diverse dopo aver fatto clic sul pulsante "Avanti":<br><br>
    1. Se il sito selezionato non offre la prenotazione online, ti contatteremo a breve telefonicamente per prenotare un appuntamento. <br><br>
    2. Se il sito selezionato offre la prenotazione online, potresti avere la possibilità di prenotare i seguenti tipi di appuntamenti:<br>`,
    t_ssPopup2: `• Video on-demand – un video appuntamento virtuale che avrà luogo immediatamente.<br><br>
    • Video programmato: un video appuntamento virtuale che puoi programmare per il futuro.<br><br>
    • Telefono programmato: un appuntamento telefonico virtuale che puoi programmare per il futuro.<br><br>
    • In sede: un appuntamento di persona presso un sito clinico che è possibile programmare per il futuro.
    <br>`,
    t_ssPopup3: `<strong>E se volessi cambiare la mia area di ricerca?</strong><br><br>
    Nessun problema! Inserisci un indirizzo e una distanza (raggio di ricerca) nella barra di ricerca e ti forniremo un nuovo elenco di siti tra cui scegliere.<br><br>
    <strong>E se nessun sito è conveniente per me?</strong><br><br>
    Se selezioni "Nessun sito di ricerca è conveniente", ti contatteremo per discutere le opzioni e fissare un appuntamento. <br><br>
    <strong>E se non sono sicuro di come arrivare al mio appuntamento.</strong><br><br>
    Se fai clic sul riquadro della posizione, ti mostreremo le indicazioni stradali e di transito verso il sito.`,

    revision_changed: {
      revision_changed_1: `Le domande all'interno di questo particolare sondaggio sono state modificate da quando l'hai avviato. Pertanto, dovrai ricominciare dalla domanda 1`,
      revision_changed_2: `Ci scusiamo per l'inconveniente.`,
      ctaText: "Avvia nuovo questionario."
    },

    maintenanceError: {
      header: 'Manutenzione programmata',
      body: 'Siamo spiacenti, al momento siamo fuori servizio per manutenzione programmata.'
    },

    t_appointmentAlreadyBookedHeader: 'Aspetta! Hai già un appuntamento programmato con noi.',
    t_appointmentAlreadyBookedBody: `Non possiamo prenotarti per un altro appuntamento, perché ne hai già uno programmato con noi. <br><br> Controlla il tuo telefono o cerca nella tua casella di posta elettronica per i dettagli di conferma, che abbiamo inviato in precedenza. <br><br> Se è necessario apportare modifiche all'appuntamento esistente, utilizzare le informazioni di contatto contenute nell'e-mail.`,
    t_appointmentAlreadyBookedFooter: "Grazie per il tuo entusiasmo e interesse nel fare la tua parte per far progredire la ricerca medica."
  },

  //Romanian
  ro_RO: {
    t_date: `Data`,
    t_time: `Timp`,
    t_address: `Adresă`,
    t_tel: `Tel`,
    t_email: `E-mail`,
    t_phone: `Telefon`,
    t_next: `Next`,
    t_previousQuestion: `Întrebare anterioară`,
    t_nextQuestion: `Întrebarea următoare`,
    t_confirm: `Confirmați`,
    t_goBack: `Du-te înapoi`,
    t_videoCall: `Apel video`,

    t_selectDate: "Următorul pas este să vă programați pentru o întâlnire cu echipa medicului de studiu. În timpul acestei vizite, echipa medicului de studiu va discuta în continuare cerințele de studiu și vă va răspunde la orice întrebări.",
    t_selectDateVideo: "Vă rugăm să selectați data și ora pentru care doriți să vă programați întâlnirea video online pentru:",
    t_datesAvailableLabel: "Date disponibile",
    t_datesUnavailableLabel: "Date indisponibile",
    t_noAppointmentConvenient: "Nu este convenabilă nicio oră de întâlnire",
    t_noAppointmentsAtTheMoment: "Un membru al echipei de îngrijire a studiului clinicii vă va contacta pentru a programa o întâlnire care funcționează cel mai bine pentru dvs.",
    t_confirmToContact: "Vă rugăm să confirmați informațiile de mai jos și vă vom contacta în curând.",
    t_noTimeSlotsAvailableMsg: "Nu există orare disponibile. Încercați o altă dată.",
    t_confirmCorrect: "Vă rugăm să confirmați că următoarele detalii sunt corecte înainte de a vă rezerva întâlnirea:",
    t_sendEmailConfirmation: "Trimiteți confirmarea prin e-mail",
    t_sendSMSConfirmation: "Trimiteți confirmarea mesajului text",
    t_bookAppointment: "Rezervare rezervare",
    t_appointmentBooked: "Programarea dvs. a fost rezervată cu succes!",
    t_somebodyElseBooked: "Ne pare rău, altcineva tocmai a rezervat acest interval de întâlnire. Încercați să selectați o altă dată sau oră.",
    t_bookingConfirmed: "Bună. Sunteți confirmat pentru o întâlnire la% date% la% time% cu% facilitate%.",
    t_haveQuestionsContact: "Dacă aveți întrebări sau trebuie să modificați această întâlnire, vă rugăm să contactați direct cabinetul medicului de studiu.",
    t_lookForward: "Vă așteptăm cu nerăbdare!",
    t_regards: "Salutări",
    t_unsubscribe: "V-ați dezabonat cu succes",
    t_bookingConfirmedContactOffice: "Programare confirmată cu% facilitate% la% date% la% time%. Vă rugăm să sunați la birou la% phone% cu orice întrebare",
    t_thankYouEndPage: "Mulțumesc",
    t_coronavirusText: "COVID-19 NOTĂ: Sănătatea și siguranța angajaților noștri și a pacienților noștri este prioritatea noastră principală. Vă rugăm să rețineți că facilitățile noastre de cercetare nu sunt centre medicale sau spitale. Nu vedem și nu tratăm pacienții bolnavi. Măsurile sunt în vigoare, cu toate acestea, pentru a ne asigura că facilitățile noastre verifică în mod corespunzător vizitatorii și respectă standarde importante pentru igienă și sterilizare adecvată. ",
    t_selectAppointmentType: "Cum ați dori să vă desfășurați programarea?",
    t_appointmentTypeTitle: "Tipul întâlnirii",
    t_emailTitle: 'E- mail',
    t_mobilePhoneTitle: "Telefon mobil",
    t_videNowCard: 'Începeți întâlnirea video online <br> ACUM',
    t_scheduleVideoCard: 'Programați-vă întâlnirea video online pentru MAI TÂRZIU',
    t_scheduleInPersonCard: "Programați o vizită personală",
    t_videoNotSuitable: "Programarea video nu este potrivită",
    t_confirmVideoInfo: "Programarea video online va avea loc la data și ora selectate împreună cu echipa medicului de studiu. După rezervare, veți primi un e-mail de confirmare care conține un link. Când programarea dvs. va începe, faceți clic pe link pentru a porni automat apelul video. ",
    t_videoModalText: "Minunat! Trebuie doar să confirmați următoarele detalii înainte de a începe apelul video:",
    t_optional: "(Opțional)",
    t_selectDateVideoSubtext: "Pentru a efectua întâlnirea video, veți avea nevoie de acces la un dispozitiv cu cameră și / sau microfon (de exemplu, majoritatea telefoanelor inteligente și laptopurilor le au pe ambele).",
    t_siteNameTitle: "Detalii site",
    t_videNowNotConvenientIntro: "Dacă acum nu vă convine:",
    t_videNowButton: "ÎNCEPE ACUM",
    t_scheduleVideoButton: "PROGRAMA APEL VIDEO",
    t_scheduleInPersonButton: "PROGRAMARE VIZITĂ",
    t_schedulePhoneCallCard: "Programați un apel telefonic",
    t_schedulePhoneCallButton: "PROGRAMARE APEL",
    t_emrSelectProtocolsText: "Protocoale selectate:",
    t_selectDatePhone: "Vă rugăm să selectați data și ora la care doriți să vă programați întâlnirea telefonică:",
    t_selectDatePhoneSubtext: "Pentru a efectua programarea telefonică, veți avea nevoie de acces la un telefon la data aleasă.",
    t_confirmPhoneInfo: "Programarea dvs. telefonică va avea loc la data și ora selectată împreună cu echipa clinicianului site-ului. Vă rugăm să vă asigurați că sunteți disponibil cu un semnal telefonic bun pentru a primi apelul.",

    // rebook
    t_rebookModalIntro: "Aveți deja o întâlnire confirmată pentru acest studiu:",
    t_rebookModalContinueText: "Dacă continuați, veți putea selecta o nouă dată și oră pentru această întâlnire.",
    t_rebookModalContinueText2: `Vă rugăm să selectați o opțiune de mai jos pentru a selecta o nouă dată și oră pentru această întâlnire sau pentru a anula complet întâlnirea`,
    t_rebookModalNote: "Vă rugăm să rețineți că întâlnirea dvs. curentă nu va fi anulată până când nu confirmați detaliile pentru noua dvs. întâlnire.",
    t_rebookModalNoteCancellations: "Vă rugăm să rețineți că, dacă doriți să vă reprogramați întâlnirea, aceasta nu va fi anulată până când nu confirmați detaliile noii dvs. întâlniri.",
    t_rescheduleAppointmentButton: `Schimbare interogare`,

    // anulări
    t_cancelAppointment: `Anulați programarea`,
    t_cancelAppointmentButton: `Anulați programarea`,
    t_cancelApptConfirmHeader: 'Sunteți sigur că doriți să anulați întâlnirea?',
    t_cancelApptConfirmText: 'Veți primi un e-mail care confirmă anularea rezervării.',
    t_cancelApptBtn: 'da',
    t_cancelBackBtn: 'Nu',
    t_apptCancelledSuccessHeader: 'Succes',
    t_apptCancelledSuccessText: 'Programarea dvs. a fost anulată cu succes.',
    t_apptCancelledErrorHeader: 'Eroare',
    t_apptCancelledErrorText: 'A apărut o eroare la anularea programării.',
    t_closeBtn: 'Închide',

    // site inteligent
    t_helpBtn: `Ajutor`,
    t_ssInstruction1: 'Vă rugăm să selectați un site din lista de mai jos și să faceți clic pe butonul „Următorul”. Puteți utiliza săgețile stânga și dreapta pentru a vedea mai multe site-uri. ',
    t_ssInstruction2: `Pentru a căuta site-uri suplimentare sau pentru site-uri într-o altă locație, vă rugăm să introduceți locația preferată (Adresa / Cod poștal / Oraș) și distanța (raza de căutare) și faceți clic pe *search icon*.`,
    t_ssInstruction3: 'Faceți clic pe *reset icon* pentru a vedea lista inițială de site-uri.',
    t_ssSearchInputPlaceholder: `De ex. '1666' sau 'Walnut Grove'`,

    t_ssDropdownLabel: 'Selectați distanța',
    t_ssDropdownOption: 'În cadrul% 0%% unitate%',
    t_noSites: 'Nu există site-uri disponibile',
    t_locationInputPlaceholder: 'Introduceți o locație',

    t_selectedDateLabel: 'Data selectată:',

    t_ssPopup1: `Acum că v-ați calificat pentru un studiu, următorul pas este să rezervați o întâlnire la o clinică de studiu! <br>
    <br>
    Pe această pagină, puteți selecta un site care vă este convenabil. În funcție de site-ul selectat, vi se vor prezenta câteva opțiuni diferite după ce faceți clic pe butonul „Următorul”: <br> <br>
    1. Dacă site-ul selectat nu oferă rezervare online, vă vom contacta în scurt timp prin telefon pentru a rezerva o întâlnire. <br> <br>
    2. Dacă site-ul selectat oferă rezervări online, este posibil să aveți opțiunea de a rezerva următoarele tipuri de întâlniri: <br> `,
    t_ssPopup2: `• Video la cerere - o întâlnire video virtuală care va avea loc imediat. <br> <br>
    • Video programat - o întâlnire video virtuală pe care o puteți programa pentru viitor. <br> <br>
    • Telefon programat - o întâlnire virtuală pe telefon pe care o puteți programa pentru viitor. <br> <br>
    • On-Site - o întâlnire personală la un site clinic pe care îl puteți programa pentru viitor.
    <br> `,
    t_ssPopup3: `<strong> Ce se întâmplă dacă vreau să-mi schimb zona de căutare? </strong> <br> <br>
    Nici o problemă! Introduceți o adresă și distanță (raza de căutare) în bara de căutare și vă vom oferi o nouă listă de site-uri pentru a alege. <br> <br>
    <strong> Ce se întâmplă dacă niciun site nu este convenabil pentru mine? </strong> <br> <br>
    Dacă selectați „Niciun site de cercetare nu este convenabil”, vă vom contacta pentru a discuta despre opțiuni și pentru a vă programa pentru o întâlnire. <br> <br>
    <strong> Ce se întâmplă dacă nu sunt sigur cum să ajung la programarea mea. </strong> <br> <br>
    Dacă faceți clic pe țiglă de locație, vă vom arăta indicații de conducere și de transport către site.`,

    revision_changed: {
      revision_changed_1: 'Întrebările din cadrul acestui sondaj au fost modificate de când l-ați început. Prin urmare, va trebui să reporniți de la întrebarea 1. ',
      revision_changed_2: 'Scuze pentru orice inconvenient.',
      ctaText: `Începeți un chestionar nou`,
    },

    maintenanceError: {
      header: `Întreținere programată`,
      body: `Ne pare rău, momentan nu avem servicii de întreținere programată.`
    },

    t_appointmentAlreadyBookedHeader: 'Stai! Aveți deja programată o întâlnire la noi.',
    t_appointmentAlreadyBookedBody: 'Nu vă putem rezerva pentru o altă programare, deoarece aveți deja una programată la noi. <br><br> Vă rugăm să vă verificați telefonul sau să căutați în căsuța de e-mail pentru detaliile de confirmare, pe care le-am trimis anterior. <br><br> Dacă trebuie să faceți modificări la întâlnirea dvs. existentă, vă rugăm să utilizați informațiile de contact conținute în acel e-mail.',
    t_appointmentAlreadyBookedFooter: 'Vă mulțumim pentru entuziasm și interesul de a vă face partea pentru a avansa cercetarea medicală'
  },

  //Portuguese
  pt_PT: {
    t_date: `Date`,
    t_time: `Time`,
    t_address: `Address`,
    t_tel: `Tel`,
    t_email: `Email`,
    t_phone: `Telefone`,
    t_next: `Next`,
    t_previousQuestion: `Pergunta anterior`,
    t_nextQuestion: `Próxima pergunta`,
    t_confirm: `Confirm`,
    t_goBack: `Go Back`,
    t_videoCall: `Video Call`,

    t_selectDate: "A próxima etapa é agendar uma consulta com a equipe do médico do estudo. Durante esta visita, a equipe do médico do estudo discutirá mais detalhadamente os requisitos do estudo e responderá a quaisquer perguntas que você possa ter.",
    t_selectDateVideo: "Por favor, selecione a data e hora que você gostaria de agendar seu vídeo online:",
    t_datesAvailableLabel: "Datas disponíveis",
    t_datesUnavailableLabel: "Datas indisponíveis",
    t_noAppointmentConvenient: "Nenhum horário de compromisso é conveniente",
    t_noAppointmentsAtTheMoment: "Um membro da equipe de atendimento do estudo da clínica entrará em contato com você para agendar uma consulta que funcione melhor para você.",
    t_confirmToContact: "Confirme suas informações abaixo e entraremos em contato com você em breve.",
    t_noTimeSlotsAvailableMsg: "Nenhum intervalo de tempo disponível. Tente uma data diferente.",
    t_confirmCorrect: "Por favor, confirme se os seguintes detalhes estão corretos antes de reservar sua consulta:",
    t_sendEmailConfirmation: "Enviar e-mail de confirmação",
    t_sendSMSConfirmation: "Enviar confirmação de mensagem de texto",
    t_bookAppointment: "Agendar consulta",
    t_appointmentBooked: "Seu compromisso foi agendado com sucesso!",
    t_somebodyElseBooked: "Desculpe, outra pessoa acabou de reservar este horário disponível. Tente selecionar uma data ou hora diferente.",
    t_bookingConfirmed: "Olá. Você está confirmado para um compromisso em% date% às% time% com% facility%.",
    t_haveQuestionsContact: "Se você tiver alguma dúvida ou precisar alterar esta consulta, entre em contato diretamente com o consultório do médico do estudo.",
    t_lookForward: "Estamos ansiosos para vê-lo!",
    t_regards: "Atenciosamente",
    t_unsubscribe: "Você cancelou a inscrição com sucesso",
    t_bookingConfirmedContactOffice: "Compromisso confirmado com% facility% em% date% às% time%. Por favor, ligue para o escritório em% phone% com quaisquer perguntas",
    t_thankYouEndPage: "Obrigado",
    t_coronavirusText: "COVID-19 OBSERVAÇÃO: A saúde e a segurança de nossos funcionários e pacientes são nossa prioridade. Observe que nossas instalações de pesquisa não são centros médicos ou hospitais. Não vemos nem tratamos pacientes enfermos. Medidas estão em vigor, no entanto, para garantir que nossas instalações estejam fazendo a triagem adequada dos visitantes e seguindo padrões importantes de higiene e esterilização adequada. ",
    t_selectAppointmentType: "Como você gostaria de conduzir sua consulta?",
    t_appointmentTypeTitle: "Tipo de compromisso",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Celular",
    t_videNowCard: "Comece seu compromisso de vídeo online <br> AGORA",
    t_scheduleVideoCard: "Agende seu compromisso de vídeo online para MAIS TARDE",
    t_scheduleInPersonCard: "Agendar visita pessoal",
    t_videoNotSuitable: "Compromisso de vídeo não adequado",
    t_confirmVideoInfo: "Sua consulta por vídeo online será realizada na data e hora selecionadas com a equipe do médico do estudo. Após agendar sua consulta, você receberá um e-mail de confirmação que contém um link. Quando sua consulta estiver marcada para começar, clique no link para iniciar a videochamada automaticamente. ",
    t_videoModalText: "Ótimo! Só precisamos que você confirme os seguintes detalhes antes de iniciar sua vídeo chamada:",
    t_opcional: "(Opcional)",
    t_selectDateVideoSubtext: "Para conduzir o videoconferência, você precisará acessar um dispositivo com uma câmera e / ou microfone (por exemplo, a maioria dos smartphones e laptops têm os dois).",
    t_siteNameTitle: "Detalhes do site",
    t_videNowNotConvenientIntro: "Se agora não for conveniente para você:",
    t_videNowButton: "COMECE AGORA",
    t_scheduleVideoButton: "AGENDAR VÍDEO CHAMADA",
    t_scheduleInPersonButton: "AGENDE VISITA",
    t_schedulePhoneCallCard: "Agendar chamada",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Protocolos selecionados:",
    t_selectDatePhone: "Selecione a data e a hora em que deseja agendar sua consulta por telefone:",
    t_selectDatePhoneSubtext: "Para realizar a consulta telefônica, você precisará acessar um telefone na data escolhida.",
    t_confirmPhoneInfo: "Sua consulta por telefone será realizada no horário e data selecionados com a equipe clínica do centro. Certifique-se de que você está disponível com um bom sinal de telefone para receber a chamada.",

    // rebook
    t_rebookModalIntro: "Você já tem uma consulta confirmada para este estudo:",
    t_rebookModalContinueText: "Se você continuar, poderá selecionar uma nova data e hora para este compromisso.",
    t_rebookModalContinueText2: `Selecione uma opção abaixo para selecionar uma nova data e hora para este compromisso ou para cancelá-lo totalmente`,
    t_rebookModalNote: "Observe que seu compromisso atual não será cancelado até que você confirme os detalhes de seu novo compromisso.",
    t_rebookModalNoteCancellations: "Por favor, note que se você deseja remarcar seu compromisso, ele não será cancelado até que você confirme os detalhes de seu novo compromisso.",
    t_rescheduleAppointmentButton: 'Reagendar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar Compromisso',
    t_cancelAppointmentButton: 'Cancelar Compromisso',
    t_cancelApptConfirmHeader: 'Tem certeza que deseja cancelar seu compromisso?',
    t_cancelApptConfirmText: 'Você receberá um e-mail confirmando o cancelamento do seu agendamento.',
    t_cancelApptBtn: 'Sim',
    t_cancelBackBtn: 'Não',
    t_apptCancelledSuccessHeader: 'Sucesso',
    t_apptCancelledSuccessText: 'Seu compromisso foi cancelado com sucesso.',
    t_apptCancelledErrorHeader: 'Erro',
    t_apptCancelledErrorText: 'Ocorreu um erro ao cancelar o seu compromisso.',
    t_closeBtn: 'Fechar',

    // smart site
    t_helpBtn: `Ajuda`,
    t_ssInstruction1: `Por favor, selecione um site da lista abaixo e clique no botão' Avançar '. Você pode usar as setas esquerda e direita para ver mais sites.`,
    t_ssInstruction2: 'Para pesquisar sites adicionais ou em um local diferente, insira sua localização preferida (Endereço / CEP / Cidade) e distância (raio de pesquisa) e clique em *search icon*.',
    t_ssInstruction3: 'Clique em *reset icon* para ver sua lista inicial de sites.',
    t_ssSearchInputPlaceholder: "Por exemplo, '1666' ou 'Walnut Grove'",

    t_ssDropdownLabel: 'Selecionar distância',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'Não há sites disponíveis',
    t_locationInputPlaceholder: 'Insira um local',

    t_selectedDateLabel: 'Data Selecionada:',

    t_ssPopup1: `Agora que você se qualificou para um estudo, a próxima etapa é agendar uma consulta em uma clínica de estudos! <br>
    <br>
    Nesta página, você pode selecionar um site que seja conveniente para você. Dependendo do site selecionado, você verá algumas opções diferentes assim que clicar no botão “Próximo”: <br> <br>
    1. Se o site selecionado não oferecer reserva online, entraremos em contato com você em breve por telefone para marcar uma consulta. <br> <br>
    2. Se o site selecionado oferecer reserva online, você pode ter a opção de reservar os seguintes tipos de compromissos: <br> `,
    t_ssPopup2: `• Vídeo sob demanda - um compromisso de vídeo virtual que ocorrerá imediatamente. <br> <br>
    • Vídeo agendado - um compromisso virtual com vídeo que você pode agendar para o futuro. <br> <br>
    • Telefone agendado - um compromisso virtual por telefone que você pode agendar para o futuro. <br> <br>
    • No local - uma consulta pessoal em um centro clínico que você pode agendar para o futuro.
    <br> `,
    t_ssPopup3: `<strong> E se eu quiser mudar minha área de pesquisa? </strong> <br> <br>
    Sem problemas! Insira um endereço e distância (raio de pesquisa) na barra de pesquisa e forneceremos uma nova lista de sites para você escolher. <br> <br>
    <strong> E se nenhum site for conveniente para mim? </strong> <br> <br>
    Se você selecionar “Nenhum local de pesquisa é conveniente”, entraremos em contato com você para discutir as opções e agendá-lo para uma consulta. <br> <br>
    <strong> E se eu não tiver certeza de como chegar ao meu compromisso. </strong> <br> <br>
    Se você clicar no bloco de localização, mostraremos as instruções de direção e transporte público para o site.`,

    revision_changed: {
      revision_changed_1: 'As perguntas nesta pesquisa em particular foram alteradas desde que você a iniciou. Portanto, você precisará reiniciar a partir da pergunta 1. ',
      revision_changed_2: 'Desculpas por qualquer inconveniente.',
      ctaText: "Iniciar novo questionário."
    },

    maintenanceError: {
      header: 'Manutenção programada',
      body: 'Desculpe, estamos no momento em manutenção programada.'
    },

    t_appointmentAlreadyBookedHeader: 'Espere! Você já tem um compromisso agendado conosco.',
    t_appointmentAlreadyBookedBody: 'Não podemos agendar outra consulta, porque você já tem uma agendada conosco. <br><br> Por favor, verifique seu telefone ou procure em sua caixa de entrada de e-mail os detalhes de confirmação, que enviamos anteriormente. <br><br> Se você precisar fazer alguma alteração em seu compromisso existente, use as informações de contato contidas nesse e-mail.',
    t_appointmentAlreadyBookedFooter: 'Obrigado por seu entusiasmo e interesse em fazer sua parte para o avanço da pesquisa médica.'
  },
  // ------ brazil portuguese
  pt_BR: {
    t_date: `Date`,
    t_time: `Time`,
    t_address: `Address`,
    t_tel: `Tel`,
    t_email: `Email`,
    t_phone: `Telefone`,
    t_next: `Next`,
    t_previousQuestion: `Pergunta anterior`,
    t_nextQuestion: `Próxima pergunta`,
    t_confirm: `Confirm`,
    t_goBack: `Go Back`,
    t_videoCall: `Video Call`,

    t_time: "Tempo",
    t_address: "Endereço",
    t_tel: "Tel",
    t_email: "Email",
    t_phone: "Telefone",
    t_next: "Próximo",
    t_previousQuestion: "Pergunta anterior",
    t_nextQuestion: "Próxima pergunta",
    t_confirm: "Confirmar",
    t_goBack: "Voltar",
    t_selectDate: "A próxima etapa é agendar uma consulta com a equipe do médico do estudo. Durante esta visita, a equipe do médico do estudo discutirá mais detalhadamente os requisitos do estudo e responderá a quaisquer perguntas que você possa ter.",
    t_selectDateVideo: "Por favor, selecione a data e hora que você gostaria de agendar seu vídeo online:",
    t_datesAvailableLabel: "Datas disponíveis",
    t_datesUnavailableLabel: "Datas indisponíveis",
    t_noAppointmentConvenient: "Nenhum horário de compromisso é conveniente",
    t_noAppointmentsAtTheMoment: "Um membro da equipe de atendimento do estudo da clínica entrará em contato com você para agendar uma consulta que funcione melhor para você.",
    t_confirmToContact: "Confirme suas informações abaixo e entraremos em contato com você em breve.",
    t_noTimeSlotsAvailableMsg: "Nenhum intervalo de tempo disponível. Tente uma data diferente.",
    t_confirmCorrect: "Por favor, confirme se os seguintes detalhes estão corretos antes de reservar sua consulta:",
    t_sendEmailConfirmation: "Enviar e-mail de confirmação",
    t_sendSMSConfirmation: "Enviar confirmação de mensagem de texto",
    t_bookAppointment: "Agendar consulta",
    t_appointmentBooked: "Seu compromisso foi agendado com sucesso!",
    t_somebodyElseBooked: "Desculpe, outra pessoa acabou de reservar este horário disponível. Tente selecionar uma data ou hora diferente.",
    t_bookingConfirmed: "Olá. Você está confirmado para um compromisso em% date% às% time% com% facility%.",
    t_haveQuestionsContact: "Se você tiver alguma dúvida ou precisar alterar esta consulta, entre em contato diretamente com o consultório do médico do estudo.",
    t_lookForward: "Estamos ansiosos para vê-lo!",
    t_regards: "Atenciosamente",
    t_unsubscribe: "Você cancelou a inscrição com sucesso",
    t_bookingConfirmedContactOffice: "Compromisso confirmado com% facility% em% date% às% time%. Por favor, ligue para o escritório em% phone% com quaisquer perguntas",
    t_thankYouEndPage: "Obrigado",
    t_coronavirusText: "COVID-19 OBSERVAÇÃO: A saúde e a segurança de nossos funcionários e pacientes são nossa prioridade. Observe que nossas instalações de pesquisa não são centros médicos ou hospitais. Não vemos nem tratamos pacientes enfermos. Medidas estão em vigor, no entanto, para garantir que nossas instalações estejam fazendo a triagem adequada dos visitantes e seguindo padrões importantes de higiene e esterilização adequada. ",
    t_selectAppointmentType: "Como você gostaria de conduzir sua consulta?",
    t_appointmentTypeTitle: "Tipo de compromisso",
    t_emailTitle: "Email",
    t_mobilePhoneTitle: "Celular",
    t_videNowCard: "Comece seu compromisso de vídeo online <br> AGORA",
    t_scheduleVideoCard: "Agende seu compromisso de vídeo online para MAIS TARDE",
    t_scheduleInPersonCard: "Agendar visita pessoal",
    t_videoNotSuitable: "Compromisso de vídeo não adequado",
    t_confirmVideoInfo: "Sua consulta por vídeo online será realizada na data e hora selecionadas com a equipe do médico do estudo. Após agendar sua consulta, você receberá um e-mail de confirmação que contém um link. Quando sua consulta estiver marcada para começar, clique no link para iniciar a videochamada automaticamente. ",
    t_videoModalText: "Ótimo! Só precisamos que você confirme os seguintes detalhes antes de iniciar sua vídeo chamada:",
    t_opcional: "(Opcional)",
    t_selectDateVideoSubtext: "Para conduzir o videoconferência, você precisará acessar um dispositivo com uma câmera e / ou microfone (por exemplo, a maioria dos smartphones e laptops têm os dois).",
    t_siteNameTitle: "Detalhes do site",
    t_videNowNotConvenientIntro: "Se agora não for conveniente para você:",
    t_videNowButton: "COMECE AGORA",
    t_scheduleVideoButton: "AGENDAR VÍDEO CHAMADA",
    t_scheduleInPersonButton: "AGENDE VISITA",
    t_schedulePhoneCallCard: "Agendar chamada",
    t_schedulePhoneCallButton: "SCHEDULE CALL",
    t_emrSelectProtocolsText: "Protocolos selecionados:",
    t_selectDatePhone: "Selecione a data e a hora em que deseja agendar sua consulta por telefone:",
    t_selectDatePhoneSubtext: "Para realizar a consulta telefônica, você precisará acessar um telefone na data escolhida.",
    t_confirmPhoneInfo: "Sua consulta por telefone será realizada no horário e data selecionados com a equipe clínica do centro. Certifique-se de que você está disponível com um bom sinal de telefone para receber a chamada.",

    // rebook
    t_rebookModalIntro: "Você já tem uma consulta confirmada para este estudo:",
    t_rebookModalContinueText: "Se você continuar, poderá selecionar uma nova data e hora para este compromisso.",
    t_rebookModalContinueText2: `Selecione uma opção abaixo para selecionar uma nova data e hora para este compromisso ou para cancelá-lo totalmente`,
    t_rebookModalNote: "Observe que seu compromisso atual não será cancelado até que você confirme os detalhes de seu novo compromisso.",
    t_rebookModalNoteCancellations: "Por favor, note que se você deseja remarcar seu compromisso, ele não será cancelado até que você confirme os detalhes de seu novo compromisso.",
    t_rescheduleAppointmentButton: 'Reagendar consulta',

    // cancellations
    t_cancelAppointment: 'Cancelar Compromisso',
    t_cancelAppointmentButton: 'Cancelar Compromisso',
    t_cancelApptConfirmHeader: 'Tem certeza que deseja cancelar seu compromisso?',
    t_cancelApptConfirmText: 'Você receberá um e-mail confirmando o cancelamento do seu agendamento.',
    t_cancelApptBtn: 'Sim',
    t_cancelBackBtn: 'Não',
    t_apptCancelledSuccessHeader: 'Sucesso',
    t_apptCancelledSuccessText: 'Seu compromisso foi cancelado com sucesso.',
    t_apptCancelledErrorHeader: 'Erro',
    t_apptCancelledErrorText: 'Ocorreu um erro ao cancelar o seu compromisso.',
    t_closeBtn: 'Fechar',

    // smart site
    t_helpBtn: `Ajuda`,
    t_ssInstruction1: `Por favor, selecione um site da lista abaixo e clique no botão' Avançar '. Você pode usar as setas esquerda e direita para ver mais sites.`,
    t_ssInstruction2: 'Para pesquisar sites adicionais ou em um local diferente, insira sua localização preferida (Endereço / CEP / Cidade) e distância (raio de pesquisa) e clique em *search icon*.',
    t_ssInstruction3: 'Clique em *reset icon* para ver sua lista inicial de sites.',
    t_ssSearchInputPlaceholder: "Por exemplo, '1666' ou 'Walnut Grove'",

    t_ssDropdownLabel: 'Selecionar distância',
    t_ssDropdownOption: 'Dentro de %0% %unit%',
    t_noSites: 'Não há sites disponíveis',
    t_locationInputPlaceholder: 'Insira um local',

    t_selectedDateLabel: 'Data Selecionada:',

    t_ssPopup1: `Agora que você se qualificou para um estudo, a próxima etapa é agendar uma consulta em uma clínica de estudos! <br>
    <br>
    Nesta página, você pode selecionar um site que seja conveniente para você. Dependendo do site selecionado, você verá algumas opções diferentes assim que clicar no botão “Próximo”: <br> <br>
    1. Se o site selecionado não oferecer reserva online, entraremos em contato com você em breve por telefone para marcar uma consulta. <br> <br>
    2. Se o site selecionado oferecer reserva online, você pode ter a opção de reservar os seguintes tipos de compromissos: <br> `,
    t_ssPopup2: `• Vídeo sob demanda - um compromisso de vídeo virtual que ocorrerá imediatamente. <br> <br>
    • Vídeo agendado - um compromisso virtual com vídeo que você pode agendar para o futuro. <br> <br>
    • Telefone agendado - um compromisso virtual por telefone que você pode agendar para o futuro. <br> <br>
    • No local - uma consulta pessoal em um centro clínico que você pode agendar para o futuro.
    <br> `,
    t_ssPopup3: `<strong> E se eu quiser mudar minha área de pesquisa? </strong> <br> <br>
    Sem problemas! Insira um endereço e distância (raio de pesquisa) na barra de pesquisa e forneceremos uma nova lista de sites para você escolher. <br> <br>
    <strong> E se nenhum site for conveniente para mim? </strong> <br> <br>
    Se você selecionar “Nenhum local de pesquisa é conveniente”, entraremos em contato com você para discutir as opções e agendá-lo para uma consulta. <br> <br>
    <strong> E se eu não tiver certeza de como chegar ao meu compromisso. </strong> <br> <br>
    Se você clicar no bloco de localização, mostraremos as instruções de direção e transporte público para o site.`,

    revision_changed: {
      revision_changed_1: 'As perguntas nesta pesquisa em particular foram alteradas desde que você a iniciou. Portanto, você precisará reiniciar a partir da pergunta 1. ',
      revision_changed_2: 'Desculpas por qualquer inconveniente.',
      ctaText: "Iniciar novo questionário."
    },

    maintenanceError: {
      header: 'Manutenção programada',
      body: 'Desculpe, estamos no momento em manutenção programada.'
    },

    t_appointmentAlreadyBookedHeader: 'Espere! Você já tem um compromisso agendado conosco.',
    t_appointmentAlreadyBookedBody: 'Não podemos agendar outra consulta, porque você já tem uma agendada conosco. <br><br> Por favor, verifique seu telefone ou procure em sua caixa de entrada de e-mail os detalhes de confirmação, que enviamos anteriormente. <br><br> Se você precisar fazer alguma alteração em seu compromisso existente, use as informações de contato contidas nesse e-mail.',
    t_appointmentAlreadyBookedFooter: 'Obrigado por seu entusiasmo e interesse em fazer sua parte para o avanço da pesquisa médica.',
    t_vfovCompletionHeader: "<i>Boas notícias! Você já completou uma consulta com nossa equipe e deve receber notícias <br/> nossas novamente em breve.</i>",
    t_vfovCompletionBody: "Você passou por alguma mudança de vida? Mudanças na saúde e no estilo de vida podem qualificá-lo para <br/> estudos adicionais de pesquisa clínica. Use o link no seu e-mail de confirmação para completar uma <br/> pesquisa atualizada e ver se você se qualifica agora.",
    t_vfovCompletionFooter: "Obrigado pelo seu entusiasmo e interesse em avançar na pesquisa clínica!"
  },
  //Arabic
  ar_IL: {
    t_thankYouEndPage: "شكرا جزيلا"
  },
  // lithuanian
  lt_LT: {
    t_date: `Data`,
    t_time: `Laikas`,
    t_address: `Adresas`,
    t_tel: `Tel`,
    t_email: `El. paštas`,
    t_phone: `Telefonas`,
    t_next: `Kitas`,
    t_previousQuestion: `Ankstesnis klausimas`,
    t_nextQuestion: `Kitas klausimas`,
    t_confirm: `Patvirtinti`,
    t_goBack: `Grįžti atgal`,
    t_videoCall: `Vaizdo skambutis`,

    t_selectDate: "Kitas žingsnis – paskirti jums laiką tyrimo gydytojo darbo grupės tvarkaraštyje. Per šį vizitą tyrimo gydytojo darbo grupė papildomai aptars tyrimo reikalavimus ir atsakys į jums kilusius klausimus.",
    t_selectDateVideo: "Pasirinkite vizito laiką ir datą:",
    t_datesAvailableLabel: "Užimtos datos",
    t_datesUnavailableLabel: "Laisvos datos",
    t_noAppointmentConvenient: "Netinkamas joks vizito laikas",

    t_noAppointmentsAtTheMoment: "A member of the clinic's study care team will contact you to schedule an appointment that works best for you.",
    t_confirmToContact: "Palikite savo kontaktinius duomenis, ir mes atsiradus galimybei susisieksime.",

    t_noTimeSlotsAvailableMsg: "Deja, šį vizito laiką jau rezervavo kitas žmogus. Bandykite rinktis kitą datą arba laiką.",

    t_confirmCorrect: "Prieš rezervuodami savo vizito laiką, patvirtinkite, ar šie duomenys teisingi:",
    t_sendEmailConfirmation: "Siųsti patvirtinimą el. paštu",
    t_sendSMSConfirmation: "Siųsti patvirtinimą SMS",
    t_bookAppointment: "Rezervuoti vizito laiką",
    t_appointmentBooked: "Sėkmingai rezervavote savo vizito laiką!",
    t_somebodyElseBooked: "Deja, šį vizito laiką jau rezervavo kitas žmogus. Bandykite rinktis kitą datą arba laiką.",

    t_bookingConfirmed: "Sveiki, jums patvirtintas vizito laikas %date%, %time%, %facility%.",
    t_haveQuestionsContact: "Jeigu turite klausimų arba reikia pakeisti šio vizito laiką, kreipkitės tiesiogiai į gydytojo darbovietę.",
    t_lookForward: "Laukiame atvykstant!",
    t_regards: "Pagarbiai",
    t_unsubscribe: "Atšaukti šių perspėjimų prenumeratą",
    t_bookingConfirmedContactOffice: "Vizitas patvirtintas %date%, %time%, %facility%. Jeigu kyla klausimų, skambinkite į darbovietę %phone%",
    t_thankYouEndPage: "Ačiū",

    // rebook
    t_rebookModalIntro: "Jūs jau turite patvirtintą susitikimą šiam tyrimui:",
    t_rebookModalContinueText: "Jei tęsite, galėsite pasirinkti naują šio susitikimo datą ir laiką.",
    t_rebookModalContinueText2: `Pasirinkite toliau pateiktą parinktį, kad pasirinktumėte naują šio susitikimo datą ir laiką arba visiškai atšauktumėte susitikimą`,
    t_rebookModalNote: "Atminkite, kad dabartinis susitikimas nebus atšauktas, kol nepatvirtinsite naujo susitikimo informacijos.",
    t_rebookModalNoteCancellations: "Atkreipkite dėmesį, kad jei norite iš naujo suplanuoti susitikimą, jis nebus atšauktas, kol nepatvirtinsite naujo susitikimo informacijos.",
    t_rescheduleAppointmentButton: `Suplanuoti susitikimą iš naujo`,

    // cancellations
    t_cancelAppooint: 'Atšaukti susitikimą',
    t_cancelAppointmentButton: `Atšaukti susitikimą`,
    t_cancelApptConfirmHeader: 'Ar tikrai norite atšaukti susitikimą?',
    t_cancelApptConfirmText: 'Gausite el. laišką, patvirtinantį jūsų susitikimo atšaukimą.',
    t_cancelApptBtn: `Taip`,
    t_cancelBackBtn: `Ne`,
    t_apptCancelledSuccessHeader: 'Sėkmė',
    t_apptCancelledSuccessText: 'Jūsų susitikimas buvo sėkmingai atšauktas.',
    t_apptCancelledErrorHeader: 'Klaida',
    t_apptCancelledErrorText: 'Atšaukiant susitikimą įvyko klaida.',
    t_closeBtn: `Uždaryti`,

    // smart site
    t_helpBtn: 'Pagalba',
    t_ssInstruction1: 'Pasirinkite svetainę iš žemiau pateikto sąrašo ir spustelėkite mygtuką "Kitas". Norėdami pamatyti daugiau svetainių, galite naudoti rodykles kairėn ir dešinėn. ',
    t_ssInstruction2: `Jei norite ieškoti papildomų svetainių ar svetainių kitoje vietoje, įveskite pageidaujamą vietą (adresą/pašto adresą/miestą) ir atstumą (paieškos spindulys) ir spustelėkite *search icon*.`,
    t_ssInstruction3: `Spustelėkite *reset icon*, kad peržiūrėtumėte savo pradinį svetainių sąrašą.`,
    t_ssSearchInputPlaceholder: `Pvz.,„ 1666 “arba„ Walnut Grove“`,

    t_ssDropdownLabel: 'Pasirinkite atstumą',
    t_ssDropdownOption: 'Per %0% %unit%',
    t_noSites: `Nėra svetainių`,
    t_locationInputPlaceholder: `Įveskite vietą`,

    t_selectedDateLabel: 'Pasirinkta data:',

    t_ssPopup1: `Dabar, kai turite kvalifikaciją studijuoti, kitas žingsnis yra užsisakyti susitikimą studijų klinikoje! <br>
    <br>
    Šiame puslapyje galite pasirinkti jums patogią svetainę. Priklausomai nuo pasirinktos svetainės, spustelėjus mygtuką „Kitas“, jums bus pateiktos kelios skirtingos parinktys: <br> <br>
    1. Jei pasirinkta svetainė nesiūlo užsakymo internetu, netrukus su jumis susisieksime telefonu, kad rezervuotume susitikimą. <br> <br>
    2. Jei pasirinktoje svetainėje siūlomas užsakymas internetu, galite turėti galimybę užsisakyti šių tipų susitikimus: <br> `,
    t_ssPopup2: `• Vaizdo įrašas pagal pareikalavimą-virtualus vaizdo susitikimas, kuris įvyks iš karto. <br> <br>
    • Suplanuotas vaizdo įrašas - virtualus vaizdo susitikimas, kurį galite suplanuoti ateičiai. <br> <br>
    • Suplanuotas telefonas - virtualus susitikimas telefonu, kurį galite suplanuoti ateičiai. <br> <br>
    • Vietoje-asmeninis susitikimas klinikinėje vietoje, kurį galite suplanuoti ateičiai.
    <br> `,
    t_ssPopup3: `<strong> Ką daryti, jei noriu pakeisti savo paieškos sritį? </strong> <br> <br>
    Jokiu problemu! Paieškos juostoje įveskite adresą ir atstumą (paieškos spindulys), ir mes pateiksime jums naują svetainių sąrašą. <br> <br>
    <strong> O kas, jei jokios svetainės man nėra patogios? </strong> <br> <br>
    Jei pasirinksite „Nė viena tyrimų svetainė nėra patogi“, susisieksime su jumis, kad aptartume galimybes ir suplanuotume susitikimą. <br> <br>
    <strong> Ką daryti, jei nesu tikras, kaip susitikti. </strong> <br> <br>
    Jei spustelėsite vietos plytelę, parodysime važiavimo ir viešojo transporto maršruto nuorodas į svetainę.`,
    revision_changed: {
      revision_changed_1: 'Šios apklausos klausimai buvo pakeisti nuo tada, kai ją pradėjote. Todėl turėsite iš naujo pradėti nuo 1 klausimo.',
      revision_changed_2: 'Atsiprašome už nepatogumus.',
      ctaText: "Pradėti naują klausimyną."
    },

    maintenanceError: {
      header: 'Întreținere programată',
      body: 'Ne pare rău, momentan nu avem servicii de întreținere programată.'
    },


    t_appointmentAlreadyBookedHeader: 'Palauk! Jau suplanavote susitikimą su mumis.',
    t_appointmentAlreadyBookedBody: 'Negalime užsakyti jūsų kitam susitikimui, nes jūs jau suplanavote susitikimą. <br><br> Patikrinkite savo telefoną arba el. pašto dėžutėje ieškokite patvirtinimo detalių, kurias išsiuntėme anksčiau. <br><br> Jei reikia pakeisti esamą susitikimą, naudokite kontaktinę informaciją, esančią tame el. laiške.',
    t_appointmentAlreadyBookedFooter: 'Dėkojame už jūsų entuziazmą ir susidomėjimą prisidėti prie medicininių tyrimų pažangos.'
  },
  // slovakia
  sk_SK: {
    t_date: `Dátum`,
    t_time: `Čas`,
    t_address: `Adresa`,
    t_tel: `Tel`,
    t_email: `E -mail`,
    t_phone: `Telefón`,
    t_next: `Ďalší`,
    t_previousQuestion: `Predchádzajúca otázka`,
    t_nextQuestion: `Next Question`,
    t_confirm: `Potvrdiť`,
    t_goBack: `Vráťte sa`,
    t_videoCall: `Videohovor`,

    t_selectDate: "Ďalší krok je naplánovať si stretnutie s tímom skúšajúceho lekára. Počas tejto návštevy s Vami tím skúšajúceho lekára preberie podrobnejšie podmienky klinického skúšania a odpovie Vám na prípadné otázky.",
    t_selectDateVideo: "Vyberte si dátum a čas pre Vašu návštevu:",
    t_datesAvailableLabel: "Dátumy sú k dispozícii",
    t_datesUnavailableLabel: "Dátumy nie sú k dispozícii",
    t_noAppointmentConvenient: "Nie je vhodný žiadny čas na návštevu",

    t_confirmToContact: "Potvrďte nasledujúce údaje a my Vás budeme čo najskôr kontaktovať.",

    t_confirmCorrect: "Pred rezerváciou termínu potvrďte, že sú nasledujúce údaje správne:",
    t_sendEmailConfirmation: "Poslať potvrdenie e-mailom",
    t_sendSMSConfirmation: "Poslať potvrdenie SMS",
    t_bookAppointment: "Zarezervovať termín návštevy",
    t_appointmentBooked: "Váš termín bol úspešne zarezervovaný!",
    t_somebodyElseBooked: "Bohužiaľ, niekto iný si práve zarezervoval tento termín. Skúste si vybrať iný dátum alebo čas.",

    t_bookingConfirmed: "Ahoj. Potvrdili sme Vám termín dňa %date% v čase %time% na pracovisku %facility%.",
    t_haveQuestionsContact: "Ak máte akékoľvek otázky, alebo potrebujete tento termín zmeniť, kontaktujte prosím priamo ambulanciu skúšajúceho lekára.",
    t_lookForward: "Tešíme sa na stretnutie!",
    t_regards: "S pozdravom",
    t_unsubscribe: "Odhlásiť sa z týchto upozornení",
    t_bookingConfirmedContactOffice: "Termín potvrdený na pracovisku %facility% dňa %date% v čase %time%. AK máte akékoľvek otázky, zavolajte ambulanciu na %phone%",
    t_thankYouEndPage: "Ďakujeme Vám",

    // rebook 
    t_rebookModalIntro: `Už máte potvrdený termín tejto štúdie:`,
    t_rebookModalContinueText: `Ak budete pokračovať, budete si môcť vybrať nový dátum a čas tohto stretnutia.`,
    t_rebookModalContinueText2: `Vyberte nižšie uvedenú možnosť a zvoľte nový dátum a čas tohto stretnutia alebo ho úplne zrušte`,
    t_rebookModalNote: `Upozorňujeme, že váš aktuálny termín nebude zrušený, kým nepotvrdíte podrobnosti o svojom novom stretnutí.`,
    t_rebookModalNoteCancellation: `Upozorňujeme, že ak si želáte zmeniť termín schôdzky, nebude zrušený, kým nepotvrdíte podrobnosti o svojom novom stretnutí.`,
    t_rescheduleAppointmentButton: 'Preplánovať schôdzku',

    // cancellations
    t_cancelAppointment: 'Zrušiť schôdzku',
    t_cancelAppointmentButton: 'Zrušiť schôdzku',
    t_cancelApptConfirmHeader: 'Naozaj chcete zrušiť schôdzku?',
    t_cancelApptConfirmText: 'Dostanete e -mail s potvrdením zrušenia schôdzky.',
    t_cancelApptBtn: 'Áno',
    t_cancelBackBtn: 'Nie',
    t_apptCancelledSuccessHeader: 'Úspech',
    t_apptCancelledSuccessText: 'Váš termín bol úspešne zrušený.',
    t_apptCancelledErrorHeader: 'Chyba',
    t_apptCancelledErrorText: `Pri rušení schôdzky sa vyskytla chyba.`,
    t_closeBtn: 'Zavrieť',

    // smart site
    t_helpBtn: `Pomoc`,
    t_ssInstruction1: 'Vyberte webovú stránku zo zoznamu nižšie a kliknite na tlačidlo „Ďalej“. Na ďalšie stránky môžete použiť šípky doľava a doprava. ',
    t_ssInštrukcia2: 'Ak chcete vyhľadať ďalšie stránky alebo stránky na inom mieste, zadajte požadované miesto (adresa/PSČ/mesto) a vzdialenosť (polomer vyhľadávania) a kliknite na *search icon*.',
    t_ssInstruction3: 'Kliknutím na *reset icon* zobrazíte svoj pôvodný zoznam stránok.',
    t_ssSearchInputPlaceholder: "Napr. '1666' alebo 'Walnut Grove'",

    t_ssDropdownLabel: 'Vyberte vzdialenosť',
    t_ssDropdownOption: 'within %0% %unit%',
    t_noSites: 'Nie sú k dispozícii žiadne webové stránky',
    t_locationInputPlaceholder: 'Zadajte umiestnenie',

    t_selectedDateLabel: 'Vybraný dátum:',

    t_ssPopup1: `Teraz, keď ste sa kvalifikovali na štúdium, ďalším krokom je rezervácia termínu na študijnej klinike! <br>
    <br>
    Na tejto stránke si môžete vybrať web, ktorý vám vyhovuje. V závislosti od vybraného webu sa vám po kliknutí na tlačidlo „Ďalej“ zobrazí niekoľko rôznych možností: <br> <br>
    1. Ak zvolený web neponúka online rezerváciu, v krátkom čase vás kontaktujeme telefonicky a dohodneme si termín. <br> <br>
    2. Ak zvolený web ponúka online rezerváciu, môžete mať možnosť rezervovať si nasledujúce typy schôdzok: <br>`,
    t_ssPopup2: `• Video na požiadanie-virtuálne stretnutie s videom, ktoré sa uskutoční okamžite. <br> <br>
    • Plánované video - virtuálne stretnutie s videom, ktoré si môžete naplánovať do budúcnosti. <br> <br>
    • Plánovaný telefón - virtuálne telefonické stretnutie, ktoré si môžete naplánovať do budúcnosti. <br> <br>
    • On-Site-osobné stretnutie na klinickom pracovisku, ktoré si môžete naplánovať do budúcnosti.
    <br>`,
    t_ssPopup3: `<strong> Čo keď chcem zmeniť oblasť vyhľadávania? </strong> <br> <br>
    Žiaden problém! Do vyhľadávacieho panela zadajte adresu a vzdialenosť (polomer vyhľadávania) a my vám poskytneme nový zoznam stránok, z ktorého si môžete vyberať. <br> <br>
    <strong> Čo keď mi žiadne webové stránky nevyhovujú? </strong> <br> <br>
    Ak vyberiete možnosť „Žiadny prieskum nie je vhodný“, skontaktujeme sa s vami a prediskutujeme možnosti a dohodneme si stretnutie. <br> <br>
    <strong> Čo keď si nie som istý, ako sa mám dostať k svojmu stretnutiu. </strong> <br> <br>
    Ak kliknete na dlaždicu umiestnenia, ukážeme vám trasu jazdy a verejnej dopravy na web .`,

    revision_changed: {
      revision_changed_1: 'Od začiatku prieskumu sa zmenili otázky. Preto budete musieť znova začať od otázky 1.',
      revision_changed_2: 'Ospravedlňujeme sa za prípadné nepríjemnosti.',
      ctaText: "Spustiť nový dotazník."
    },

    maintenanceError: {
      header: 'Plánovaná údržba',
      body: 'Ospravedlňujeme sa, ale momentálne sme mimo plánovanej údržby.'
    },

    t_appointmentAlreadyBookedHeader: 'Počkajte! Už máte s nami naplánované stretnutie.',
    t_appointmentAlreadyBookedBody: 'Nemôžeme si vás zarezervovať na ďalší termín, pretože už jeden máte u nás naplánovaný. <br><br> Skontrolujte si svoj telefón alebo vyhľadajte vo svojej e-mailovej schránke potvrdzovacie údaje, ktoré sme vám poslali predtým. <br><br> Ak potrebujete vykonať nejaké zmeny vo svojom existujúcom stretnutí, použite kontaktné informácie uvedené v tomto e-maile.',
    t_appointmentAlreadyBookedFooter: 'Ďakujem za váš entuziazmus a záujem podieľať sa na pokroku v medicínskom výskume.'
  },

  // slovenian
  sl_SI: {
    t_date: `Date`,
    t_time: `Čas`,
    t_address: `Naslov`,
    t_tel: `Tel`,
    t_email: `E -pošta`,
    t_phone: `Telefon`,
    t_next: `Naprej`,
    t_previousVprašanje: `Prejšnje vprašanje`,
    t_nextQuestion: `Naslednje vprašanje`,
    t_confirm: `Potrdi`,
    t_goBack: `Nazaj`,
    t_videoCall: `Video klic`,
    t_selectDate: 'kroalší krok je naplánovať si stretchnuti s tímom skúšajúceho lekára. Občasi tokrat návštevy s Vami tím skúšajúceho lekára preberie podrobnejšie podmienky klinického skúšania a odpovie Vám na prípad',
    t_selectDateVideo: "Viberte si dátum a čas pred Vašu návštevu:",
    t_datesAvailableLabel: "Dátumy sú k dispozícii",
    t_datesUnavailableLabel: "Dátumy nie sú k dispozícii",
    t_noAppointmentConvenient: "Nie je vhodný žiadny čas na návštevu",

    t_confirmToContact: "Potvrte nasledujúce údaje a my Vás budeme čo najskôr kontaktovať.",

    t_confirmCorrect: "Pred rezerváciou termínu potvrďte, že sú nasledujúce údaje správne:",
    t_sendEmailConfirmation: "Poslať potvrdenie e-mailom",
    t_sendSMSConfirmation: "Poslať potvrdenie SMS",
    t_bookAppointment: "Zarezervovať termín návštevy",
    t_appointmentBooked: "Váš termín bol úspešne zarezervovaný!",
    t_somebodyElseBooked: "Bohužiaľ, niekto iný si práve zarezervoval tento termín. Skúste si vybrať iný dátum alebo čas.",

    t_bookingConfirmed: "Ahoj. Potvrdili sme Vám termín dňa %date %v čase %time %na pracovisku %objekt %.",
    t_haveVprašanjaKontakt: "Ak máte akékoľvek otázky, ali potrebujete tento terminín zmeniť, kontaktirajte prosim priamo ambulanciu skúšajúceho lekára.",
    t_lookForward: "Tešíme sa na stretchnuti!",
    t_regards: "S pozdravom",
    t_unsubscribe: "Odhlásiť sa z týchto upozornení",
    t_bookingConfirmedContactOffice: "Termín potrdený na pracovisku %objekt %dňa %date %v čase %time %. AK máte akékoľvek otázky, zavolajte ambulanciu na %phone %",
    t_thankYouEndPage: "Ďakujeme Vám",

    // rebook
    t_rebookModalIntro: "Že imate potrjen termin za to študijo:",
    t_rebookModalContinueText: "Če nadaljujete, boste lahko izbrali nov datum in čas za ta sestanek.",
    t_rebookModalContinueText2: `Prosimo, izberite spodnjo možnost, če želite izbrati nov datum in čas za ta sestanek ali v celoti preklicati sestanek`,
    t_rebookModalOpomba: "Upoštevajte, da vaš trenutni sestanek ne bo preklican, dokler ne potrdite podrobnosti o svojem novem sestanku.",
    t_rebookModalNoteCancellations: "Če želite prestaviti termin, ne bo preklican, dokler ne potrdite podrobnosti svojega novega sestanka.",
    t_rescheduleAppointmentButton: 'Poizvedba o prerazporeditvi',

    // cancellations
    t_cancelAppointment: 'Prekliči sestanek',
    t_cancelAppointmentButton: 'Prekliči sestanek',
    t_cancelApptConfirmHeader: 'Ali ste prepričani, da želite preklicati sestanek?',
    t_cancelApptConfirmText: 'Prejeli boste e -poštno sporočilo s potrditvijo odpovedi sestanka.',
    t_cancelApptBtn: 'da',
    t_cancelBackBtn: 'št',
    t_apptCanceledSuccessHeader: 'Uspeh',
    t_apptCanceledSuccessText: 'Vaš sestanek je bil uspešno preklican.',
    t_apptCanceledErrorHeader: 'Napaka',
    t_apptCanceledErrorText: 'Pri preklicu sestanka je prišlo do napake.',
    t_closeBtn: 'Zapri',

    // smart site
    t_helpBtn: `Pomoč`,
    t_ssInstruction1: 'Prosimo, izberite spletno mesto s spodnjega seznama in kliknite gumb "Naprej". Za ogled več spletnih mest lahko uporabite puščice levo in desno. ',
    t_ssInstruction2: 'Če želite poiskati dodatna spletna mesta ali spletna mesta na drugi lokaciji, vnesite želeno lokacijo (naslov/poštno številko/mesto) in razdaljo (polmer iskanja) in kliknite *search icon*.',
    t_ssInstruction3: 'Kliknite na *reset icon* za ogled začetnega seznama spletnih mest.',
    t_ssSearchInputPlaceholder: `Npr." 1666 "ali" Orehov gaj "`,

    t_ssDropdownLabel: 'Izberite razdaljo',
    t_ssDropdownOption: 'Znotraj %0% %unit%',
    t_noSites: 'Ni na voljo nobenih spletnih mest',
    t_locationInputPlaceholder: 'Vnesite lokacijo',

    t_selectedDateLabel: 'Izbran datum:',

    t_ssPopup1: `Zdaj, ko ste se kvalificirali za študij, je naslednji korak, da rezervirate termin za študijsko kliniko! <br>
    <br>
    Na tej strani lahko izberete spletno mesto, ki vam ustreza. Odvisno od izbranega spletnega mesta vam bo, ko kliknete gumb »Naprej«, prikazanih nekaj različnih možnosti: <br> <br>
    1. Če izbrano spletno mesto ne ponuja spletnih rezervacij, vas bomo kmalu poklicali po telefonu in se dogovorili za termin. <br> <br>
    2. Če izbrano spletno mesto ponuja spletne rezervacije, boste morda imeli možnost rezervirati naslednje vrste sestankov: <br> `,
    t_ssPopup2: `• Video na zahtevo-virtualni video sestanek, ki bo potekal takoj. <br> <br>
    • Načrtovani video - virtualni video sestanek, ki ga lahko načrtujete za prihodnost. <br> <br>
    • Načrtovani telefon - virtualni telefonski sestanek, ki ga lahko načrtujete za prihodnost. <br> <br>
    • Na kraju samem-osebni sestanek na kliniki, ki ga lahko načrtujete za prihodnost.
    <br> `,
    t_ssPopup3: `<strong> Kaj, če želim spremeniti območje iskanja? </strong> <br> <br>
    Ni problema! V iskalno vrstico vnesite naslov in razdaljo (polmer iskanja), mi pa vam bomo ponudili nov seznam spletnih mest, med katerimi lahko izbirate. <br> <br>
    <strong> Kaj, če mi nobeno spletno mesto ni primerno? </strong> <br> <br>
    Če izberete »No Research Site is Convenient«, se bomo obrnili na vas, da se pogovorimo o možnostih in vas dogovorimo za sestanek. <br> <br>
    <strong> Kaj pa, če nisem prepričan, kako naj pridem na sestanek. </strong> <br> <br>
    Če kliknete ploščico lokacije, vam prikažemo navodila za vožnjo in tranzit do spletnega mesta. `,

    revision_changed: {
      revision_changed_1: 'Vprašanja v tej anketi so bila spremenjena, odkar ste jo začeli. Zato boste morali znova začeti s 1. vprašanjem. ',
      revision_changed_2: 'Opravičujemo se za morebitne nevšečnosti.',
      ctaText: "Začni nov vprašalnik."
    },

    maintenanceError: {
      header: "Načrtovano vzdrževanje",
      body: 'Oprostite, trenutno smo na urniku za vzdrževanje.'
    },

    t_appointmentAlreadyBookedHeader: 'Počakaj! Pri nas že imate dogovorjen termin.',
    t_appointmentAlreadyBookedBody: 'Ne moremo vam rezervirati drugega termina, ker ga že imate pri nas. <br><br> Preverite svoj telefon ali poiščite v svojem e-poštnem nabiralniku za podrobnosti o potrditvi, ki smo jih poslali prej. <br><br> Če želite spremeniti svoj obstoječi termin, uporabite kontaktne podatke v tem e-pošti.',
    t_appointmentAlreadyBookedFooter: 'Hvala za vaše navdušenje in zanimanje za sodelovanje pri napredovanju medicinskih raziskav.'
  },
  // croatia
  hr_HR: {
    t_date: `Datum`,
    t_time: `Vrijeme`,
    t_address: `Adresa`,
    t_tel: `Tel`,
    t_email: `E -pošta`,
    t_phone: `Telefon`,
    t_next: `Dalje`,
    t_previousQuestion: `Prethodno pitanje`,
    t_nextQuestion: `Sljedeće pitanje`,
    t_confirm: `Potvrdi`,
    t_goBack: `Vrati se`,
    t_videoCall: `Video poziv`,

    t_address: "Adresa",
    t_selectDateVideo: "Odaberite datum i vrijeme za koje želite zakazati internetski video sastanak za:",
    t_noAppointmentsAtTheMoment: "Član kliničkog tima za njegu studija kontaktirat će vas kako bi zakazao sastanak koji vam najbolje odgovara.",
    t_noTimeSlotsAvailableMsg: "Nema raspoloživih vremenskih termina. Molimo pokušajte s drugim datumom.",
    t_thankYouEndPage: "Hvala vam",
    t_coronavirusText: "COVID-19 NAPOMENA: Zdravlje i sigurnost naših zaposlenika i naših pacijenata naš je glavni prioritet. Napominjemo da naši istraživački objekti nisu medicinski centri ili bolnice. Ne vidimo i ne liječimo bolesne pacijente. Međutim, postoje mjere kako bi se osiguralo da naši objekti na odgovarajući način pregledavaju posjetitelje i slijede važne higijenske i pravilne sterilizacije.",
    t_selectAppointmentType: "Kako biste voljeli obaviti svoj sastanak?",
    t_appointmentTypeTitle: "Vrsta imenovanja",
    t_emailTitle: "E-mail",
    t_mobilePhoneTitle: "Mobitel",
    t_videNowCard: "Započnite internetski video sastanak <br> SADA",
    t_scheduleVideoCard: "Zakažite internetski video sastanak za KASNIJE",
    t_scheduleInPersonCard: "Zakažite posjet licem u lice",
    t_videoNotSuitable: "Video zakazivanje nije prikladno",
    t_confirmVideoInfo: "Vaš internetski video sastanak održat će se na datum i vrijeme odabrani s timom liječnika. Nakon što rezervirate svoj termin, primit ćete e-poruku s potvrdom koja sadrži vezu. Kad treba započeti sastanak, kliknite vezu da biste automatski započeli videopoziv.",
    t_videoModalText: "Sjajno! Trebate samo potvrditi sljedeće detalje prije početka videopoziva:",
    t_optional: " (Neobvezno)",
    t_selectDateVideoSubtext: "Da biste proveli video zakaz, trebat će vam pristup uređaju s kamerom i / ili mikrofonom (npr. Većina pametnih telefona i prijenosnih računala ima oboje)",
    t_rebookModalIntro: "Već imate potvrđen sastanak za ovu studiju:",
    t_rebookModalContinueText: "Ako nastavite, moći ćete odabrati novi datum i vrijeme za ovaj sastanak.",
    t_rebookModalNote: "Imajte na umu da vaš trenutni sastanak neće biti otkazan dok ne potvrdite detalje za novi sastanak.",
    t_siteNameTitle: "Pojedinosti o klinici",
    t_videNowNotConvenientIntro: "Ako vam sada nije prikladno:",
    t_videNowButton: "ZAPOČNI SADA",
    t_scheduleVideoButton: "RASPORED VIDEO POZIVA",
    t_scheduleInPersonButton: "RASPORED POSJETA",
    t_schedulePhoneCallCard: "Zakažite telefonski poziv",
    t_schedulePhoneCallButton: "RASPORED POZIV",
    t_emrSelectProtocolsText: "Odabrani protokoli:",
    t_selectDatePhone: "Odaberite datum i vrijeme kada želite zakazati telefonski sastanak:",
    t_selectDatePhoneSubtext: "Da biste obavili telefonski sastanak, trebat će vam pristup telefonu na odabrani datum.",
    t_confirmPhoneInfo: "Vaš telefonski sastanak održat će se u vrijeme i datum odabrani s timom kliničara na web mjestu. Provjerite imate li dobar telefonski signal za primanje poziva.",

    // rebook
    t_rebookModalIntro: "Već imate potvrđen termin za ovu studiju:",
    t_rebookModalContinueText: "Ako nastavite, moći ćete odabrati novi datum i vrijeme za ovaj sastanak.",
    t_rebookModalContinueText2: `Molimo odaberite donju opciju za odabir novog datuma i vremena za ovaj termin ili za potpuno otkazivanje sastanka`,
    t_rebookModalNapomena: "Imajte na umu da vaš trenutni termin neće biti otkazan sve dok ne potvrdite pojedinosti za svoj novi termin.",
    t_rebookModalNoteCancellations: "Imajte na umu da ako želite promijeniti termin zakazanog termina, on neće biti otkazan sve dok ne potvrdite pojedinosti za svoj novi termin.",
    t_rescheduleAppointmentButton: 'Promijeni termin',

    // cancellations
    t_cancelAppointment: 'Otkaži sastanak',
    t_cancelAppointmentButton: 'Otkaži sastanak',
    t_cancelApptConfirmHeader: 'Jeste li sigurni da želite otkazati svoj termin?',
    t_cancelApptConfirmText: 'Primit ćete e -poruku s potvrdom otkazivanja termina.',
    t_cancelApptBtn: 'Da',
    t_cancelBackBtn: 'Ne',
    t_apptCanceledSuccessHeader: 'Uspjeh',
    t_apptCanceledSuccessText: 'Vaš je termin uspješno otkazan.',
    t_apptCanceledErrorHeader: 'Pogreška',
    t_apptCanceledErrorText: 'Došlo je do pogreške prilikom otkazivanja vašeg termina.',
    t_closeBtn: 'Zatvori',

    // smart site
    t_helpBtn: `Pomoć`,
    t_ssInstruction1: 'Molimo odaberite web mjesto s donjeg popisa i kliknite gumb "Dalje". Pomoću strelica lijevo i desno možete vidjeti više web stranica. ',
    t_ssInstruction2: 'Za traženje dodatnih web stranica ili za web stranice na drugom mjestu, unesite željenu lokaciju (adresa/poštanski broj/grad) i udaljenost (radijus pretraživanja), a zatim kliknite *search icon*.',
    t_ssInstruction3: 'Kliknite na *reset icon* za prikaz početnog popisa web stranica.',
    t_ssSearchInputPlaceholder: "Npr. '1666' ili 'Walnut Grove'",

    t_ssDropdownLabel: 'Odaberite udaljenost',
    t_ssDropdownOption: 'Unutar %0% %unit%',
    t_noSites: 'Nema dostupnih web stranica',
    t_locationInputPlaceholder: 'Unesite lokaciju',

    t_selectedDateLabel: 'Odabrani datum:',

    t_ssPopup1: `Sada kada ste se kvalificirali za studij, sljedeći korak je rezerviranje termina za studijsku kliniku! <br>
    <br>
    Na ovoj stranici možete odabrati web lokaciju koja vam odgovara. Ovisno o odabranom mjestu, nakon što pritisnete gumb "Dalje", prikazat će vam se nekoliko različitih opcija: <br> <br>
    1. Ako odabrano mjesto ne nudi online rezervacije, uskoro ćemo vam se obratiti telefonom kako bismo zakazali termin. <br> <br>
    2. Ako odabrano mjesto nudi online rezervacije, možda ćete imati mogućnost rezervirati sljedeće vrste termina: <br> `,
    t_ssPopup2: `• Video na zahtjev-virtualni video sastanak koji će se održati odmah. <br> <br>
    • Zakazani video - virtualni video sastanak koji možete zakazati za budućnost. <br> <br>
    • Zakazani telefon - virtualni telefonski sastanak koji možete zakazati za budućnost. <br> <br>
    • Na licu mjesta-osobni sastanak na kliničkoj lokaciji koji možete zakazati za budućnost.
    <br> `,
    t_ssPopup3: `<strong> Što ako želim promijeniti područje pretraživanja? </strong> <br> <br>
    Nema problema! Unesite adresu i udaljenost (radijus pretraživanja) u traku za pretraživanje, a mi ćemo vam ponuditi novi popis web lokacija za izbor. <br> <br>
    <strong> Što ako mi nijedno web mjesto nije prikladno? </strong> <br> <br>
    Ako odaberete "Nijedno mjesto za istraživanje nije prikladno", obratit ćemo vam se kako bismo razgovarali o mogućnostima i zakazali vam termin. <br> <br>
    <strong> Što ako nisam siguran kako doći na termin. </strong> <br> <br>
    Ako kliknete pločicu lokacije, pokazat ćemo vam upute za vožnju i tranzit do stranice. `,

    revision_changed: {
      revision_changed_1: 'Pitanja u ovoj anketi promijenjena su otkad ste je pokrenuli. Stoga ćete morati ponovno krenuti od 1. pitanja.',
      revision_changed_2: 'Ispričavamo se zbog neugodnosti.',
      ctaText: "Pokrenite novi upitnik."
    },

    maintenanceError: {
      header: 'Planirano održavanje',
      body: 'Žao nam je, trenutno smo na rasporedu za održavanje.'
    },

    t_appointmentAlreadyBookedHeader: 'Čekaj! Već imate zakazan termin kod nas.',
    t_appointmentAlreadyBookedBody: 'Ne možemo vas rezervirati za drugi termin, jer već imate zakazan kod nas. <br><br> Provjerite svoj telefon ili pretražite svoju pristiglu e-poštu za detalje o potvrdi, koje smo prethodno poslali. <br><br> Ako trebate unijeti bilo kakve promjene u svoj postojeći termin, upotrijebite podatke za kontakt sadržane u toj e-pošti.',
    t_appointmentAlreadyBookedFooter: 'Hvala vam na vašem entuzijazmu i interesu da učinite svoj dio u unapređenju medicinskog istraživanja.'
  },
  // greek
  el_GR: {
    t_date: `Date`,
    t_time: `Time`,
    t_address: `Address`,
    t_tel: `Tel`,
    t_email: `Email`,
    t_phone: `Phone`,
    t_next: `Next`,
    t_previousQuestion: `Προηγούμενη ερώτηση`,
    t_nextQuestion: `Next Question`,
    t_confirm: `Confirm`,
    t_goBack: `Go Back`,
    t_videoCall: `Video Call`,

    t_selectDateVideo: "Επιλέξτε την ημερομηνία και την ώρα που θέλετε να προγραμματίσετε το διαδικτυακό ραντεβού σας για βίντεο:",
    t_noAppointmentsAtTheMoment: "Ένα μέλος της ομάδας μελέτης φροντίδας της κλινικής θα επικοινωνήσει μαζί σας για να προγραμματίσετε ένα ραντεβού που λειτουργεί καλύτερα για εσάς.",
    t_noTimeSlotsAvailableMsg: "Δεν υπάρχουν διαθέσιμα χρονικά διαστήματα. Δοκιμάστε μια διαφορετική ημερομηνία.",
    t_thankYouEndPage: "Σας ευχαριστώ",
    t_coronavirusText: "COVID-19 ΣΗΜΕΙΩΣΗ: Η υγεία και η ασφάλεια των υπαλλήλων μας και των ασθενών μας είναι η πρώτη μας προτεραιότητα. Λάβετε υπόψη ότι οι ερευνητικές μας εγκαταστάσεις δεν είναι ιατρικά κέντρα ή νοσοκομεία. Δεν βλέπουμε ούτε θεραπεύουμε άρρωστους ασθενείς. Ωστόσο, εφαρμόζονται μέτρα για να διασφαλιστεί ότι οι εγκαταστάσεις μας ελέγχουν κατάλληλα τους επισκέπτες και ακολουθούν σημαντικά πρότυπα υγιεινής και σωστής αποστείρωσης.",
    t_selectAppointmentType: "Πώς θα θέλατε να πραγματοποιήσετε το ραντεβού σας;",
    t_appointmentTypeTitle: "Τύπος ραντεβού",
    t_emailTitle: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    t_mobilePhoneTitle: "Κινητό τηλέφωνο",
    t_videNowCard: "Ξεκινήστε το διαδικτυακό ραντεβού βίντεο <br> ΤΩΡΑ",
    t_scheduleVideoCard: "Προγραμματίστε το διαδικτυακό ραντεβού βίντεο για LATER",
    t_scheduleInPersonCard: "Προγραμματίστε πρόσωπο με πρόσωπο επίσκεψη",
    t_videoNotSuitable: "Το ραντεβού βίντεο δεν είναι κατάλληλο",
    t_confirmVideoInfo: "Το διαδικτυακό ραντεβού σας βίντεο θα πραγματοποιηθεί την ημερομηνία και την ώρα που θα επιλεγούν από την ομάδα του γιατρού μελέτης. Μετά την κράτηση του ραντεβού σας, θα λάβετε ένα email επιβεβαίωσης που περιέχει έναν σύνδεσμο. Όταν το ραντεβού σας πρόκειται να ξεκινήσει, κάντε κλικ στον σύνδεσμο για να ξεκινήσει αυτόματα η βιντεοκλήση.",
    t_videoModalText: "Μεγάλος! Απλώς πρέπει να επιβεβαιώσετε τις ακόλουθες λεπτομέρειες πριν ξεκινήσετε τη βιντεοκλήση σας:",
    t_optional: " (Προαιρετικός)",
    t_selectDateVideoSubtext: "Για να πραγματοποιήσετε τη συνάντηση βίντεο, θα χρειαστείτε πρόσβαση σε μια συσκευή με κάμερα ή / και μικρόφωνο (π.χ. τα περισσότερα έξυπνα τηλέφωνα και φορητοί υπολογιστές διαθέτουν και τα δύο)",
    t_rebookModalIntro: "Έχετε ήδη επιβεβαιωμένο ραντεβού για αυτήν τη μελέτη:",
    t_rebookModalContinueText: "Εάν συνεχίσετε, θα μπορείτε να επιλέξετε μια νέα ημερομηνία και ώρα για αυτό το ραντεβού.",
    t_rebookModalNote: "Λάβετε υπόψη ότι το τρέχον ραντεβού σας δεν θα ακυρωθεί έως ότου επιβεβαιώσετε τις λεπτομέρειες για το νέο ραντεβού σας.",
    t_siteNameTitle: "Λεπτομέρειες κλινικής",
    t_videNowNotConvenientIntro: "Εάν τώρα δεν είναι βολικό για εσάς:",
    t_videNowButton: "ΞΕΚΙΝΑ ΤΩΡΑ",
    t_scheduleVideoButton: "ΠΡΟΓΡΑΜΜΑ ΚΛΗΣΗ ΒΙΝΤΕΟ",
    t_scheduleInPersonButton: "ΕΠΙΣΚΕΨΗ ΠΡΟΓΡΑΜΜΑΤΙΣΜΟΥ",
    t_schedulePhoneCallCard: "Προγραμματίστε τηλεφωνική κλήση",
    t_schedulePhoneCallButton: "ΠΡΟΓΡΑΜΜΑ ΚΛΗΣΗΣ",
    t_emrSelectProtocolsText: "Επιλεγμένα πρωτόκολλα:",
    t_selectDatePhone: "Επιλέξτε την ημερομηνία και την ώρα που θέλετε να προγραμματίσετε το τηλεφωνικό ραντεβού σας:",
    t_selectDatePhoneSubtext: "Για να πραγματοποιήσετε το τηλεφωνικό ραντεβού, θα χρειαστείτε πρόσβαση σε ένα τηλέφωνο την ημερομηνία που έχετε επιλέξει.",
    t_confirmPhoneInfo: "Το ραντεβού σας μέσω τηλεφώνου θα πραγματοποιηθεί την ώρα και την ημερομηνία που επιλέξατε με την ομάδα του ιατρού. Βεβαιωθείτε ότι είστε διαθέσιμοι με ένα καλό τηλεφωνικό σήμα για να λάβετε την κλήση.",

    // rebook
    t_rebookModalIntro: "Έχετε ήδη επιβεβαιωμένο ραντεβού για αυτήν τη μελέτη:",
    t_rebookModalContinueText: "Εάν συνεχίσετε, θα μπορείτε να επιλέξετε μια νέα ημερομηνία και ώρα για αυτό το ραντεβού.",
    t_rebookModalContinueText2: `Επιλέξτε μια από τις παρακάτω επιλογές για να ορίσετε μια νέα ημερομηνία και ώρα για αυτό το ραντεβού ή να ακυρώσετε το ραντεβού εντελώς`,
    t_rebookModalNote: "Λάβετε υπόψη ότι το τρέχον ραντεβού σας δεν θα ακυρωθεί μέχρι να επιβεβαιώσετε τις λεπτομέρειες για το νέο σας ραντεβού.",
    t_rebookModalNoteCancellations: "Σημειώστε ότι εάν θέλετε να προγραμματίσετε εκ νέου το ραντεβού σας δεν θα ακυρωθεί μέχρι να επιβεβαιώσετε τα στοιχεία για το νέο σας ραντεβού.",
    t_rescheduleAppointmentButton: "Αναδιάταξη ραντεβού",

    // cancellations
    t_cancelAppointment: 'Cancel Appointment',
    t_cancelAppointmentButton: "Cancel Appointment",
    t_cancelApptConfirmHeader: 'Είστε βέβαιοι ότι θέλετε να ακυρώσετε το ραντεβού σας;',
    t_cancelApptConfirmText: "Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου που θα επιβεβαιώνει την ακύρωση του ραντεβού σας.",
    t_cancelApptBtn: 'Ναι',
    t_cancelBackBtn: 'Όχι',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: 'Το ραντεβού σας ακυρώθηκε επιτυχώς.',
    t_apptCancelledErrorHeader: "Σφάλμα",
    t_apptCancelledErrorText: "Παρουσιάστηκε σφάλμα κατά την ακύρωση του ραντεβού σας.",
    t_closeBtn: "Κλείσιμο",

    // smart site
    t_helpBtn: `βοήθεια`,
    t_ssInstruction1: 'Επιλέξτε έναν ιστότοπο από την παρακάτω λίστα και κάντε κλικ στο κουμπί "Επόμενο". Μπορείτε να χρησιμοποιήσετε το αριστερό και το δεξί βέλος για να δείτε περισσότερους ιστότοπους. ',
    t_ssInstruction2: "Για αναζήτηση πρόσθετων ιστότοπων ή ιστότοπων σε διαφορετική τοποθεσία, εισαγάγετε την προτιμώμενη τοποθεσία (Διεύθυνση/Ταχυδρομείο/Πόλη) και απόσταση (ακτίνα αναζήτησης) και κάντε κλικ στο *search icon*.",
    t_ssInstruction3: 'Κάντε κλικ στο *reset icon* για να δείτε την αρχική λίστα των ιστότοπων σας.',
    t_ssSearchInputPlaceholder: "Π.χ. '1666' ή 'Walnut Grove'",

    t_ssDropdownLabel: "Επιλογή απόστασης",
    t_ssDropdownOption: "Εντός %0% %unit%",
    t_noSites: "Δεν υπάρχουν διαθέσιμοι ιστότοποι",
    t_locationInputPlaceholder: "Εισαγάγετε μια τοποθεσία",

    t_selectedDateLabel: 'Ημερομηνία επιλογής:',

    t_ssPopup1: `Τώρα που πληροίτε τις προϋποθέσεις για μελέτη, το επόμενο βήμα είναι να κλείσετε ραντεβού σε κλινική μελέτης! <br>
    <br>
    Σε αυτήν τη σελίδα, μπορείτε να επιλέξετε έναν ιστότοπο που σας βολεύει. Ανάλογα με τον επιλεγμένο ιστότοπο, θα εμφανιστούν μερικές διαφορετικές επιλογές μόλις κάνετε κλικ στο κουμπί "Επόμενο": <br> <br>
    1. Εάν ο επιλεγμένος ιστότοπος δεν προσφέρει online κράτηση, θα επικοινωνήσουμε μαζί σας σύντομα τηλεφωνικά για να κλείσετε ραντεβού. <br> <br>
    2. Εάν ο επιλεγμένος ιστότοπος προσφέρει online κράτηση, μπορεί να έχετε την επιλογή να κάνετε κράτηση για τους ακόλουθους τύπους ραντεβού: <br> `,
    t_ssPopup2: `• Βίντεο κατά παραγγελία-ένα εικονικό ραντεβού με βίντεο που θα πραγματοποιηθεί αμέσως. <br> <br>
    • Προγραμματισμένο βίντεο - ένα εικονικό ραντεβού μέσω βίντεο που μπορείτε να προγραμματίσετε για το μέλλον. <br> <br>
    • Προγραμματισμένο τηλέφωνο - ένα εικονικό τηλεφωνικό ραντεβού που μπορείτε να προγραμματίσετε για το μέλλον. <br> <br>
    • Επιτόπου-ένα ραντεβού προσωπικά σε μια κλινική τοποθεσία που μπορείτε να προγραμματίσετε για το μέλλον.
    <br> `,
    t_ssPopup3: `<strong> Τι γίνεται αν θέλω να αλλάξω την περιοχή αναζήτησης; </strong> <br> <br>
    Κανένα πρόβλημα! Εισαγάγετε μια διεύθυνση και απόσταση (ακτίνα αναζήτησης) στη γραμμή αναζήτησης και θα σας παρέχουμε μια νέα λίστα ιστότοπων για να διαλέξετε. <br> <br>
    <strong> Τι γίνεται αν κανένας ιστότοπος δεν με βολεύει; </strong> <br> <br>
    Εάν επιλέξετε "No Research Site is Convenient", θα επικοινωνήσουμε μαζί σας για να συζητήσουμε τις επιλογές και να σας ενημερώσουμε για ραντεβού. <br> <br>
    <strong> Τι γίνεται αν δεν είμαι σίγουρος για το πώς θα φτάσω στο ραντεβού μου. </strong> <br> <br>
    Αν κάνετε κλικ στο πλακίδιο τοποθεσίας, θα σας δείξουμε οδηγίες οδήγησης και συγκοινωνίας προς τον ιστότοπο.`,

    revision_changed: {
      revision_changed_1: 'Οι ερωτήσεις σε αυτήν τη συγκεκριμένη έρευνα έχουν αλλάξει από τότε που την ξεκινήσατε. Επομένως, θα πρέπει να ξεκινήσετε ξανά από την Ερώτηση 1.',
      revision_changed_2: 'Συγγνώμη για οποιαδήποτε αναστάτωση.',
      ctaText: "Ξεκινήστε νέο ερωτηματολόγιο."
    },

    maintenanceError: {
      header: 'Προγραμματισμένη συντήρηση',
      body: 'Συγγνώμη, είμαστε αυτήν τη στιγμή εκτός λειτουργίας για προγραμματισμένη συντήρηση.'
    },

    t_appointmentAlreadyBookedHeader: 'Περιμένετε! Έχετε ήδη προγραμματίσει ένα ραντεβού μαζί μας.',
    t_appointmentAlreadyBookedBody: 'Δεν μπορούμε να σας κλείσουμε για άλλο ραντεβού, γιατί έχετε ήδη προγραμματίσει ένα ραντεβού μαζί μας. <br><br> Ελέγξτε το τηλέφωνό σας ή ψάξτε στα εισερχόμενα του email σας για τα στοιχεία επιβεβαίωσης που στείλαμε προηγουμένως. <br><br> Εάν πρέπει να κάνετε οποιεσδήποτε αλλαγές στο υπάρχον ραντεβού σας, χρησιμοποιήστε τα στοιχεία επικοινωνίας που περιέχονται σε αυτό το email.',
    t_appointmentAlreadyBookedFooter: "Σας ευχαριστούμε για τον ενθουσιασμό και το ενδιαφέρον σας να συμβάλετε στην προώθηση της ιατρικής έρευνας."
  },
  // finland
  fi_FI: {
    t_date: "Päivämäärä",
    t_time: `Aika`,
    t_address: `Osoite`,
    t_tel: `Puh`,
    t_email: `Sähköposti`,
    t_phone: `Puhelin`,
    t_next: `Seuraava`,
    t_previousQuestion: `Edellinen kysymys`,
    t_nextQuestion: `Seuraava kysymys`,
    t_confirm: `Vahvista`,
    t_goBack: `Palaa`,
    t_videoCall: `Videopuhelu`,

    t_selectDateVideo: "Valitse päivämäärä ja aika varataksesi ajan videovastaanotolle: ",
    t_noAppointmentsAtTheMoment: "Kliinisen lääketutkimuksen hoitohenkilökunta ottaa sinuun yhteyttä varatakseen sinulle sopivan ajan vastaanotolle.  ",
    t_noTimeSlotsAvailableMsg: "Ei vapaita aikoja. Valitse toinen päivämäärä. ",
    t_thankYouEndPage: "Kiitos",
    t_coronavirusText: "COVID-19: Työntekijöidemme ja potilaidemme terveys ja turvallisuus on meille ensisijaisen tärkeää. Huomioithan, että tutkimuskeskuksemme eivät ole terveyskeskuksia tai sairaaloita. Emme vastaanota tai hoida sairaita potilaita. Varmistamme kuitenkin, että tutkimuskeskuksemme seulovat potilaat koronaviruksen varalta asianmukaisesti ja suorittavat vaadittavat toimenpiteet hygienian ylläpitämiseksi. ",
    t_selectAppointmentType: "Miten haluaisit käydä vastaanotolla?",
    t_appointmentTypeTitle: "Vastaanoton tyyppi",
    t_emailTitle: "Sähköposti",
    t_mobilePhoneTitle: "Puhelinnumero ",
    t_videNowCard: "Aloita videovastaanotto NYT",
    t_scheduleVideoCard: "Varaa videovastaanotto MYÖHEMMÄKSI AJANKOHDAKSI",
    t_scheduleInPersonCard: "Varaa henkilökohtainen käynti",
    t_videoNotSuitable: "Videovastaanotto ei ole mahdollinen",
    t_confirmVideoInfo: "Varaamasi videovastaanotto tapahtuu valitsemanasi päivänä ja aikana tutkimuslääkärin työryhmän kanssa. Varattuasi ajan saat sähköpostitse vahvistuksen, joka sisältää linkin. Pääset videovastaanotolle varaamanasi ajankohtana klikkaamalla linkkiä. ",
    t_videoModalText: "Hienoa! Tarvitsemme vielä vahvistuksen seuraavista tiedoista ennen videopuhelun alkamista: ",
    t_optional: "(Valinnainen)",
    t_selectDateVideoSubtext: "Videovastaanotto vaatii laitteen, jossa on kamera ja/tai mikrofoni (useimmissa puhelimissa ja kannettavissa tietokoneissa on molemmat)",
    t_rebookModalIntro: "Sinulla on jo vahvistettu ajanvaraus tähän tutkimukseen: ",
    t_rebookModalContinueText: "Jos jatkat, voit valita uuden päivämäärän ja ajan tälle vastaanotolle. ",
    t_rebookModalNote: "Huomioithan, että tämänhetkistä ajanvaraustasi ei peruuteta ennen kuin olet vahvistanut uuden varauksen tiedot. ",
    t_siteNameTitle: "Tutkimuskeskuksen tiedot",
    t_videNowNotConvenientIntro: "Mikäli tämä ajankohta ei sovi: ",
    t_videNowButton: "ALOITA NYT",
    t_scheduleVideoButton: "VARAA VIDEOPUHELU",
    t_scheduleInPersonButton: "VARAA KÄYNTI",
    t_schedulePhoneCallCard: "Varaa puhelu",
    t_schedulePhoneCallButton: "VARAA PUHELU",
    t_emrSelectProtocolsText: "Valitut tutkimukset:",
    t_selectDatePhone: "Valitse päivämäärä ja aika puhelinvastaanotolle: ",
    t_selectDatePhoneSubtext: "Puhelinvastaanotto vaatii pääsyn puhelimeen valitsemanasi ajankohtana. ",
    t_confirmPhoneInfo: "Varaamasi puhelinvastaanotto tapahtuu valitsemanasi päivänä ja aikana tutkimusryhmän kanssa. Varmista, että puhelimessasi on hyvä kuuluvuus vastaanottaaksesi puhelun valittuna ajankohtana. ",

    // rebook
    t_rebookModalIntro: "Sinulla on jo vahvistettu tapaaminen tähän tutkimukseen:",
    t_rebookModalContinueText: "Jos jatkat, voit valita uuden päivämäärän ja ajan tälle tapaamiselle.",
    t_rebookModalContinueText2: "Valitse alla oleva vaihtoehto, jos haluat valita uuden päivämäärän ja ajan tälle tapaamiselle tai peruuttaa tapaamisen kokonaan",
    t_rebookModalNote: "Huomaa, että nykyistä tapaamistasi ei peruuteta ennen kuin olet vahvistanut uuden tapaamisen tiedot.",
    t_rebookModalNoteCancellations: "Huomaa, että jos haluat siirtää tapaamisen uudelleen, sitä ei peruuteta ennen kuin olet vahvistanut uuden tapaamisen tiedot.",
    t_rescheduleAppointmentButton: 'Varaa tapaaminen uudelleen',

    // cancellations
    t_cancelAppooint: `Peruuta tapaaminen`,
    t_cancelAppointmentButton: `Peruuta tapaaminen`,
    t_cancelApptConfirmHeader: 'Haluatko varmasti peruuttaa tapaamisen?',
    t_cancelApptConfirmText: 'Saat sähköpostin, jossa vahvistetaan tapaamisen peruutus.',
    t_cancelApptBtn: 'Kyllä',
    t_cancelBackBtn: 'Ei',
    t_apptCancelledSuccessHeader: 'Success',
    t_apptCancelledSuccessText: 'Tapaamisesi peruutettiin onnistuneesti.',
    t_apptCancelledErrorHeader: 'Virhe',
    t_apptCancelledErrorText: 'Tapaamisen peruuttamisessa tapahtui virhe.',
    t_closeBtn: 'Sulje',

    // smart site
    t_helpBtn: `Ohje`,
    t_ssInstruction1: 'Valitse sivusto alla olevasta luettelosta ja napsauta "Seuraava" -painiketta. Voit nähdä lisää sivustoja vasemmalla ja oikealla nuolella. ',
    t_ssInstruction2: 'Jos haluat etsiä muita sivustoja tai sivustoja eri sijainnista, kirjoita haluamasi sijainti (osoite/postinumero/kaupunki) ja etäisyys (hakusäde) ja napsauta *search icon*.',
    t_ssInstruction3: 'Napsauta *reset icon* nähdäksesi alkuperäisen luettelon sivustoista.',
    t_ssSearchInputPlaceholder: `esim. '1666' tai 'Walnut Grove'`,

    t_ssDropdownLabel: 'Valitse etäisyys',
    t_ssDropdownOption: 'Sisällä %0% %unit%',
    t_noSites: 'Sivustoja ei ole saatavilla',
    t_locationInputPlaceholder: 'Anna sijainti',

    t_selectedDateLabel: 'Valintapäivä:',

    t_ssPopup1: `Nyt kun olet pätevä opiskelemaan, seuraava askel on varata aika tutkimusklinikalle! <br>
    <br>
    Tällä sivulla voit valita sinulle sopivan sivuston. Valitsemastasi sivustosta riippuen sinulle esitetään muutama vaihtoehto, kun napsautat Seuraava -painiketta: <br> <br>
    1. Jos valittu sivusto ei tarjoa online -varausta, otamme sinuun pian yhteyttä puhelimitse varataksesi ajan. <br> <br>
    2. Jos valittu sivusto tarjoaa online -varauksen, sinulla voi olla mahdollisuus varata seuraavanlaisia ​​tapaamisia: <br> `,
    t_ssPopup2: `• On-Demand Video-virtuaalinen videotapaaminen, joka järjestetään välittömästi. <br> <br>
    • Ajoitettu video - virtuaalinen videotapaaminen, jonka voit ajoittaa tulevaisuutta varten. <br> <br>
    • Ajoitettu puhelin - virtuaalinen puhelinvaraus, jonka voit ajoittaa tulevaisuutta varten. <br> <br>
    • Paikalla-henkilökohtainen tapaaminen kliinisessä paikassa, jonka voit varata tulevaisuutta varten.
    <br> `,
    t_ssPopup3: `<strong> <strong> Entä jos haluan muuttaa hakualuettani? </strong> <br> <br>
    Ei ongelmaa! Kirjoita osoite ja etäisyys (hakusäde) hakupalkkiin, niin tarjoamme sinulle uuden sivustoluettelon. <br> <br>
    <strong> Entä jos mikään sivusto ei ole minulle sopiva? </strong> <br> <br>
    Jos valitset "Ei tutkimussivustoa ole kätevä", otamme sinuun yhteyttä keskustellaksemme vaihtoehdoista ja varataksemme tapaamisen. <br> <br>
    <strong> Entä jos en ole varma, miten pääsen tapaamiseen. </strong> <br> <br>
    Jos napsautat sijaintiruutua, näytämme sinulle ajo- ja julkisen liikenteen reittiohjeet sivustoon.`,

    revision_changed: {
      revision_changed_1: 'Tämän kyselyn kysymyksiä on muutettu sen aloittamisen jälkeen. Siksi sinun on aloitettava uudelleen kysymyksestä 1.',
      revision_changed_2: 'Pahoittelemme häiriöitä.',
      ctaText: "Aloita uusi kyselylomake."
    },

    maintenanceError: {
      header: 'Määräaikaishuolto',
      body: 'Valitettavasti meillä on tällä hetkellä määräaikaishuoltoja.'
    },

    t_appointmentAlreadyBookedHeader: 'Odota! Sinulla on jo aika sovittu kanssamme.',
    t_appointmentAlreadyBookedBody: 'Emme voi varata sinulle toista tapaamista, koska sinulla on jo sellainen sovittu kanssamme. <br><br> Tarkista puhelimestasi tai etsi sähköpostiisi aiemmin lähettämämme vahvistustiedot. <br><br> Jos sinun on tehtävä muutoksia olemassa olevaan tapaamiseen, käytä sähköpostissa olevia yhteystietoja.',
    t_appointmentAlreadyBookedFooter: "Kiitos innostuksestasi ja kiinnostuksestasi edistää lääketieteellistä tutkimusta."
  },
};

const validatorLanguageStrings = {
  sv_SE: {
    required: "Detta fält är obligatoriskt.",
    remote: "Korrigera fältet.",
    email: "Ange en giltig e-postadress.",
    url: "Ange en giltig webbadress.",
    date: "Ange ett giltigt datum.",
    dateISO: "Ange ett giltigt datum (ISO).",
    number: "Ange ett giltigt nummer.",
    equalTo: "Ange samma värde igen.",
    extension: "Ange ett värde med ett giltigt tillägg.",
    maxlength: $.validator.format("Ange inte mer än {0} tecken."),
    minlength: $.validator.format("Ange minst {0} tecken."),
    rangelength: $.validator.format("Ange ett värde mellan {0} och{1} tecken långt."),
    range: $.validator.format("Ange ett värde mellan {0} och{1}."),
    max: $.validator.format("Ange ett värde mindre eller lika med {0}."),
    min: $.validator.format("Ange ett värde större eller lika med {0}.")
  },
  es_ES: {
    required: "Este campo es obligatorio",
    remote: "Corrija este campo",
    email: "Introduzca una dirección de correo electrónico válida.",
    url: "Introduzca una URL válida.",
    date: "Introduzca una fecha válida.",
    dateISO: "Introduzca una fecha válida (ISO).",
    number: "Introduzca un número válido.",
    equalTo: "Vuelva a introducir el mismo valor.",
    extension: "Introduzca un valor con una extensión válida.",
    maxlength: $.validator.format("No introduzca más de {0} caracteres."),
    minlength: $.validator.format("Introduzca al menos {0} caracteres."),
    rangelength: $.validator.format("Introduzca un valor de entre {0} y {1} caracteres de largo."),
    range: $.validator.format("Introduzca un valor de entre {0} y {1}."),
    max: $.validator.format("Introduzca un valor menor o igual a {0}."),
    min: $.validator.format("Introduzca un valor mayor o igual a {0}.")
  },
  es_MX: {
    required: "Este campo es obligatorio",
    remote: "Corrija este campo",
    email: "Introduzca una dirección de correo electrónico válida.",
    url: "Introduzca una URL válida.",
    date: "Introduzca una fecha válida.",
    dateISO: "Introduzca una fecha válida (ISO).",
    number: "Introduzca un número válido.",
    equalTo: "Vuelva a introducir el mismo valor.",
    extension: "Introduzca un valor con una extensión válida.",
    maxlength: $.validator.format("No introduzca más de {0} caracteres."),
    minlength: $.validator.format("Introduzca al menos {0} caracteres."),
    rangelength: $.validator.format("Introduzca un valor de entre {0} y {1} caracteres."),
    range: $.validator.format("Introduzca un valor de entre {0} y {1}."),
    max: $.validator.format("Introduzca un valor menor o igual a {0}."),
    min: $.validator.format("Introduzca un valor mayor o igual a {0}.")
  },
  es_US: {
    required: "Este campo es obligatorio",
    remote: "Corrija este campo",
    email: "Ingrese una dirección de correo electrónico válida.",
    url: "Introduzca una URL válida.",
    date: "Introduzca una fecha válida.",
    dateISO: "Introduzca una fecha válida (ISO).",
    number: "Introduzca un número válido.",
    equalTo: "Vuelva a introducir el mismo valor.",
    extension: "Introduzca un valor con una extensión válida.",
    maxlength: $.validator.format("No introduzca más de {0} caracteres."),
    minlength: $.validator.format("Introduzca al menos {0} caracteres."),
    rangelength: $.validator.format("Introduzca un valor de entre {0} y {1} caracteres."),
    range: $.validator.format("Introduzca un valor de entre {0} y {1}."),
    max: $.validator.format("Introduzca un valor menor o igual a {0}."),
    min: $.validator.format("Introduzca un valor mayor o igual a {0}."),
    step: $.validator.format("Introduzca un múltiplo de {0}.")
  },
  es_CO: {
    required: "Este campo es obligatorio.",
    remote: "Corrija este campo.",
    email: "Introduzca una dirección de correo electrónico válida.",
    url: "Introduzca una URL válida.",
    date: "Introduzca una fecha válida.",
    dateISO: "Introduzca una fecha válida (ISO).",
    number: "Introduzca un número válido.",
    equalTo: "Vuelva a introducir el mismo valor.",
    extension: "Introduzca un valor con una extensión válida.",
    maxlength: $.validator.format("No introduzca más de {0} caracteres."),
    minlength: $.validator.format("Introduzca al menos {0} caracteres."),
    rangelength: $.validator.format("Introduzca un valor entre {0} y {1} caracteres de longitud."),
    range: $.validator.format("Introduzca un valor entre {0} y {1}."),
    max: $.validator.format("Introduzca un valor menor o igual a {0}."),
    min: $.validator.format("Introduzca un valor mayor o igual a {0}.")
  },
  es_AR: {
    required: "Este campo es obligatorio.",
    remote: "Corrija este campo.",
    email: "Ingrese una dirección de correo electrónico válida.",
    url: "Ingrese una URL válida.",
    date: "Ingrese una fecha válida.",
    dateISO: "Ingrese una fecha válida (ISO).",
    number: "Ingrese un número válido.",
    equalTo: "Vuelva a ingresar el mismo valor.",
    extension: "Ingrese un valor con una extensión válida.",
    maxlength: $.validator.format("No ingrese más de {0} caracteres."),
    minlength: $.validator.format("Ingrese al menos {0} caracteres."),
    rangelength: $.validator.format("Ingrese un valor de entre {0} y {1} caracteres de largo."),
    range: $.validator.format("Ingrese un valor entre {0} y {1}."),
    max: $.validator.format("Ingrese un valor menor o igual a {0}."),
    min: $.validator.format("Ingrese un valor mayor o igual a {0}."),
    step: $.validator.format("Introduzca un múltiplo de {0}.")
  },
  ru_UA: {
    required: "Это поле обязательно для заполнения",
    remote: "Пожалуйста, исправьте это поле",
    email: "Пожалуйста, введите действительный адрес электронной почты",
    url: "Пожалуйста, введите действительный URL-адрес",
    date: "Пожалуйста, введите действительную дату",
    dateISO: "Пожалуйста, введите действительную дату (ISO)",
    number: "Пожалуйста, введите действительный номер",
    equalTo: "Пожалуйста, введите это же значение еще раз",
    extension: "Пожалуйста, введите значение с действительным расширением",
    maxlength: $.validator.format("Пожалуйста, введите не более {0} символов"),
    minlength: $.validator.format("Пожалуйста, введите не менее {0} символов"),
    rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов"),
    range: $.validator.format("Пожалуйста, введите значение от {0} до {1}"),
    max: $.validator.format("Пожалуйста, введите значение, равное или менее {0}"),
    min: $.validator.format("Пожалуйста, введите значение, равное или более {0}")
  },
  ru_LV: {
    required: "Это поле обязательно для заполнения",
    remote: "Пожалуйста, исправьте это поле",
    email: "Пожалуйста, введите действительный адрес электронной почты",
    url: "Пожалуйста, введите действительный URL-адрес",
    date: "Пожалуйста, введите действительную дату",
    dateISO: "Пожалуйста, введите действительную дату (ISO)",
    number: "Пожалуйста, введите действительный номер",
    equalTo: "Пожалуйста, введите это же значение еще раз",
    extension: "Пожалуйста, введите значение с действительным расширением",
    maxlength: $.validator.format("Пожалуйста, введите не более {0} символов"),
    minlength: $.validator.format("Пожалуйста, введите не менее {0} символов"),
    rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов"),
    range: $.validator.format("Пожалуйста, введите значение от {0} до {1}"),
    max: $.validator.format("Пожалуйста, введите значение, равное или менее {0}"),
    min: $.validator.format("Пожалуйста, введите значение, равное или более {0}")
  },
  ru_LT: {
    required: "Это поле обязательно для заполнения",
    remote: "Пожалуйста, исправьте это поле",
    email: "Пожалуйста, введите действительный адрес электронной почты",
    url: "Пожалуйста, введите действительный URL-адрес",
    date: "Пожалуйста, введите действительную дату",
    dateISO: "Пожалуйста, введите действительную дату (ISO)",
    number: "Пожалуйста, введите действительный номер",
    equalTo: "Пожалуйста, введите это же значение еще раз",
    extension: "Пожалуйста, введите значение с действительным расширением",
    maxlength: $.validator.format("Пожалуйста, введите не более {0} символов"),
    minlength: $.validator.format("Пожалуйста, введите не менее {0} символов"),
    rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов"),
    range: $.validator.format("Пожалуйста, введите значение от {0} до {1}"),
    max: $.validator.format("Пожалуйста, введите значение, равное или менее {0}"),
    min: $.validator.format("Пожалуйста, введите значение, равное или более {0}")
  },
  ru_IL: {
    required: "Это поле подлежит обязательному заполнению",
    remote: "Пожалуйста, исправьте это поле",
    email: "Пожалуйста, введите действительный адрес электронной почты",
    url: "Пожалуйста, введите действительный URL-адрес",
    date: "Пожалуйста, введите действительную дату",
    dateISO: "Пожалуйста, введите действительную дату (ISO)",
    number: "Пожалуйста, введите действительный номер",
    equalTo: "Пожалуйста, введите это же значение еще раз",
    extension: "Пожалуйста, введите значение с действительным расширением",
    maxlength: $.validator.format("Пожалуйста, введите не более {0} символов"),
    minlength: $.validator.format("Пожалуйста, введите не менее {0} символов"),
    rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов"),
    range: $.validator.format("Пожалуйста, введите значение от {0} до {1}"),
    max: $.validator.format("Пожалуйста, введите значение, равное или менее {0}"),
    min: $.validator.format("Пожалуйста, введите значение, равное или более {0}")
  },
  pt_BR: {
    required: "Este campo é obrigatório.",
    remote: "Corrija este campo.",
    email: "Insira um endereço de e-mail válido.",
    url: "Insira um URL válido.",
    date: "Insira uma data válida.",
    dateISO: "Insira uma data válida (ISO).",
    number: "Insira um número válido.",
    equalTo: "Insira o mesmo valor novamente.",
    extension: "Insira um valor com uma extensão válida.",
    maxlength: $.validator.format("Insira no máximo {0} caracteres."),
    minlength: $.validator.format("Insira pelo menos {0} caracteres."),
    rangelength: $.validator.format("Insira um valor com {0} a {1} caracteres."),
    range: $.validator.format("Insira um valor entre {0} e {1}."),
    max: $.validator.format("Insira um valor menor que ou igual a {0}."),
    min: $.validator.format("Insira um valor maior que ou igual a {0}.")
  },
  lv_LV: {
    required: "Šis lauks ir obligāts.",
    remote: "Lūdzu, izlabojiet šo lauku.",
    email: "Lūdzu, ievadiet derīgu e-pasta adresi.",
    url: "Lūdzu, ievadiet derīgu URL.",
    date: "Lūdzu, ievadiet derīgu datumu.",
    dateISO: "Lūdzu, ievadiet derīgu datumu (ISO).",
    number: "Lūdzu, ievadiet derīgu numuru.",
    equalTo: "Lūdzu, vēlreiz ievadiet to pašu vērtību.",
    extension: "Lūdzu, ievadiet vērtību ar derīgu paplašinājumu.",
    maxlength: $.validator.format("Lūdzu, ievadiet ne vairāk kā {0} rakstzīmju."),
    minlength: $.validator.format("Lūdzu, ievadiet vismaz {0} rakstzīmes."),
    rangelength: $.validator.format("Lūdzu, ievadiet vērtību no {0} līdz {1} rakstzīmēm."),
    range: $.validator.format("Lūdzu, ievadiet vērtību no {0} līdz {1}."),
    max: $.validator.format("Lūdzu, ievadiet vērtību, kas ir mazāka par {0} vai vienāda ar to."),
    min: $.validator.format("Lūdzu, ievadiet vērtību, kas ir lielāka par {0} vai vienāda ar to.")
  },
  ja_JP: {
    required: "このフィールドは必須です。",
    remote: "このフィールドを修正してください。",
    email: "有効な Eメールアドレスを入力してください。",
    url: "有効な URL を入力してください。",
    date: "有効な日付を入力してください。",
    dateISO: "有効な日付（ISO）を入力してください。",
    number: "有効な番号を入力してください。",
    equalTo: "同じ値を再度入力してください。",
    extension: "有効な拡張子を使って値を入力してください。",
    maxlength: $.validator.format(" {0} 文字超を入力してください。"),
    minlength: $.validator.format("少なくとも {0} 文字を入力してください。"),
    rangelength: $.validator.format("{0} と {1\} の間の文字数で値を入力してください。"),
    range: $.validator.format("{0} と {1} の間の値を入力してください。"),
    max: $.validator.format(" {0}以下の値を入力してください。"),
    min: $.validator.format(" {0}以上の値を入力してください。")
  },
  de_AT: {
    required: 'Dies ist ein Pflichtfeld.',
    remote: 'Bitte dieses Feld korrigieren.',
    email: 'Bitte eine gültige E-Mail-Adresse eingeben.',
    url: 'Bitte eine gültige-URL-Adresse eingeben.',
    date: 'Bitte ein gültiges Datum eingeben.',
    dateISO: 'Bitte ein gültiges Datum (ISO) eingeben.',
    number: 'Bitte eine gültige Anzahl eingeben.',
    digits: 'Bitte nur Zahlen eingeben.',
    equalTo: 'Bitte geben Sie den gleich Wert erneut ein.',
    extension: 'Bitte geben Sie einen Wert mit einer gültigen Endung ein.',
    maxlength: jQuery.validator.format('Bitte geben Sie maximal {0} Zeichen ein.'),
    minlength: jQuery.validator.format('Bitte geben Sie mindestens {0} Zeichen ein.'),
    rangelength: jQuery.validator.format('Bitte geben Sie einen zwischen {0} und {1} Zeichen langen Wert ein.'),
    range: jQuery.validator.format('Bitte geben Sie einen Wert zwischen {0} und {1} ein.'),
    max: jQuery.validator.format('Bitte geben Sie einen Wert kleiner oder gleich {0} ein.'),
    min: jQuery.validator.format('Bitte geben Sie einen Wert größer oder gleich {0} ein.')
  },
  es_CL: {
    required: "Este campo es obligatorio.",
    remote: "Corrija este campo.",
    email: "Ingrese una dirección de correo electrónico válida.",
    url: "Ingrese una URL válida.",
    date: "Ingrese una fecha válida.",
    dateISO: "Ingrese una fecha válida (ISO).",
    number: "Ingrese un número válido.",
    digits: "Ingrese solo números.",
    creditcard: " Por favor, introduzca un número de tarjeta de crédito válida.",
    equalTo: "Ingrese nuevamente el mismo valor.",
    extension: "Ingrese un valor con una extensión válida.",
    maxlength: jQuery.validator.format("No ingrese más de {0} caracteres."),
    minlength: jQuery.validator.format("Ingrese al menos {0} caracteres."),
    rangelength: jQuery.validator.format("Ingrese un valor de entre {0} y {1} caracteres."),
    range: jQuery.validator.format("Ingrese un valor entre {0} y {1}."),
    max: jQuery.validator.format("Ingrese un valor menor o igual a {0}."),
    min: jQuery.validator.format("Ingrese un valor mayor o igual a {0}.")
  },
  fr_CA: {
    required: "Ce champs est requis.",
    remote: "Veuillez corriger ce champ.",
    email: "Veuillez saisir une adresse courriel valide.",
    url: "Veuillez saisir une URL valide.",
    date: "Veuillez saisir une date valide.",
    dateISO: "Veuillez saisir une date valide (ISO).",
    number: "Veuillez saisir un nombre valide.",
    equalTo: "Veuillez saisir à nouveau la même valeur.",
    extension: "Veuillez saisir une valeur avec une extension valide.",
    maxlength: $.validator.format("Veuillez ne pas saisir plus de {0} caractères."),
    minlength: $.validator.format("Veuillez saisir au moins {0} caractères."),
    rangelength: $.validator.format("Veuillez saisir une valeur dont la longueur comprend entre {0} et {1} caractères."),
    range: $.validator.format("Veuillez saisir une valeur comprise entre {0} et {1}."),
    max: $.validator.format("Veuillez saisir une valeur inférieure ou égale à {0}."),
    min: $.validator.format("Veuillez saisir une valeur supérieure ou égale à {0}."),
    step: $.validator.format("Veuillez saisir un multiple de {0}.")
  },
  fr_FR: {
    required: "Ce champs est requis.",
    remote: "Veuillez corriger ce champ.",
    email: "Veuillez saisir une adresse e-mail valide.",
    url: "Veuillez saisir une URL valide.",
    date: "Veuillez saisir une date valide.",
    dateISO: "Veuillez saisir une date valide (ISO).",
    number: "Veuillez saisir un nombre valide.",
    equalTo: "Veuillez saisir à nouveau la même valeur.",
    extension: "Veuillez saisir une valeur avec une extension valide.",
    maxlength: $.validator.format("Veuillez ne pas saisir plus de {0} caractères."),
    minlength: $.validator.format("Veuillez saisir au moins {0} caractères."),
    rangelength: $.validator.format("Veuillez saisir une valeur dont la longueur comprend entre {0} et {1} caractères."),
    range: $.validator.format("Veuillez saisir une valeur comprise entre {0} et {1}."),
    max: $.validator.format("Veuillez saisir une valeur inférieure ou égale à {0}."),
    min: $.validator.format("Veuillez saisir une valeur supérieure ou égale à {0}.")
  },
  fr_BE: {
    required: "Ce champs est requis.",
    remote: "Veuillez corriger ce champ.",
    email: "Veuillez saisir une adresse e-mail valide.",
    url: "Veuillez saisir une URL valide.",
    date: "Veuillez saisir une date valide.",
    dateISO: "Veuillez saisir une date valide (ISO).",
    number: "Veuillez saisir un nombre valide.",
    equalTo: "Veuillez saisir à nouveau la même valeur.",
    extension: "Veuillez saisir une valeur avec une extension valide.",
    maxlength: $.validator.format("Veuillez ne pas saisir plus de {0} caractères."),
    minlength: $.validator.format("Veuillez saisir au moins {0} caractères."),
    rangelength: $.validator.format("Veuillez saisir une valeur dont la longueur comprend entre {0} et {1} caractères."),
    range: $.validator.format("Veuillez saisir une valeur comprise entre {0} et {1}."),
    max: $.validator.format("Veuillez saisir une valeur inférieure ou égale à {0}."),
    min: $.validator.format("Veuillez saisir une valeur supérieure ou égale à {0}.")
  },
  lt_LT: {
    required: "Šį lauką būtina užpildyti.",
    number: "Įveskite tinkamą skaičių.",
    maxlength: $.validator.format("Įveskite ne daugiau kaip {0} simbolius."),
    minlength: $.validator.format("Įveskite mažiausiai {0} simbolius."),
    min: $.validator.format("Įveskite didesnę arba lygią {0} reikšmę."),
    max: $.validator.format("Įveskite mažesnę arba lygią {0} reikšmę."),
    remote: "Pataisykite šį lauką.",
    email: "Įveskite galiojantį elektroninio pašto adresą.",
    url: "Įveskite tinkamą URL.",
    date: "Įveskite tinkamą datą.",
    dateISO: "Įveskite tinkamą datą (ISO).",
    equalTo: "Dar kartą įveskite tą pačią reikšmę.",
    extension: "Įveskite reikšmę su tinkamu plėtiniu.",
    rangelength: $.validator.format("Įveskite reikšmę nuo {0} iki {1} simbolių ilgio."),
    range: $.validator.format("Įveskite reikšmę nuo {0} iki {1} simbolių."),
  },
  en_NZ: {
    required: "This field is required.",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: $.validator.format("Please enter no more than {0} characters."),
    minlength: $.validator.format("Please enter at least {0} characters."),
    rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
    range: $.validator.format("Please enter a value between {0} and {1}."),
    max: $.validator.format("Please enter a value less than or equal to {0}."),
    min: $.validator.format("Please enter a value greater than or equal to {0}.")
  },
  sk_SK: {
    required: "Táto položka je povinná.",
    remote: "Opravte túto položku.",
    email: "Zadajte platnú e-mailovú adresu.",
    url: "Zadajte platnú URL.",
    date: "Zadajte platný dátum.",
    dateISO: "Zadajte platný dátum (ISO).",
    number: "Zadajte platné číslo.",
    digits: "Zadávajte len číslice",
    equalTo: "Znovu zadajte rovnakú hodnotu.",
    extension: "Zadajte hodnotu s platnou príponou.",
    maxlength: $.validator.format("Zadajte max. {0} znakov."),
    minlength: $.validator.format("Zadajte minimálne {0} znakov."),
    rangelength: $.validator.format("Zadajte hodnotu s počtom znakov od {0} do {1}."),
    range: $.validator.format("Zadajte hodnotu s počtom znakov od {0} do {1}."),
    max: $.validator.format("Zadajte hodnotu menšiu alebo rovnú {0}."),
    min: $.validator.format("Zadajte hodnotu väčšiu alebo rovnú {0}."),
    step: $.validator.format("Zadajte násobok {0}.")
  },
  en_CA: {
    required: "This field is required.",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: $.validator.format("Please enter no more than {0} characters."),
    minlength: $.validator.format("Please enter at least {0} characters."),
    rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
    range: $.validator.format("Please enter a value between {0} and {1}."),
    max: $.validator.format("Please enter a value less than or equal to {0}."),
    min: $.validator.format("Please enter a value greater than or equal to {0}.")
  },
  en_AU: {
    required: "This field is required.",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: $.validator.format("Please enter no more than {0} characters."),
    minlength: $.validator.format("Please enter at least {0} characters."),
    rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
    range: $.validator.format("Please enter a value between {0} and {1}."),
    max: $.validator.format("Please enter a value less than or equal to {0}."),
    min: $.validator.format("Please enter a value greater than or equal to {0}.")
  },
  nl_NL: {
    required: "Dit veld is vereist.",
    remote: "Maak dit veld kloppend.",
    email: "Voer een geldig e-mailadres in.",
    url: "Voer een geldige URL in.",
    date: "Voer een geldige datum in.",
    dateISO: "Voer een geldige datum in (ISO).",
    number: "Voer een geldig cijfer in.",
    equalTo: "Voer dezelfde waarde opnieuw in.",
    extension: "Voer een waarde met een geldige extensie in.",
    maxlength: $.validator.format("Voer niet meer dan {0} tekens in."),
    minlength: $.validator.format("Voer op zijn minst {0} tekens in."),
    rangelength: $.validator.format("Voer een waarde in met minimaal {0} en maximaal {1} tekens."),
    range: $.validator.format("Voer een waarden in tussen de {0} en {1} tekens."),
    max: $.validator.format("Voer een waarden in lager of gelijk aan {0}."),
    min: $.validator.format("Voer een waarden in hoger of gelijk aan {0}.")
  },
  nl_BE: {
    required: "Dit veld is vereist.",
    remote: "Maak dit veld kloppend.",
    email: "Voer een geldig e-mailadres in.",
    url: "Voer een geldige URL in.",
    date: "Voer een geldige datum in.",
    dateISO: "Voer een geldige datum in (ISO).",
    number: "Voer een geldig cijfer in.",
    equalTo: "Voer dezelfde waarde opnieuw in.",
    extension: "Voer een waarde met een geldige extensie in.",
    maxlength: $.validator.format("Voer niet meer dan {0} tekens in."),
    minlength: $.validator.format("Voer op zijn minst {0} tekens in."),
    rangelength: $.validator.format("Voer een waarde in met minimaal {0} en maximaal {1} tekens."),
    range: $.validator.format("Voer een waarde in tussen de {0} en {1} tekens."),
    max: $.validator.format("Voer een waarde in lager of gelijk aan {0}."),
    min: $.validator.format("Voer een waarde in hoger of gelijk aan {0}.")
  },
  en_US: {
    required: "This field is required.",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
  },
  en_GB: {
    required: "This field is required.",
    remote: "Please amend this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
  },
  en_IE: {
    required: "This field is required.",
    remote: "Please amend this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
  },
  // Danish
  da_DK: {
    required: "Dette felt er obligatorisk.",
    remote: "Udfyld venligst dette felt.",
    email: "Indtast en gyldig e-mailadresse.",
    url: "Indtast en gyldig URL.",
    date: "Indtast en gyldig dato.",
    dateISO: "Indtast en gyldig dato (ISO).",
    number: "Indtast et gyldigt tal.",
    digits: "Indtast kun cifre.",
    equalTo: "Indtast venligst den samme værdi igen.",
    extension: "Indtast en værdi med en gyldig type.",
    maxlength: $.validator.format("Indtast ikke flere end {0} karakterer."),
    minlength: $.validator.format("Indtast mindst {0} karakterer."),
    rangelength: $.validator.format("Indtast en værdi mellem {0} og {1} karakterer."),
    range: $.validator.format("Indtast en værdi mellem {0} og {1}."),
    max: $.validator.format("Indtast en værdi mindre end eller lig {0}."),
    min: $.validator.format("Indtast en værdi større end eller lig {0}.")
  },
  //polish
  pl_PL: {
    required: "To pole jest wymagane.",
    remote: "Popraw błędy w tym polu.",
    email: "Proszę o podanie prawidłowego adresu email.",
    url: "Wprowadź poprawny URL.",
    date: "Wprowadź poprawną datę.",
    dateISO: "Wprowadź poprawną datę (w formacie ISO).",
    number: "Wprowadź poprawną wartość liczbową.",
    digits: "Wprowadź tylko cyfry.",
    creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
    equalTo: "Wprowadź ponownie tę samą wartość.",
    extension: "Wprowadź wartość z poprawnym rozszerzeniem.",
    nipPL: "Proszę o podanie prawidłowego numeru NIP.",
    phonePL: "Proszę o podanie prawidłowego numeru telefonu",
    maxlength: $.validator.format("Wprowadź nie więcej niż {0} znaki."),
    minlength: $.validator.format("Wprowadź co najmniej {0} znaki."),
    rangelength: $.validator.format("Wprowadź wartość o długości od {0} do {1} znaków."),
    range: $.validator.format("Wprowadź wartość pomiędzy {0} a {1}."),
    max: $.validator.format("Wprowadź wartość mniejszą albo równą {0}."),
    min: $.validator.format("Wprowadź wartość większą albo równą {0}."),
    pattern: $.validator.format("Pole zawiera niedozwolone znaki.")
  },
  //german
  de_DE: {
    required: "Dies ist ein Pflichtfeld.",
    maxlength: $.validator.format("Bitte geben Sie maximal {0} Zeichen ein."),
    minlength: $.validator.format("Bitte geben Sie mindestens {0} Zeichen ein."),
    rangelength: $.validator.format("Bitte geben Sie einen zwischen {0} und {1} Zeichen langen Wert ein."),
    email: "Bitte eine gültige E-Mail-Adresse eingeben.",
    url: "Bitte eine gültige-URL-Adresse eingeben.",
    date: "Bitte ein gültiges Datum eingeben.",
    number: "Bitte eine gültige Anzahl eingeben.",
    digits: "Bitte nur Zahlen eingeben.",
    equalTo: "Bitte geben Sie den gleich Wert erneut ein.",
    range: $.validator.format("Bitte geben Sie einen Wert zwischen {0} und {1} ein."),
    max: $.validator.format("Bitte geben Sie einen Wert kleiner oder gleich {0} ein."),
    min: $.validator.format("Bitte geben Sie einen Wert größer oder gleich {0} ein."),
    creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
    remote: "Bitte dieses Feld korrigieren.",
    dateISO: "Bitte ein gültiges Datum (ISO) eingeben.",
    step: $.validator.format("Geben Sie bitte ein Vielfaches von {0} ein."),
    maxWords: $.validator.format("Geben Sie bitte {0} Wörter oder weniger ein."),
    minWords: $.validator.format("Geben Sie bitte mindestens {0} Wörter ein."),
    rangeWords: $.validator.format("Geben Sie bitte zwischen {0} und {1} Wörtern ein."),
    extension: "Bitte geben Sie einen Wert mit einer gültigen Endung ein.",
    alphanumeric: "Geben Sie bitte nur Buchstaben (keine Umlaute), Zahlen oder Unterstriche ein.",
    bankaccountNL: "Geben Sie bitte eine gültige Kontonummer ein.",
    bankorgiroaccountNL: "Geben Sie bitte eine gültige Bank- oder Girokontonummer ein.",
    bic: "Geben Sie bitte einen gültigen BIC-Code ein.",
    cifES: "Geben Sie bitte eine gültige CIF-Nummer ein.",
    cpfBR: "Geben Sie bitte eine gültige CPF-Nummer ein.",
    creditcardtypes: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
    currency: "Geben Sie bitte eine gültige Währung ein.",
    giroaccountNL: "Geben Sie bitte eine gültige Girokontonummer ein.",
    iban: "Geben Sie bitte eine gültige IBAN ein.",
    integer: "Geben Sie bitte eine positive oder negative Nicht-Dezimalzahl ein.",
    ipv4: "Geben Sie bitte eine gültige IPv4-Adresse ein.",
    ipv6: "Geben Sie bitte eine gültige IPv6-Adresse ein.",
    lettersonly: "Geben Sie bitte nur Buchstaben ein.",
    letterswithbasicpunc: "Geben Sie bitte nur Buchstaben oder Interpunktion ein.",
    mobileNL: "Geben Sie bitte eine gültige Handynummer ein.",
    mobileUK: "Geben Sie bitte eine gültige Handynummer ein.",
    netmask: "Geben Sie bitte eine gültige Netzmaske ein.",
    nieES: "Geben Sie bitte eine gültige NIE-Nummer ein.",
    nifES: "Geben Sie bitte eine gültige NIF-Nummer ein.",
    nipPL: "Geben Sie bitte eine gültige NIP-Nummer ein.",
    notEqualTo: "Geben Sie bitte einen anderen Wert ein. Die Werte dürfen nicht gleich sein.",
    nowhitespace: "Kein Leerzeichen bitte.",
    pattern: "Ungültiges Format.",
    phoneNL: "Geben Sie bitte eine gültige Telefonnummer ein.",
    phonesUK: "Geben Sie bitte eine gültige britische Telefonnummer ein.",
    phoneUK: "Geben Sie bitte eine gültige Telefonnummer ein.",
    phoneUS: "Geben Sie bitte eine gültige Telefonnummer ein.",
    postalcodeBR: "Geben Sie bitte eine gültige brasilianische Postleitzahl ein.",
    postalCodeCA: "Geben Sie bitte eine gültige kanadische Postleitzahl ein.",
    postalcodeIT: "Geben Sie bitte eine gültige italienische Postleitzahl ein.",
    postalcodeNL: "Geben Sie bitte eine gültige niederländische Postleitzahl ein.",
    postcodeUK: "Geben Sie bitte eine gültige britische Postleitzahl ein.",
    require_from_group: $.validator.format("Füllen Sie bitte mindestens {0} dieser Felder aus."),
    skip_or_fill_minimum: $.validator.format("Überspringen Sie bitte diese Felder oder füllen Sie mindestens {0} von ihnen aus."),
    stateUS: "Geben Sie bitte einen gültigen US-Bundesstaat ein.",
    strippedminlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
    time: "Geben Sie bitte eine gültige Uhrzeit zwischen 00:00 und 23:59 ein.",
    time12h: "Geben Sie bitte eine gültige Uhrzeit im 12-Stunden-Format ein.",
    vinUS: "Die angegebene Fahrzeugidentifikationsnummer (VIN) ist ungültig.",
    zipcodeUS: "Die angegebene US-Postleitzahl ist ungültig.",
    ziprange: "Ihre Postleitzahl muss im Bereich 902xx-xxxx bis 905xx-xxxx liegen."
  },
  //afrikaans
  en_ZA: {
    required: "This field is required.",
    remote: "Please fix this field.",
    email: "Please enter a valid email address.",
    url: "Please enter a valid URL.",
    date: "Please enter a valid date.",
    dateISO: "Please enter a valid date (ISO).",
    number: "Please enter a valid number.",
    digits: "Please enter only digits.",
    creditcard: "Please enter a valid credit card number.",
    equalTo: "Please enter the same value again.",
    extension: "Please enter a value with a valid extension.",
    maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
    minlength: jQuery.validator.format("Please enter at least {0} characters."),
    rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
    range: jQuery.validator.format("Please enter a value between {0} and {1}."),
    max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
    min: jQuery.validator.format("Please enter a value greater than or equal to {0}.")
  },
  af_ZA: {
    required: "Hierdie veld word vereis.",
    remote: "Herstel asseblief hierdie veld.",
    email: "Voer asseblief 'n geldige e-posadres in.",
    url: "Voer asseblief 'n geldige URL in.",
    date: "Voer asseblief 'n geldige datum in.",
    dateISO: "Voer asseblief 'n geldige datum (ISO) in.",
    number: "Voer asseblief 'n geldige nommer in.",
    digits: "Voer asseblief slegs syfers in.",
    creditcard: "Voer 'n geldige kredietkaartnommer in.",
    equalTo: "Voer asseblief dieselfde waarde weer in.",
    extension: "Voer asseblief 'n waarde met 'n geldige uitbreiding in.",
    maxlength: jQuery.validator.format("Voer asseblief nie meer as {0} karakters in nie."),
    minlength: jQuery.validator.format("Voer asseblief ten minste {0} karakters in."),
    rangelength: jQuery.validator.format("Voer asseblief 'n waarde in tussen {0} en {1} karakters."),
    range: jQuery.validator.format("Voer asseblief 'n waarde in tussen {0} en {1}."),
    max: jQuery.validator.format("Voer asseblief 'n waarde in wat minder of gelyk is aan {0}."),
    min: jQuery.validator.format("Voer asseblief 'n waarde in wat groter of gelyk is aan {0}.")
  },
  zu_ZA: {
    required: "Le ndawo iyadingeka.",
    remote: "Sicela ulungise le ndawo.",
    email: "Sicela ufake ikheli le-imeyili efanele.",
    url: "Sicela ufake i-URL efanele.",
    date: "Sicela ufake usuku olufanele.",
    dateISO: "Sicela ufake usuku olufanele (ISO).",
    number: "Sicela ufake inombolo efanele.",
    digits: "Sicela ufake amadijithi kuphela.",
    equalTo: "Sicela uphinde ufake inani elifanayo.",
    extension: "Sicela ufake inani lenombolo enesenezelo esifanele.",
    maxlength: jQuery.validator.format("Sicela ungafaki izinhlamvu ezingeqi kwezingu-{0}."),
    minlength: jQuery.validator.format("Sicela ufake okungenani izinhlamvu ezingu-{0}."),
    rangelength: jQuery.validator.format("Sicela ufake inani lezinhlamvu eliphakathi kuka-{0} no-{1} ubude."),
    range: jQuery.validator.format("Sicela ufake inani eliphakathi kuka-{0} no-{1}."),
    max: jQuery.validator.format("Sicela ufake inani elingaphansi noma elilingana no-{0}."),
    min: jQuery.validator.format("Sicela ufake inani elingenhla noma elilingana no-{0}.")
  },
  //hungarian
  hu_HU: {
    required: "A mező kitöltése kötelező.",
    maxlength: $.validator.format("Legfeljebb {0} karaktert írjon be."),
    minlength: $.validator.format("Legalább {0} karaktert írjon be."),
    rangelength: $.validator.format("{0} és {1} karakter hosszúságú értéket írjon be."),
    email: "Érvényes e-mail címet adjon meg.",
    url: "Érvényes URL-címet adjon meg.",
    date: "Érvényes dátumot adjon meg.",
    number: "Érvényes számot adjon meg.",
    digits: "Csak számjegyeket írjon be.",
    equalTo: "Írja be ismét az előző értéket.",
    range: $.validator.format("{0} és {1} közötti értéket írjon be."),
    max: $.validator.format("A beírt érték legfeljebb {0} legyen."),
    min: $.validator.format("A beírt érték legalább {0} legyen."),
    creditcard: "Érvényes hitelkártyaszámnak kell lennie.",
    remote: "Javítsa ezt a mezőt.",
    dateISO: "Érvényes dátumot (ISO) adjon meg.",
    step: $.validator.format("A {0} egyik többszörösét adja meg."),
    extension: "Az értéket érvényes kiterjesztéssel írja be."
  },
  //ukrainian
  uk_UA: {
    required: "Це поле є обов'язковим.",
    remote: "Будь ласка, виправте це поле.",
    email: "Будь ласка, вкажіть дійсну адресу електронної пошти.",
    url: "Будь ласка, вкажіть дійсну URL-адресу.",
    date: "Будь ласка, вкажіть дійсну дату.",
    dateISO: "Будь ласка, вкажіть дійсну дату (ISO).",
    number: "Будь ласка, вкажіть дійсний номер.",
    digits: "Будь ласка, вкажіть тільки цифри.",
    creditcard: "Будь ласка, введіть правильний номер кредитної карти.",
    equalTo: "Будь ласка, вкажіть те ж саме значення ще раз.",
    extension: "Будь ласка, вкажіть значення с дійсним розширенням.",
    maxlength: $.validator.format("Будь ласка, вкажіть не більш {0} символів."),
    minlength: $.validator.format("Будь ласка, вкажіть не менш {0} символів."),
    rangelength: $.validator.format("Будь ласка, вкажіть значення довжиною від {0} до {1} символів."),
    range: $.validator.format("Будь ласка, вкажіть значення від {0} до {1}."),
    max: $.validator.format("Будь ласка, введіть число, менше або рівно {0}."),
    min: $.validator.format("Будь ласка, введіть число, більше або рівно {0}."),
    step: $.validator.format("Введіть число, кратне {0}.")
  },
  //czech
  cs_CZ: {
    required: "Toto pole je povinné.",
    remote: "Opravte toto pole.",
    email: "Zadejte platnou e-mailovou adresu.",
    url: "Zadejte platný URL.",
    date: "Zadejte platné datum.",
    dateISO: "Zadejte platné datum (ISO).",
    number: "Hodnota musí být platné číslo.",
    digits: "Zadejte pouze číslice.",
    creditcard: "Prosím, zadejte číslo kreditní karty.",
    equalTo: "Zadejte znovu stejnou hodnotu.",
    extension: "Zadejte hodnotu s platnou příponou.",
    maxlength: $.validator.format("Zadejte maximálně {0} znaků."),
    minlength: $.validator.format("Zadejte nejméně {0} znaků."),
    rangelength: $.validator.format("Zadejte hodnotu, která má mezi {0} a {1} znaky."),
    range: $.validator.format("Zadejte hodnotu mezi {0} a {1}."),
    max: $.validator.format("Zadejte hodnotu menší nebo rovnu {0}."),
    min: $.validator.format("Zadejte hodnotu větší nebo rovnu {0}."),
    step: $.validator.format("Musí být násobkem čísla {0}.")
  },
  //bulgarian
  bg_BG: {
    required: "Това поле е задължително.",
    remote: "Коригирайте полето.",
    email: "Въведете валиден имейл адрес.",
    url: "Въведете валиден URL адрес.",
    date: "Въведената дата не е валидна.",
    dateISO: "Въведете валидна дата (ISO).",
    number: "Въведете валидно число.",
    digits: "Въведете само цифри.",
    creditcard: "Моля, въведете валиден номер на кредитна карта.",
    equalTo: "Въведете същата стойност отново.",
    extension: "Въведете стойност с валидно разширение.",
    maxlength: $.validator.format("Въведете не повече от {0} знака."),
    minlength: $.validator.format("Въведете най-малко {0} знака."),
    rangelength: $.validator.format("Въведете стойност с дължина между {0} и {1} знака."),
    range: $.validator.format("Въведете стойност между {0} и {1}."),
    max: $.validator.format("Въведете стойност, която е по-малка или равна на {0}."),
    min: $.validator.format("Въведете стойност, която е по-голяма или равна на {0}.")
  },
  //Hebrew
  iw_IL: {
    required: "שדה חובה",
    remote: "נא לתקן את השדה",
    email: `נא להזין כתובת דוא"ל תקינה`,
    url: "נא להזין כתובת URL תקינה",
    date: "נא להזין תאריך תקין",
    dateISO: "נא להזין תאריך תקין (ISO)",
    number: "נא להזין מספר תקין",
    digits: "נא להזין ספרות בלבד",
    equalTo: "נא להזין שנית את אותו ערך",
    extension: "נא להזין ערך עם הארכה בתוקף",
    maxlength: $.validator.format("נא לא להזין יותר מ-{0} תווים"),
    minlength: $.validator.format("נא להזין לפחות {0} תווים"),
    rangelength: $.validator.format("נא להזין ערך בין {0} ל/{1} תווים"),
    range: $.validator.format("הזן ערך בין {0} ל- {1}"),
    max: $.validator.format("{0}-נא להזין ערך נמוך מ- או שווה ל"),
    min: $.validator.format("{0}-נא להזין ערך גדול מ- או שווה ל")
  },
  //Italian
  it_IT: {
    required: "Questo campo è obbligatorio.",
    remote: "Per favore, corregga questo campo.",
    email: "Inserisca un indirizzo e-mail valido.",
    url: "Inserire un URL valido.",
    date: "Inserire una data valida.",
    dateISO: "Inserire una data valida (ISO).",
    number: "Inserire un numero valido.",
    digits: "Si prega di inserire solo cifre.",
    equalTo: "Si prega di inserire nuovamente lo stesso valore.",
    extension: "Inserire un valore con un'estensione valida",
    maxlength: jQuery.validator.format("Si prega di inserire non più di {0} caratteri."),
    minlength: jQuery.validator.format("Inserisca almeno {0} caratteri."),
    rangelength: jQuery.validator.format("Inserire un valore compreso tra {0} e {1} caratteri"),
    range: jQuery.validator.format("Inserire un valore compreso tra {0} e {1}"),
    max: jQuery.validator.format("Inserire un valore inferiore o uguale a {0}."),
    min: jQuery.validator.format("Inserire un valore maggiore o uguale a {0}.")
  },
  //Romainian
  ro_RO: {
    required: "Acest câmp este obligatoriu",
    remote: "Completați acest câmp",
    email: "Introduceți o adresă de e-mail validă",
    url: "Introduceți un URL valid",
    date: "Introduceți o dată validă",
    dateISO: "Introduceți o dată validă (ISO)",
    number: "Introduceți un număr valid",
    digits: "Introduceți doar cifre",
    equalTo: "Introduceți din nou aceeași valoare",
    extension: "Introduceți o valoare cu o extensie validă",
    maxlength: jQuery.validator.format("Introduceți maxim {0} caractere"),
    minlength: jQuery.validator.format("Introduceți cel puțin {0} caractere"),
    rangelength: jQuery.validator.format("Introduceți o valoare cu {0} - {1} caractere"),
    range: jQuery.validator.format("Introduceți o valoare cuprinsă între {0} și {1}"),
    max: jQuery.validator.format("Introduceți o valoare mai mică decât sau egală cu {0}"),
    min: jQuery.validator.format("Introduceți o valoare mai mare decât sau egală cu {0}")
  },
  //Portuguese
  pt_PT: {
    required: "Este campo é obrigatório.",
    remote: "Corrija este campo.",
    email: "Insira um endereço de e-mail válido.",
    url: "Insira um URL válido.",
    date: "Insira uma data válida.",
    dateISO: "Insira uma data válida (ISO).",
    number: "Insira um número válido.",
    digits: "Insira somente dígitos.",
    equalTo: "Insira o mesmo valor novamente.",
    extension: "Insira um valor com uma extensão válida.",
    maxlength: jQuery.validator.format("Insira no máximo {0} caracteres."),
    minlength: jQuery.validator.format("Insira pelo menos {0} caracteres."),
    rangelength: jQuery.validator.format("Insira um valor com {0} a {1} caracteres."),
    range: jQuery.validator.format("Insira um valor entre {0} e {1}."),
    max: jQuery.validator.format("Insira um valor menor que ou igual a {0}."),
    min: jQuery.validator.format("Insira um valor maior que ou igual a {0}.")
  },
  //Arabic
  ar_IL: {
    required: "هذه الخانة مطلوبه.",
    remote: "الرجاء تصحيح هذا الحقل.",
    email: "من فضلك أدخل بريد أليكترونى صحيح.",
    url: "أدخل رابط صحيح من فضلك.",
    date: "ارجوك ادخل تاريخ صحيح.",
    dateISO: "الرجاء إدخال تاريخ صالح (ISO).",
    number: "من فضلك أدخل رقما صالحا.",
    digits: "الرجاء إدخال أرقام فقط.",
    equalTo: "من فظلك ادخل نفس القيمة مرة أخرى.",
    extension: "الرجاء إدخال قيمة بامتداد صالح.",
    maxlength: jQuery.validator.format("الرجاء إدخال ما لا يزيد عن {0} حرف."),
    minlength: jQuery.validator.format("الرجاء إدخال {0} حرف على الأقل."),
    rangelength: jQuery.validator.format("الرجاء إدخال قيمة يتراوح طولها بين {0} و {1} حرفًا."),
    range: jQuery.validator.format("الرجاء إدخال قيمة بين {0} و {1}."),
    max: jQuery.validator.format("الرجاء إدخال قيمة أقل من أو تساوي {0}."),
    min: jQuery.validator.format("الرجاء إدخال قيمة أكبر من أو تساوي {0}."),
    step: $.validator.format("الرجاء إدخال رقم يمثل أحد مضاعفات {0}.")
  },
  hr_HR: {
    required: "Ovo polje je obavezno.",
    remote: "Popravite ovo polje.",
    email: "Unesite važeću adresu e-pošte.",
    url: "Unesite važeći URL.",
    date: "Unesite važeći datum.",
    dateISO: "Unesite važeći datum (ISO).",
    number: "Unesite važeći broj.",
    digits: "Unesite samo znamenke.",
    equalTo: "Unesite istu vrijednost ponovo.",
    extension: "Unesite vrijednost s važećim produžetkom.",
    maxlength: jQuery.validator.format("Unesite najviše {0} znakova."),
    minlength: jQuery.validator.format("Unesite najmanje {0} znakova."),
    rangelength: jQuery.validator.format("Unesite vrijednost dužine između {0} i {1} znakova."),
    range: jQuery.validator.format("Unesite vrijednost dužine između {0} i {1}."),
    max: jQuery.validator.format("Unesite vrijednost manju ili jednaku {0}."),
    min: jQuery.validator.format("Unesite vrijednost veću ili jednaku {0}.")
  },
  el_GR: {
    required: "Αυτό το πεδίο απαιτείται.",
    remote: "Διορθώστε αυτό το πεδίο.",
    email: "Εισαγάγετε έγκυρη διεύθυνση email.",
    url: "Εισαγάγετε έγκυρο URL.",
    date: "Εισαγάγετε έγκυρη ημερομηνία.",
    dateISO: "Εισαγάγετε έγκυρη ημερομηνία (ISO).",
    number: "Εισαγάγετε έγκυρο αριθμό.",
    digits: "Πληκτρολογήστε μόνο ψηφία.",
    equalTo: "Εισαγάγετε την ίδια τιμή ξανά.",
    extension: "Εισαγάγετε τιμή με έγκυρη επέκταση.",
    maxlength: jQuery.validator.format("Εισαγάγετε μέχρι {0} χαρακτήρες."),
    minlength: jQuery.validator.format("Εισαγάγετε τουλάχιστον {0} χαρακτήρες."),
    rangelength: jQuery.validator.format("Εισαγάγετε μια τιμή μεταξύ {0} και {1} χαρακτήρων."),
    range: jQuery.validator.format("Εισαγάγετε μια τιμή μεταξύ {0} και {1}."),
    max: jQuery.validator.format("Εισαγάγετε μια τιμή μικρότερη ή ίση με {0}."),
    min: jQuery.validator.format("Εισαγάγετε μια τιμή μεγαλύτερη ή ίση με {0}."),
  },
  fi_FI: {
    required: 'Tämä kenttä on pakollinen.',
    remote: 'Korjaa tämä kenttä.',
    email: 'Syötä kelvollinen sähköpostiosoite.',
    url: 'Syötä kelvollinen URL.',
    date: 'Syötä kelvollinen päivämäärä.',
    dateISO: 'Syötä kelvollinen päivämäärä(ISO- formaatti).',
    number: 'Syötä kelvollinen numero.',
    digits: 'Syötä vain yksimerkkinen numero.',
    equalTo: '”Syötä sama arvo uudelleen.',
    extension: 'Syötä arvo ja kelvollinen jatko- osa.',
    maxlength: jQuery.validator.format('Syötä enintään {0} merkkiä.'),
    minlength: jQuery.validator.format('Syötä vähintään {0} merkkiä.'),
    rangelength: jQuery.validator.format('Syötä arvo, joka on {0}–{1} merkkiä pitkä.'),
    range: jQuery.validator.format('Syötä arvo, joka on välillä {0}–{1}.'),
    max: jQuery.validator.format('Syötä arvo, joka on korkeintaan {0}.'),
    min: jQuery.validator.format('Syötä arvo, joka on vähintään {0}.')
  },
  et_EE: {
    required: "See väli on kohustuslik.",
    remote: "Parandage see väli.",
    email: "Sisestage kehtiv e-posti aadress.",
    url: "Sisestage kehtiv URL.",
    date: "Sisestage kehtiv kuupäev.",
    dateISO: "Sisestage kehtiv kuupäev. (ISO)",
    number: "Sisestage kehtiv arv.",
    digits: "Sisestage ainult numbreid.",
    creditcard: "Palun sisestage kehtiv krediitkaardi number.",
    equalTo: "Sisestage sama väärtus uuesti.",
    extension: "Sisestage väärtus, millel on kehtiv laiend.",
    maxlength: jQuery.validator.format("Ärge sisestage üle {0} tähemärgi."),
    minlength: jQuery.validator.format("Sisestage vähemalt {0} tähemärki."),
    rangelength: jQuery.validator.format("Sisestage väärtus, mis jääb {0} ja {1} tähemärgi vahele."),
    range: jQuery.validator.format("Sisestage väärtus, mis jääb {0} ja {1} vahele."),
    max: jQuery.validator.format("Sisestage väärtus, mis on väiksem kui {0} või sellega võrdne."),
    min: jQuery.validator.format("Sisestage väärtus, mis on suurem kui {0} või sellega võrdne.")
  },

};

const errorContentStrings = {
  api_generic: {
    sv_SE: {
      header: "Oj! Nåt gick fel i vår ände",
      subHeader: "Det här var oväntat och vi ber om ursäkt om något gick förlorat.",
      suggestedAction: "Vänligen prova att starta om frågeformuläret:",
      ctaText: "Starta om frågeformulär",
      contact: "Om detta felet består, vänligen kontakta vårt team på 1-(855)-622-8748."
    },
    es_ES: {
      header: "¡Vaya! Algo salió mal por nuestra parte",
      subHeader: "Se trata de un error inesperado y nos disculpamos por cualquier progreso que haya perdido hasta este punto.",
      suggestedAction: "Le rogamos que intente reiniciar el cuestionario:",
      ctaText: "Reiniciar el cuestionario",
      contact: "Si el error persiste, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_MX: {
      header: "¡Vaya! Algo salió mal por nuestra parte",
      subHeader: "Fue un error inesperado y nos disculpamos por la pérdida de lo que avanzó hasta ahora.",
      suggestedAction: "Le rogamos que intente reiniciar el cuestionario:",
      ctaText: "Reiniciar el cuestionario",
      contact: "Si el error persiste, póngase en contacto con nuestro equipo al teléfono 1-(855)-622-8748."
    },
    es_US: {
      header: "¡Vaya! Algo salió mal por nuestra parte",
      subHeader: "Fue un error inesperado y nos disculpamos por la pérdida de lo que avanzó hasta ahora.",
      suggestedAction: "Le rogamos que intente reiniciar el cuestionario:",
      ctaText: "Reiniciar el cuestionario",
      contact: "Si el error persiste, póngase en contacto con nuestro equipo al teléfono 1-(833) 211-3611."
    },
    es_CO: {
      header: "¡Vaya! Algo salió mal por nuestra parte",
      subHeader: "Se trata de un error inesperado y nos disculpamos por cualquier progreso que haya perdido hasta este punto.",
      suggestedAction: "Por favor, intente reiniciar el cuestionario:",
      ctaText: "Reiniciar el cuestionario",
      contact: "Si el error persiste, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_AR: {
      header: "¡Disculpe! Tuvimos un problema.",
      subHeader: "Fue algo inesperado y nos disculpamos por cualquier progreso que haya perdido hasta ahora.",
      suggestedAction: "Intente reiniciar el cuestionario:",
      ctaText: "Reiniciar el cuestionario",
      contact: "Si este error persiste, póngase en contacto con nuestro equipo llamando al 1-(855)-622-8748."
    },
    en_NZ: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    ru_UA: {
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Просим прощения за непредвиденные сложности и за возможную потерю введенных данных.",
      suggestedAction: "Пожалуйста, попробуйте заново начать заполнение анкеты:",
      ctaText: "Начать заполнение анкеты снова",
      contact: "Если эта ошибка повторится, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_LV: {
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Просим прощения за непредвиденные сложности и за возможную потерю введенных данных.",
      suggestedAction: "Пожалуйста, попробуйте заново начать заполнение анкеты:",
      ctaText: "Начать заполнение анкеты снова",
      contact: "Если эта ошибка повторится, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_LT: {
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Просим прощения за непредвиденные сложности и за возможную потерю введенных данных.",
      suggestedAction: "Пожалуйста, попробуйте заново начать заполнение анкеты:",
      ctaText: "Начать заполнение анкеты снова",
      contact: "Если эта ошибка повторится, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_IL: {
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Просим прощения за непредвиденные сложности и за возможную потерю введенных данных.",
      suggestedAction: "Пожалуйста, попробуйте заново начать заполнение анкеты:",
      ctaText: "Начать заполнение анкеты снова",
      contact: "Если эта ошибка повторится, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    pt_BR: {
      header: "Epa! Algo deu errado do nosso lado",
      subHeader: "Isso foi inesperado. Lamentamos qualquer progresso perdido.",
      suggestedAction: "Tente reiniciar o questionário:",
      ctaText: "Reiniciar o questionário",
      contact: "Caso este erro persista, contate nossa equipe no número 1-(855)-622-8748."
    },
    lv_LV: {
      header: "Ak vai! Mūsu pusē radās kļūda",
      subHeader: "Tas bija negaidīti, un mēs atvainojamies par zaudēto darbu.",
      suggestedAction: "Mēģiniet restartēt aptaujas anketu:",
      ctaText: "Restartēt anketu",
      contact: "Ja šī kļūda netiek novērsta, sazinieties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748."
    },
    ja_JP: {
      header: "オット！こちら側で何か間違いが起こりました",
      subHeader: "これは予想外で、これまでの進展が失われたことにお詫び申し上げます。",
      suggestedAction: "質問票の再開を試みてください: ",
      ctaText: "質問票の再開",
      contact: "このエラーが続く場合は、弊社のチームに +1 (855) 622-8748 までご連絡ください。"
    },
    de_AT: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Dies war nicht vorgesehen und wir bitten um Entschuldigung für allfällig verlorengegangene Informationen.",
      suggestedAction: "Bitte versuchen Sie, den Fragebogen erneut zu starten:",
      ctaText: "Fragebogen erneut starten",
      contact: "Falls der Fehler weiter besteht, wenden Sie sich bitte an unser Team unter 1-(855)-622-8748.",
    },
    fr_CA: {
      header: "Oh! Oh! Quelque chose s'est mal passé de notre côté",
      subHeader: "Ce n'était pas prévu et nous nous excusons pour toute progression perdue jusqu'à présent.",
      suggestedAction: "Veuillez essayer de recommencer le questionnaire :",
      ctaText: "Recommencer le questionnaire",
      contact: "Si cette erreur persiste, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    fr_FR: {
      header: "Oh! Oh! Quelque chose s'est mal passé de notre côté",
      subHeader: "Ce n'était pas prévu et nous nous excusons pour toute progression perdue jusqu'à présent.",
      suggestedAction: "Veuillez essayer de recommencer le questionnaire :",
      ctaText: "Recommencer le questionnaire",
      contact: "Si cette erreur persiste, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    fr_BE: {
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté",
      subHeader: "Ce n'était pas prévu et nous nous excusons pour toute progression perdue jusqu'à présent.",
      suggestedAction: "Veuillez essayer de recommencer le questionnaire :",
      ctaText: "Recommencer le questionnaire",
      contact: "Si cette erreur persiste, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    en_CA: {
      header: "Uh oh! Something went wrong at our end",
      subHeader: "This was unexpected and we apologize for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team on +1-(855)-622-8748."
    },
    en_AU: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    lt_LT: {
      header: "Vaje! Pas mus kažkas ne taip",
      subHeader: "Netikėta, apgailestaujame, jeigu praradote užpildytus duomenis.",
      suggestedAction: "Bandykite pradėti anketą iš naujo:",
      ctaText: "Pradėti anketą iš naujo",
      contact: "Jei klaida išlieka, kreipkitės į mūsų komandą paskambinę 1-(855)-622-8748."
    },
    sk_SK: {
      header: "Pozor! Niečo sa pokazilo u nás",
      subHeader: "Toto bolo neočakávané a my sa ospravedlňujeme, ak ste prišli o čas.",
      suggestedAction: "Skúste prosím znovu začať dotazník:",
      ctaText: "Znovu začať dotazník",
      contact: "Ak chyba pretrváva, kontaktujte našich pracovníkov na tel. č. +1 (855) 622-8748."
    },
    nl_NL: {
      header: "Oeps! Er ging iets fout bij ons",
      subHeader: "Dit was onverwacht en excuses voor eventueel verlies van voortgang tot nu toe.",
      suggestedAction: "Probeer de vragenlijst opnieuw op te starten:",
      ctaText: "Vragenlijst opnieuw opstarten",
      contact: "Als dit probleem zich blijft voordoen, neem dan contact op met ons team op 1-(855)-622-8748."
    },
    nl_BE: {
      header: "Oeps! Er ging iets fout bij ons",
      subHeader: "Dit was onverwacht en excuses voor eventueel verlies van voortgang tot nu toe.",
      suggestedAction: "Probeer de vragenlijst opnieuw op te starten:",
      ctaText: "Vragenlijst opnieuw opstarten",
      contact: "Als dit probleem zich blijft voordoen, neem dan contact op met ons team op 1-(855)-622-8748."
    },
    es_CL: {
      header: "¡Oh no! Algo anduvo mal de nuestro lado",
      subHeader: "Esto fue inesperado y pedimos disculpas si perdió lo avanzado hasta ahora.",
      suggestedAction: "Intente comenzar de nuevo el cuestionario:",
      ctaText: "Comenzar de nuevo el cuestionario",
      contact: "Si este error continúa, pónganse en contacto con nuestro equipo al +1 (855) 622-8748."
    },
    en_US: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    en_GB: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    en_IE: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    da_DK: {
      header: "Hovsa! Der opstod en fejl hos os",
      subHeader: "Det var uventet, og vi undskylder, hvis du har mistet data.",
      suggestedAction: "Prøv at genstarte spørgeskemaet:",
      ctaText: "Genstart spørgeskemaet",
      contact: "Hvis du bliver ved med at se denne fejl, bedes du kontakte vores team på 1-(855)-622-8748."
    },
    de_DE: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Dies war nicht vorgesehen und wir bitten um Entschuldigung für allfällig verlorengegangene Informationen.",
      suggestedAction: "Bitte versuchen Sie, den Fragebogen erneut zu starten:",
      ctaText: "Fragebogen erneut starten",
      contact: "Falls der Fehler weiter besteht, wenden Sie sich bitte an unser Team unter 0341 94679992."
    },
    pl_PL: {
      header: "Ups! Coś poszło nie tak po naszej stronie...",
      subHeader: "To nieoczekiwana sytuacja — przepraszamy za utracone do tej pory postępy.",
      suggestedAction: "Prosimy spróbować ponownie uruchomić kwestionariusz:",
      ctaText: "Reset kwestionariusza",
      contact: "Jeśli błąd ten się powtarza, prosimy o kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    //afrikaans
    en_ZA: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "This was unexpected and we apologise for any lost progress so far.",
      suggestedAction: "Please try restarting the questionnaire:",
      ctaText: "Restart questionnaire",
      contact: "If this error persists, please contact our team at 27 (21) 2022983."
    },
    af_ZA: {
      header: "Oeps! Iets het aan ons kant verkeerd geloop",
      subHeader: "Dit was onverwags en ons vra om verskoning vir enige verlore vordering tot dusver.",
      suggestedAction: "Probeer asseblief om die vraelys te herbegin:",
      ctaText: "Herbegin vraelys",
      contact: "As hierdie fout voortduur, kontak asseblief ons span by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Hawu! Kukhona okungahambanga kahle ohlangothini lwethu",
      subHeader: "Lokhu bekungalindelekile futhi siyaxolisa nganoma iyiphi inqubekela phambili elahlekile kuze kube manje.",
      suggestedAction: "Sicela uzame ukuqala kabusha uhlu lwemibuzo:",
      ctaText: "Qala kabusha uhlu lwemibuzo",
      contact: "Uma ngabe leli phutha liphikelela, sicela uthinte ithimba lethu ku-27 (21) 2022983."
    },
    hu_HU: {
      header: "Ó, jaj! Valamit elrontottunk.",
      subHeader: "Váratlan hiba történt, és elnézést kérünk, hogy elvesztek az eddig bevitt adatok.",
      suggestedAction: "Kérjük, próbálja meg újra a kérdőív kitöltését.",
      ctaText: "Kérdőív újrakezdése",
      contact: "Ha a hiba továbbra is fennáll, hívja kollégáinkat ezen a számon: 36 1 881 26 77."
    },
    uk_UA: {
      header: "Ой! В нас щось пішло не так",
      subHeader: "Це відбулося зненацька, і ми просимо пробачення, що Ваші дані втрачено.",
      suggestedAction: "Будь ласка, постарайтеся запустити опитувач знову:",
      ctaText: "Запустити опитувач знову",
      contact: "Якщо ця помилка буде повторюватися, зверніться, будь ласка, до нашої підтримки за номером 38 094 7104843."
    },
    cs_CZ: {
      header: "A jé! Na naší straně je nějaká chyba",
      subHeader: "To je nečekané, omlouváme se za ztrátu dosavadního pokroku ve vyplňování.",
      suggestedAction: "Zkuste prosím restartovat dotazník:",
      ctaText: "Restartujte dotazník",
      contact: "Pokud tato chyba přetrvává, kontaktujte náš tým na telefonním čísle 234 092 144."
    },
    bg_BG: {
      header: "Грешка! При нас възникна проблем",
      subHeader: "Не го очаквахме и се извиняваме за изгубения напредък до момента.",
      suggestedAction: "Моля, опитайте да заредите въпросника отново:",
      ctaText: "Повторно зареждане на въпросника",
      contact: "Ако грешката се повтори, свържете се нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      header: "אופס! קרתה תקלה אצלנו",
      subHeader: "התקלה בלתי צפויה ואנו מתנצלים על אובדן מידע שמילאת עד כה.",
      suggestedAction: "אנא נסה להתחיל שנית את השאלון:",
      ctaText: "להתחיל שאלון מחדש",
      contact: "אם התקלה נמשכת, אנא צור קשר עם הצוות שלנו בטלפון 1-(855)- 622-8748."
    },
    it_IT: {
      header: "Ops! Qualcosa è andato storto da parte nostra",
      subHeader: "Questo non era previsto e ci scusiamo per qualsiasi progresso perduto finora.",
      suggestedAction: "Provi a riavviare il questionario:",
      ctaText: "Riavviare il questionario",
      contact: "Se questo errore persiste, si prega di contattare il nostro team al numero 1 (855) -622-8748."
    },
    ro_RO: {
      header: "Ups! Ceva nu a funcționat bine de partea noastră",
      subHeader: "Acest lucru a fost neprevăzut și ne cerem scuze pentru orice progres pierdut până în prezent.",
      suggestedAction: "Încercați să lansați din nou chestionarul:",
      ctaText: "Lansați din nou chestionarul",
      contact: "Dacă această eroare persistă, contactați echipa noastră la numărul de telefon 1-(855)-622-8748."
    },
    pt_PT: {
      header: "Ups! Algo não correu bem deste lado",
      subHeader: "Foi um erro inesperado e pedimos desculpa por eventuais perdas de informação inserida.",
      suggestedAction: "Tente recomeçar o questionário:",
      ctaText: "Recomeçar o questionário",
      contact: "Se este erro persistir, contacte a nossa equipa pelo 1-(855)-622-8748.",
    },
    ar_IL: {
      header: "اه اوه! حدث خطأ ما من جانبنا",
      subHeader: "كان هذا غير متوقع ونعتذر عن أي تقدم ضائع حتى الآن.",
      suggestedAction: "يرجى محاولة إعادة تشغيل الاستبيان:",
      ctaText: "أعد تشغيل الاستبيان",
      contact: "إذا استمر هذا الخطأ ، يرجى الاتصال بفريقنا على 1- (855) -622-8748."
    },
    hr_HR: {
      header: "O ne! Nešto je pošlo po zlu kod nas",
      subHeader: "To je bilo neočekivano i ispričavamo se zbog dosadašnjeg nepostignutog napretka.",
      suggestedAction: "Pokušajte ponovno započeti upitnik:",
      ctaText: "Ponovno započnite upitnik.",
      contact: "Ako se ova pogreška i dalje pojavljuje, kontaktirajte naš tim na 1-(855)-622-8748.",
      header: "O ne! Nešto je pošlo po zlu kod nas",
      subHeader: "Vratite se i pokušajte opet:",
      ctaText: "Vratite se",
      contact: "Ako se ova pogreška nastavi, možete upotrijebiti gumb u nastavku da ponovno započnete upitnik:",
      cta2Text: "Ponovno započnite upitnik.",
      contactNumber: "Možete kontaktirati i naš tim i na 1-(855)-622-8748.",
    },
    el_GR: {
      header: "Ωχ όχι! Κάτι πήγε λάθος από πλευράς μας",
      subHeader: "Ήταν κάτι μη αναμενόμενο και λυπούμαστε αν κάτι χάθηκε.",
      suggestedAction: "Δοκιμάστε να ξεκινήσετε το ερωτηματολόγιο από την αρχή:",
      ctaText: "Επανέναρξη ερωτηματολογίου",
      contact: "Αν το πρόβλημα παραμένει, επικοινωνήστε με την ομάδα μας στο 1-(855)-622-8748.",
    },
    fl_FL: {
      header: "Hups! Järjestelmässämme tapahtui jokin virhe.",
      subHeader: "Olemme pahoillamme, jos menetit jo antamasi vastaukset.",
      suggestedAction: "Kokeile käynnistää kysely uudelleen:",
      ctaText: "Aloita kysely uudelleen",
      contact: "Jos virhe jatkuu, ota yhteys tiimiimme numeroon 1 (855) -622-8748."
    },
    et_EE: {
      header: "Oih! Meil läks midagi valesti",
      subHeader: "See oli ootamatu. Vabandage meid, et seni sisestatud teave kaotsi läks.",
      suggestedAction: "Proovige küsimustik taaskäivitada:",
      ctaText: "Taaskäivita küsimustik",
      contact: "Kui see viga ei kao, võtke ühendust meie meeskonnaga numbril 1 (855) 622-8748."
    }

  },
  gnq_generic: {
    sv_SE: {
      ctaText: "Gå tillbaka",
      header: "Oj! Nåt gick fel i vår ände",
      cta2Text: "Starta om frågeformulär",
      subHeader: "Vänligen gå tillbaka och försök igen:",
      contact: "Består felet, kan du använda knappen nedan för att starta om frågeformuläret:",
      contactNumber: "Du kan också kontakta vårt team på 1-(855)-622-8748."
    },
    es_ES: {
      ctaText: "Atrás",
      header: "¡Vaya! Algo salió mal por nuestra parte",
      cta2Text: "Reiniciar el cuestionario",
      subHeader: "Le rogamos que lo vuelva a intentar:",
      contact: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contactNumber: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_MX: {
      ctaText: "Atrás",
      header: "¡Vaya! Algo salió mal por nuestra parte",
      cta2Text: "Reiniciar el cuestionario",
      subHeader: "Le rogamos que lo vuelva a intentar:",
      contact: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contactNumber: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(855)-622-8748."
    },
    es_US: {
      ctaText: "Atrás",
      header: "¡Vaya! Algo salió mal por nuestra parte",
      cta2Text: "Reiniciar el cuestionario",
      subHeader: "Le rogamos que lo vuelva a intentar:",
      contact: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contactNumber: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(833) 211-3611."
    },
    es_CO: {
      ctaText: "Atrás",
      header: "¡Vaya! Algo salió mal por nuestra parte",
      cta2Text: "Reiniciar el cuestionario",
      subHeader: "Por favor, vuelva atrás e inténtelo de nuevo:",
      contact: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contactNumber: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_AR: {
      ctaText: "Atrás",
      header: "¡Disculpe! Tuvimos un problema.",
      cta2Text: "Reiniciar el cuestionario",
      subHeader: "Le rogamos que lo vuelva a intentar:",
      contact: "Si este error persiste, puede usar el botón que aparece abajo para reiniciar el cuestionario:",
      contactNumber: "También puede ponerse en contacto con nuestro equipo llamando al 1-(855)-622-8748."
    },
    ru_UA: {
      ctaText: "Назад",
      header: "Ой! У нас что-то пошло не так.",
      cta2Text: "Начать заполнение анкеты снова",
      subHeader: "Пожалуйста, вернитесь назад и попробуйте выполнить действие еще раз:",
      contact: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contactNumber: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_LV: {
      ctaText: "Назад",
      header: "Ой! У нас что-то пошло не так.",
      cta2Text: "Начать заполнение анкеты снова",
      subHeader: "Пожалуйста, вернитесь назад и попробуйте выполнить действие еще раз:",
      contact: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contactNumber: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_LT: {
      ctaText: "Назад",
      header: "Ой! У нас что-то пошло не так.",
      cta2Text: "Начать заполнение анкеты снова",
      subHeader: "Пожалуйста, вернитесь назад и попробуйте выполнить действие еще раз:",
      contact: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contactNumber: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    ru_IL: {
      ctaText: "Назад",
      header: "Ой! У нас что-то пошло не так.",
      cta2Text: "Начать заполнение анкеты снова",
      subHeader: "Пожалуйста, вернитесь назад и попробуйте выполнить действие еще раз:",
      contact: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contactNumber: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748."
    },
    pt_BR: {
      ctaText: "Retornar",
      header: "Epa! Algo deu errado do nosso lado",
      cta2Text: "Reiniciar o questionário",
      subHeader: "Retorne e tente novamente:",
      contact: "Caso este erro persista, você pode usar o botão abaixo para reiniciar o questionário:",
      contactNumber: "Você também pode contatar nossa equipe no número 1-(855)-622-8748."
    },
    lv_LV: {
      ctaText: "Atpakaļ",
      header: "Ak vai! Mūsu pusē radās kļūda",
      cta2Text: "Restartēt anketu",
      subHeader: "Lūdzu, atgriezieties un mēģiniet vēlreiz:",
      contact: "Ja šī kļūda netiek novērsta, varat izmantot pogu zemāk, lai restartētu anketu:",
      contactNumber: "Varat arī sazināties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748."
    },
    ja_JP: {
      ctaText: "戻る",
      header: "オット！こちら側で何か間違いが起こりました",
      cta2Text: "質問票の再開",
      subHeader: "戻って再度お試しください: ",
      contact: "このエラーが続く場合は、質問票を再開するために、下のボタンを使うことができます: ",
      contactNumber: "また弊社のチームに 1-(855)-622-8748 まで連絡することもできます。"
    },
    de_AT: {
      ctaText: "Zurück",
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      cta2Text: "Fragebogen erneut starten",
      subHeader: "Bitte gehen Sie zurück und versuchen Sie es nochmals:",
      contact: "Falls dieser Fehler weiter besteht, können Sie auf die Schaltfläche unten klicken, um den Fragebogen erneut zu starten:",
      contactNumber: "Sie können sich auch an unser Team unter 1-(855)-622-8748 wenden."
    },
    fr_CA: {
      ctaText: "Revenir en arrière",
      header: "Oh! Oh! Quelque chose s'est mal passé de notre côté",
      cta2Text: "Recommencer le questionnaire",
      subHeader: "Veuillez revenir en arrière et réessayer :",
      contact: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contactNumber: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748."
    },
    fr_FR: {
      ctaText: "Revenir en arrière",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté",
      cta2Text: "Recommencer le questionnaire",
      subHeader: "Veuillez revenir en arrière et réessayer :",
      contact: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contactNumber: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748."
    },
    fr_BE: {
      ctaText: "Revenir en arrière",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté",
      cta2Text: "Recommencer le questionnaire",
      subHeader: "Veuillez revenir en arrière et réessayer :",
      contact: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contactNumber: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748."
    },
    en_NZ: {
      ctaText: "Go back",
      header: "Uh oh! Something went wrong on our end",
      cta2Text: "Restart questionnaire",
      subHeader: "Please go back and try again:",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      contactNumber: "You can also contact our team at 1-(855)-622-8748."
    },
    sk_SK: {
      ctaText: "Vrátiť sa späť",
      header: "Pozor! Niečo sa pokazilo u nás",
      cta2Text: "Znovu začať dotazník",
      subHeader: "Vráťte sa späť a skúste to znovu:",
      contact: "Ak táto chyba pretrváva, môžete použiť nasledujúce tlačidlo a dotazník reštartovať:",
      contactNumber: "Môžete tiež kontaktovať náš tím na tel. č. 1-(855)-622-8748."
    },
    lt_LT: {
      ctaText: "Grįžti",
      header: "Vaje! Pas mus kažkas ne taip",
      cta2Text: "Pradėti anketą iš naujo",
      subHeader: "Grįžkite ir bandykite dar kartą:",
      contact: "Jeigu klaida išlieka, žemiau esančiu mygtuku galite anketą pradėti iš naujo:",
      contactNumber: "Arba galite kreiptis į mūsų komandą paskambinę 1-(855)-622-8748."
    },
    en_CA: {
      ctaText: "Go back",
      header: "Uh oh! Something went wrong at our end",
      cta2Text: "Restart questionnaire",
      subHeader: "Please go back and try again:",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      contactNumber: "You can also contact our team on +1-(855)-622-8748."
    },
    en_AU: {
      ctaText: "Go back",
      header: "Uh oh! Something went wrong on our end",
      cta2Text: "Restart questionnaire",
      subHeader: "Please go back and try again:",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      contactNumber: "You can also contact our team at 1-(855)-622-8748."
    },
    nl_NL: {
      ctaText: "Ga terug",
      header: "Oeps! Er ging iets fout bij ons",
      cta2Text: "Vragenlijst opnieuw opstarten",
      subHeader: "Ga terug en probeer het opnieuw:",
      contact: "Als dit probleem zich blijft voordoen kunt u de onderstaande knop gebruiken om de vragenlijst opnieuw op te starten:",
      contactNumber: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748."
    },
    nl_BE: {
      ctaText: "Ga terug",
      header: "Oeps! Er ging iets fout bij ons",
      cta2Text: "Vragenlijst opnieuw opstarten",
      subHeader: "Ga terug en probeer het opnieuw:",
      contact: "Als dit probleem zich blijft voordoen kunt u de onderstaande knop gebruiken om de vragenlijst opnieuw op te starten:",
      contactNumber: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748."
    },

    es_CL: {
      header: "¡Oh no! Algo anduvo mal de nuestro lado",
      subHeader: "Vuelva e inténtelo de nuevo:",
      ctaText: "Volver",
      contact: "Si el error continúa, puede usar el botón a continuación para comenzar de nuevo el cuestionario:",
      cta2Text: "Comenzar de nuevo el cuestionario",
      contactNumber: "También puede ponerse en contacto con nuestro equipo al +1 (855) 622-8748."
    },
    en_US: {  //USA
      header: "Uh oh! Something went wrong on our end",
      subHeader: "Please go back and try again:",
      ctaText: "Go back",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      cta2Text: "Restart questionnaire",
      contactNumber: "You can also contact our team at 1-(855)-622-8748."
    },
    en_GB: {  //great britain
      header: "Uh oh! Something went wrong on our end",
      subHeader: "Please go back and try again:",
      ctaText: "Go back",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      cta2Text: "Restart questionnaire",
      contactNumber: "You can also contact our team at 1-(855)-622-8748."
    },
    en_IE: {  //great britain
      header: "Uh oh! Something went wrong on our end",
      subHeader: "Please go back and try again:",
      ctaText: "Go back",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      cta2Text: "Restart questionnaire",
      contactNumber: "You can also contact our team at 1-(855)-622-8748."
    },
    da_DK: {
      header: "Hovsa! Der opstod en fejl hos os",
      subHeader: "Gå tilbage og prøv igen:",
      ctaText: "Gå tilbage",
      contact: "Hvis du bliver ved med at se denne fejl, kan du bruge knappen nedenunder for at genstarte spørgeskemaet:",
      cta2Text: "Genstart spørgeskemaet",
      contactNumber: "Du kan også kontakte vores team på 1-(855)-622-8748."
    },
    de_DE: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Bitte gehen Sie zurück und versuchen Sie es nochmals:",
      ctaText: "Zurück",
      contact: "Falls dieser Fehler weiter besteht, können Sie auf die Schaltfläche unten klicken um den Fragebogen erneut zu starten:",
      cta2Text: "Fragebogen erneut starten",
      contactNumber: "Sie können sich auch an unser Team unter 0341 94679992 wenden."
    },
    pl_PL: {
      header: "Ups! Coś poszło nie tak po naszej stronie...",
      subHeader: "Prosimy wrócić i spróbować ponownie:",
      ctaText: "Wróć",
      contact: "Jeśli błąd ten się powtarza, za pomocą poniższego przycisku można ponownie uruchomić kwestionariusz:",
      cta2Text: "Reset kwestionariusza",
      contactNumber: "Możliwy jest też kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    en_ZA: {
      header: "Uh oh! Something went wrong on our end",
      subHeader: "Please go back and try again:",
      ctaText: "Go back",
      contact: "If this error persists, you can use the button below to restart the questionnaire:",
      cta2Text: "Restart questionnaire",
      contactNumber: "You can also contact our team at 27 (21) 2022983."
    },
    af_ZA: {
      header: "Oeps! Iets het aan ons kant verkeerd geloop",
      subHeader: "Gaan asseblief terug en probeer weer:",
      ctaText: "Gaan terug",
      contact: "As hierdie fout voortduur, kan jy die onderstaande knoppie gebruik om die vraelys te herbegin:",
      cta2Text: "Herbegin vraelys",
      contactNumber: "Jy kan ook ons span kontak by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Hawu! Kukhona okungahambanga kahle ohlangothini lwethu",
      subHeader: "Sicela ubuyele emuva bese uzama futhi:",
      ctaText: "Buyela emuva",
      contact: "Uma leli phutha liphikelela, ungasebenzisa inkinobho engezansi ukuqala kabusha uhlu lwemibuzo:",
      cta2Text: "Qala kabusha uhlu lwemibuzo",
      contactNumber: "Ungakwazi futhi ukuthintana nethimba lethu ku-27 (21) 2022983."
    },
    hu_HU: {
      header: "Ó, jaj! Valamit elrontottunk.",
      subHeader: "Kérjük, lépjen vissza, és próbálja újra:",
      ctaText: "Visszalépek",
      contact: "Ha a hiba továbbra is fennáll, nyomja meg a lenti gombot a kérdőív újrakezdéséhez.",
      cta2Text: "Kérdőív újrakezdése",
      contactNumber: "Kollégáinkat is felhívhatja ezen a számon: 36 1 881 26 77."
    },
    uk_UA: {
      header: "Ой! В нас щось пішло не так",
      subHeader: "Будь ласка, поверніться й повторить спробу ще раз:",
      ctaText: "Повернутися",
      contact: "Якщо ця помилка буде повторюватися, Ви можете скористатися кнопкою нижче, щоб запустити опитувач знову:",
      cta2Text: "Запустити опитувач знову",
      contactNumber: "Ви можете також звернутися до нашої підтримки за номером 38 094 7104843."
    },
    cs_CZ: {
      header: "A jé! Na naší straně je nějaká chyba",
      subHeader: "Vraťte se a zkuste to znovu:",
      ctaText: "Vraťte se",
      contact: "Pokud tato chyba přetrvává, můžete pomocí níže uvedeného tlačítka dotazník restartovat:",
      cta2Text: "Restartujte dotazník",
      contactNumber: "Můžete nás rovněž kontaktovat na telefonním čísle 234 092 144."
    },
    bg_BG: {
      header: "Грешка! При нас възникна проблем",
      subHeader: "Моля да се върнете назад и да опитате отново:",
      ctaText: "Назад",
      contact: "Ако грешката се повтори, използвайте бутона по-долу, за да заредите въпросника отново:",
      cta2Text: "Повторно зареждане на въпросника",
      contactNumber: "Можете също да се свържете с нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      header: "אופס! קרתה תקלה אצלנו",
      subHeader: "אנא חזור ונסה שנית:",
      ctaText: "חזרה",
      contact: "אם התקלה נמשכת, תוכל להשתמש בכפתור מטה כדי להתחיל מחדש את השאלון:",
      cta2Text: "להתחיל שאלון מחדש",
      contactNumber: "תוכל גם ליצור קשר עם הצוות שלנו במספר טלפון 1-(855)-622-8748."
    },
    it_IT: {
      header: "Ops! Qualcosa è andato storto da parte nostra",
      subHeader: "Per favore, tornare indietro e riprovare:",
      ctaText: "Torna indietro",
      contact: "Se questo errore persiste, è possibile utilizzare il pulsante qui sotto per riavviare il questionario:",
      cta2Text: "Riavviare il questionario",
      contactNumber: "Può anche contattare il nostro team al numero 1 (855) -622-8748."
    },
    ro_RO: {
      header: "Ups! Ceva nu a funcționat bine de partea noastră",
      subHeader: "Reveniți la pagina anterioară și încercați din nou:",
      ctaText: "Înapoi",
      contact: "Dacă această eroare persistă, puteți utiliza butonul de mai jos pentru a lansa din nou chestionarul:",
      cta2Text: "Lansați din nou chestionarul",
      contactNumber: "De asemenea, puteți contacta echipa la numărul de telefon 1-(855)-622-8748."
    },
    pt_PT: {
      header: "Ups! Algo não correu bem deste lado",
      subHeader: "Clique em “voltar' e tente novamente:",
      ctaText: "Voltar",
      contact: "Se este erro persistir, pode utilizar o botão abaixo para recomeçar o questionário:",
      cta2Text: "Recomeçar o questionário",
      contactNumber: "Também pode contactar a nossa equipa pelo 1-(855)-622-8748.",
    },
    ar_IL: {
      header: "اه اوه! حدث خطأ ما من جانبنا",
      subHeader: "الرجاء العودة والمحاولة مجددا:",
      ctaText: "عد",
      contact: "إذا استمر هذا الخطأ ، يمكنك استخدام الزر أدناه لإعادة تشغيل الاستبيان:",
      cta2Text: "أعد تشغيل الاستبيان",
      contactNumber: "يمكنك أيضًا الاتصال بفريقنا على 1- (855) -622-8748."
    },
    el_GR: {
      header: "Ωχ όχι! Κάτι πήγε λάθος από πλευράς μας",
      subHeader: "Πηγαίνετε πίσω και δοκιμάστε ξανά:",
      ctaText: "Επιστροφή",
      contact: "Αν το πρόβλημα παραμένει, μπορείτε να χρησιμοποιήσετε το παρακάτω κουμπί για να ξεκινήσετε από την αρχή το ερωτηματολόγιο:",
      cta2Text: "Επανέναρξη ερωτηματολογίου",
      contactNumber: "Μπορείτε επίσης να επικοινωνήσετε με την ομάδα μας στο 1-(855)-622-8748.",
    },
    fl_FL: {
      header: "Hups! Järjestelmässämme tapahtui jokin virhe.",
      subHeader: "Palaa takaisin ja kokeile uudelleen:",
      ctaText: "Palaa takaisin",
      contact: "Jos virhe jatkuu, voit käynnistää kyselyn uudelleen alla olevasta painikkeesta:",
      cta2Text: "Aloita kysely uudelleen",
      contactNumber: "Voit myös ottaa yhteyttä tiimiimme numeroon 1 (855) -622-8748."
    },
    et_EE: {  //Estonia
      header: "Oih! Meil läks midagi valesti",
      subHeader: "Minge tagasi ja proovige uuesti",
      ctaText: "Mine tagasi",
      contact: "Kui see viga püsib, saate küsimustiku taaskäivitamiseks kasutada allolevat nuppu",
      cta2Text: "Taaskäivita küsimustik",
      contactNumber: "Võite ühendust võtta ka meie meeskonnaga telefonil 1-(855)-622-8748."
    }
  },
  token_expired: {
    sv_SE: {
      header: "Det här frågeformuläret har nått sin tidsgräns.",
      subHeader: "Vi sparar bara dina svar under en begränsad tid.",
      ctaText: "Använd knappen nedan för att starta ett nytt frågeformulär:",
      contact: "Starta nytt frågeformulär"
    },
    es_ES: {
      header: "Este cuestionario ha caducado.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      ctaText: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      contact: "Iniciar nuevo cuestionario"
    },
    es_MX: {
      header: "Este cuestionario ha caducado.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      ctaText: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      contact: "Iniciar nuevo cuestionario"
    },
    es_US: {
      header: "Este cuestionario ha caducado.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      ctaText: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      contact: "Iniciar nuevo cuestionario"
    },
    es_CO: {
      header: "Este cuestionario ha caducado.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      ctaText: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      contact: "Iniciar nuevo cuestionario"
    },
    es_AR: {
      header: "Este cuestionario se ha vencido.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      ctaText: "Use el botón que aparece abajo para iniciar un nuevo cuestionario:",
      contact: "Iniciar un nuevo cuestionario"
    },
    ru_UA: {
      header: "Время заполнения этой анкеты истекло.",
      subHeader: "Мы будем хранить введенные Вами данные только в течение ограниченного периода времени.",
      ctaText: "Воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      contact: "Начать заполнение новой анкеты"
    },
    ru_LV: {
      header: "Время заполнения этой анкеты истекло.",
      subHeader: "Мы будем хранить введенные Вами данные только в течение ограниченного периода времени.",
      ctaText: "Воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      contact: "Начать заполнение новой анкеты"
    },
    ru_LT: {
      header: "Время заполнения этой анкеты истекло.",
      subHeader: "Мы будем хранить введенные Вами данные только в течение ограниченного периода времени.",
      ctaText: "Воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      contact: "Начать заполнение новой анкеты"
    },
    ru_IL: {
      header: "Время заполнения этой анкеты истекло.",
      subHeader: "Мы будем хранить введенные Вами данные только в течение ограниченного периода времени.",
      ctaText: "Воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      contact: "Начать заполнение новой анкеты"
    },
    pt_BR: {
      header: "Este questionário expirou.",
      subHeader: "Salvamos seu progresso somente por um período limitado.",
      ctaText: "Use o botão abaixo para iniciar um novo questionário:",
      contact: "Iniciar novo questionário"
    },
    lv_LV: {
      header: "Anketas derīguma termiņš ir beidzies.",
      subHeader: "Mēs saglabājam jūsu veikto darbu tikai ierobežotu laiku.",
      ctaText: "Izmantojiet pogu zemāk, lai sāktu jaunu anketu:",
      contact: "Sākt jaunu anketu"
    },
    ja_JP: {
      header: "質問票は期限切れです。",
      subHeader: "これまでの進展を、限られた時間だけ保存します。",
      ctaText: "新しい質問票を開始するには、下のボタンを使います: ",
      contact: "新しい質問票を開始"
    },
    de_AT: {
      contact: "Fragebogen erneut starten",
      header: "Dieser Fragebogen ist nicht mehr verfügbar.",
      subHeader: "Wir können Ihre Eingaben nur für einen bestimmten Zeitraum speichern.",
      suggestedAction: "Verwenden Sie das Feld unten, um einen neuen Fragebogen zu starten:",
      ctaText: "Fragebogen erneut starten",
    },
    fr_CA: {
      header: "Ce questionnaire a expiré.",
      subHeader: "Nous ne sauvegardons votre progression que pour une durée limitée.",
      ctaText: "Utilisez le bouton ci-dessous pour commencer un nouveau questionnaire :",
      contact: "Commencer un nouveau questionnaire"
    },
    fr_FR: {
      header: "Ce questionnaire a expiré.",
      subHeader: "Nous ne sauvegardons votre progression que pour une durée limitée.",
      ctaText: "Utilisez le bouton ci-dessous pour commencer un nouveau questionnaire :",
      contact: "Commencer un nouveau questionnaire"
    },
    fr_BE: {
      header: "Ce questionnaire a expiré.",
      subHeader: "Nous ne sauvegardons votre progression que pour une durée limitée.",
      ctaText: "Utilisez le bouton ci-dessous pour commencer un nouveau questionnaire :",
      contact: "Commencer un nouveau questionnaire"
    },
    en_NZ: {
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      ctaText: "Use the button below to start a new questionnaire:",
      contact: "Start new questionnaire"
    },
    sk_SK: {
      header: "Tento dotazník vypršal.",
      subHeader: "Vaše odpovede ukladáme len na obmedzený čas.",
      ctaText: "Pomocou tlačidla nižšie začnete nový dotazník:",
      contact: "Začať nový dotazník"
    },
    lt_LT: {
      header: "Šios anketos laikas baigėsi.",
      subHeader: "Jūsų užpildytus punktus saugome ribotą laiką.",
      ctaText: "Žemiau esančiu mygtuku pradėkite naują anketą:",
      contact: "Pradėti naują anketą"
    },
    en_CA: {
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      ctaText: "Use the button below to start a new questionnaire:",
      contact: "Start new questionnaire"
    },
    en_AU: {
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      ctaText: "Use the button below to start a new questionnaire:",
      contact: "Start new questionnaire"
    },
    nl_NL: {
      header: "Deze vragenlijst is verlopen.",
      subHeader: "We slaan uw voortgang slechts een beperkte tijd op.",
      ctaText: "Gebruik onderstaande knop om een nieuwe vragenlijst te starten:",
      contact: "Nieuwe vragenlijst opstarten"
    },
    nl_BE: {
      header: "Deze vragenlijst is verlopen.",
      subHeader: "We slaan uw voortgang slechts een beperkte tijd op.",
      ctaText: "Gebruik onderstaande knop om een nieuwe vragenlijst te starten:",
      contact: "Nieuwe vragenlijst opstarten"
    },
    en_US: {
      header: "This questionnaire has expired.",
      subHeader: "This link is no longer valid. To continue, you must restart your questionnaire.",
      suggestedAction: "Use the button below to start a new questionnaire:",
      ctaText: "Start new questionnaire"
      //     contact: "If this error persists, please contact our team at 1-(855)-622-8748."
    },
    en_GB: {  //great britain
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      suggestedAction: "Use the button below to start a new questionnaire:",
      ctaText: "Start new questionnaire"
    },
    en_IE: {  //great britain
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      suggestedAction: "Use the button below to start a new questionnaire:",
      ctaText: "Start new questionnaire"
    },
    da_DK: {
      header: "Spørgeskemaet er udløbet.",
      subHeader: "Vi gemmer kun dine data i en begrænset periode.",
      suggestedAction: "Anvend knappen nedenunder til at starte et nyt spørgeskema:",
      ctaText: "Start et nyt spørgeskema"
    },
    de_DE: {
      header: "Dieser Fragebogen ist nicht mehr verfügbar.",
      subHeader: "Wir können Ihre Eingaben nur für einen bestimmten Zeitraum speichern.",
      suggestedAction: "Verwenden Sie das Feld unten, um einen neuen Fragebogen zu starten:",
      ctaText: "Fragebogen erneut starten"
    },
    pl_PL: {
      header: "Kwestionariusz wygasł.",
      subHeader: "Postęp zachowywany jest tylko przez określony czas.",
      suggestedAction: "Za pomocą poniższego przycisku można rozpocząć nowy kwestionariusz:",
      ctaText: "Rozpocznij nowy kwestionariusz",
    },
    es_CL: {
      header: "Este cuestionario ha caducado.",
      subHeader: "Solo guardamos su progreso durante un tiempo limitado.",
      suggestedAction: "Use el botón a continuación para comenzar un nuevo cuestionario.",
      ctaText: "Comenzar un nuevo cuestionario."
      //     contact: "Si este error continúa, pónganse en contacto con nuestro equipo al +1 (855)-622-8748."
    },
    en_ZA: {
      header: "This questionnaire has expired.",
      subHeader: "We only save your progress for a limited amount of time.",
      suggestedAction: "Use the button below to start a new questionnaire:",
      ctaText: "Start new questionnaire"
    },
    af_ZA: {
      header: "Hierdie vraelys het verval.",
      subHeader: "Ons stoor slegs jou vordering vir 'n beperkte tyd.",
      suggestedAction: "Gebruik die onderstaande knoppie om 'n nuwe vraelys te begin:",
      ctaText: "Begin nuwe vraelys"
    },
    zu_ZA: {
      header: "Lolu hlu lwemibuzo liphelelwe isikhathi.",
      subHeader: "Sigcina inqubekela phambili yakho isikhathi esilinganiselwe kuphela.",
      suggestedAction: "Sebenzisa inkinobho engezansi ukuqala uhlu lwemibuzo olusha:",
      ctaText: "Qala uhlu lwemibuzo olusha"
    },
    hu_HU: {
      header: "A kérdőív lejárt.",
      subHeader: "A bevitt adatokat csak korlátozott ideig tároljuk.",
      suggestedAction: "Nyomja meg a lenti gombot egy új kérdőív kitöltéséhez.",
      ctaText: "Új kérdőív kitöltése",
    },
    uk_UA: {
      header: "Цей опитувач прострочений.",
      subHeader: "Ми зберігаємо введені вами дані тільки протягом обмеженого часу.",
      suggestedAction: "Скористайтеся кнопкою нижче, щоб почати новий опитувач:",
      ctaText: "Почати новий опитувач",
    },
    cs_CZ: {
      header: "Platnost tohoto dotazníku vypršela.",
      subHeader: "Váš pokrok ve vyplňování ukládáme pouze po omezenou dobu.",
      suggestedAction: "Pomocí tlačítka dole můžete začít vyplňovat nový dotazník:",
      ctaText: "Začněte nový dotazník",
    },
    bg_BG: {
      header: "Срокът на въпросника е изтекъл.",
      subHeader: "Напредъкът Ви се запазва само за ограничен период от време.",
      suggestedAction: "Използвайте бутона по-долу, за да заредите нов въпросник:",
      ctaText: "Зареждане на нов въпросник",
    },
    iw_IL: {
      header: "פג תוקפו של השאלון.",
      subHeader: "המידע שמילאת נשמר לפרק זמן מוגבל בלבד.",
      ctaText: "לחץ על הכפתור מטה כדי להתחיל שאלון חדש:",
      contact: "להתחיל שאלון חדש"
    },
    it_IT: {
      header: "Questo questionario è scaduto.",
      subHeader: "Salviamo il Suo progresso solo per un periodo di tempo limitato.",
      suggestedAction: "Utilizzare il pulsante qui sotto per iniziare un nuovo questionario:",
      ctaText: "Avviare un nuovo questionario"
    },
    ro_RO: {
      header: "Acest chestionar a expirat.",
      subHeader: "Vă salvăm progresul o perioadă limitată de timp.",
      suggestedAction: "Utilizați butonul de mai jos pentru a lansa un chestionar nou:",
      ctaText: "Lansați un chestionar nou"
    },
    pt_PT: {
      header: "Este questionário expirou.",
      subHeader: "Apenas guardamos as informações inseridas durante um período limitado.",
      suggestedAction: "Utilize o botão abaixo para começar um novo questionário:",
      ctaText: "Começar um novo questionário",
    },
    ar_IL: {
      header: "لقد انتهت صلاحية هذا الاستبيان.",
      subHeader: "نحن نحفظ تقدمك فقط لفترة محدودة من الوقت.",
      suggestedAction: "استخدم الزر أدناه لبدء استبيان جديد:",
      ctaText: "ابدأ استبيان جديد"
    },
    hr_HR: {
      header: "Ovaj je upitnik istekao.",
      subHeader: "Vaš napredak čuvamo samo ograničeno vrijeme.",
      suggestedAction: "Upotrijebite gumb u nastavku da započnete novi upitnik:",
      ctaText: "Počnite novi upitnik",
    },
    el_GR: {
      header: "Το ερωτηματολόγιο αυτό έχει λήξει.",
      subHeader: "Η πρόοδός σας αποθηκεύεται μόνο για περιορισμένο χρόνο.",
      suggestedAction: "Χρησιμοποιήστε το παρακάτω κουμπί για να ξεκινήσετε ένα νέο ερωτηματολόγιο:",
      ctaText: "Έναρξη νέου ερωτηματολογίου",
    },
    fl_FL: {
      header: "Tämä kysely on vanhentunut.",
      subHeader: "Säilytämme täyttämäsi tiedot vain tietyn aikaa.",
      suggestedAction: "Aloita uusi kysely alla olevasta painikkeesta:",
      ctaText: "Aloita uusi kysely"
    },
    et_EE: {
      header: "See küsimustik on aegunud.",
      subHeader: "See link ei kehti enam. Jätkamiseks peate küsimustiku taaskäivitama.",
      suggestedAction: "Uue küsimustiku alustamiseks kasutage allolevat nuppu:",
      ctaText: "Alustage uut küsimustikku."
      //     contact: "Kui see viga ei kao, võtke ühendust meie meeskonnaga numbril 1 (855) 622-8748."
    }
  },
  token_invalid: {
    sv_SE: {
      header: "Ojdå!",
      subHeader: "Det ser ut som om du inte har behörighet att tillgå den här informationen.",
      suggestedAction: "Försök att starta ett nytt frågeformulär.",
      ctaText: "Starta nytt frågeformulär."
    },
    es_ES: {
      header: "¡Vaya!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Le rogamos que intente iniciar un nuevo cuestionario.",
      ctaText: "Iniciar un nuevo cuestionario."
    },
    es_MX: {
      header: "¡Vaya!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Le rogamos que intente iniciar un nuevo cuestionario.",
      ctaText: "Iniciar un nuevo cuestionario."
    },
    es_US: {
      header: "¡Vaya!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Le rogamos que intente iniciar un nuevo cuestionario.",
      ctaText: "Iniciar un nuevo cuestionario."
    },
    es_CO: {
      header: "¡Vaya!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Por favor, intente iniciar un nuevo cuestionario.",
      ctaText: "Iniciar un nuevo cuestionario."
    },
    es_AR: {
      header: "¡Uy!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Intente iniciar un nuevo cuestionario.",
      ctaText: "Iniciar un nuevo cuestionario."
    },
    ru_UA: {
      header: "Ой!",
      subHeader: "Похоже, что у Вас нет разрешения на доступ к этой информации.",
      suggestedAction: "Пожалуйста, попробуйте начать заполнение новой анкеты.",
      ctaText: "Начать заполнение новой анкеты."
    },
    ru_LV: {
      header: "Ой!",
      subHeader: "Похоже, что у Вас нет разрешения на доступ к этой информации.",
      suggestedAction: "Пожалуйста, попробуйте начать заполнение новой анкеты.",
      ctaText: "Начать заполнение новой анкеты."
    },
    ru_LT: {
      header: "Ой!",
      subHeader: "Похоже, что у Вас нет разрешения на доступ к этой информации.",
      suggestedAction: "Пожалуйста, попробуйте начать заполнение новой анкеты.",
      ctaText: "Начать заполнение новой анкеты."
    },
    ru_IL: {
      header: "Ой!",
      subHeader: "Похоже, что у Вас нет разрешения на доступ к этой информации.",
      suggestedAction: "Пожалуйста, попробуйте начать заполнение новой анкеты.",
      ctaText: "Начать заполнение новой анкеты."
    },
    pt_BR: {
      header: "Opa!",
      subHeader: "Parece que você não tem permissão para acessar essas informações.",
      suggestedAction: "Tente iniciar um novo questionário.",
      ctaText: "Iniciar novo questionário."
    },
    lv_LV: {
      header: "Ak vai!",
      subHeader: "Izskatās, ka jums nav atļauts piekļūt šai informācijai.",
      suggestedAction: "Mēģiniet sākt jaunu aptaujas anketu.",
      ctaText: "Sāciet jaunu anketu."
    },
    ja_JP: {
      header: "オット",
      subHeader: "この情報にアクセスする許可をお持ちでないようです",
      suggestedAction: "新しい質問票の開始を試みてください",
      ctaText: "新しい質問票の開始"
    },
    de_AT: {
      header: "Oops! ",
      subHeader: "Es scheint, dass Sie nicht berechtigt sind, auf diese Informationen zuzugreifen. ",
      suggestedAction: "Bitte starten Sie einen neuen Fragebogen. ",
      ctaText: "Neuen Fragebogen starten.",
    },
    fr_CA: {
      header: "Oups!",
      subHeader: "Il semblerait que vous n'ayez pas la permission d'accéder à ces renseignements.",
      suggestedAction: "Veuillez essayer de commencer un nouveau questionnaire.",
      ctaText: "Commencer un nouveau questionnaire."
    },
    fr_FR: {
      header: "Oups !",
      subHeader: "Il semblerait que vous n'ayez pas la permission d'accéder à ces informations.",
      suggestedAction: "Veuillez essayer de commencer un nouveau questionnaire.",
      ctaText: "Commencer un nouveau questionnaire."
    },
    fr_BE: {
      header: "Oups !",
      subHeader: "Il semblerait que vous n'ayez pas la permission d'accéder à ces informations.",
      suggestedAction: "Veuillez essayer de commencer un nouveau questionnaire.",
      ctaText: "Commencer un nouveau questionnaire."
    },
    en_NZ: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    sk_SK: {
      header: "Hopla!",
      subHeader: "Vyzerá to tak, že nemáte povolený prístup k týmto údajom.",
      suggestedAction: "Skúste znovu začať nový dotazník.",
      ctaText: "Začať nový dotazník."
    },
    lt_LT: {
      header: "Oi!",
      subHeader: "Atrodo, kad neturite leidimo prieigai prie šios informacijos.",
      suggestedAction: "Bandykite pradėti naują anketą",
      ctaText: "Pradėti naują anketą."
    },
    en_CA: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    en_AU: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    nl_NL: {
      header: "Oeps!",
      subHeader: "Het lijkt erop dat u geen toestemming heeft om deze informatie in te zien.",
      suggestedAction: "Probeer een nieuwe vragenlijst op te starten.",
      ctaText: "Een nieuwe vragenlijst opstarten."
    },
    nl_BE: {
      header: "Oeps!",
      subHeader: "Het lijkt erop dat u geen toestemming heeft om deze informatie in te zien.",
      suggestedAction: "Probeer een nieuwe vragenlijst op te starten.",
      ctaText: "Een nieuwe vragenlijst opstarten."
    },
    en_US: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    en_GB: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    en_IE: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    da_DK: {
      header: "Hovsa!",
      subHeader: "Det ser ud til, at du ikke har tilladelse til at få adgang til disse oplysninger.",
      suggestedAction: "Prøv at starte et nyt spørgeskema.",
      ctaText: "Start et nye spørgeskema."
    },
    de_DE: {
      header: "Oops!",
      subHeader: "Es scheint, dass Sie nicht berechtigt sind, auf diese Informationen zuzugreifen.",
      suggestedAction: "Bitte starten Sie einen neuen Fragebogen.",
      ctaText: "Neuen Fragebogen starten."
    },
    pl_PL: {
      header: "Ups!",
      subHeader: "Nie mamy zgody na dostęp do tych danych.",
      suggestedAction: "Prosimy spróbować rozpocząć nowy kwestionariusz.",
      ctaText: "Rozpocznij nowy kwestionariusz.",
    },
    en_ZA: {
      header: "Oops!",
      subHeader: "It looks like you don't have permission to access this information.",
      suggestedAction: "Please try starting a new questionnaire.",
      ctaText: "Start new questionnaire."
    },
    af_ZA: {
      header: "Oeps!",
      subHeader: "Dit lyk of jy nie toestemming het om toegang tot hierdie inligting te kry nie.",
      suggestedAction: "Probeer asseblief om 'n nuwe vraelys te begin.",
      ctaText: "Begin nuwe vraelys."
    },
    zu_ZA: {
      header: "Habe!",
      subHeader: "Kubukeka sengathi awunayo imvume yokuthola lolu lwazi.",
      suggestedAction: "Sicela uzame ukuqala uhlu lwemibuzo olusha.",
      ctaText: "Qala uhlu lwemibuzo olusha."
    },
    es_CL: {
      header: "¡Vaya!",
      subHeader: "Parece que no tiene permiso para acceder a esta información.",
      suggestedAction: "Intente comenzar de nuevo el cuestionario:",
      ctaText: "Comenzar un nuevo cuestionario."
    },
    hu_HU: {
      header: "Hoppá!",
      subHeader: "Úgy tűnik, hogy nincs jogosultsága hozzáférni ezekhez az adatokhoz.",
      suggestedAction: "Kérjük, próbáljon meg kitölteni egy új kérdőívet.",
      ctaText: "Új kérdőív kitöltése.",
    },
    uk_UA: {
      header: "Нажаль!",
      subHeader: "Схоже, що у Вас немає дозволу на доступ до цієї інформації.",
      suggestedAction: "Будь ласка, спробуйте почати новий опитувач.",
      ctaText: "Почати новий опитувач.",
    },
    cs_CZ: {
      header: "Jejda!",
      subHeader: "Zdá se, že nemáte oprávnění k přístupu k těmto informacím.",
      suggestedAction: "Zkuste začít nový dotazník.",
      ctaText: "Začněte nový dotazník.",
    },
    bg_BG: {
      header: "Хмм!",
      subHeader: "Изглежда, че нямате разрешение за достъп до тази информация.",
      suggestedAction: "Моля, опитайте да заредите нов въпросник.",
      ctaText: "Зареждане на нов въпросник.",
    },
    hr_HR: {
      header: "Ups!  ",
      subHeader: "Čini se da nemate dopuštenje za pristup ovim informacijama.",
      suggestedAction: "Pokušajte započeti novi upitnik.",
      ctaText: "Započnite novi upitnik.",
    },
    pt_PT: {
      header: "Ups!  ",
      subHeader: "Parece que não tem permissão para aceder a esta informação.",
      suggestedAction: "Tente começar um novo questionário. ",
      ctaText: "Começar um novo questionário.",
    },
    el_GR: {
      header: "Ωχ!",
      subHeader: "Φαίνεται ότι δεν έχετε άδεια πρόσβασης σε αυτές τις πληροφορίες. ",
      suggestedAction: "Δοκιμάστε να ξεκινήσετε ένα νέο ερωτηματολόγιο. ",
      ctaText: " Έναρξη νέου ερωτηματολογίου.",
    },
    fl_FL: {
      header: "Hups!",
      subHeader: "Sinulla ei ilmeisesti ole lupaa tarkastella näitä tietoja. ",
      suggestedAction: "Kokeile aloittaa uusi kysely. ",
      ctaText: "Aloita uusi kysely."
    },
    et_EE: {
      header: "Oih!",
      subHeader: "Näib, et teil ei ole õigust sellele teabele juurdepääsuks.",
      suggestedAction: "Proovige alustada uut küsimustikku.",
      ctaText: "Alusta uut küsimustikku."
    }
  },
  progress_recovery_completed: {
    sv_SE: {
      header: "Ojdå! Du har redan avslutat det här frågeformuläret.",
      subHeader: "Använd knappen nedan för att starta ett nytt:",
      ctaText: "Starta nytt frågeformulär.",
      contact: "Vid eventuella frågor om ditt avslutade frågeformulär, kontakta vårt team på 1-(855)-622-8748."
    },
    es_ES: {
      header: "¡Vaya! Ya ha completado este cuestionario.",
      subHeader: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      ctaText: "Iniciar un nuevo cuestionario.",
      contact: "Si tiene alguna pregunta sobre el cuestionario que ha rellenado, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_MX: {
      header: "¡Vaya! Ya ha completado este cuestionario.",
      subHeader: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      ctaText: "Iniciar un nuevo cuestionario.",
      contact: "Si tiene alguna pregunta sobre el cuestionario que ha rellenado, póngase en contacto con nuestro equipo al teléfono 1-(855)-622-8748."
    },
    es_US: {
      header: "¡Vaya! Ya ha completado este cuestionario.",
      subHeader: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      ctaText: "Iniciar un nuevo cuestionario.",
      contact: "Si tiene alguna pregunta sobre el cuestionario que ha rellenado, póngase en contacto con nuestro equipo al teléfono 1-(833) 211-3611."
    },
    es_CO: {
      header: "¡Vaya! Ya ha completado este cuestionario.",
      subHeader: "Utilice el botón que aparece abajo para iniciar un nuevo cuestionario:",
      ctaText: "Iniciar un nuevo cuestionario.",
      contact: "Si tiene alguna pregunta sobre el cuestionario que ha rellenado, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    },
    es_AR: {
      header: "¡Uy! Ya completó este cuestionario.",
      subHeader: "Use el botón que aparece abajo para iniciar uno nuevo:",
      ctaText: "Iniciar un nuevo cuestionario.",
      contact: "Si tiene alguna pregunta sobre el cuestionario que completó, póngase en contacto con nuestro equipo llamando al 1-(855)-622-8748."
    },
    ru_UA: {
      header: "Ой! Вы уже заполнили эту анкету.",
      subHeader: "Пожалуйста, воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      ctaText: "Начать заполнение новой анкеты.",
      contact: "Если у Вас возникнут какие-либо вопросы по поводу заполненной Вами анкеты, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748."
    },
    ru_LV: {
      header: "Ой! Вы уже заполнили эту анкету.",
      subHeader: "Пожалуйста, воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      ctaText: "Начать заполнение новой анкеты.",
      contact: "Если у Вас возникнут какие-либо вопросы по поводу заполненной Вами анкеты, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748."
    },
    ru_LT: {
      header: "Ой! Вы уже заполнили эту анкету.",
      subHeader: "Пожалуйста, воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      ctaText: "Начать заполнение новой анкеты.",
      contact: "Если у Вас возникнут какие-либо вопросы по поводу заполненной Вами анкеты, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748."
    },
    ru_IL: {
      header: "Ой! Вы уже заполнили эту анкету.",
      subHeader: "Пожалуйста, воспользуйтесь кнопкой ниже, чтобы начать заполнение новой анкеты:",
      ctaText: "Начать заполнение новой анкеты.",
      contact: "Если у Вас возникнут какие-либо вопросы по поводу заполненной Вами анкеты, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748."
    },
    pt_BR: {
      header: "Opa! Você já concluiu este questionário.",
      subHeader: "Use o botão abaixo para iniciar um novo questionário:",
      ctaText: "Iniciar novo questionário.",
      contact: "Caso tenha quaisquer perguntas sobre o questionário concluído, contate nossa equipe no número 1-(855)-622-8748."
    },
    lv_LV: {
      header: "Ak vai! Jūs jau esat aizpildījis šo anketu.",
      subHeader: "Izmantojiet pogu zemāk, lai sāktu jaunu:",
      ctaText: "Sāciet jaunu anketu.",
      contact: "Ja jums rodas kādi jautājumi par savu aizpildīto anketu, sazinieties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748"
    },
    ja_JP: {
      header: "オット！あなたはすでに、この質問票を完了されました。",
      subHeader: "新しい物を開始するには、下のボタンを使ってください。",
      ctaText: "新しい質問票の開始。",
      contact: "完了された質問票について何かご質問がある場合は、弊社のチームに 1-(855)-622-8748 までご連絡ください。"
    },
    de_AT: {
      header: "Oops! Sie haben diesen Fragebogen bereits ausgefüllt.",
      subHeader: "Bitte verwenden Sie die Schaltfläche unten, um einen neuen zu starten:",
      ctaText: "Neuen Fragebogen starten.",
      contact: "Wenn Sie eine Frage zu Ihrem ausgefüllten Fragebogen haben, wenden Sie sich bitte an unser Team unter 1-(855)-622-8748.",
    },
    fr_CA: {
      header: "Oups! Vous avez déjà rempli ce questionnaire.",
      subHeader: "Veuillez utiliser le bouton ci-dessous pour en commencer un nouveau :",
      ctaText: "Commencer un nouveau questionnaire.",
      contact: "Si vous avez des questions à propos du questionnaire que vous avez rempli, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    fr_FR: {
      header: "Oups ! Vous avez déjà rempli ce questionnaire.",
      subHeader: "Veuillez utiliser le bouton ci-dessous pour en commencer un nouveau :",
      ctaText: "Commencer un nouveau questionnaire.",
      contact: "Si vous avez des questions à propos du questionnaire que vous avez rempli, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    fr_BE: {
      header: "Oups ! Vous avez déjà rempli ce questionnaire.",
      subHeader: "Veuillez utiliser le bouton ci-dessous pour en commencer un nouveau :",
      ctaText: "Commencer un nouveau questionnaire.",
      contact: "Si vous avez des questions à propos du questionnaire que vous avez rempli, veuillez contacter notre équipe au 1-(855)-622-8748."
    },
    en_NZ: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 1-(855)-622-8748."
    },
    sk_SK: {
      header: "Hopla! Tento dotazník ste už dokončili.",
      subHeader: "Pomocou tlačidla nižšie začnete nový dotazník:",
      ctaText: "Začať nový dotazník.",
      contact: "Ak máte akékoľvek otázky týkajúce sa dokončeného dotazníka, kontaktujte náš tím na tel.č. 1-(855)-622-8748."
    },
    lt_LT: {
      header: "Oi! Jūs jau užpildėte šią anketą.",
      subHeader: "Žemiau esančiu mygtuku pradėkite naują:",
      ctaText: "Pradėti naują anketą.",
      contact: "Jeigu turite klausimų apie savo užpildytą anketą, kreipkitės į mūsų komandą paskambinę 1-(855)-622-8748."
    },
    en_CA: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team on +1-(855)-622-8748."
    },
    en_AU: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 1-(855)-622-8748."
    },
    nl_NL: {
      header: "Oeps! U heeft deze vragenlijst al ingevuld.",
      subHeader: "Gebruik onderstaande knop om een andere vragenlijst op te starten:",
      ctaText: "Een nieuwe vragenlijst opstarten.",
      contact: "Als u vragen heeft over uw ingevuld vragenlijst, neem dan contact op met ons team op 1-(855)-622-8748."
    },
    nl_BE: {
      header: "Oeps! U heeft deze vragenlijst al ingevuld.",
      subHeader: "Gebruik onderstaande knop om een andere vragenlijst op te starten:",
      ctaText: "Een nieuwe vragenlijst opstarten.",
      contact: "Als u vragen heeft over uw ingevuld vragenlijst, neem dan contact op met ons team op 1-(855)-622-8748."
    },
    en_US: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 1-(855)-622-8748."
    },
    en_GB: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 1-(855)-622-8748."
    },
    en_IE: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 1-(855)-622-8748."
    },
    da_DK: {
      header: "Hovsa! Du har allerede udfyldt dette spørgeskema.",
      subHeader: "Anvend knappen nedenunder til at starte et nyt:",
      ctaText: "Start et nye spørgeskema.",
      contact: "Hvis du har spørgsmål om dit udfyldte spørgeskema, bedes du kontakte vores team på 1-(855)-622-8748."
    },
    de_DE: {
      header: "Oops! Sie haben diesen Fragebogen bereits ausgefüllt.",
      subHeader: "Bitte verwenden Sie die Schaltfläche unten, um einen neuen zu starten:",
      ctaText: "Neuen Fragebogen starten.",
      contact: "Wenn Sie eine Frage zu Ihrem ausgefüllten Fragebogen haben, wenden Sie sich bitte an unser Team unter 0341 94679992."
    },
    pl_PL: {
      header: "Ups! Ten kwestionariusz był już uzupełniony.",
      subHeader: "Za pomocą poniższego przycisku proszę rozpocząć nowy kwestionariusz:",
      ctaText: "Rozpocznij nowy kwestionariusz.",
      contact: "W przypadku pytań dotyczących uzupełnionego kwestionariusza prosimy o kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    en_ZA: {
      header: "Oops! You already completed this questionnaire.",
      subHeader: "Please use the button below to start a new one:",
      ctaText: "Start new questionnaire.",
      contact: "If you have any questions about your completed questionnaire, please contact our team at 27 (21) 2022983."
    },
    af_ZA: {
      header: "Oeps! Jy het reeds hierdie vraelys voltooi.",
      subHeader: "Gebruik asseblief die onderstaande knoppie om 'n nuwe een te begin:",
      ctaText: "Begin nuwe vraelys.",
      contact: "As jy enige vrae oor jou voltooide vraelys het, kontak asseblief ons span by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Habe! Usuluqedile lolu hlu lwemibuzo.",
      subHeader: "Sicela usebenzise inkinobho engezansi ukuqala olusha:",
      ctaText: "Qala uhlu lwemibuzo olusha.",
      contact: "Uma unemibuzo emayelana nohlu lwakho lwemibuzo osulugcwalisile, sicela uthintane nethimba lethu ku-27 (21) 2022983."
    },
    es_CL: {
      header: "¡Upss! Ya completó este cuestionario.",
      subHeader: "Use el botón a continuación para comenzar uno nuevo:",
      ctaText: "Comenzar un nuevo cuestionario.",
      contact: "Si tiene preguntas sobre el cuestionario que completó, pónganse en contacto con nuestro equipo al +1 (855) 622-8748."
    },
    hu_HU: {
      header: "Hoppá! Ezt a kérdőívet Ön már kitöltötte.",
      subHeader: "Kérjük, nyomja meg a lenti gombot egy új kérdőív kitöltéséhez.",
      ctaText: "Új kérdőív kitöltése.",
      contact: "Ha kérdése van a kitöltött kérdőívvel kapcsolatban, hívja kollégáinkat ezen a számon: 36 1 881 26 77."
    },
    uk_UA: {
      header: "Нажаль! Ви вже заповнили цей опитувач",
      subHeader: "Будь ласка, скористайтеся кнопкою нижче, щоб почати новий опитувач:",
      ctaText: "Почати новий опитувач.",
      contact: "Якщо у Вас виникнуть які-небудь питання щодо опитувача, який Ви заповнили, зверніться, будь ласка, до нашої підтримки за номером 38 094 7104843."
    },
    cs_CZ: {
      header: "Jejda! Tento dotazník jste již vyplnili.",
      subHeader: "Pomocí tlačítka dole začněte vyplňovat nový dotazník:",
      ctaText: "Začněte nový dotazník.",
      contact: "Máte-li jakékoli dotazy ohledně vyplněného dotazníku, kontaktujte náš tým na čísle 234 092 144."
    },
    bg_BG: {
      header: "Хмм! Вече сте попълнили този въпросник.",
      subHeader: "Моля, използвайте бутона по-долу, за да заредите нов:",
      ctaText: "Зареждане на нов въпросник.",
      contact: "Ако имате въпроси относно попълнения въпросник, свържете се с нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      header: "אופס! כבר מילאת את השאלון הזה.",
      subHeader: "אנא לחץ על הכפתור מטה כדי להתחיל שאלון חדש:",
      ctaText: "להתחיל שאלון חדש.",
      contact: "אם יש לך שאלות בנוגע לשאלון שמילאת, אנא צור קשר עם הצוות שלנו במספר טלפון 1-(855)-622-8748."
    },
    it_IT: {
      header: "Ops! Lei ha già completato questo questionario.",
      subHeader: "Utilizzare il pulsante qui sotto per iniziarne uno nuovo:",
      ctaText: "Avviare un nuovo questionario.",
      contact: "Se ha delle domande sul questionario completato, contatti il nostro team al numero 1-(855)-622-8748."
    },
    ro_RO: {
      header: "Ups! Ați completat deja acest chestionar.",
      subHeader: "Utilizați butonul de mai jos pentru a lansa unul nou:",
      ctaText: "Lansați un chestionar nou.",
      contact: "Dacă aveți întrebări privind chestionarul completat, contactați echipa noastră la numărul de telefon 1-(855)-622-8748."
    },
    pt_PT: {
      header: "Ups! Já preencheu este questionário.",
      subHeader: "Utilize o botão abaixo para começar um novo:",
      ctaText: "Começar um novo questionário.",
      contact: "Se tiver alguma questão em relação a um questionário preenchido, contacte a nossa equipa através do 1-(855)-622-8748.",
    },
    ar_IL: {
      header: "وجه الفتاة! لقد أكملت هذا الاستبيان بالفعل.",
      subHeader: "الرجاء استخدام الزر أدناه لبدء زر جديد:",
      ctaText: "ابدأ استبيان جديد.",
      contact: "إذا كانت لديك أي أسئلة حول الاستبيان المكتمل ، فيرجى الاتصال بفريقنا على 1- (855) -622-8748."
    },
    hr_HR: {
      header: "Ups! Već ste popunili ovaj upitnik.",
      subHeader: "Upotrijebite gumb u nastavku da započnete novi:",
      ctaText: "Započnite novi upitnik.",
      contact: "Ako imate pitanja o Vašem popunjenom upitniku, kontaktirajte naš tim na 1-(855)-622-8748.",
    },
    el_GR: {
      header: "Ωχ! Έχετε ήδη συμπληρώσει αυτό το ερωτηματολόγιο.",
      subHeader: "Χρησιμοποιήστε το παρακάτω κουμπί για να ξεκινήσετε ένα νέο:",
      ctaText: "Έναρξη νέου ερωτηματολογίου.",
      contact: "Εάν έχετε ερωτήσεις σχετικά με το συμπληρωμένο ερωτηματολόγιο, επικοινωνήστε με την ομάδα μας στο 1-(855)-622-8748.",
    },
    fl_FL: {
      header: "Hups! Olet jo täyttänyt tämän kyselyn.",
      subHeader: "Paina alla olevaa painiketta aloittaaksesi uuden kyselyn:",
      ctaText: "Aloita uusi kysely.",
      contact: "Jos sinulla on kysyttävää täyttämästäsi kyselystä, ota yhteyttä tiimiimme numeroon 1-(855)-622-8748."
    },
    et_EE: {
      header: "Oih! Olete selle küsimustiku juba täitnud.",
      subHeader: "Uue küsimustiku alustamiseks kasutage allolevat nuppu:",
      ctaText: "Alusta uut küsimustikku.",
      contact: "Kui teil on täidetud küsimustiku kohta küsimusi, võtke ühendust meie meeskonnaga numbril 1-(855)-622-8748."
    }
  },
  site_scheduler_generic: {
    sv_SE: {
      goBackCtaText: "Gå tillbaka",
      restartCtaText: "Starta om frågeformulär",
      suggestedAction: "Består felet, kan du använda knappen nedan för att starta om frågeformuläret:",
      contact: "Du kan också kontakta vårt team på 1-(855)-622-8748.",
      header: "Oj! Nåt gick fel i vår ände.",
      subHeader: "Gå tillbaka och försök igen eller försök att välja en annan plats:"
    },
    es_ES: {
      goBackCtaText: "Atrás",
      restartCtaText: "Reiniciar el cuestionario",
      suggestedAction: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contact: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Le rogamos que vuelva atrás para intentarlo de nuevo o que intente seleccionar un centro distinto:"
    },
    es_MX: {
      goBackCtaText: "Atrás",
      restartCtaText: "Reiniciar el cuestionario",
      suggestedAction: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contact: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Le rogamos que vuelva atrás para intentarlo de nuevo o intente seleccionar un centro distinto:"
    },
    es_CO: {
      goBackCtaText: "Atrás",
      restartCtaText: "Reiniciar el cuestionario",
      suggestedAction: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contact: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Por favor, vuelva atrás para intentarlo de nuevo o intente seleccionar un centro distinto:"
    },
    es_AR: {
      goBackCtaText: "Atrás",
      restartCtaText: "Reiniciar el cuestionario",
      suggestedAction: "Si este error persiste, puede usar el botón que aparece abajo para reiniciar el cuestionario:",
      contact: "También puede ponerse en contacto con nuestro equipo llamando al 1-(855)-622-8748.",
      header: "¡Disculpe! Tuvimos un problema.",
      subHeader: "Vuelva atrás para intentarlo de nuevo o intente seleccionar un centro distinto:"
    },
    es_US: {
      goBackCtaText: "Atrás",
      restartCtaText: "Reiniciar el cuestionario",
      suggestedAction: "Si el error persiste, utilice el botón que aparece abajo para reiniciar el cuestionario:",
      contact: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(833) 211-3611.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Le rogamos que vuelva atrás para intentarlo de nuevo o intente seleccionar un centro distinto:"
    },
    ru_UA: {
      goBackCtaText: "Назад",
      restartCtaText: "Начать заполнение анкеты снова",
      suggestedAction: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста. вернитесь назад и повторите попытку или попробуйте выбрать другой адрес центра:"
    },
    ru_LV: {
      goBackCtaText: "Назад",
      restartCtaText: "Начать заполнение анкеты снова",
      suggestedAction: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста. вернитесь назад и повторите попытку или попробуйте выбрать другой адрес центра:"
    },
    ru_LT: {
      goBackCtaText: "Назад",
      restartCtaText: "Начать заполнение анкеты снова",
      suggestedAction: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста. вернитесь назад и повторите попытку или попробуйте выбрать другой адрес центра:"
    },
    ru_IL: {
      goBackCtaText: "Назад",
      restartCtaText: "Начать заполнение анкеты снова",
      suggestedAction: "Если эта ошибка повторится, воспользуйтесь кнопкой ниже, чтобы заново начать заполнение анкеты:",
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста. вернитесь назад и повторите попытку или попробуйте выбрать другой адрес центра:"
    },
    pt_BR: {
      goBackCtaText: "Retornar",
      restartCtaText: "Reiniciar o questionário",
      suggestedAction: "Caso este erro persista, você pode usar o botão abaixo para reiniciar o questionário:",
      contact: "Você também pode contatar nossa equipe no número 1-(855)-622-8748.",
      header: "Epa! Algo deu errado do nosso lado.",
      subHeader: "Retorne e tente novamente ou tente selecionar um centro diferente:"
    },
    lv_LV: {
      goBackCtaText: "Atpakaļ",
      restartCtaText: "Restartēt anketu",
      suggestedAction: "Ja šī kļūda netiek novērsta, varat izmantot pogu zemāk, lai restartētu anketu:",
      contact: "Varat arī sazināties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748.",
      header: "Ak vai! Mūsu pusē radās kļūda",
      subHeader: "Lūdzu, atgriezieties un mēģiniet vēlreiz vai izvēlieties citu centru:"
    },
    ja_JP: {
      goBackCtaText: "戻る",
      restartCtaText: "質問票の再開",
      suggestedAction: "このエラーが続く場合は、質問票を再開するために、下のボタンを使うことができます: ",
      contact: "また弊社のチームに 1-(855)-622-8748 まで連絡することもできます。",
      header: "オット！こちら側で何か間違いが起こりました。",
      subHeader: "戻って再度試されるか、または異なる施設の場所を選択してみてください: "
    },
    de_AT: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Bitte gehen Sie zurück und versuchen Sie es erneut oder wählen Sie eine andere Seite der Website:",
      goBackCtaText: "Zurück",
      suggestedAction: "Falls dieser Fehler weiter besteht, können Sie auf die Schaltfläche unten klicken, um den Fragebogen erneut zu starten:",
      restartCtaText: "Fragebogen erneut starten",
      contact: "Sie können sich auch an unser Team unter 1-(855)-622-8748 wenden.",
    },
    fr_CA: {
      goBackCtaText: "Revenir en arrière",
      restartCtaText: "Recommencer le questionnaire",
      suggestedAction: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "Oh! Oh! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez revenir en arrière et réessayer ou essayez de sélectionner un autre emplacement de centre :"
    },
    fr_FR: {
      goBackCtaText: "Revenir en arrière",
      restartCtaText: "Recommencer le questionnaire",
      suggestedAction: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez revenir en arrière et réessayer ou essayez de sélectionner un autre emplacement de centre :"
    },
    fr_BE: {
      goBackCtaText: "Revenir en arrière",
      restartCtaText: "Recommencer le questionnaire",
      suggestedAction: "Si cette erreur persiste, vous pouvez utiliser le bouton ci-dessous pour recommencer le questionnaire :",
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez revenir en arrière et réessayer ou essayez de sélectionner un autre emplacement de centre :"
    },
    en_NZ: {
      goBackCtaText: "Go back",
      restartCtaText: "Restart questionnaire",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      contact: "You can also contact our team at 1-(855)-622-8748.",
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:"
    },
    sk_SK: {
      goBackCtaText: "Vrátiť sa späť",
      restartCtaText: "Znovu začať dotazník",
      suggestedAction: "Ak táto chyba pretrváva, môžete použiť nasledujúce tlačidlo a dotazník reštartovať:",
      contact: "Môžete tiež kontaktovať náš tím na tel. č. 1-(855)-622-8748.",
      header: "Pozor! Niečo sa pokazilo u nás.",
      subHeader: "Vráťte sa späť a skúste zvoliť inú lokalitu:"
    },
    lt_LT: {
      goBackCtaText: "Grįžti",
      restartCtaText: "Pradėti anketą iš naujo",
      suggestedAction: "Jeigu klaida išlieka, žemiau esančiu mygtuku galite anketą pradėti iš naujo:",
      contact: "Arba galite kreiptis į mūsų komandą paskambinę 1-(855)-622-8748.",
      header: "Vaje! Kažkas nutiko mūsų pusėje.",
      subHeader: "Grįžkite ir bandykite dar kartą arba bandykite rinktis kitą tyrimo centro vietovę:"
    },
    en_CA: {
      goBackCtaText: "Go back",
      restartCtaText: "Restart questionnaire",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      contact: "You can also contact our team on +1-(855)-622-8748.",
      header: "Uh oh! Something went wrong at our end.",
      subHeader: "Please go back and try again or try selecting a different site location:"
    },
    en_AU: {
      goBackCtaText: "Go back",
      restartCtaText: "Restart questionnaire",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      contact: "You can also contact our team at 1-(855)-622-8748.",
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:"
    },
    nl_NL: {
      goBackCtaText: "Ga terug",
      restartCtaText: "Vragenlijst opnieuw opstarten",
      suggestedAction: "Als dit probleem zich blijft voordoen kunt u de onderstaande knop gebruiken om de vragenlijst opnieuw op te starten:",
      contact: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748.",
      header: "Oeps! Er ging iets fout bij ons.",
      subHeader: "Ga terug en probeer het opnieuw of selecteer een andere locatie:"
    },
    nl_BE: {
      goBackCtaText: "Ga terug",
      restartCtaText: "Vragenlijst opnieuw opstarten",
      suggestedAction: "Als dit probleem zich blijft voordoen kunt u de onderstaande knop gebruiken om de vragenlijst opnieuw op te starten:",
      contact: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748.",
      header: "Oeps! Er ging iets fout bij ons.",
      subHeader: "Ga terug en probeer het opnieuw of selecteer een andere locatie:"
    },
    en_US: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:",
      goBackCtaText: "Go back",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      restartCtaText: "Restart questionnaire",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    en_GB: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:",
      goBackCtaText: "Go back",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      restartCtaText: "Restart questionnaire",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    es_CL: {
			goBackCtaText: "Volver",
      restartCtaText: "Comenzar de nuevo el cuestionario:",
			suggestedAction: "Si el error continúa, puede usar el botón a continuación para comenzar de nuevo el cuestionario:",
			contact: "También puede ponerse en contacto con nuestro equipo al +1 (855) 622-8748.",
			header: "¡Oh no! Algo anduvo mal de nuestro lado",
      subHeader: "Vaya para atrás e intente de nuevo o intente seleccionar otra ubicación:"
    },
    en_IE: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:",
      goBackCtaText: "Go back",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      restartCtaText: "Restart questionnaire",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    da_DK: {
      header: "Hovsa! Der opstod en fejl hos os.",
      subHeader: "Gå tilbage og prøv igen, eller prøv at vælge et andet center:",
      goBackCtaText: "Gå tilbage",
      suggestedAction: "Hvis du bliver ved med at se denne fejl, kan du bruge knappen nedenunder for at genstarte spørgeskemaet:",
      restartCtaText: "Genstart spørgeskemaet",
      contact: "Du kan også kontakte vores team på 1-(855)-622-8748."
    },
    de_DE: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Bitte gehen Sie zurück und versuchen Sie es erneut oder wählen Sie eine andere Seite der Website:",
      goBackCtaText: "Zurück",
      suggestedAction: "Falls dieser Fehler weiter besteht, können Sie auf die Schaltfläche unten klicken um den Fragebogen erneut zu starten:",
      restartCtaText: "Fragebogen erneut starten",
      contact: "Sie können sich auch an unser Team unter 0341 94679992 wenden."
    },
    pl_PL: {
      header: "Ups! Coś poszło nie tak po naszej stronie...",
      subHeader: "Prosimy wrócić i spróbować ponownie albo spróbować wybrać inną lokalizację ośrodka:",
      goBackCtaText: "Wróć",
      suggestedAction: "Jeśli błąd ten się powtarza, za pomocą poniższego przycisku można ponownie uruchomić kwestionariusz:",
      restartCtaText: "Reset kwestionariusza",
      contact: "Możliwy jest też kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    en_ZA: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please go back and try again or try selecting a different site location:",
      goBackCtaText: "Go back",
      suggestedAction: "If this error persists, you can use the button below to restart the questionnaire:",
      restartCtaText: "Restart questionnaire",
      contact: "You can also contact our team at 27 (21) 2022983."
    },
    af_ZA: {
      header: "Oeps! Iets het aan ons kant verkeerd geloop.",
      subHeader: "Gaan asseblief terug en probeer weer of probeer om 'n ander sentrumligging te kies:",
      goBackCtaText: "Gaan terug",
      suggestedAction: "As hierdie fout voortduur, kan jy die onderstaande knoppie gebruik om die vraelys te herbegin:",
      restartCtaText: "Herbegin vraelys",
      contact: "Jy kan ook ons span kontak by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Hawu! Kukhona okungahambanga kahle ohlangothini lwethu.",
      subHeader: "Sicela ubuyele emuva bese uzame futhi noma zama ukukhetha indawo ehlukile yesayithi:",
      goBackCtaText: "Buyela emuva",
      suggestedAction: "Uma leli phutha liphikelela, ungasebenzisa inkinobho engezansi ukuqala kabusha uhlu lwemibuzo:",
      restartCtaText: "Qala kabusha uhlu lwemibuzo",
      contact: "Ungakwazi futhi ukuthintana nethimba lethu ku-27 (21) 2022983."
    },
    hu_HU: {
      header: "Ó, jaj! Valamit elrontottunk.",
      subHeader: "Kérjük, lépjen vissza, és próbálja meg újra, vagy válasszon más vizsgálóhelyet:",
      goBackCtaText: "Visszalépek",
      suggestedAction: "Ha a hiba továbbra is fennáll, nyomja meg a lenti gombot a kérdőív újrakezdéséhez.",
      restartCtaText: "Kérdőív újrakezdése",
      contact: "Kollégáinkat is felhívhatja ezen a számon: 36 1 881 26 77."
    },
    uk_UA: {
      header: "Ой! В нас щось пішло не так.",
      subHeader: "Будь ласка, поверніться і спробуйте ще раз або спробуйте обрати інше розташування центру:",
      goBackCtaText: "Повернутися",
      suggestedAction: "Якщо ця помилка буде повторюватися, Ви можете скористатися кнопкою нижче, щоб запустити опитувач знову:",
      restartCtaText: "Запустити опитувач знову",
      contact: "Ви можете також звернутися до нашої підтримки за номером 38 094 7104843."
    },
    cs_CZ: {
      header: "A jé! Na naší straně je nějaká chyba.",
      subHeader: "Vraťte se a zkuste to znovu nebo zkuste vybrat centrum v jiné lokalitě:",
      goBackCtaText: "Vraťte se",
      suggestedAction: "Pokud tato chyba přetrvává, můžete pomocí níže uvedeného tlačítka dotazník restartovat:",
      restartCtaText: "Restartujte dotazník",
      contact: "Můžete nás rovněž kontaktovat na telefonním čísle 234 092 144."
    },
    bg_BG: {
      header: "Грешка! При нас възникна проблем.",
      subHeader: "Моля, върнете се назад и опитайте отново или опитайте да изберете друго местоположение на център:",
      goBackCtaText: "Назад",
      suggestedAction: "Ако грешката се повтори, използвайте бутона по-долу, за да заредите въпросника отново:",
      restartCtaText: "Повторно зареждане на въпросника",
      contact: "Можете също да се свържете с нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      goBackCtaText: "חזרה",
      restartCtaText: "להתחיל שאלון מחדש",
      suggestedAction: "אם התקלה נמשכת, תוכל להשתמש בכפתור מטה כדי להתחיל מחדש את השאלון:",
      contact: "תוכל גם ליצור קשר עם הצוות שלנו במספר טלפון 1-(855)-622-8748.",
      header: "אופס! קרתה תקלה אצלנו.",
      subHeader: "אנא חזור ונסה שנית או נסה לבחור מרכז במיקום אחר:"
    },
    it_IT: {
      header: "Ops! Qualcosa è andato storto da parte nostra.",
      subHeader: "Si prega di tornare indietro e riprovare o provare a selezionare un altro centro:",
      goBackCtaText: "Torna indietro",
      suggestedAction: "Se questo errore persiste, è possibile utilizzare il pulsante qui sotto per riavviare il questionario:",
      restartCtaText: "Riavviare il questionario",
      contact: "Può anche contattare il nostro team al numero 1 (855) -622-8748."
    },
    ro_RO: {
      header: "Ups! Ceva nu a funcționat bine de partea noastră.",
      subHeader: "Reveniți la pagina anterioară și încercați din nou sau încercați să selectați un alt centru:",
      goBackCtaText: "Înapoi",
      suggestedAction: "Dacă această eroare persistă, puteți utiliza butonul de mai jos pentru a lansa din nou chestionarul:",
      restartCtaText: "Lansați din nou chestionarul",
      contact: "De asemenea, puteți contacta echipa la numărul de telefon 1-(855)-622-8748."
    },
    pt_PT: {
      header: "Ups! Algo não correu bem deste lado.",
      subHeader: "Clique em “voltar” e tente novamente ou experimente selecionar uma localização de centro diferente:",
      goBackCtaText: "Voltar",
      suggestedAction: "Se este erro persistir, pode utilizar o botão abaixo para recomeçar o questionário:",
      restartCtaText: "Recomeçar o questionário",
      contact: "Também pode contactar a nossa equipa pelo 1-(855)-622-8748.",
    },
    ar_IL: {
      header: "اه اوه! حدث خطأ ما من جانبنا.",
      subHeader: "يرجى الرجوع والمحاولة مرة أخرى أو محاولة تحديد موقع موقع مختلف:",
      goBackCtaText: "عد",
      suggestedAction: "إذا استمر هذا الخطأ ، يمكنك استخدام الزر أدناه لإعادة تشغيل الاستبيان:",
      restartCtaText: "أعد تشغيل الاستبيان",
      contact: "يمكنك أيضًا الاتصال بفريقنا على 1- (855) -622-8748."
    },
    hr_HR: {
      header: "O ne! Nešto je pošlo po zlu kod nas.",
      subHeader: "Vratite se i pokušajte ponovno ili pokušajte odabrati drugi centar:",
      goBackCtaText: "Vratite se",
      suggestedAction: "Ako se ova pogreška nastavi, možete upotrijebiti gumb u nastavku da ponovno započnete upitnik:",
      restartCtaText: "Ponovno započnite upitnik.",
      contact: "Možete kontaktirati i naš tim i na 1-(855)-622-8748.",
    },
    el_GR: {
      header: "Ωχ όχι! Κάτι πήγε λάθος από πλευράς μας.",
      subHeader: "Πηγαίνετε πίσω και δοκιμάστε ξανά ή δοκιμάστε να επιλέξετε διαφορετική τοποθεσία:",
      goBackCtaText: "Επιστροφή",
      suggestedAction: "Αν το πρόβλημα παραμένει, μπορείτε να χρησιμοποιήσετε το κουμπί παρακάτω για να ξεκινήσετε από την αρχή το ερωτηματολόγιο:",
      restartCtaText: "Επανέναρξη ερωτηματολογίου",
      contact: "Μπορείτε επίσης να επικοινωνήσετε με την ομάδα μας στο 1-(855)-622-8748.",
    },
    fl_FL: {
      header: "Hups! Järjestelmässämme tapahtui jokin virhe.",
      subHeader: "Palaa takaisin ja kokeile uudelleen tai kokeile valita eri tutkimuskeskuksen sijainti:",
      goBackCtaText: "Palaa takaisin",
      suggestedAction: "Jos virhe jatkuu, voit käynnistää kyselyn uudelleen alla olevasta painikkeesta:",
      restartCtaText: "Aloita kysely uudelleen",
      contact: "Voit myös ottaa yhteyttä tiimiimme numeroon 1 (855) -622-8748."
    },
    et_EE: {
      header: "Oih! Midagi läks meie poolt valesti.",
      subHeader: "Minge tagasi ja proovige uuesti või proovige valida mõni muu uuringukoht:",
      goBackCtaText: "Mine tagasi",
      suggestedAction: "Kui see viga püsib, saate küsimustiku taaskäivitamiseks kasutada allolevat nuppu",
      restartCtaText: "Taaskäivita küsimustik",
      contact: "Võite ühendust võtta ka meie meeskonnaga telefonil 1-(855)-622-8748."
    }
  },
  site_scheduler_refreshPage: {  //siteScheduler.js
    sv_SE: {
      contact: "Du kan också kontakta vårt team på 1-(855)-622-8748.",
      header: "Oj! Nåt gick fel i vår ände.",
      subHeader: "Uppdatera sidan och försök igen om en stund.",
      suggestedAction: "Består felet, kan du gå tillbaka till frågeformuläret och försöka välja en annan plats."
    },
    es_ES: {
      contact: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Actualice la página e inténtelo de nuevo pronto.",
      suggestedAction: "Si el error persiste, puede volver al cuestionario e intentar seleccionar un centro distinto."
    },
    es_MX: {
      contact: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Actualice la página e inténtelo de nuevo pronto.",
      suggestedAction: "Si el error persiste, puede volver al cuestionario e intentar seleccionar un centro distinto."
    },
    es_CO: {
      contact: "También puede ponerse en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Actualice la página e inténtelo de nuevo pronto.",
      suggestedAction: "Si el error persiste, puede volver al cuestionario e intentar seleccionar un centro distinto."
    },
    es_AR: {
      contact: "También puede ponerse en contacto con nuestro equipo llamando al 1-(855)-622-8748.",
      header: "¡Disculpe! Tuvimos un problema.",
      subHeader: "Actualice la página y vuelva a intentar.",
      suggestedAction: "Si este error persiste, puede volver al cuestionario e intentar seleccionar un centro distinto."
    },
    es_US: {
      contact: "También puede ponerse en contacto con nuestro equipo al teléfono 1-(833) 211-3611.",
      header: "¡Vaya! Algo salió mal por nuestra parte.",
      subHeader: "Actualice la página e inténtelo de nuevo pronto.",
      suggestedAction: "Si el error persiste, puede volver al cuestionario e intentar seleccionar un centro distinto."
    },
    ru_UA: {
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста, обновите страницу и попробуйте повторить действие через некоторое время.",
      suggestedAction: "Если эта ошибка повторится, Вы можете вернуться к анкете и попробовать выбрать другой адрес центра."
    },
    ru_LV: {
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста, обновите страницу и попробуйте повторить действие через некоторое время.",
      suggestedAction: "Если эта ошибка повторится, Вы можете вернуться к анкете и попробовать выбрать другой адрес центра."
    },
    ru_LT: {
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста, обновите страницу и попробуйте повторить действие через некоторое время.",
      suggestedAction: "Если эта ошибка повторится, Вы можете вернуться к анкете и попробовать выбрать другой адрес центра."
    },
    ru_IL: {
      contact: "Вы также можете связаться с нашим персоналом по телефону 1-(855)- 622-8748.",
      header: "Ой! У нас что-то пошло не так.",
      subHeader: "Пожалуйста, обновите страницу и попробуйте повторить действие через некоторое время.",
      suggestedAction: "Если эта ошибка повторится, Вы можете вернуться к анкете и попробовать выбрать другой адрес центра."
    },
    pt_BR: {
      contact: "Você também pode contatar nossa equipe no número 1-(855)-622-8748.",
      header: "Epa! Algo deu errado do nosso lado.",
      subHeader: "Atualize a página e tente novamente em breve.",
      suggestedAction: "Caso este erro persista, você pode retornar ao questionário e tentar selecionar um centro diferente."
    },
    lv_LV: {
      contact: "Varat arī sazināties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748.",
      header: "Ak vai! Mūsu pusē radās kļūda",
      subHeader: "Atsvaidziniet lapu un drīzumā mēģiniet vēlreiz.",
      suggestedAction: "Ja šī kļūda netiek novērsta, varat atgriezties pie anketas un mēģināt izvēlēties citu centru."
    },
    ja_JP: {
      contact: "また弊社のチームに 1-(855)-622-8748 まで連絡することもできます。",
      header: "オット！こちら側で何か間違いが起こりました。",
      subHeader: "ページを更新し、間も無く再試行してください。",
      suggestedAction: "このエラーが続く場合は、質問票に戻り、異なる施設の場所を選択してみることができます。"
    },
    de_AT: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Bitte aktualisieren Sie die Seite und versuchen Sie es nochmals.",
      suggestedAction: "Wenn dieser Fehler weiter besteht, können Sie zum Fragebogen zurückkehren und versuchen, eine andere Seite der Website zu wählen.",
      contact: "Sie können sich auch an unser Team unter 1-(855)-622-8748 wenden.",
    },
    fr_CA: {
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "Oh! Oh! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez rafraîchir la page et réessayer prochainement.",
      suggestedAction: "Si cette erreur persiste, vous pouvez revenir en arrière au questionnaire et essayer de sélectionner un nouvel emplacement de centre."
    },
    fr_FR: {
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez rafraîchir la page et réessayer prochainement.",
      suggestedAction: "Si cette erreur persiste, vous pouvez revenir en arrière au questionnaire et essayer de sélectionner un nouvel emplacement de centre."
    },
    fr_BE: {
      contact: "Vous pouvez également contacter notre équipe au 1-(855)-622-8748.",
      header: "oh ! oh ! Quelque chose s'est mal passé de notre côté.",
      subHeader: "Veuillez rafraîchir la page et réessayer prochainement.",
      suggestedAction: "Si cette erreur persiste, vous pouvez revenir en arrière au questionnaire et essayer de sélectionner un nouvel emplacement de centre."
    },
    en_NZ: {
      contact: "You can also contact our team at 1-(855)-622-8748.",
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location."
    },
    sk_SK: {
      contact: "Môžete tiež kontaktovať náš tím na tel. č. 1-(855)-622-8748.",
      header: "Pozor! Niečo sa pokazilo u nás.",
      subHeader: "O chvíľu prosím obnovte stránku a skúste znovu.",
      suggestedAction: "Ak táto chyba pretrváva, môžete sa vrátiť späť k dotazníku a skúsiť vybrať inú lokalitu."
    },
    lt_LT: {
      contact: "Arba galite kreiptis į mūsų komandą paskambinę 1-(855)-622-8748.",
      header: "Vaje! Kažkas nutiko mūsų pusėje.",
      subHeader: "Atnaujinkite puslapį ir netrukus bandykite vėl.",
      suggestedAction: "Jeigu klaida išlieka, grįžkite į anketą ir bandykite rinktis kitą tyrimo centro vietovę."
    },
    en_CA: {
      contact: "You can also contact our team on +1-(855)-622-8748.",
      header: "Uh oh! Something went wrong at our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location."
    },
    en_AU: {
      contact: "You can also contact our team at 1-(855)-622-8748.",
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location."
    },
    nl_NL: {
      contact: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748.",
      header: "Oeps! Er ging iets fout bij ons.",
      subHeader: "Ververs de pagina en probeer het later opnieuw.",
      suggestedAction: "Als dit probleem zich blijft voordoen, ga dan terug naar de vragenlijst en selecteer een andere locatie."
    },
    nl_BE: {
      contact: "U kunt ook contact opnemen met ons team op 1-(855)-622-8748.",
      header: "Oeps! Er ging iets fout bij ons.",
      subHeader: "Ververs de pagina en probeer het later opnieuw.",
      suggestedAction: "Als dit probleem zich blijft voordoen, ga dan terug naar de vragenlijst en selecteer een andere locatie."
    },
    en_US: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location.",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    en_GB: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location.",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    en_IE: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location.",
      contact: "You can also contact our team at 1-(855)-622-8748."
    },
    da_DK: {
      header: "Hovsa! Der opstod en fejl hos os.",
      subHeader: "Genindlæs siden og prøv snart igen.",
      suggestedAction: "Hvis du bliver ved med at se denne fejl, kan du gå tilbage til spørgeskemaet og prøve at vælge et andet center.",
      contact: "Du kan også kontakte vores team på 1-(855)-622-8748."
    },
    de_DE: {
      header: "Oh! Leider ist bei uns etwas schief gelaufen.",
      subHeader: "Bitte aktualisieren Sie die Seite und versuchen Sie es nochmals.",
      suggestedAction: "Wenn dieser Fehler weiter besteht, können Sie zum Fragebogen zurückkehren und versuchen, eine andere Seite der Website zu wählen.",
      contact: "Sie können sich auch an unser Team unter 0341 94679992 wenden."
    },
    pl_PL: {
      header: "Ups! Coś poszło nie tak po naszej stronie...",
      subHeader: "Prosimy odświeżyć stronę i spróbować ponownie za chwilę.",
      suggestedAction: "Jeśli błąd ten się powtarza, prosimy wrócić do kwestionariusza i spróbować wybrać inną lokalizację ośrodka.",
      contact: "Możliwy jest też kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    en_ZA: {
      header: "Uh oh! Something went wrong on our end.",
      subHeader: "Please refresh the page and try again soon.",
      suggestedAction: "If this error persists, you can go back to the questionnaire and try selecting a different site location.",
      contact: "You can also contact our team at 27 (21) 2022983."
    },
    af_ZA: {
      header: "Oeps! Iets het aan ons kant verkeerd geloop.",
      subHeader: "Verfris asseblief die bladsy en probeer binnekort weer.",
      suggestedAction: "As hierdie fout voortduur, kan jy na die vraelys terugkeer en probeer om 'n ander sentrumligging te kies.",
      contact: "Jy kan ook ons span kontak by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Hawu! Kukhona okungahambanga kahle ohlangothini lwethu.",
      subHeader: "Sicela uvuselele ikhasi bese uzame futhi kungekudala.",
      suggestedAction: "Uma leli phutha liphikelela, ungaphindela emuva kuhlu lwemibuzo bese uzama ukukhetha indawo ehlukile yesayithi.",
      contact: "Ungakwazi futhi ukuthintana nethimba lethu ku-27 (21) 2022983."
    },
    hu_HU: {
      header: "Ó, jaj! Valamit elrontottunk.",
      subHeader: "Kérjük, frissítse az oldalt, és kis idő múlva próbálja meg újra.",
      suggestedAction: "Ha a hiba továbbra is fennáll, visszaléphet a kérdőívre, és megpróbálhat kiválasztani egy másik vizsgálóhelyet.",
      contact: "Kollégáinkat is felhívhatja ezen a számon: 36 1 881 26 77."
    },
    es_CL: {
			contact: "También puede ponerse en contacto con nuestro equipo al +1 (855) 622-8748.",
      header: "¡Oh no! Algo anduvo mal de nuestro lado",
      subHeader: "Cargue de nuevo la página e intente de nuevo pronto.",
      suggestedAction: "Si este error continúa, puede volver al cuestionario y tratar de seleccionar otro centro."
    },
    uk_UA: {
      header: "Ой! В нас щось пішло не так.",
      subHeader: "Будь ласка, обновите сторінку та повторіть спробу через деякій час.",
      suggestedAction: "Якщо ця помилка буде повторюватися, Ви можете повернутися до опитувача та спробувати обрати інше розташування центру.",
      contact: "Ви можете також звернутися до нашої підтримки за номером 38 094 7104843."
    },
    cs_CZ: {
      header: "A jé! Na naší straně je nějaká chyba.",
      subHeader: "Obnovte stránku a zkuste to brzy znovu.",
      suggestedAction: "Pokud tato chyba přetrvává, můžete se vrátit k dotazníku a zkusit vybrat centrum v jiné lokalitě.",
      contact: "Můžete nás rovněž kontaktovat na telefonním čísle 234 092 144."
    },
    bg_BG: {
      header: "Грешка! При нас възникна проблем.",
      subHeader: "Моля, обновете страницата и опитайте отново.",
      suggestedAction: "Ако грешката се появи отново, можете да се върнете назад към въпросника и да се опитате да изберете друго местоположение на център.",
      contact: "Можете също да се свържете с нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      header: "אופס! קרתה תקלה אצלנו.",
      subHeader: "נא לרענן את העמוד ולנסות שנית בעוד כמה דקות.",
      suggestedAction: "אם השגיאה נמשכת, ניתן לחזור לשאלון ולנסות לבחור מרכז באזור אחר.",
      contact: "תוכל גם ליצור קשר עם הצוות שלנו במספר טלפון 1-(855)-622-8748."
    },
    it_IT: {
      header: "Ops! Qualcosa è andato storto da parte nostra.",
      subHeader: "Si prega di aggiornare la pagina e riprovare subito.",
      suggestedAction: "Se l'errore persiste, può tornare al questionario e provare a selezionare un'altra ubicazione del centro.",
      contact: "Può anche contattare il nostro team al numero 1 (855) -622-8748."
    },
    ro_RO: {
      header: "Ups! Ceva nu a funcționat bine de partea noastră.",
      subHeader: "Reîmprospătați pagina și încercați din nou în curând.",
      suggestedAction: "Dacă această eroare persistă, puteți să reveniți la chestionar și să încercați să selectați un alt centru.",
      contact: "De asemenea, puteți contacta echipa la numărul de telefon 1-(855)-622-8748."
    },
    pt_PT: {
      header: "Ups! Algo não correu bem deste lado.",
      subHeader: "Atualize a página e tente novamente.",
      suggestedAction: "Se este erro persistir, pode voltar para o questionário e selecionar uma localização de centro diferente.",
      contact: "Também pode contactar a nossa equipa pelo 1-(855)-622-8748.",
    },
    ar_IL: {
      header: "اه اوه! حدث خطأ ما من جانبنا.",
      subHeader: "الرجاء تحديث الصفحة وحاول مرة أخرى قريبا.",
      suggestedAction: "إذا استمر هذا الخطأ ، يمكنك الرجوع إلى الاستبيان ومحاولة تحديد موقع موقع مختلف.",
      contact: "يمكنك أيضًا الاتصال بفريقنا على 1- (855) -622-8748."
    },
    hr_HR: {
      header: "O ne! Nešto je pošlo po zlu kod nas.",
      subHeader: "Osvježite stranicu i pokušajte ponovo uskoro.",
      suggestedAction: "Ako se ova pogreška nastavi, možete se vratiti na upitnik i pokušati odabrati drugi centar",
      contact: "Možete kontaktirati i naš tim i na 1-(855)-622-8748.",
    },
    el_GR: {
      header: "Ωχ όχι! Κάτι πήγε λάθος από πλευράς μας.",
      subHeader: "Ανανεώστε τη σελίδα και δοκιμάστε αργότερα.",
      suggestedAction: "Αν το πρόβλημα παραμένει, μπορείτε να επιστρέψετε στο ερωτηματολόγιο και να δοκιμάσετε να επιλέξετε διαφορετική τοποθεσία.",
      contact: "Μπορείτε επίσης να επικοινωνήσετε με την ομάδα μας στο 1-(855)-622-8748.",
    },
    fl_FL: {
      header: "Hups! Järjestelmässämme tapahtui jokin virhe.",
      subHeader: "Virkistä sivu ja kokeile pian uudelleen.",
      suggestedAction: "Jos virhe jatkuu, voit palata takaisin kyselyyn ja kokeilla valita eri tutkimuskeskuksen sijainnin.",
      contact: "Voit myös ottaa yhteyttä tiimiimme numeroon 1 (855) -622-8748."
    },
    et_EE: {
      header: "Oih! Midagi läks meie poolt valesti.",
      subHeader: "Värskendage lehte ja proovige varsti uuesti.",
      suggestedAction: "Kui see viga püsib, naaske küsimustiku juurde ja proovige valida mõni muu uuringukoht.",
      contact: "Võite ühendust võtta ka meie meeskonnaga telefonil 1-(855)-622-8748."
    }
  },
  site_scheduler_appointmentBooked: {  //siteScheduler.js
    sv_SE: {
      header: "Tid redan bokad",
      subHeader: "Du har redan bokat en tid för denna studie och plats.",
      content: "Du bör har fått ett bekräftelsemejl med mötesuppgifter.",
      contact: "Vid eventuella frågor, kontakta vårt team på 1-(855)-622-8748."
    },
    es_ES: {
      header: "Cita ya reservada",
      subHeader: "Ya ha reservado una cita para este estudio y centro.",
      content: "Debería haber recibido un correo electrónico de confirmación con la información de su cita.",
      contact: "Si tiene alguna pregunta, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748"
    },
    es_MX: {
      header: "Cita ya reservada",
      subHeader: "Ya ha reservado una cita para este estudio y centro.",
      content: "Debería haber recibido un correo electrónico de confirmación con la información de su cita.",
      contact: "Si tiene alguna pregunta, póngase en contacto con nuestro equipo al teléfono 1-(855)-622-8748"
    },
    es_US: {
      header: "Cita ya reservada",
      subHeader: "Ya ha reservado una cita para este estudio y centro.",
      content: "Debería haber recibido un correo electrónico de confirmación con la información de su cita.",
      contact: "Si tiene alguna pregunta, póngase en contacto con nuestro equipo al teléfono 1-(833) 211-3611"
    },
    es_CO: {
      header: "Cita ya reservada",
      subHeader: "Ya ha reservado una cita para este estudio y centro.",
      content: "Debería haber recibido un correo electrónico de confirmación con la información de su cita.",
      contact: "Si tiene alguna pregunta, póngase en contacto con nuestro equipo en el número de teléfono 1-(855)-622-8748."
    }, es_AR: {
      header: "Cita ya reservada",
      subHeader: "Ya ha reservado una cita para este estudio y centro.",
      content: "Debería haber recibido la confirmación por correo electrónico con la información de su cita.",
      contact: "Si tiene alguna pregunta, póngase en contacto con nuestro equipo llamando al 1-(855)-622-8748"
    },
    ru_UA: {
      header: "Вы уже записаны на визит",
      subHeader: "Вы уже записаны на визит в этот центр в рамках этого исследования.",
      content: "Вам было направлено подтверждение по электронной почте с подробной информацией о визите.",
      contact: "Если у Вас возникнут какие-либо вопросы, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748"
    },
    ru_LV: {
      header: "Вы уже записаны на визит",
      subHeader: "Вы уже записаны на визит в этот центр в рамках этого исследования.",
      content: "Вам было направлено подтверждение по электронной почте с подробной информацией о визите.",
      contact: "Если у Вас возникнут какие-либо вопросы, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748"
    },
    ru_LT: {
      header: "Вы уже записаны на визит",
      subHeader: "Вы уже записаны на визит в этот центр в рамках этого исследования.",
      content: "Вам было направлено подтверждение по электронной почте с подробной информацией о визите.",
      contact: "Если у Вас возникнут какие-либо вопросы, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748"
    },
    ru_IL: {
      header: "Вы уже записаны на визит",
      subHeader: "Вы уже записаны на визит в этот центр в рамках этого исследования.",
      content: "Вам было направлено подтверждение по электронной почте с подробной информацией о визите.",
      contact: "Если у Вас возникнут какие-либо вопросы, свяжитесь, пожалуйста, с нашим персоналом по телефону 1-(855)-622-8748"
    },
    pt_BR: {
      header: "Horário já reservado",
      subHeader: "Você já marcou um agendamento para este estudo e este centro.",
      content: "Você deverá receber um e-mail de confirmação com os detalhes do agendamento.",
      contact: "Caso tenha quaisquer perguntas, contate nossa equipe no número 1-(855)-622-8748"
    },
    lv_LV: {
      header: "Vizīte jau pieteikta",
      subHeader: "Jūs jau esat pieteicis vizīti šim pētījumam un centram.",
      content: "Jums ir jābūt apstiprinājuma e-pastam ar datiem par vizīti.",
      contact: "Ja jums rodas kādi jautājumi, sazinieties ar mūsu komandu pa tālruņa nr. 1-(855)-622-8748."
    },
    ja_JP: {
      header: "予約はすでに予定済み",
      subHeader: "この試験と施設について、すでに予約が予定済みです。",
      content: "この予約詳細について、確認のEメールをお受け取りになったはずです。",
      contact: "何かご質問がありましたら、弊社のチームに 1-(855)-622-8748 までご連絡ください。"
    },
    de_AT: {
      header: "Termin bereits vereinbart",
      subHeader: "Sie haben bereits einen Termin für diese Studie und das Prüfzentrum vereinbart.",
      content: "Sie sollten eine Bestätigungsmail mit den Angaben zum Termin erhalten haben.",
      contact: "Bei Fragen wenden Sie sich bitte an unser Team unter 1-(855)-622-8748.",
    },
    fr_CA: {
      header: "Rendez-vous déjà pris",
      subHeader: "Vous avez déjà pris un rendez-vous pour cette étude et ce centre.",
      content: "Vous devriez avoir reçu un courriel de confirmation avec les détails du rendez-vous.",
      contact: "Si vous avez des questions, veuillez contacter notre équipe au 1-(855)-622-8748"
    },
    fr_FR: {
      header: "Rendez-vous déjà pris",
      subHeader: "Vous avez déjà pris un rendez-vous pour cette étude et ce centre.",
      content: "Vous devriez avoir reçu un e-mail de confirmation avec les détails du rendez-vous.",
      contact: "Si vous avez des questions, veuillez contacter notre équipe au 1-(855)-622-8748"
    },
    fr_BE: {
      header: "Rendez-vous déjà pris",
      subHeader: "Vous avez déjà pris un rendez-vous pour cette étude et ce centre.",
      content: "Vous devriez avoir reçu un e-mail de confirmation avec les détails du rendez-vous.",
      contact: "Si vous avez des questions, veuillez contacter notre équipe au 1-(855)-622-8748"
    },
    en_NZ: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 1-(855)-622-8748"
    },
    sk_SK: {
      header: "Termín už bol zarezervovaný",
      subHeader: "Už ste zarezervovali termín pre toto skúšanie a toto pracovisko.",
      content: "Mal by ste dostať potvrdzovací e-mail s podrobnosťami termínu.",
      contact: "Ak máte akékoľvek otázky, kontaktujte náš tím na tel. č. 1-(855)-622-8748"
    },
    lt_LT: {
      header: "Vizito laikas jau rezervuotas",
      subHeader: "Jūs jau rezervavote vizitą šiam tyrimui šiame tyrimų centre.",
      content: "Turėjote gauti patvirtinantį el. laišką ir paskirto vizito duomenis.",
      contact: "Jeigu turite klausimų, kreipkitės į mūsų komandą paskambinę 1-(855)-622-8748."
    },
    en_CA: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team on +1-(855)-622-8748"
    },
    en_AU: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 1-(855)-622-8748"
    },
    nl_NL: {
      header: "Afspraak al gemaakt",
      subHeader: "U heeft al een afspraak gemaakt voor dit onderzoek en deze locatie.",
      content: "U heeft ter bevestiging een e-mail ontvangen met informatie over de afspraak.",
      contact: "Neem, als u vragen heeft, contact op met ons team op 1-(855)-622-8748."
    },
    nl_BE: {
      header: "Afspraak al gemaakt",
      subHeader: "U heeft al een afspraak gemaakt voor dit onderzoek en deze locatie.",
      content: "U heeft ter bevestiging een e-mail ontvangen met informatie over de afspraak.",
      contact: "Neem, als u vragen heeft, contact op met ons team op 1-(855)-622-8748."
    },
    en_US: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 1-(855)-622-8748"
    },
    en_GB: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 1-(855)-622-8748"
    },
    en_IE: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 1-(855)-622-8748"
    },
    da_DK: {
      header: "Aftalen er allerede booket",
      subHeader: "Du har allerede booket en aftale for dette studie og center.",
      content: "Du bør have modtaget en bekræftelse via e-mail med aftaleoplysninger.",
      contact: "Hvis du har spørgsmål, bedes du kontakte vores team på 1-(855)-622-8748"
    },
    de_DE: {
      header: "Termin bereits vereinbart",
      subHeader: "Sie haben bereits einen Termin für diese Studie und das Prüfzentrum vereinbart.",
      content: "Sie sollten eine Bestätigungsmail mit den Angaben zum Termin erhalten haben.",
      contact: "Bei Fragen wenden Sie sich bitte an unser Team unter 0341 94679992."
    },
    pl_PL: {
      header: "Termin wizyty został już ustalony",
      subHeader: "Wybrano już termin wizyty w tym badaniu i w danym ośrodku.",
      content: "Powinien zostać już przesłany e-mail z potwierdzeniem i szczegółami wizyty.",
      contact: "W przypadku pytań prosimy o kontakt z naszym zespołem pod numerem 32 508 65 06."
    },
    en_ZA: {
      header: "Appointment already booked",
      subHeader: "You have already booked an appointment for this study and site.",
      content: "You should have received a confirmation email with the appointment details.",
      contact: "If you have any questions, please contact our team at 27 (21) 2022983"
    },
    af_ZA: {
      header: "Afspraak is reeds bespreek",
      subHeader: "Jy het reeds 'n afspraak vir hierdie studie en sentrum bespreek.",
      content: "Jy sou 'n bevestigings-e-pos met die afspraakbesonderhede ontvang het.",
      contact: "As jy enige vrae het, kontak asseblief ons span by 27 (21) 2022983."
    },
    zu_ZA: {
      header: "Usuku lokuhlangana seluhleliwe kakade",
      subHeader: "Usulihlelile kakade usuku lokuhlangana lwalolu cwaningo kanye nendawo.",
      content: "Kufanele ngabe usuyithole i-imeyili yokuqinisekisa enemininingwane yokuhlangana.",
      contact: "Uma unemibuzo, sicela uthinte ithimba lethu ku-27 (21) 2022983"
    },
    hu_HU: {
      header: "Az időpont már foglalt.",
      subHeader: "Ön már foglalt időpontot ebben a vizsgálatban erre a vizsgálóhelyre.",
      content: "Visszaigazoló levelet kellett kapnia a foglalás adataival.",
      contact: "Ha kérdése van, hívja kollégáinkat ezen a számon: 36 1 881 26 77."
    },
    uk_UA: {
      header: "Ви вже записалися на прийом.",
      subHeader: "Ви вже записалися на прийом в цьому дослідженні та центрі.",
      content: "Ви мали отримати повідомлення на електронну пошту з підтвердженням даних щодо Вашого прийому.",
      contact: "Якщо у Вас виникнуть які-небудь питання, зверніться, будь ласка, до нашої підтримки за номером 38 094 7104843"
    },
    cs_CZ: {
      header: "Návštěva již byla rezervována",
      subHeader: "Už jste si rezervovali návštěvu pro tuto studii a centrum.",
      content: "Měli byste obdržet potvrzovací e-mail s podrobnostmi o návštěvě.",
      contact: "Máte-li jakékoli dotazy, kontaktujte náš tým na čísle 234 092 144"
    },
    es_CL: {
      header: "Ya tiene una cita reservada",
      subHeader: "Ya reservó una cita para este estudio en este centro.",
      content: "Debería haber recibido un correo electrónico de confirmación con los detalles de la cita.",
      contact: "Si tiene preguntas, pónganse en contacto con nuestro equipo al +1 (855) 622-8748"
    },
    bg_BG: {
      header: "Вече имате запазен час",
      subHeader: "Вече имате запазен час за това проучване и този център.",
      content: "Трябва да сте получили имейл за потвърждение с подробна информация за часа.",
      contact: "Ако имате въпроси, свържете се с нашия екип на телефон 02/490 30 48."
    },
    iw_IL: {
      header: "התור כבר נקבע",
      subHeader: "כבר נקבע לך תור למחקר ולמרכז הזה.",
      content: `היית אמור לקבל אישור בדוא"ל עם פרטי התור.`,
      contact: "אם יש לך שאלה, אנא צור קשר עם הצוות שלנו במספר טלפון 1-(855)-622-8748."
    },
    it_IT: {
      header: "Appuntamento già prenotato",
      subHeader: "Ha già prenotato un appuntamento per questo studio e per questo centro.",
      content: "Dovrebbe aver ricevuto una e-mail di conferma con i dettagli dell'appuntamento.",
      contact: "Se ha delle domande, contatti il nostro team al numero 1-(855)-622-8748"
    },
    ro_RO: {
      header: "Vizita a fost deja programată",
      subHeader: "Ați programat deja o vizită pentru acest studiu și centru.",
      content: "Ar fi trebuit să primiți un e-mail de confirmare cu detaliile vizitei.",
      contact: "Dacă aveți întrebări, contactați echipa noastră la numărul de telefon 1-(855)-622-8748"
    },
    pt_PT: {
      header: "Consulta já agendada",
      subHeader: "Já agendou uma consulta para este estudo neste centro.",
      content: "Deverá ter recebido uma confirmação por email com as informações da consulta.",
      contact: "Se tiver alguma questão, contacte a nossa equipa pelo 1-(855)-622-8748",
    },
    ar_IL: {
      header: "تم حجز الموعد بالفعل",
      subHeader: "لقد حجزت بالفعل موعدًا لهذه الدراسة والموقع.",
      content: "يجب أن تكون قد تلقيت رسالة تأكيد بالبريد الإلكتروني تحتوي على تفاصيل الموعد.",
      contact: "إذا كانت لديك أي أسئلة ، فيرجى الاتصال بفريقنا على 1- (855) -622-8748"
    },
    hr_HR: {
      header: "Sastanak je već rezerviran",
      subHeader: "Već ste rezervirali sastanak za ovu studiju i centar.",
      content: "Trebali ste dobiti poruku e-pošte s potvrdom o detaljima sastanka.",
      contact: "Ako imate bilo kakva pitanja, kontaktirajte naš tim na 1-(855)-622-8748",
    },
    el_GR: {
      header: "Το ραντεβού είναι ήδη κλεισμένο",
      subHeader: "Έχετε ήδη κλείσει ραντεβού για αυτή τη μελέτη και αυτό το κέντρο.",
      content: "Πρέπει να έχετε λάβει ένα email επιβεβαίωσης με τα στοιχεία του ραντεβού.",
      contact: "Εάν έχετε ερωτήσεις, επικοινωνήστε με την ομάδα μας στο 1-(855)-622-8748",
    },
    fl_FL: {
      header: "Vastaanottoaika on jo varattu",
      subHeader: "Olet jo varannut vastaanottoajan tähän tutkimukseen ja tutkimuskeskukseen.",
      content: "Sinun olisi pitänyt saada sähköpostiisi vahvistus, jossa on vastaanottoajan tiedot.",
      contact: "Jos sinulla on kysyttävää, ota yhteyttä tiimiimme numeroon 1-(855)-622-8748."
    },
    et_EE: {
      header: "Visiit on juba broneeritud",
      subHeader: "Olete selle uuringu ja koha jaoks juba aja broneerinud.",
      content: "Oleksite pidanud saama kinnitusmeili visiidi üksikasjadega.",
      contact: "Kui teil on küsimusi, võtke ühendust meie meeskonnaga numbril 1-(855)-622-8748"
    }
  }
  // revision changed is in language strings because that object can be overwritten if strings are updated in the bi
};

const telephoneValidationStrings = {
  lt_LT: ["Įveskite tinkamą skaičių.", "Netinkamas šalies kodas.", "Per trumpas numeris.", "Per ilgas numeris", "Įveskite tinkamą skaičių."],
  en_US: ["Please enter a valid number.", "Invalid country code.", "Number is too short.", "Number is too long.", "Please enter a valid number."],
  en_GB: ["Please enter a valid number.", "Invalid country code.", "Number is too short.", "Number is too long.", "Please enter a valid number."],
  en_IE: ["Please enter a valid number.", "Invalid country code.", "Number is too short.", "Number is too long.", "Please enter a valid number."],
  sk_SK: ["Zadajte platné číslo.", "Neplatný kód krajiny.", "Číslo je príliš krátke.", "Číslo je príliš dlhé.", "Zadajte platné číslo."],
  de_DE: ["Bitte eine gültige Anzahl eingeben.", "Bitte eine gültige Anzahl eingeben.", "Bitte eine gültige Anzahl eingeben.", "Bitte eine gültige Anzahl eingeben.", "Bitte eine gültige Anzahl eingeben."],
  pl_PL: ["Wprowadź poprawną wartość liczbową.", "Wprowadź poprawną wartość liczbową.", "Wprowadź poprawną wartość liczbową.", "Wprowadź poprawną wartość liczbową.", "Wprowadź poprawną wartość liczbową."],
  en_ZA: ["Please enter a valid number.", "Invalid country code.", "Number is too short.", "Number is too long.", "Please enter a valid number."],
  af_ZA: ["Voer asseblief 'n geldige nommer in.", "Voer asseblief 'n geldige nommer in.", "Voer asseblief 'n geldige nommer in.", "Voer asseblief 'n geldige nommer in.", "Voer asseblief 'n geldige nommer in."],
  zu_ZA: ["Sicela ufake inombolo efanele.", "Sicela ufake inombolo efanele.", "Sicela ufake inombolo efanele.", "Sicela ufake inombolo efanele.", "Sicela ufake inombolo efanele."],
  hu_HU: ["Érvényes számot adjon meg.", "Érvényes számot adjon meg.", "Érvényes számot adjon meg.", "Érvényes számot adjon meg.", "Érvényes számot adjon meg."],
  uk_UA: ["Будь ласка, вкажіть дійсний номер.", "Будь ласка, вкажіть дійсний номер.", "Будь ласка, вкажіть дійсний номер.", "Будь ласка, вкажіть дійсний номер."],
  cs_CZ: ["Hodnota musí být platné číslo.", "Hodnota musí být platné číslo.", "Hodnota musí být platné číslo.", "Hodnota musí být platné číslo.", "Hodnota musí být platné číslo."],
  bg_BG: ["Въведете валидно число.", "Въведете валидно число.", "Въведете валидно число.", "Въведете валидно число.", "Въведете валидно число."],
  sv_SE: ["Var vänlig skriv in ett giltigt nummer.", "Ogiltig landskod.", "Antalet är för kort.", "Antalet är för långt.", "Var vänlig skriv in ett giltigt nummer."],
  es_ES: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  es_CL: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  es_MX: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  es_US: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  es_CO: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  es_AR: ["Por favor ingrese un número valido.", "Código de país no válido", "El número es demasiado corto", "El número es demasiado largo", "Por favor ingrese un número valido."],
  ru_UA: ["Пожалуйста введите правильное число.", "Неверный код страны.", "Номер слишком короткий.", "Номер слишком длинный.", "Пожалуйста введите правильное число."],
  ru_LT: ["Пожалуйста введите правильное число.", "Неверный код страны.", "Номер слишком короткий.", "Номер слишком длинный.", "Пожалуйста введите правильное число."],
  ru_IL: ["Пожалуйста введите правильное число.", "Неверный код страны.", "Номер слишком короткий.", "Номер слишком длинный.", "Пожалуйста введите правильное число."],
  ru_LV: ["Пожалуйста введите правильное число.", "Неверный код страны.", "Номер слишком короткий.", "Номер слишком длинный.", "Пожалуйста введите правильное число."],
  lv_LV: ["Lūdzu, ievadiet derīgu numuru.", "Nederīgs valsts kods.", "Numurs ir pārāk īss.", "Numurs ir pārāk garš.", "Lūdzu, ievadiet derīgu numuru."],
  ja_JP: ["有効な数値を入力してください。", "無効な国コードです。", "数値が短すぎます。", "数値が長すぎます。", "有効な数値を入力してください。"],
  fr_CA: ["S'il vous plait, entrez un nombre valide.", "Code de pays non valide.", "Le nombre est trop court.", "Le nombre est trop long.", "S'il vous plait, entrez un nombre valide."],
  nl_NL: ["Gelieve een geldig nummer invoeren.", "Ongeldige landcode.", "Nummer is te kort.", "Nummer is te lang.", "Gelieve een geldig nummer invoeren."],
  da_DK: ["Indtast et gyldigt nummer.", "Ugyldig landekode.", "Nummeret er for kort.", "Nummeret er for langt.", "Indtast et gyldigt nummer."],
  it_IT: ["Codice paese non valido.", "Codice paese non valido.", "Il numero è troppo breve.", "Il numero è troppo lungo.", "Per favore, inserire un numero valido."],
  pt_PT: ["Por favor insira um número válido.", "Código de país inválido.", "O número é muito curto.", "O número é muito longo.", "Por favor insira um número válido."],
  pt_BR: ["Por favor insira um número válido.", "Código de país inválido.", "O número é muito curto.", "O número é muito longo.", "Por favor insira um número válido."],
  ar_IL: ["من فضلك أدخل رقما صالحا.", "رمز الدولة غير صحيح.", "الرقم قصير جدًا.", "الرقم طويل جدًا.", "من فضلك أدخل رقما صالحا."],
  iw_IL: ["נא הכנס מספר תקף.", "קוד מדינה לא חוקי.", "המספר קצר מדי.", "המספר ארוך מדי.", "נא הכנס מספר תקף."],
  ro_RO: ["Vă rugăm să introduceți un număr valid.", "Cod de țară nevalid.", "Numărul este prea scurt.", "Numărul este prea lung.", "Vă rugăm să introduceți un număr valid."],
  de_AT: ["Bitte geben Sie eine gültige Nummer ein.", "Ungültiger Ländercode.", "Nummer ist zu kurz.", "Nummer ist zu lang.", "Bitte geben Sie eine gültige Nummer ein."],
  hr_HR: ["Unesite valjani broj.", "Nevažeći pozivni broj države.", "Broj je prekratak.", "Broj je predug.", "Unesite valjani broj."],
  el_GR: ["Παρακαλώ εισάγετε έναν έγκυρο αριθμό.", "Μη έγκυρος κωδικός χώρας.", "Ο αριθμός είναι πολύ μικρός.", "Ο αριθμός είναι πολύ μεγάλος.", "Παρακαλώ εισάγετε έναν έγκυρο αριθμό."],
  fi_FI: ["Virheellinen numero.", "Virheellinen maakoodi.", "Numero on liian lyhyt. ", "Numero on liian pitkä. ", "Virheellinen numero."],
  et_EE: ["Sisestage kehtiv arv.", "Vale riigikood.", "Number on liiga lühike.", "Number on liiga pikk.", "Sisestage kehtiv arv."]
};

const tailSelectStrings = {
  en_GB: {
    all: "All",
    none: "None",
    actionAll: "Select All",
    actionNone: "Unselect All",
    empty: "No options available",  //no options content after seaching
    emptySearch: "No options found",
    limit: "You can't select more Options",
    placeholder: "Select an Option...",
    placeholderMulti: "Select up to :limit Options...",
    search: "Search...",  //default content within the search box
    disabled: "This Field is disabled"
  },
  en_IE: {
    all: "All",
    none: "None",
    actionAll: "Select All",
    actionNone: "Unselect All",
    empty: "No options available",  //no options content after seaching
    emptySearch: "No options found",
    limit: "You can't select more Options",
    placeholder: "Select an Option...",
    placeholderMulti: "Select up to :limit Options...",
    search: "Search...",  //default content within the search box
    disabled: "This Field is disabled"
  },
  en_US: {
    all: "All",
    none: "None",
    actionAll: "Select All",
    actionNone: "Unselect All",
    empty: "No options available",
    emptySearch: "No options found",
    limit: "You can't select more Options",
    placeholder: "Select an Option...",
    placeholderMulti: "Select up to :limit Options...",
    search: "Search...",
    disabled: "This Field is disabled"
  },
  pl_PL: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Brak dostępnych opcji",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Szukaj",
    disabled: ""
  },
  cs_CZ: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Nejsou k dispozici žádné možnosti",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Vyhledávání",
    disabled: ""
  },
  cs_CZ: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Nejsou k dispozici žádné možnosti",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Vyhledávání",
    disabled: ""
  },
  bg_BG: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Няма възможни опции",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Търсене",
    disabled: ""
  },
  hu_HU: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Nincs elérhető lehetőség",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Keresés",
    disabled: ""
  },
  af_ZA: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Geen opsie beskikbaar nie",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Soek",
    disabled: ""
  },
  de_DE: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Keine Optionen verfügbar",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Suchen",
    disabled: ""
  },
  uk_UA: {
    all: "",
    none: "",
    actionAll: "",
    actionNone: "",
    empty: "Немає варіантів",
    emptySearch: "",
    limit: "",
    placeholder: "",
    placeholderMulti: "",
    search: "Пошук",
    disabled: ""
  },
  de_AT: {
    all: "Alle",
    none: "Keiner",
    actionAll: "Wählen Sie Alle",
    actionNone: "Alles wiederufen",
    empty: "Keine Optionen verfügbar",
    emptySearch: "Keine Optionen gefunden",
    limit: "Sie können keine weiteren Optionen auswählen",
    placeholder: "Wähle eine Option...",
    placeholderMulti: "Wählen Sie bis zu: Optionen einschränken...",
    search: "Suche...",
    disabled: "Dieses Feld ist deaktiviert"
  },
  hr_HR: {
    all: "Svi",
    none: "Nijedna",
    actionAll: "Odaberi sve",
    actionNone: "Poništi odabir Sve",
    empty: "Nema dostupnih opcija",
    emptySearch: "Nije pronađena nijedna opcija",
    limit: "Ne možete odabrati više opcija",
    placeholder: "Odaberite opciju...",
    placeholderMulti: "Odaberite do: ograničite Opcije...",
    search: "Traži...",
    disabled: "Ovo je polje onemogućeno"
  },
  pt_PT: {
    all: "Tudo",
    none: "Nenhum",
    actionAll: "Selecionar tudo",
    actionNone: "Desmarque todos",
    empty: "Sem opções disponíveis",
    emptySearch: "Nenhuma opção encontrada",
    limit: "Você não pode selecionar mais opções",
    placeholder: "Selecione uma opção...",
    placeholderMulti: "Selecione até: Opções de limite...",
    search: "Procurar...",
    disabled: "Este campo está desabilitado"
  },
  pt_BR: {
    all: "Tudo",
    none: "Nenhum",
    actionAll: "Selecionar tudo",
    actionNone: "Desmarque todos",
    empty: "Sem opções disponíveis",
    emptySearch: "Nenhuma opção encontrada",
    limit: "Você não pode selecionar mais opções",
    placeholder: "Selecione uma opção...",
    placeholderMulti: "Selecione até: Opções de limite...",
    search: "Procurar...",
    disabled: "Este campo está desabilitado"
  },
  el_GR: {
    all: "Ολα",
    none: "Κανένας",
    actionAll: "Επιλογή όλων",
    actionNone: "Αποεπιλογή όλων",
    empty: "Δεν υπάρχουν διαθέσιμες επιλογές",
    emptySearch: "Δεν βρέθηκαν επιλογές",
    limit: "Δεν μπορείτε να ορίσετε περισσότερες επιλογές",
    placeholder: "Επιλέξτε μια επιλογή. . .",
    placeholderMulti: "Επιλέξτε έως: όριο Επιλογές. . .",
    search: "Αναζήτηση. . .",
    disabled: "Αυτό το πεδίο είναι απενεργοποιημένο"
  },
  fi_FI: {
    all: "Kaikki",
    none: "Ei valintoja",
    actionAll: "Valitse kaikki",
    actionNone: "Poista valinnat",
    empty: "Ei vaihtoehtoja",
    emptySearch: "Vaihtoehtoja ei löytynyt",
    limit: "Et voi tehdä enempää valintoja ",
    placeholder: "Valitse vaihtoehto…",
    placeholderMulti: "Valitse enintään :limit vaihtoehtoa …",
    search: "Hae..",
    disabled: "Tätä kenttää ei ole mahdollista täyttää. "
  },
  et_EE: {
    all: "Kõik",
    none: "Mitte ühtegi",
    actionAll: "Vali kõik",
    actionNone: "Tühista valik Kõik",
    empty: "No options available",
    emptySearch: "Ühtegi valikut ei leitud",
    limit: "Te ei saa rohkem valikuid valida",
    placeholder: "Valige suvand...",
    placeholderMulti: "Valige kuni :limit Valikud...",
    search: "Otsing...",
    disabled: "See väli on keelatud"
  },
  es_CL: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
  es_ES: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
  es_MX: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
  es_US: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
  es_CO: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
  es_AR: {
    all: " Todo ",
    none: " Ninguno ",
    actionAll: " Seleccionar todo ",
    actionNone: " Deselecciona todo ",
    empty: " No hay opciones disponibles ",
    emptySearch: " No se encontraron opciones ",
    limit: " No puedes seleccionar más opciones ",
    placeholder: " Seleccione una opción...",
    placeholderMulti: " Seleccione hasta :limitar opciones...",
    search: " Buscar...",
    disabled: " Este campo está deshabilitado "
  },
};



// the search icon used in the ss help
const ssInstructionsSearchIcon = `<div class="ss-instructions-inline-icon-search">
                                    <div class="ss-inline-icon-inner">
                                      <i class="fas fa-check"></i>
                                    </div>
                                  </div>`;

const ssInstructionsResetIcon = `<div class="ss-instructions-inline-icon">
                                    <div class="ss-inline-icon-inner">
                                      <i class="fas fa-redo-alt"></i>
                                    </div>
                                  </div>`;